<template>
  <div></div>
</template>

<script setup>
import Swal from 'sweetalert2';

Swal.fire(
  '이미 가입되어 있습니다.',
  `${window.location.pathname
    .toString()
    .replace('/exist-google-id/', '')}로 이미 가입되어 있습니다.`,
  'warning'
).then(() => {
  window.location.href =
    process.env.VUE_APP_NODE_STATUS === 'production'
      ? process.env.VUE_APP_FRONT_PROD_HOST
      : process.env.VUE_APP_FRONT_DEV_HOST;
});
</script>
<script>
export default {
  name: 'ExistGoogleId',
};
</script>

<style></style>
