<template>
  <div>
    <Dialog
      v-model:visible="store.state.bomManageGetdata.bomCopyState"
      :style="{ width: '450px' }"
      header="BOM 이동/복사"
      :modal="true"
      @hide="closeCopyModal"
    >
      <div class="confirmation-content flex flex-wrap align-items-center">
        <i class="pi pi-info-circle mr-2" style="font-size: 2rem" />
        <span
          ><b class="text-red-600">기존 Target 의사에 등록된 BOM이 삭제</b>됩니다.</span
        >
        <span class="vertical-align-text-bottom"> 계속하시겠습니까? </span>
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeCopyModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="copyExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
const store = useStore();
const isSubmitted = ref(false);

watchEffect(() => {
  isSubmitted.value = store.state.bomManageGetdata.bomCopyState;
});

// 이동/복사 확인 모달 닫을 시, 이벤트
function closeCopyModal() {
  store.commit('bomCopyStateCommit', false);
}

// 이동/복사 진행 시, 이벤트
function copyExecution() {
  if (isSubmitted.value) {
    store.dispatch('copyBomToDoctor');
  } else {
    Swal.fire({
      icon: 'warning',
      title: '에러',
      text: '예상치 못한 에러가 발생하였습니다.',
    });
  }
}
</script>
<script>
export default {
  name: 'BomCopyDialog',
};
</script>

<style></style>
