<template>
  <div class="border-bottom-1 border-400 relative pb-3">
    <h4 class="mb-2">티케팅 내역</h4>
    <div class="absolute right-0" style="top: -8px">
      <Button label="상품선택" class="p-button-outlined p-button-help p-button-sm mr-2" @click="store.commit('productStateCommit', true)" v-if="authData()"/>
      <Button label="전체선택" class="p-button-outlined p-button-secondary p-button-sm mr-2" @click="allCheck"/>
      <Button label="결제대기" class="p-button-outlined p-button-sm" @click="waitingExecution"/>
    </div>
  </div>
  <div class="border-bottom-1 border-400 relative pb-3 mt-3 flex">
    <div class="col-6">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-chart-line"></i>
        </span>
        <div class="flex justify-content-center align-items-center input-group-div">
          {{ topView().count ? topView().count + '건' : '' }}
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"> ₩ </span>
        <div class="flex justify-content-center align-items-center input-group-div">
          {{  topView().sum}}
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <div class="grid">
      <div class="col-4">
        <Dropdown
          :options="paymentMethod"
          optionLabel="name"
          optionValue="name"
          placeholder="결제방법"
          class="w-full"
          v-model="v$.payment_type.$model"
          :class="{
            'p-invalid': v$.payment_type.$invalid && ticketingSummit,
          }"
        />
      </div>
      <div class="col-4">
        <Dropdown
          :options="paymentSort"
          optionLabel="name"
          optionValue="name"
          placeholder="수납구분"
          class="w-full"
          v-model="v$.payment_sort.$model"
          :class="{
            'p-invalid': v$.payment_sort.$invalid && ticketingSummit,
          }"
        />
      </div>
      <div class="col-4">
        <span class="p-float-label" style="width: -webkit-fill-available">
          <InputNumber
            id="inputnumber"
            style="width: inherit"
            :inputStyle="{ width: 'inherit' }"
            :disabled="labelSum ? false : true"
            v-model="v$.payment_price.$model"
            :class="{
              'p-invalid': v$.payment_price.$invalid && ticketingSummit,
            }"
          />
          <label for="inputnumber">{{labelSum}}</label>
        </span>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <Card class="relative mb-3" v-for="a,i in initData" :key="i" v-show="a.total_price > 0" :class="{'bg-yellow-50': !a.counsel_state }">
      <template #title> {{a.concat}} </template>
      <template #content>
        <div v-if="a.menu_id_before_change" class="absolute" style="top: -0.6rem; left: -0.8rem">
          <Badge 
            :value="a.menu_id === a.menu_id_before_change ? '변경 전' : '변경 후'" 
            class="mr-2" 
            style="font-size: 13px"
            :severity="a.menu_id === a.menu_id_before_change ? 'warning' : 'danger'"
          >
          </Badge>
        </div>
        <div class="absolute flex" style="bottom: 4%; right: 1%">
          <Button 
            style="height: 10px; width: 6rem; font-size: 12px; margin-right: 10px"
            icon="pi pi-times"
            class="p-button-danger"
            label="삭제"
            v-if="!a.counsel_state && !a.misoo && a.sales_price === a.total_price" 
            @click="noCounselProductDelete(a)"
          />
          <MisooComponent :data="a" @reloadPrice="reloadPrice"></MisooComponent>
        </div>
        <div class="grid mb-2">
          <div class="col-2 surface-200 text-center">상담일자</div>
          <div class="col-2 text-center">{{a.counseling_date}}</div>
          <div class="col-2 surface-200 text-center">할인구분</div>
          <div class="col-2 text-center">{{a.discount_name}}</div>
          <div class="col-2 surface-200 text-center">할인율</div>
          <div class="col-2 text-center">{{a.discount_rate}}%</div>
        </div>
        <small>{{a.medical_menu_content}}</small><small v-if="a.medical_menu_content"> / </small><small>{{a.note}}</small>
        <div
          class="absolute flex justify-content-center align-itmes-center border-1 border-blue-700 border-round-lg p-2"
          style="top: 6%; right: 1%"
        >
          <label for="binary" class="mr-2">{{a.total_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}}</label>
          <Checkbox id="binary" :binary="true" v-model="a.checkbox" @click="sumInput(a.total_price, i)" :disabled="a.misoo" />
        </div>
      </template>
    </Card>
  </div>

  <div>
    <TicketingProductDialog></TicketingProductDialog>
  </div>
</template>

<script setup>
import { ref, watchEffect, getCurrentInstance, reactive } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash'
import { required, minValue,   } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Swal from 'sweetalert2';
import MisooComponent from './MisooComponent.vue';
import TicketingProductDialog from './TicketingProductDialog.vue';


const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const store = useStore();
const paymentMethod = ref([
  { name: '카드결제' },
  { name: '현금결제' },
  { name: '바코드결제' },
]);
const paymentSort = ref([{ name: '예약금' }, { name: '잔금' }]);
const initDataCompare = ref([]);
const initData = ref([]);
const labelSum = ref(0);
const ticketingSummit = ref(false);
const checkValidate = ref(false);

const sendData = reactive({
  payment_type: '',
  payment_sort: '',
  payment_price: 0,
})

const maxs = () => sendData.payment_price > _.sumBy(initData.value, (a) => {return a.checkbox === true ? a.total_price : 0}) ? false : true;

const rules = {
    payment_type: { required },
    payment_sort: { required },
    payment_price: { required, minValue: minValue(1), maxs},
}

const v$ = useVuelidate(rules, sendData);

watchEffect(() => {
  const oldValue = initDataCompare.value;
  const newValue = store.state.paymentStore.paymentInitTicketingData;
  if (!_.isEqual([...oldValue], [...newValue ])) {

    initDataCompare.value = _.cloneDeep(store.state.paymentStore.paymentInitTicketingData)
    initData.value = _.cloneDeep(store.state.paymentStore.paymentInitTicketingData)
  }
  labelSum.value = $wonCurrency(_.sumBy(initData.value, (a) => {return a.checkbox === true ? a.total_price : 0}))
});

function authData() {
  const auth = store.state.userAuthData;

  if (auth.includes('000') || auth.includes('113')) {
    return true;
  } else {
    return false;
  }
}

const topView = () => {
  const sum = $wonCurrency(_.sumBy(_.filter(initData.value, (a) => !a.misoo), 'total_price'))
  const count = _.filter(initData.value, (a) => a.total_price && !a.misoo).length

  return {
    sum,
    count
  }
}

const allCheck = _.debounce(() => {  
  if (checkValidate.value == true) {
    _.forEach(initData.value, (a) => {
      a.checkbox = false
      sendData.payment_price = 0
    })
    checkValidate.value = false;
  } else if (!_.every(initData.value, 'checkbox')) {
    _.forEach(initData.value, (a) => {
      if(a.misoo == false) {
        a.checkbox = true
      }
      sendData.payment_price = _.sumBy(initData.value, (a) => {return a.checkbox === true ? a.total_price : 0})
    })
    checkValidate.value = true;
  } else {
    _.forEach(initData.value, (a) => {
      a.checkbox = false
      sendData.payment_price = 0
    })
  }
},250)

const sumInput =  (a, i) => {
  if(!initData.value[i].checkbox) {
    sendData.payment_price = sendData.payment_price + a
  } else {
    sendData.payment_price = sendData.payment_price - a < 0 ? 0 : sendData.payment_price - a 
  }
}

const reloadPrice = () => {
  sendData.payment_price = 0;
}

const paymentSeparation = () => {
  const total = _.sumBy(initData.value, (a) => {return a.checkbox === true ? a.total_price : 0})
  const cloneData = _.cloneDeep(_.filter(initData.value,(a) => a.checkbox))

  _.forEach(cloneData, (a) => {
    a.seperate_price = _.round(a.total_price/total*sendData.payment_price)    
  })

  const seperateSum = _.sumBy(cloneData, 'seperate_price');
  const sendDataSum = sendData.payment_price

  if(!_.isEqual(seperateSum, sendDataSum)) {
    const max = _.maxBy(cloneData, 'seperate_price')
    const min = _.minBy(cloneData, 'seperate_price')

    if (seperateSum > sendDataSum) {
      const index = _.findIndex(cloneData, ['seperate_price', max.seperate_price])
      cloneData[index].seperate_price = cloneData[index].seperate_price - (seperateSum - sendDataSum)
    } else {
      const index = _.findIndex(cloneData, ['seperate_price', min.seperate_price])
      cloneData[index].seperate_price = cloneData[index].seperate_price + (sendDataSum - seperateSum)
    }
  }

  return cloneData
}

const waitingExecution = _.debounce(async () => {
  ticketingSummit.value = true
  
  if(!v$.value.$invalid) {
    const payload = {
      waiting: _.cloneDeep(sendData),
      group: paymentSeparation()
    }
    
    await store.dispatch('paymentWaiting', payload)
    Swal.fire('결제대기로 등록 완료되었습니다.','','success').then(() => {
      sendData.payment_price = 0
      sendData.payment_sort = ''
      sendData.payment_type = ''
      ticketingSummit.value = false

    })
  }
},250)

const noCounselProductDelete = _.debounce(noCounselProductDeleteExecute, 250)
function noCounselProductDeleteExecute(data) {
  Swal.fire({
    icon: 'info',
    title: '해당 상품을 삭제하시겠습니까?',
    showDenyButton: true,
    confirmButtonText: '네',
    denyButtonText: '아니오',
  }).then(async (result) => {
    if (result.isConfirmed) {

      await store.dispatch('paymentProductDelete', data.counseling_id)
    }
  });
}
</script>
<script>
export default {
  name: 'TIcketingSection',
};
</script>

<style scoped lang="scss">
.input-group-div {
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0;
  margin: 0;
  flex: 1 1 auto;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 10px;
  left: auto;
  right: 5%;
}

.p-float-label > label {
  left: auto;
  right: 5%;
}

::v-deep(.p-card .p-card-title) {
  width: 65%;
  font-size: 15px !important;
}

::v-deep(.p-card .p-card-body) {
  padding-bottom: 0;
}

::v-deep(.p-card-content) {
  padding: 2.25rem 0 !important;
}

::v-deep(.p-badge.p-badge-lg) {
  font-size: 12px;
}
</style>
