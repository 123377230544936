import _ from 'lodash';
import { formatDate } from '../../util/date';

const boardStore = {
  state() {
    return {
      boardModal: {
        boardModalState: false,
        boardSendCutomerCode: null,
        boardSendDate: null,
        boardReceptionCode: null,
        boardSendCutomerName: null,
        boardSendCutomerMarketingLabel: null,
        specailDay1: [],
        specailDay2: [],
        frontBoardDate: null,
      },
      customerData: {},
      customerRecommendData: [],
      sidebarValue: '',
      boardInitDataCompare: '',
      boardInitSort: '',
      sortState: null,
      boardDataIs: true,
      boardLoading: false,
    };
  },
  mutations: {
    boardModalStateChange(state) {
      state.boardModal.boardModalState = !state.boardModal.boardModalState;
    },
    boardModalStateFalse(state) {
      state.boardModal.boardModalState = false;
    },
    boardCustomerDataInput(state, payload) {
      state.boardModal.boardSendCutomerCode = payload.customer_id;
      state.boardModal.boardSendCutomerName = payload.customer_name;
      state.boardModal.boardSendCutomerMarketingLabel = payload.marketing_label
        ? payload.marketing_label
        : null;
    },
    boardDateDataInput(state, payload) {
      state.boardModal.boardSendDate = _.cloneDeep(payload.selectedDate);
      state.boardDataIs = formatDate('date') === payload.selectedDate;
    },
    boardReciptionMutation(state, payload) {
      state.boardModal.boardReceptionCode = payload;
    },
    boardSpecialDay1(state, payload) {
      state.boardModal.specailDay1 = payload;
    },
    boardSpecialDay2(state, payload) {
      state.boardModal.specailDay2 = payload;
    },
    boardSideBarValue(state, payload) {
      state.sidebarValue = payload;
    },
    boardSortStateCommit(state, payload) {
      state.sortState = payload;
    },
    boardInitSortCommit(state, payload) {
      state.boardInitSort = payload;
    },
    boardFrontDateCommit(state, payload) {
      state.boardModal.frontBoardDate = payload;
    },
    boardLoadingCommit(state, payload) {
      state.boardLoading = payload;
    },
  },
  getters: {
    getBoardModalCustomerId(state) {
      return state.boardModal.boardSendCutomerCode;
    },
    getBoardModalReceptionId(state) {
      return state.boardModal.boardReceptionCode;
    },
    getBoardModalDate(state) {
      return state.boardModal.boardSendDate;
    },
  },
};

export default boardStore;
