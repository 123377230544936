import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/pages/LoginPage.vue';
import BoardPage from '@/pages/BoardPage.vue';
import EmailVerify from '@/components/login/EmailVerify.vue';
import GenerateId from '@/components/login/GenerateId.vue';
import ChangePass from '@/components/login/ChangePass.vue';
import SoogaManagePage from '@/pages/SoogaManagePage.vue';
import BomManagePage from '@/pages/BomManagePage.vue';
import DoctorManagePage from '@/pages/DoctorManagePage.vue';
import PurchasePage from '@/pages/PurchasePage.vue';
import ExcelPage from '@/pages/ExcelPage.vue';
import MyPage from '@/pages/MyPage.vue';
import AuthPage from '@/pages/AuthPage.vue';
import ExistGoogleId from '@/components/login/ExistGoogleId.vue';
import PurchaseManageComponent from '@/components/purchase/PurchaseManageComponent.vue';
import PurchaseExcelComponent from '@/components/purchase/PurchaseExcelComponent.vue';
import ReserveDataVue from '@/components/excel/ReserveData.vue';
import marketingDataVue from '@/components/excel/MarketingData.vue';

const routes = [
  {
    path: '/',
    name: 'first',
    component: LoginPage,
  },
  {
    path: '/exist-google-id/:id',
    name: 'exist-google-id',
    component: ExistGoogleId,
  },
  {
    path: '/status-board',
    name: 'status-board',
    component: BoardPage,
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: EmailVerify,
  },
  {
    path: '/social-verify-modal',
    name: 'social-verify',
    component: GenerateId,
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePass,
  },
  { path: '/sooga-manage', name: 'sooga-manage', component: SoogaManagePage },
  { path: '/bom-manage', name: 'bom-manage', component: BomManagePage },
  { path: '/doctor-manage', name: 'doctor-manage', component: DoctorManagePage },
  {
    path: '/purchase',
    name: 'purchase',
    component: PurchasePage,
    children: [
      {
        path: 'manage',
        component: PurchaseManageComponent,
      },
      {
        path: 'excel',
        component: PurchaseExcelComponent,
      },
    ],
  },
  { path: '/reserve-excel', name: 'reserve-excel', component: ExcelPage },
  { path: '/purchase-manage', name: 'purchase-manage', component: PurchasePage },
  {
    path: '/excel',
    component: ExcelPage,
    name: 'excel',
    children: [
      { path: 'reserve', component: ReserveDataVue },
      { path: 'marketing', component: marketingDataVue },
    ],
  },
  { path: '/my-page', name: 'my-page', component: MyPage },
  { path: '/auth-manage', name: 'auth-manage', component: AuthPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
