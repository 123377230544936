<template>
  <Dialog
    :header="
      is.every((a) => !a)
        ? '아이디 찾기 / 비밀번호 변경'
        : is[0]
        ? '아이디 찾기'
        : '비밀번호 변경'
    "
    :style="{ width: '50vw' }"
    position="center"
    :closeOnEscape="false"
    v-model:visible="$store.state.login.findMyId"
    :closable="true"
    :modal="true"
    :baseZIndex="1000"
    :autoZIndex="false"
    @hide="init()"
  >
    <div class="flex flex-column" v-if="is.every((a) => !a)">
      <Button
        @click="is[0] = true"
        label="아이디 찾기"
        class="p-button-outlined p-button-secondary mb-3"
      />
      <Button
        @click="is[1] = true"
        label="비밀번호 변경"
        class="p-button-outlined p-button-secondary"
      />
    </div>
    <form @submit.prevent="findIdSummit = true" v-if="is[0]">
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 이름 </span>
        <InputText
          placeholder="한글로만 기재해주세요."
          v-model="v$.username.$model"
          :class="{
            'p-invalid': v$.username.$invalid && findIdSummit,
          }"
          name="name"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 생년월일 </span>
        <Calendar
          id="date"
          v-model="v$.birth.$model"
          :class="{
            'p-invalid': v$.birth.$invalid && findIdSummit,
          }"
          :showIcon="true"
          dateFormat="yy-mm-dd"
          name="birth"
        />
      </div>
      <div class="p-inputgroup mb-3">
        <span class="p-inputgroup-addon w-7rem text-sm"> 핸드폰번호 </span>
        <InputMask
          placeholder="핸드폰번호"
          v-model="v$.phone.$model"
          :class="{
            'p-invalid': v$.phone.$invalid && findIdSummit,
          }"
          name="phone"
          mask="999-9999-9999"
        />
      </div>
      <div class="flex justify-content-end">
        <Button @click="init()" label="취소" class="p-button-danger mr-2" />
        <Button @click="findMyIdExcution" type="submit" label="확인" />
      </div>
    </form>
    <form @submit.prevent="changePassSummit = true" v-if="is[1]">
      <div class="p-inputgroup mb-3">
        <span class="p-inputgroup-addon w-7rem text-sm"> email </span>
        <InputText
          placeholder="email"
          v-model="v$.email.$model"
          :class="{
            'p-invalid': v$.email.$invalid && changePassSummit,
          }"
          name="email"
        />
      </div>
      <div class="flex justify-content-end">
        <Button @click="init()" label="취소" class="p-button-danger mr-2" />
        <Button @click="changePassExcution" type="submit" label="확인" />
      </div>
    </form>
  </Dialog>
</template>

<script setup>
import { required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import _ from 'lodash';
// import axios from 'axios';
import Swal from 'sweetalert2';
import { reactive, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const store = useStore();
const phoneReg = (value) => /^01([0|1|6|7|8|9])-([0-9]{4})-([0-9]{4})$/.test(value);
const state = reactive({
  username: '',
  birth: '',
  phone: '',
  email: '',
});
const rules = {
  username: {
    required,
  },
  birth: {
    required,
  },
  phone: {
    required,
    phoneReg,
  },
  email: {
    required,
    email,
  },
};
const v$ = useVuelidate(rules, state);

const findIdSummit = ref(false);
const changePassSummit = ref(false);
const is = ref([false, false]);

const findMyIdExcution = _.debounce(async () => {
  try {
    const validation = [
      v$.value.username.$invalid,
      v$.value.birth.$invalid,
      v$.value.phone.$invalid,
    ];
    const sendData = {
      username: state.username,
      birth: state.birth ? $formatDate('date', state.birth) : '',
      phone: state.phone,
    };

    if (validation.every((a) => !a)) {
      store.commit('loadingChangeValue', true);
      const result = await store.dispatch('loginFindMyId', sendData);

      if (result.data.is) {
        Swal.fire('찾으신 아이디는 아래와 같습니다.', result.data.email, 'success').then(
          () => {
            init();
          }
        );
      } else {
        Swal.fire('해당 정보로 이메일을 찾을 수 없습니다.', '', 'error');
      }
      store.commit('loadingChangeValue', false);
    }
  } catch {
    return;
  }
}, 250);

const changePassExcution = _.debounce(async () => {
  try {
    if (!v$.value.email.$invalid) {
      store.commit('loadingChangeValue', true);
      const result = await store.dispatch('loginPassChange', { email: state.email });
      if (result.data.is) {
        Swal.fire(
          '이메일 변경 메일이 전송되었습니다.',
          '메일을 확인해주세요.',
          'success'
        ).then(() => {
          init();
        });
      } else {
        Swal.fire(
          '해당 이메일로 가입되어 있지 않습니다.',
          '메일을 확앤해주세요.',
          'error'
        );
      }
      store.commit('loadingChangeValue', false);
    }
  } catch {
    return;
  }
}, 250);

function init() {
  is.value = [false, false];
  changePassSummit.value = false;
  Object.keys(state).forEach((a) => {
    state[a] = '';
  });
}
</script>

<script>
export default {
  name: 'FindId',
};
</script>

<style scoped></style>
