<template>
  <Toast position="top-right" group="bominput-toast" />

  <div class="relative">
    <div
      @click="backBom"
      class="absolute z-2 cursor-pointer"
      style="right: 3px; top: 3px"
    >
      <i class="pi pi-times"></i>
    </div>
    <DataTable
      :value="fontBomData"
      rowGroupMode="rowspan"
      :groupRowsBy="[
        'sort',
        'counseling_id',
        'menu_id',
        'menu_name',
        'abbreviation',
        'medical_menu_content',
        'note',
        'price_include_vat',
        'sales_price',
        'discount_rate',
        'discount_name',
        'doc_nm',
      ]"
      :sortOrder="1"
      @cell-edit-complete="onCellEditComplete($event)"
      editMode="cell"
      responsiveLayout="scroll"
      id="bomDataTable"
    >
      <template #header>
        <div class="grid">
          <div class="col-6 relative">
            <div class="grid" v-if="fontBomData.length > 0">
              <!-- <div class="col-4 mt-2">
                <div
                  class="flex justify-content-between align-items-center border-bottom-1 surface-border"
                >
                  <div class="text-gray-800 font-medium">총액</div>
                  <div class="text-900">{{ topValue.total }}</div>
                </div>
              </div>
              <div class="col-4 mt-2">
                <div
                  class="flex justify-content-between align-items-center border-bottom-1 surface-border"
                >
                  <div class="text-gray-800 font-medium">티케팅</div>
                  <div class="text-900">{{ topValue.sales }}</div>
                </div>
              </div>
              <div class="col-4 mt-2">
                <div
                  class="flex justify-content-between align-items-center border-bottom-1 surface-border"
                >
                  <div class="text-gray-800 font-medium">할인율</div>
                  <div class="text-900">{{ topValue.discount }}%</div>
                </div>
              </div> -->
              <div class="col-4 mt-2">
                <div
                  class="flex justify-content-between align-items-center border-bottom-1 surface-border"
                >
                  <div class="text-gray-800 font-medium">오염</div>
                  <div class="text-900">{{ topValue.contamination }}개 품목</div>
                </div>
              </div>
              <div class="col-4 mt-2">
                <div
                  class="flex justify-content-between align-items-center border-bottom-1 surface-border"
                >
                  <div class="text-gray-800 font-medium">파손</div>
                  <div class="text-900">{{ topValue.distruction }}개 품목</div>
                </div>
              </div>
              <div class="col-4 mt-2">
                <div
                  class="flex justify-content-between align-items-center border-bottom-1 surface-border"
                >
                  <div class="text-gray-800 font-medium">서비스</div>
                  <div class="text-900">{{ topValue.service }}개 품목</div>
                </div>
              </div>
            </div>
            <div class="absolute bottom-0 left-0 mb-2 w-full">
              <div class="flex justify-content-between">
                <SplitButton
                  id="soogaDoc"
                  :label="docnameis()"
                  :model="categoryItems"
                  class="p-button-sm p-button-raised p-button-text p-button-secondary"
                ></SplitButton>
                <!-- @click="clickDoc($event)" -->
                <div>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon" style="font-size: 12px"
                      >종료시간</span
                    >
                    <Calendar
                      :timeOnly="true"
                      hourFormat="24"
                      placeholder="종료시간입력"
                      @hide="inputBomTime"
                      v-model="endTime"
                      :inputStyle="{ 'text-align': 'center' }"
                      @input="correct($event)"
                    />
                  </div>
                </div>
                <div>
                  <Button
                    label="추가"
                    class="p-button-sm p-button-outlined p-button-warning mr-2"
                    @click="addProduct"
                  />
                  <Button
                    label="저장"
                    class="p-button-sm p-button-outlined"
                    @click="bomSave"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div style="width: 99%">
              <Editor
                @text-change="
                  memoStateChange($event);
                  bomMemoChange($event);
                "
                @selection-change="memoSelection($event)"
                v-if="!memoEditState"
                v-model="bomMemo.memo"
                editorStyle="height: 100px;"
                id="bom-memo"
              >
                <template v-slot:toolbar>
                  <span class="ql-formats">
                    <div>수/시술메모</div>
                  </span>
                </template>
              </Editor>
              <div v-else>
                <Editor
                  v-model="bomMemo.memo"
                  editorStyle="height: 145px;"
                  :readonly="true"
                >
                  <template v-slot:toolbar>
                    <span class="ql-formats">
                      <div class="relative">
                        <div>수/시술메모</div>
                        <Message
                          class="absolute"
                          severity="info"
                          :closable="false"
                          style="
                            top: -21px;
                            right: -178px;
                            width: 172px;
                            height: 31px;
                            display: flex;
                          "
                          >{{ bomMemo.name }} 편집 중</Message
                        >
                      </div>
                    </span>
                  </template>
                </Editor>
              </div>
            </div>
          </div>
        </div>
      </template>
      <Column header="#">
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template>
      </Column>
      <Column field="doc_nm" header="의사이름" headerStyle="color:darkblue">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ data.doc_nm }}
          </div>
        </template>
      </Column>
      <Column field="menu_name" header="구분">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ data.menu_name }}
          </div>
        </template>
      </Column>
      <Column field="abbreviation" header="약어">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ data.abbreviation }}
          </div>
          <!-- <div class="flex justify-content-center align-items-center flex-column">
            <Button
              label="추가"
              class="p-button-sm mb-1"
              style="height: 20px; width: 30px; padding: 0; font-size: 11px"
              @click="addNewProductByMenu($event, index, data)"
            />
          </div> -->
        </template>
      </Column>
      <Column field="medical_menu_content" header="내용">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ data.medical_menu_content }}
          </div>
        </template>
      </Column>
      <Column field="price_include_vat" header="수가가격" headerStyle="color:gray">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ changeCurrency(data.price_include_vat) }}
          </div>
        </template>
      </Column>
      <Column field="sales_price" header="판매가" headerStyle="color:gray">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ changeCurrency(data.sales_price) }}
          </div>
        </template>
      </Column>
      <Column field="discount_rate" header="할인율" headerStyle="color:gray">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{
              data.discount_rate
                ? data.discount_rate + '%'
                : data.discount_rate === 0
                ? '0%'
                : ''
            }}
          </div>
        </template>
      </Column>
      <Column field="discount_name" header="할인및서비스" headerStyle="color:gray">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ data.discount_name ? data.discount_name : '' }}
          </div>
        </template>
      </Column>
      <Column header="품목명" headerStyle="color:indigo">
        <template #body="{ data, index }">
          <div class="flex justify-content-center align-items-center">
            <div v-if="!data?.product_name_is">{{ data.product_name }}</div>
            <div v-else>
              <Dropdown
                v-model="data.product_id"
                :optionLabel="
                  data.activeTab === 1 ? (data) => getBomLabel(data) : 'product_name'
                "
                :optionValue="data.activeTab === 1 ? 'menu_id' : 'product_code'"
                class="product_name_input"
                :options="data.activeTab === 1 ? listBom : productItmes"
                :filter="true"
                :reset-filter-on-hide="true"
                @hide="data.activeTab = 0"
                :filterFields="
                  data.activeTab === 1
                    ? ['menu_name', 'abbreviation', 'medical_menu_content']
                    : ['product_name']
                "
                :showClear="true"
                @change="
                  data.activeTab === 1
                    ? bomMenuSelected($event, index)
                    : productSelected($event, index)
                "
                :inputStyle="{ width: '-webkit-fill-available' }"
              >
                <template #header>
                  <TabView class="border-round" @tab-change="returnheader($event, data)">
                    <TabPanel header="원재료" />
                    <TabPanel header="수가표" />
                  </TabView>
                </template>
              </Dropdown>
            </div>
          </div>
        </template>
      </Column>
      <Column header="입력단위" headerStyle="color:indigo">
        <template #body="{ data }">
          <div class="flex justify-content-center align-items-center">
            {{ data.unit_when_input }}
          </div>
        </template>
      </Column>
      <Column field="use_cnt" header="사용량" headerStyle="color:indigo">
        <template #editor="{ data, field }">
          <div class="flex justify-content-center align-items-center">
            <InputText v-model="data[field]" @input="numberOnly($event)" />
          </div>
        </template>
      </Column>
      <Column header="서비스" headerStyle="color:indigo">
        <template #body="{ index, data }">
          <div class="flex justify-content-center align-items-center">
            <Checkbox
              name="service"
              :value="serviceValue(index)"
              v-model="data.is_service"
              :binary="true"
              :disabled="data?.breakage_or_contamination ? true : false"
            />
          </div>
        </template>
      </Column>
      <Column header="해당없음" headerStyle="color:indigo">
        <template #body="{ index, data }">
          <div class="flex justify-content-center align-items-center">
            <Checkbox
              name="na"
              :value="serviceValue(index)"
              v-model="data.is_na"
              :binary="true"
              :disabled="data?.breakage_or_contamination ? true : false"
            />
          </div>
        </template>
      </Column>
      <Column headerStyle="color:orangered">
        <template #body="{ data, index }">
          <div
            class="flex justify-content-center align-items-center flex-column"
            v-if="data?.breakage_or_contamination"
          >
            <span
              class="mb-1 font-bold"
              :class="{
                'text-purple-700': data.breakage_or_contamination === '오염',
                'text-orange-700': data.breakage_or_contamination === '파손',
              }"
              >{{ data.breakage_or_contamination }}</span
            >
            <Button
              label="삭제"
              class="p-button-sm p-button-danger"
              style="height: 20px; width: 30px; padding: 0; font-size: 11px"
              @click="deleteExcution($event, data, index)"
            />
          </div>
          <div class="flex justify-content-center align-items-center flex-column" v-else>
            <Button
              label="오염"
              class="p-button-sm p-button-help mb-1"
              style="height: 20px; width: 30px; padding: 0; font-size: 11px"
              @click="destroy($event, index, data)"
            />
            <Button
              label="파손"
              class="p-button-sm p-button-warning mb-1"
              style="height: 20px; width: 30px; padding: 0; font-size: 11px"
              @click="destroy($event, index, data)"
            />
            <Button
              label="삭제"
              class="p-button-sm p-button-danger"
              style="height: 20px; width: 30px; padding: 0; font-size: 11px"
              @click="deleteExcution($event, data, index)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>

  <div v-if="visibleLeft">
    <BomMenuSideVar />
  </div>
</template>

<script setup>
import BomMenuSideVar from './BomMenuSideVar.vue';

import {
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watchEffect,
  getCurrentInstance,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { useIdle } from '@vueuse/core';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

const { idle } = useIdle(1000 * 2);
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const $formatDate = app.appContext.config.globalProperties.$formatDate;

const toast = useToast();
const store = useStore();
const visibleLeft = ref(false);
const storeComputed = computed(() => store.getters);
const getters = computed(() => store.getters);

const items = ref([]);
const categoryItems = ref([]);
const productItmes = ref([]);
const listBom = ref([]);
// const bomMaterials = ref([]);
// const materialTabIndex = ref(0);
const bomSelectedDoc = computed({
  get: () => {
    return store.state.surgeryStore.bomSelectedDoc;
  },
  set: (value) => {
    store.commit('bomSelectedDocCommit', value);
  },
});
const bomSelectedDocCompare = ref();
const fontBomData = computed({
  get: () => {
    return store.state.surgeryStore.surgeryFrontDataTable;
  },
  set: (value) => {
    store.commit('surgeryFrontDataTableCommit', value);
  },
});
const bomMemo = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const bomMemoCompare = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const memoAsyncState = ref(true);
const memoEditState = ref(false);
const topValue = ref({});
const endTime = ref();
const headerDataCompare = ref([]);
const category = [
  { name: 'ㄱ', value: 0 },
  { name: 'ㄴ', value: 2 },
  { name: 'ㄷ', value: 3 },
  { name: 'ㄹ', value: 5 },
  { name: 'ㅁ', value: 6 },
  { name: 'ㅂ', value: 7 },
  { name: 'ㅅ', value: 9 },
  { name: 'ㅇ', value: 11 },
  { name: 'ㅈ', value: 12 },
  { name: 'ㅊ', value: 14 },
  { name: 'ㅋ', value: 15 },
  { name: 'ㅌ', value: 16 },
  { name: 'ㅍ', value: 17 },
  { name: 'ㅎ', value: 18 },
];

const returnheader = (event, data) => {
  data.activeTab = event.index;
};
onBeforeMount(async () => {
  try {
    const result = await axios.get(store.state.URL + '/registration-modal/bom-doc');
    const resultData = result.data;

    items.value = resultData.data.map((a) => ({
      label: a.doc_nm,
      command: () => {
        docExcution(a.id, a.doc_nm);
      },
    }));

    categoryItems.value = _.filter(
      category.map((a, i) => ({
        label: a.name,
        icon: 'pi',
        items: sortByLastname(items.value, a.value),
      })),
      function (i) {
        return i.items.length > 0;
      }
    );

    await store.dispatch('surgeryBomMenuLoad', { menu: 'bomInput' });
    listBom.value = store.state.surgeryStore.commonBomMenu
      ? store.state.surgeryStore.commonBomMenu
      : [];

    // bomMaterials.value = store.state.surgeryStore.commonBom
    //   ? store.state.surgeryStore.commonBom
    //   : [];
  } catch (error) {
    $errorHandling(null, error, clearFunc);
  }

  try {
    const result = await axios.get(store.state.URL + '/registration-modal/bom-product');
    const resultData = result.data;
    productItmes.value = resultData.data;
  } catch (error) {
    $errorHandling(null, error, clearFunc);
  }

  await store.dispatch('sugeryModiInit');

  const timeoutData = {
    name: 'modal-surgery-memo',
    url: '/registration-modal/sugery-memo?code=$customer_id',
    module: 'surgeryStore',
    compareData: 'sugeryMemoCompare',
  };

  store.dispatch('timeoutCommonFunc', timeoutData);
});

onBeforeUnmount(() => {
  document.querySelector('#soogaDoc .p-button-label').innerText = '수가표';
  store.commit('surgerybomSelectedDoc', { id: '', name: '' });
  store.commit('surgeryStateUpdate');
  store.commit('surgeryReset');
  store.commit('deleteTimeout', 'modal-surgery');
  store.commit('deleteTimeout', 'modal-surgery-memo');
  endTime.value = '';
  bomSelectedDocCompare.value = _.cloneDeep(bomSelectedDoc.value);
});

watchEffect(() => {
  const headerData = storeComputed.value.sugeryInit.initDataTitle;
  const bomId = store.state.surgeryStore.bom_id;
  if (!_.isEqual([...headerDataCompare.value], [...headerData])) {
    const filtered = _.filter(headerData, (a) => _.toString(a.id) === _.toString(bomId));
    if (filtered.length > 0) {
      headerDataCompare.value = _.cloneDeep(filtered);
      endTime.value = _.cloneDeep(filtered[0].end_time);
    }
  }

  visibleLeft.value = store.state.surgeryStore.bomMenuState;
  fontBomData.value = store.state.surgeryStore.surgeryFrontDataTable;

  topContent();
  const result = store.state.surgeryStore.sugeryMemoCompare;
  result && memoInit(result);

  if (!_.isEqual(bomSelectedDocCompare.value, bomSelectedDoc.value)) {
    bomSelectedDocCompare.value = _.cloneDeep(bomSelectedDoc.value);
    addPropofol();
  }
});

watchEffect(() => {
  memoEditState.value =
    store.state.loginUserData.user_id === bomMemo.value.user_id
      ? false
      : bomMemo.value.editing;
});
function sortByLastname(item, unicode) {
  let arr = [];
  for (let i = 0; i < item.length; i++) {
    if (
      // 초성을 유니코드로 계산하여 일치할 시, 해당 초성 리스트에 넣는 방식
      parseInt((item[i].label.substring(0, 1).charCodeAt(0) - 44032) / (21 * 28)) ===
      unicode
    ) {
      if (item[i].label !== '공통BOM') {
        arr.push(item[i]);
      }
    }
  }
  return arr;
}

function correct(e) {
  const noNumberValue = e.target.value.replace(/[^\d]|\s/g, '');
  if (noNumberValue.length === 4) {
    endTime.value = noNumberValue.replace(/\B(?=(\d{2})+(?!\d))/g, ':');
  }
}

function clearFunc() {
  store.commit('boardModalStateFalse');
}

const changeCurrency = (value) => {
  return $wonCurrency(value);
};

async function memoInit(result) {
  const newValue = result.result[0]
    ? result.result[0]
    : {
        memo: null,
        customer_id: null,
        editing: null,
      };
  const oldValue = bomMemoCompare.value;

  if (!_.isEqual(newValue, oldValue)) {
    bomMemo.value = _.cloneDeep(newValue);
    bomMemoCompare.value = _.cloneDeep(newValue);
  }
}

function topContent() {
  if (fontBomData.value.length > 0) {
    const data = fontBomData.value.map((a) => ({
      menu_id: a.menu_id,
      menu_count: a.menu_count,
      price_include_vat: a.price_include_vat ? a.price_include_vat : 0,
      sales_price: a.sales_price ? a.sales_price : 0,
    }));

    const dataUniq = _.uniqWith(data, _.isEqual);

    const total = dataUniq.reduce(
      (a, b) =>
        a +
        (b.price_include_vat ? b.price_include_vat : 0) *
          (b.menu_count ? b.menu_count : 1),
      0
    );

    const sales = dataUniq.reduce((a, b) => a + (b.sales_price ? b.sales_price : 0), 0);
    const distruction = fontBomData.value.reduce((a, b) => {
      const trans = b.breakage_or_contamination === '파손' ? 1 : 0;
      return a + trans;
    }, 0);
    const contamination = fontBomData.value.reduce((a, b) => {
      const trans = b.breakage_or_contamination === '오염' ? 1 : 0;
      return a + trans;
    }, 0);
    const service = fontBomData.value.reduce((a, b) => {
      const trans = b.is_service ? 1 : 0;
      return a + trans;
    }, 0);

    topValue.value = {
      total: $wonCurrency(total),
      sales: $wonCurrency(sales),
      discount: Math.floor((1 - sales / total) * 10000) / 100,
      distruction,
      contamination,
      service,
    };
  }
}

function docExcution(id, doc_nm) {
  store.commit('surgerybomSelectedDoc', { id, name: doc_nm });
}

function docnameis() {
  if (store.state.surgeryStore.bomSelectedDocName) {
    return store.state.surgeryStore.bomSelectedDocName;
  } else {
    return '수가표';
  }
}

// function clickDoc(e) {
//   const docLabel = document.querySelector('#soogaDoc .p-button-label').innerText;
//   if (docLabel === '수가표') {
//     toast.add({
//       severity: 'warn',
//       summary: '의사를 먼저 선택해주세요.',
//       detail: ' ',
//       group: 'bominput-toast',
//       life: 3000,
//     });
//   } else {
//     store.commit('surgeryBomMenuState');
//   }
// }

function onCellEditComplete(e) {
  const newData = e.newValue;
  const index = e.index;
  fontBomData.value[index].use_cnt = newData;
}

function addPropofol() {
  const doctor_id = bomSelectedDoc.value ? bomSelectedDoc.value : null;
  const doc_nm = store.state.surgeryStore.bomSelectedDocName;

  // store.commit('surgeryBomMenuState');
  if (doctor_id) {
    const propofolProduct = _.filter(productItmes.value, (obj) =>
      obj.product_name.includes('포폴주사')
    )[0];
    const insertData = {
      sort: false,
      doc_nm: doc_nm,
      doctor_id: doctor_id,
      product_name_is: true,
      is_service: false,
      product_id: propofolProduct.product_code,
      product_name: propofolProduct.product_name,
      unit_when_input: propofolProduct.unit_when_input,
      use_cnt: '',
    };
    fontBomData.value.push(insertData);
  }
}

const serviceValue = (index) => {
  return index.toString();
};

const destroy = _.debounce((e, index, data) => {
  const cloneData = _.cloneDeep(data);
  delete cloneData.bom_product_id;
  fontBomData.value.splice(index, 0, cloneData);
  const buttonValue = e.target
    .closest('.p-button')
    .querySelector('.p-button-label').innerText;
  fontBomData.value[index].breakage_or_contamination = buttonValue;
});

const deleteExcution = _.debounce(async (e, data, index) => {
  if (data?.bom_product_id) {
    const deleteData = {
      bom_product_id: data.bom_product_id,
      index,
    };

    try {
      await store.dispatch('deleteSurgeryProduct', deleteData);
    } catch (error) {
      $errorHandling(null, error, clearFunc);
    }
  } else {
    store.commit('sugeryProductDelete', index);
  }
}, 300);

const addProduct = _.debounce(() => {
  const doctor_id = bomSelectedDoc.value;
  const doc_nm = store.state.surgeryStore.bomSelectedDocName;

  if (!doc_nm) {
    Swal.fire('의사를 먼저 선택해주세요', '이후 추가를 눌러주세요', 'warning').then(
      () => {
        document.querySelector('#soogaDoc').focus();
      }
    );
  } else {
    const insertData = {
      sort: false,
      doc_nm: doc_nm,
      doctor_id: doctor_id,
      product_name_is: true,
      is_service: false,
      product_id: '',
      unit_when_input: '',
    };
    fontBomData.value.push(insertData);
  }
}, 200);

// 약어 하단 추가 버튼 누를 시 이벤트
// const addNewProductByMenu = _.debounce((e, index, data) => {
//   const insertData = {
//     sort: false,
//     doc_nm: data.doc_nm,
//     doctor_id: data.doc_id,
//     bom_id: data.bom_id,
//     bom_product_menu_id: data.bom_product_menu_id,
//     product_name_is: true,
//     is_service: false,
//     product_id: '',
//     unit_when_input: '',
//     counseling_id: data.counseling_id,
//     menu_id: data.menu_id,
//     menu_name: data.menu_name,
//     abbreviation: data.abbreviation,
//     medical_menu_content: data.medical_menu_content,
//     note: data.note,
//     price_include_vat: data.price_include_vat,
//     sales_price: data.sales_price,
//     discount_rate: data.discount_rate,
//     discount_name: data.discount_name,
//   };

//   const groups = _.groupBy(fontBomData.value, 'menu_id');
//   let keyCount = 0;

//   if (Object.keys(groups).indexOf(data.menu_id) >= 0) {
//     for (const key in groups) {
//       if (key !== data.menu_id) {
//         keyCount += groups[key].length;
//       } else {
//         break;
//       }
//     }
//   }
//   const indexOfProduct = keyCount + groups[data.menu_id].length;
//   fontBomData.value.splice(indexOfProduct, 0, insertData);
// }, 200);

function productSelected(e, index) {
  const filterValue = productItmes.value.filter((a) => a.product_code === e.value);
  fontBomData.value[index].unit_when_input = filterValue[0]
    ? filterValue[0].unit_when_input
    : '';
  fontBomData.value[index].product_name = filterValue[0]
    ? filterValue[0].product_name
    : '';
  fontBomData.value[index].use_cnt = '';
}

function bomMenuSelected(e, idx) {
  // const filterValue = bomMaterials.value.filter((a) => a.menu_id === e.value);
  // if (filterValue.length > 0) {
  //   filterValue.forEach((materialData, i) => {
  //     fontBomData.value[index + i].unit_when_input = materialData
  //       ? materialData.unit_when_input
  //       : '';
  //     fontBomData.value[index + i].product_name = materialData
  //       ? materialData.product_name
  //       : '';
  //     fontBomData.value[index + i].use_cnt = '';
  //   });
  // }
  const index = _.findIndex(
    [...store.state.surgeryStore.commonBomMenu.map((data) => data.menu_id)],
    {
      ...e.value,
    }
  );
  store.commit('sugerySideSelected', index);
  selectedBom(idx);
}

const bomSave = _.debounce(async () => {
  if (!endTime.value) {
    Swal.fire('종료시간을 입력해주세요.', '', 'warning');
  } else if (fontBomData.value.length === 0) {
    Swal.fire('BOM을 먼저 입력해주세요.', '', 'warning');
  } else {
    const product_validation = [
      ...document.querySelectorAll('.product_name_input .p-dropdown-label'),
    ].map((a) => {
      if (a.innerText.replace(/\s/g, '')) {
        a.parentElement.classList.remove('p-invalid');
        return true;
      } else {
        a.parentElement.classList.add('p-invalid');
        return false;
      }
    });

    const product_use_cnt = fontBomData.value.map((a) => {
      return a?.use_cnt && a.use_cnt.toString().replace(/\s/g, '') ? true : false;
    });

    const validation = _.concat(product_validation, product_use_cnt).every((a) => a);

    if (!validation) {
      Swal.fire(
        'BOM내용을 모두 입력해주세요.',
        `${
          product_validation.filter((a) => !a).length > 0
            ? '상품명을 모두 입력해주세요.'
            : ''
        }${
          product_use_cnt.filter((a) => !a).length > 0
            ? ' ' +
              product_use_cnt
                .map((a, ai) => !a && ai + 1)
                .filter((b) => b)
                .join(', ') +
              '번째의 사용량을 입력해주세요.'
            : ''
        }`,
        'warning'
      );
    } else {
      store.commit('loadingChangeValue', true);

      const bom_id = store.state.surgeryStore.bom_id;
      let response = {};
      // BOMID가 등록되어 업데이트 되는 데이터
      const updateMenu = _.cloneDeep(fontBomData.value.filter((a) => a?.bom_id));
      if (updateMenu.length > 0) {
        try {
          await axios.post(
            store.state.URL + '/registration-modal/update-surgery-menu',
            updateMenu
          );
          response.updateMenu = true;
        } catch (error) {
          $errorHandling(null, error, clearFunc);
        }
      }

      // 상담 기록이 있는 insert data
      const insertMenuCounsel = _.cloneDeep(
        fontBomData.value.filter((a) => !a?.bom_id && a?.menu_id && a.counseling_id)
      );
      insertMenuCounsel.forEach((a) => (a.bom_id = bom_id));
      if (insertMenuCounsel.length > 0) {
        try {
          await axios.post(
            store.state.URL + '/registration-modal/insert-surgery-menu-counsel',
            insertMenuCounsel
          );
          response.insertMenuCounsel = true;
        } catch (error) {
          $errorHandling(null, error, clearFunc);
        }
      }

      // 상담 기록이 없는 insert data
      const insertMenu = _.cloneDeep(
        fontBomData.value.filter((a) => !a?.bom_id && a?.menu_id && !a.counseling_id)
      );
      insertMenu.forEach((a) => (a.bom_id = bom_id));
      if (insertMenu.length > 0) {
        try {
          await axios.post(
            store.state.URL + '/registration-modal/insert-surgery-menu',
            insertMenu
          );
          response.insertMenu = true;
        } catch (error) {
          $errorHandling(null, error, clearFunc);
        }
      }

      // 서비스 품목
      const serviceProduct = _.cloneDeep(
        fontBomData.value.filter((a) => !a.bom_id && !a?.menu_id)
      );
      serviceProduct.forEach((a) => (a.bom_id = bom_id));
      if (serviceProduct.length > 0) {
        try {
          await axios.post(
            store.state.URL + '/registration-modal/insert-service-product',
            serviceProduct
          );
          response.serviceProduct = true;
        } catch (error) {
          $errorHandling(null, error, clearFunc);
        }
      }

      Swal.fire({
        icon: 'success',
        title: '저장이 완료되었습니다.',
        html: '수/시술 내용을 복사하여<br>SmartCRM에 붙여 넣으시겠습니까?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        let count = 0;
        const pasteData = fontBomData.value.map((a, ai) => {
          if (!a.is_na) {
            return `${ai + 1 - count} : ${a.doc_nm} ${a.product_name} ${a.use_cnt} ${
              a.unit_when_input
            }${a.is_service ? ' 서비스' : ''}${
              a?.breakage_or_contamination ? ' ' + a.breakage_or_contamination : ''
            }`;
          } else {
            count += 1;
            return undefined;
          }
        });

        const union = _.concat(pasteData.filter((data) => data !== undefined));

        result.isConfirmed ? navigator.clipboard.writeText(union.join('\n')) : '';
      });
    }
  }
}, 250);

function backBom() {
  store.commit('surgeryStateUpdate');
}

const numberOnly = _.debounce((e) => {
  const inputValue = e.target.value;
  const firstString = inputValue.slice(0, 1).replace(/\d/, '');

  if (firstString) {
    e.target.value = '';
  } else {
    e.target.value = inputValue.replace(/[^\d|^.]/g, '');
  }
}, 200);

const bomMemoChange = _.debounce(async (e) => {
  const memoValue = e.htmlValue;
  const customerCode = store.state.board.boardModal.boardSendCutomerCode;

  const result = await axios.put(store.state.URL + '/registration-modal/memo-upsert', {
    memoValue,
    customerCode,
    classification: 'BOM',
  });

  if (!result.data.is) {
    toast.add({
      severity: 'error',
      summary: '메모가 저장에러',
      detail: '메모가 저장되지 않았습니다.',
      life: 3000,
    });
  }
}, 1000);

async function memoStateChange() {
  if (memoAsyncState.value) {
    memoAsyncState.value = false;
    const customerCode = store.state.board.boardModal.boardSendCutomerCode;

    await axios.post(store.state.URL + '/registration-modal/memo-state', {
      state: true,
      customerCode,
      classification: 'BOM',
    });
  }
}

function memoSelection(e) {
  let stayMemo = setTimeout(function tick() {
    if (idle.value) {
      e.instance.root.blur();
    } else {
      stayMemo = setTimeout(tick, 1000);
    }
  }, 1000);
}

async function inputBomTime() {
  const sendData = {
    id: store.state.surgeryStore.bom_id,
    time: /^\d{2}:\d{2}$/.test(endTime.value)
      ? endTime.value
      : $formatDate('time', endTime.value),
    sort: 'end_time',
  };

  await store.dispatch('surgeryTime', sendData);
}

function selectedBom(idx) {
  fontBomData.value.splice(idx, 1);
  getters.value.surgerySelectedSubFilter;
  // fontBomData.value[idx].activeTab = 0;
  store.commit('selectedIndexReset');

  // 기존 수가표 선택한 데이터 삭제
  // const filteredFontBomData = fontBomData.value.filter(
  //   (obj) => obj.product_id.length === 10 || obj.product_id.startsWith('000')
  // );
  // materialTabIndex.value = 0;
}

function getBomLabel(data) {
  return `${data.menu_name} ${
    data.abbreviation ? '// 약어 : ' + data.abbreviation : ''
  } ${data.medical_menu_content ? '// 내용 : ' + data.medical_menu_content : ''}`;
}
</script>

<script>
export default {
  name: 'BomInput',
};
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-header) {
  padding: 10px;
}
::v-deep(.p-inputtext) {
  width: 60px;
}
::v-deep(.p-tabview-panels) {
  display: none !important;
}
</style>
