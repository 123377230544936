// 예약, 접수 관련
const reserveReason = [
  { name: '바로예약', code: '바로예약' },
  { name: '성형상담', code: '성형상담' },
  { name: '성형상담(동행)', code: '성형상담(동행)' },
  { name: '쁘띠상담', code: '쁘띠상담' },
  { name: '쁘띠상담(동행)', code: '쁘띠상담(동행)' },
  { name: '전화상담', code: '전화상담' },
  { name: '예약쁘띠', code: '예약쁘띠' },
  { name: '예약미니', code: '예약미니' },
  { name: '예약수술', code: '예약수술' },
  { name: '경과', code: '경과' },
  { name: '퇴사경과', code: '퇴사경과' },
  { name: '처치', code: '처치' },
];

const smartCRMReason = {
  상담: [
    { name: '성형상담', code: '성형상담' },
    { name: '쁘띠상담', code: '쁘띠상담' },
  ],
  '8층': [{ name: '예약쁘띠', code: '예약쁘띠' }],
  '9층': [{ name: '예약쁘띠', code: '예약쁘띠' }],
  OP: [
    { name: '예약미니', code: '예약미니' },
    { name: '예약수술', code: '예약수술' },
  ],
  경과: [{ name: '경과', code: '경과' }],
  처치: [{ name: '처치', code: '처치' }],
  전화상담: [
    { name: '성형상담', code: '성형상담' },
    { name: '쁘띠상담', code: '쁘띠상담' },
  ],
  원내재진: [
    { name: '성형상담', code: '성형상담' },
    { name: '쁘띠상담', code: '쁘띠상담' },
  ],
};

const afterProcess = [
  { name: '상담', code: '상담' },
  { name: '상담 후 수술', code: '상담 후 수술' },
  { name: '상담 후 수면 시술', code: '상담 후 수면 시술' },
  { name: '상담 후 시술', code: '상담 후 시술' },
  { name: '예약 수술', code: '예약 수술' },
  { name: '예약 수면 시술', code: '예약 수면 시술' },
  { name: '예약 시술', code: '예약 시술' },
  { name: '경과', code: '경과' },
  { name: '처치', code: '처치' },
];

const boardProcess = [
  '접수',
  '상담',
  '수납',
  '세안',
  '사진',
  '동의서',
  '진료',
  '환복',
  '수/시술',
  '회복',
  '귀가',
];

// 할인 및 서비스
const discountNames = [
  { name: '해당없음', discountRate: 0 },
  { name: '실장할인', discountRate: 0 },
  { name: '실장서비스', discountRate: 100 },
  { name: '실장클레임할인', discountRate: 0 },
  { name: '실장클레임서비스', discountRate: 100 },
  { name: '원장할인', discountRate: 0 },
  { name: '원장서비스', discountRate: 100 },
  { name: '원장클레임할인', discountRate: 0 },
  { name: '원장클레임서비스', discountRate: 100 },
  { name: '직원할인', discountRate: 0 },
  { name: '직원가족할인', discountRate: 0 },
  { name: 'VIP할인', discountRate: 0 },
  { name: 'VIP서비스', discountRate: 100 },
  { name: '인플루언서', discountRate: 100 },
  { name: '리얼모델/협찬', discountRate: 100 },
  { name: '양도할인', discountRate: 0 },
];

const discountValues = {
  해당없음: 0,
  실장할인: 0,
  실장서비스: 100,
  실장클레임할인: 0,
  실장클레임서비스: 100,
  원장할인: 0,
  원장서비스: 100,
  원장클레임할인: 0,
  원장클레임서비스: 100,
  직원할인: 0,
  직원가족할인: 0,
  VIP할인: 0,
  VIP서비스: 100,
  인플루언서: 100,
  '리얼모델/협찬': 100,
  양도할인: 0,
};

// 원장등록 소속파트, 소속직책
const doctorPartTitles = [
  {
    label: 'PS',
    items: [
      {
        label: '대표원장',
        value: '대표원장',
      },
      {
        label: 'Chief 원장',
        value: 'Chief 원장',
      },
      {
        label: '책임원장',
        value: '책임원장',
      },
      {
        label: '외래원장',
        value: '외래원장',
      },
      {
        label: '원장',
        value: '원장',
      },
    ],
  },
  {
    label: '피부',
    items: [
      {
        label: '피부파트',
        value: '피부파트',
      },
    ],
  },
];

// 소속부서
const department = [
  { name: '고객서비스' },
  { name: '고객서비스(관리자)' },
  { name: '상담' },
  { name: '상담(관리자)' },
  { name: '간호' },
  { name: '간호(관리자)' },
  { name: '의료' },
  { name: '의료지원' },
  { name: '경영지원' },
  { name: '병원운영' },
];

// 마케팅 고객 분류
const marketingCustomerList = [
  { name: 'DD', description: 'DD(닥터 다이렉트 상담)' }, // 닥터 다이렉트 상담건(실장 상담 전 원장님 선상담)
  { name: 'PF', description: 'PF(퍼포먼스 컨택)' }, // 퍼포먼스팀 컨택한 DB(실장재진에 포함)
  { name: 'BM', description: 'BM(바이럴 마케팅 모델)' }, // (마케팅부문 선정한)바이럴 마케팅 모델-FREE
  { name: 'DM', description: 'DM(닥터 마케팅 모델)' }, // 닥터 마케팅 모델-원장님 오더로 영상,사진공개조건 할인적용
  { name: 'MM', description: 'MM(마케팅 모델)' }, // (실장)마케팅 모델-사진공개조건 실장 할인적용
  { name: 'ON', description: 'ON(홈페이지 다이렉트 예약)' }, // 홈페이지 다이렉트 예약
];

// 해외고객 국가 리스트
const foreignCountryList = [
  { name: '중국' },
  { name: '미국' },
  { name: '캐나다' },
  { name: '호주' },
  { name: '몽골' },
  { name: '필리핀' },
  { name: '태국' },
  { name: '베트남' },
  { name: '독일' },
  { name: '일본' },
];

module.exports = {
  reserveReason,
  smartCRMReason,
  afterProcess,
  boardProcess,
  discountNames,
  discountValues,
  doctorPartTitles,
  department,
  marketingCustomerList,
  foreignCountryList,
};
