<template>
  <div></div>
</template>

<script setup>
import Swal from 'sweetalert2';
import { getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';

const app = getCurrentInstance();
const $reaplcePresentURL = app.appContext.config.globalProperties.$reaplcePresentURL;
const store = useStore();

onMounted(async () => {
  try {
    const presentLoacation = window.location.href;

    const result = await store.dispatch(
      'emailVeryfy',
      $reaplcePresentURL(presentLoacation)
    );

    if (result.data) {
      Swal.fire('회원인증이 완료되었습니다.', '로그인 해주세요!', 'success').then(() => {
        window.location.href =
          process.env.VUE_APP_NODE_STATUS === 'production'
            ? process.env.VUE_APP_FRONT_PROD_HOST
            : process.env.VUE_APP_FRONT_DEV_HOST;
      });
    } else {
      Swal.fire(
        '인증 오류가 있습니다.',
        '이메일을 확인해주시거나 다시 회원가입 해주세요.',
        'error'
      ).then(() => {
        window.location.href =
          process.env.VUE_APP_NODE_STATUS === 'production'
            ? process.env.VUE_APP_FRONT_PROD_HOST
            : process.env.VUE_APP_FRONT_DEV_HOST;
      });
    }
  } catch (error) {
    Swal.fire(
      '인증 오류가 있습니다. ' + error.message,
      '이메일을 확인해주시거나 다시 회원가입 해주세요.',
      'error'
    ).then(() => {
      window.location.href =
        process.env.VUE_APP_NODE_STATUS === 'production'
          ? process.env.VUE_APP_FRONT_PROD_HOST
          : process.env.VUE_APP_FRONT_DEV_HOST;
    });
  }
});
</script>

<script>
export default {
  name: 'EmailVerify',
};
</script>

<style></style>
