<template>
  <div v-if="!store.state.counselingStore.counselingModalIsShow">
    <CounselingTreatStartEnd> </CounselingTreatStartEnd>
  </div>
  <div class="grid" v-if="store.state.counselingStore.counselingModalIsShow">
    <CounselingContents></CounselingContents>

    <div class="col-4">
      <CounselingMemo></CounselingMemo>
    </div>

    <div class="col-12">
      <CounselingTreatSelectedSooga></CounselingTreatSelectedSooga>
    </div>
  </div>

  <div v-if="store.state.sidebarVisible">
    <SidebarSooga></SidebarSooga>
  </div>
  <div
    class="flex justify-content-end"
    v-if="store.state.counselingStore.counselingModalIsShow"
  >
    <Button label="저장" icon="pi pi-check" @click="counselingSave"> </Button>
  </div>

  <div v-if="isDebug">
    <Button label="디버그용" @click="doDebug"> </Button>
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import Swal from 'sweetalert2';
import _ from 'lodash';

import SidebarSooga from './counselingAndTreat/sidebar_sooga.vue';
import CounselingContents from './counselingAndTreat/counseling_contents.vue';
import CounselingTreatSelectedSooga from './counselingAndTreat/counseling_treat_selected_sooga.vue';
import CounselingTreatStartEnd from './counselingAndTreat/counseling_treat_start.vue';
import CounselingMemo from './counselingAndTreat/counseling_memo.vue';

const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const $formatDate = app.appContext.config.globalProperties.$formatDate;

onBeforeMount(async () => {
  try {
    store.commit('setIsCounseling', true);

    // 수가표 전체 정보 가져오기
    await store.dispatch('getSoogaData');

    // 접수 ID가 있으면 데이터 받아옴

    // counseling => setTimeout 용 data 가져오기
    const timeoutData = {
      name: 'modal-counseling',
      url: '/counsel?customerid=$customer_id',
      module: 'counselingStore',
      compareData: 'counselingCompare',
    };

    await store.dispatch('timeoutCommonFunc', timeoutData);

    // counseling memo 데이터 가져오기
    const classification = '상담';
    const counselingMemoTimeoutData = {
      name: 'modal-counseling-memo',
      url: `/counsel/memo?customerid=$customer_id&classification=${classification}`,
      module: 'counselingStore',
      compareData: 'counselingMemoCompare',
    };

    await store.dispatch('timeoutCommonFunc', counselingMemoTimeoutData);
  } catch (error) {
    $errorHandling(toast, error, clearFunc);
  }
});

onBeforeUnmount(() => {
  store.commit('deleteTimeout', 'modal-counseling'); // setTimeout 삭제
  store.commit('deleteTimeout', 'modal-counseling-memo'); // setTimeout 삭제
  store.commit('setCounselingId', null); // 상담 아이디 삭제
  store.commit('setCounselingModalShow', false); // 상담 contents modal 초기화
  store.commit('initSelectedCounselingData'); // 상담 데이터 초기화
  store.commit('selectedSoogaListInit'); // SoogaList 빈 배열([])로 초기화
  store.commit('setCounselingHasStarted', false); // 상담 시작 여부 false로 초기화
  store.commit('setIsDoctor', false); // 원장 체크박스 초기화
  store.commit('setSelectedDoctor', null); // 원장 선택 드랍박스 초기화
  store.commit('boardSpecialDay2', []); // 캘린더에 표시 상담 일자 초기화
});

function clearFunc() {
  store.commit('boardModalStateFalse');
}

const counselingSave = _.debounce(async () => {
  const isInsert = store.state.counselingStore.counselingId === null ? true : false;
  const { isChecked, writer, counselerIsDoctor } =
    storeGetters.value.getDoctorCheckboxChecked;

  if (!isChecked) {
    // fasle 일 경우 save 멈춤
    toast.add({
      severity: 'warn',
      summary: '의사 선택',
      detail: '의사 선생님을 입력해주세요.',
      life: 3000,
    });
    return -1;
  }

  // 상담 데이터 null check
  const nullCheck = storeGetters.value.isCounselingIncludesNull;

  if (nullCheck.isIncludesNull) {
    // null 값이 있다면
    toast.add({
      severity: 'warn',
      summary: `상담 데이터 '${nullCheck.name}'을(를) 입력해주세요.`,
      detail: `상담 데이터 '${nullCheck.name}'이(가) 입력이 되지 않았습니다.`,
      life: 5000,
    });

    return -1;
  }

  // 티케팅이 성공일 경우, 수가표가 있어야 함
  const isTicketingSuccess = store.state.counselingStore.counselingData.selectedTicketing;
  if (
    isTicketingSuccess === 1 &&
    _.isEqual(storeGetters.value.getSelectedSoogaList, [])
  ) {
    // 티케팅 성공인데 수가표가 없을 경우
    toast.add({
      severity: 'warn',
      summary: `상담 데이터 '선택된 수가표'을(를) 입력해주세요.`,
      detail: `티케팅 성공일 경우, '선택된 수가표'가 입력되어야 합니다.`,
      life: 5000,
    });

    return -1;
  }

  // 추가기능 : 수가표에 제한할인율을 넘는 경우 확인
  let validateDiscountLimit = true;
  storeGetters.value.getSelectedSoogaList.forEach((soogaData) => {
    if (soogaData.discount_limit < soogaData.discount_rate) {
      if (!/서비스/.test(soogaData.discount_name)) {
        validateDiscountLimit = false;
        Swal.fire({
          title: '할인 제한',
          text: `서비스가 아닐 경우 제한된 할인율을 초과하여 할인하실 수 없습니다.`,
          icon: 'warning',
        });
      } else if (soogaData.paid_price > soogaData.sales_price) {
        validateDiscountLimit = false;
        Swal.fire({
          title: '할인 제한',
          text: `기존 수납된 금액보다 낮은 금액을 입력하여 저장하실 수 없습니다.`,
          icon: 'warning',
        });
      }
    }
  });

  // 제한 할인율을 넘을 경우
  if (!validateDiscountLimit) {
    return -1;
  }

  const duplicateReulst = await store.dispatch('counselDuplicate');
  const counselData = store.state.counselingStore.counselingCompare;

  if (
    duplicateReulst.id &&
    !_.includes(
      counselData.data.map((a) => a.id),
      duplicateReulst.id
    )
  ) {
    Swal.fire('새로고침 경고', '데이터가 변경되어 새로고침 합니다.', 'warning').then(() =>
      window.location.reload()
    );

    return false;
  }

  // 상담 데이터가 모두 입력되었다면 서버와 통신 시작
  // 상담 데이터 upsert
  await store.dispatch('counselingSave', {
    writer: writer,
    counselerIsDoctor: counselerIsDoctor,
  });

  // 예상절차 update
  await store.dispatch('updateAfterProcess', {
    writer: writer,
    counselerIsDoctor: counselerIsDoctor,
  });

  if (isInsert) {
    // 입력 insert
    // await counselingInsert(writer, counselerIsDoctor);
    // 종료시간 update
    await store.dispatch('upsertBoard', {
      isStart: false,
      writer: writer,
      time: $formatDate('time'),
      process: '상담',
      counselerIsDoctor: counselerIsDoctor,
    });
    await endProcessWhenCounselingSave();
  } else {
    let isPaid = false;
    storeGetters.value.getSelectedSoogaList.forEach((soogaData) => {
      isPaid = isPaid || soogaData.is_paid;
    });
    if (isPaid) {
      toast.add({
        severity: 'warn',
        summary: '상담 내역에 결제내역이 있습니다.',
        detail: '결제 내역을 포함하여 수정했으니 주의해주세요.',
        life: 4000,
      });
    }
    // update
    // await counselingUpdate(writer, counselerIsDoctor);
    // 정민님 요청으로 작성자만 update
    await store.dispatch('upsertBoardOnlyWriter', {
      isStart: false,
      writer: writer,
      process: '상담',
      counselerIsDoctor: counselerIsDoctor,
    });

    // update 알림
    toast.add({
      severity: 'success',
      summary: '상담 수정',
      detail: '상담 정보를 수정했습니다.',
      life: 5000,
    });
    // 상담 start 창으로 go
    store.commit('setCounselingModalShow', false);
  }
}, 250);

// async function counselingInsert(writer, counselerIsDoctor) {
//   try {
//     // 상담 데이터 upsert
//     store.dispatch('counselingSave', {
//       writer: writer,
//       counselerIsDoctor: counselerIsDoctor,
//     });
//     // // 상담 입력
//     // store.dispatch('counselingInsert', {
//     //   writer: writer,
//     //   counselerIsDoctor: counselerIsDoctor,
//     // });
//     // 예상절차 update
//     store.dispatch('updateAfterProcess', {
//       writer: writer,
//       counselerIsDoctor: counselerIsDoctor,
//     });
//     // 종료시간 update
//     store.dispatch('upsertBoard', {
//       isStart: false,
//       writer: writer,
//       time: $formatDate('time'),
//       process: '상담',
//       counselerIsDoctor: counselerIsDoctor,
//     });
//   } catch (error) {
//     $errorHandling(toast, error);
//   }
// }

// async function counselingUpdate(writer, counselerIsDoctor) {
//   try {
//     // // 상담 update
//     // store.dispatch('counselngUpdate', {
//     //   writer: writer,
//     //   counselerIsDoctor: counselerIsDoctor,
//     // });

//     // 상담 데이터 upsert
//     store.dispatch('counselingSave', {
//       writer: writer,
//       counselerIsDoctor: counselerIsDoctor,
//     });

//     // 정민님 요청으로 작성자만 update
//     store.dispatch('upsertBoardOnlyWriter', {
//       isStart: false,
//       writer: writer,
//       process: '상담',
//       counselerIsDoctor: counselerIsDoctor,
//     });

//     // // 수정에서 추가된 상담 수가표 insert
//     // store.dispatch('counselingInsertSurgery', {
//     //   writer: writer,
//     //   counselerIsDoctor: counselerIsDoctor,
//     // });

//     // 예상절차 업데이트
//     store.dispatch('updateAfterProcess', {
//       writer: writer,
//       counselerIsDoctor: counselerIsDoctor,
//     });
//   } catch (error) {
//     $errorHandling(toast, error);
//   }
// }

async function endProcessWhenCounselingSave() {
  // 접수 사유 가져오기
  const receptionData = await store.dispatch('getReceptionData');
  const reservationReason = receptionData.data.data[0].reception_reason;

  // 클립보드에 복사할 내용 soogaList 만들기
  const soogaSurgeries = storeGetters.value.getSelectedSoogaList;
  const pasteSoogaSurgeries = soogaSurgeries.map((soogaSurgery) => {
    return `${soogaSurgery.small_category} | ${soogaSurgery.abbreviation}(${
      soogaSurgery.menu_count
    }) // ${soogaSurgery.discount_rate}% // ${
      soogaSurgery.discount_name
    } // ${$wonCurrency(soogaSurgery.sales_price)}\n`;
    // return `${soogaSurgery.large_category} ▶ ${soogaSurgery.middle_category} ▶ ${soogaSurgery.small_category} § ${soogaSurgery.abbreviation} § ${soogaSurgery.medical_menu_content} § ${soogaSurgery.note} § ${soogaSurgery.price} § ${soogaSurgery.event_price} § ${soogaSurgery.price_include_vat} § ${soogaSurgery.menu_count} §${soogaSurgery.sales_price} § ${soogaSurgery.discount_rate} § ${soogaSurgery.discount_name} \n`;
  });

  copyDataToClipBoardWhenCounselingSave(
    reservationReason,
    store.state.counselingStore.counselingData.expectedDateOfSurgery,
    store.state.counselingStore.counselingData.selectedExpectProcess,
    store.state.counselingStore.counselingData.selectedTicketing,
    store.state.counselingStore.counselingData.selectedPatientState,
    store.state.counselingStore.counselingData.selectedTicketingSurgeries,
    store.state.counselingStore.counselingData.selectedTicketingProcess,
    pasteSoogaSurgeries
  );
}

function copyDataToClipBoardWhenCounselingSave(
  reservationReason,
  reservationSurgeryDate,
  expectProcess,
  ticketing,
  patientState,
  ticketingSurgery,
  ticketingProcess,
  pasteSoogaSurgeries
) {
  // 예약사유 : ${reservationReason}
  // 시수술예상날짜 : ${ reservationSurgeryDate === '' ? '미정' : $formatDate('date', reservationSurgeryDate)}
  // 예상절차: ${expectProcess}
  // 티케팅여부: ${ticketing === 0 ? '실패' : ticketing === 1 ? '성공' : '해당없음'}
  // 환자구분: ${patientState ? '구환' : '신환'}
  // 티케팅시수술: ${ticketingSurgery}
  // 티케팅절차: ${ticketingProcess}
  Swal.fire({
    icon: 'success',
    title: '상담 데이터 저장 성공',
    html: '상담 내용을 클립보드에 복사하시겠습니까?',
    showDenyButton: true,
    confirmButtonText: 'Yes',
  }).then(async (result) => {
    let counselPasteData = `티케팅내역:
  ${pasteSoogaSurgeries}`;
    if (result.isConfirmed) {
      // 클립보드 복사
      navigator.clipboard.writeText(counselPasteData);
    } else {
      // 상담 start 창으로 go
      store.commit('setCounselingModalShow', false);
    }
  });
}

const isDebug = false;
const doDebug = async () => {};
</script>

<style lang="scss" scoped>
::v-deep(#counsel-process .p-dropdown-label) {
  margin: auto;
  text-align: center;
}
</style>
