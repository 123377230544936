const copyBomMutations = {
  // 이동/복사
  copySourceDoctorSelectedCommit(state, payload) {
    state.copySourceDoctorSelected = payload;
  },
  copySourceDoctorToSurgerySeenCommit(state, payload) {
    state.copySourceDoctorToSurgerySeen = payload;
  },
  copySurgeryToTargetDoctorSeenCommit(state, payload) {
    state.copySurgeryToTargetDoctorSeen = payload;
  },
  copySurgerySelectedCommit(state, payload) {
    state.copySurgerySelected = payload;
  },
  copyTargetDoctorSelectedCommit(state, payload) {
    state.copyTargetDoctorSelected = payload;
  },
  copySurgeryFilteredDataCommit(state, payload) {
    state.copySurgeryFilteredData = payload;
  },
  copyRecentSelectedBomCommit(state, payload) {
    state.copyRecentSelectedBom = payload;
  },
};

export default copyBomMutations;
