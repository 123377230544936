<template>
  <div v-show="store.state.bomManageGetdata.confirmSurgeryToBomSeen">
    <h5>(3) BOM 선택</h5>
    <div class="pb-3">
      <Listbox
        v-model="bomConfirmSelected"
        :options="store.state.bomManageGetdata.bomData"
        optionLabel="product_name"
        optionValue="product_code"
        :multiple="false"
        :filter="true"
        listStyle="max-height:250px"
        style="width: 50rem"
        :filterFields="['product_code', 'product_name', 'standard_name']"
        filterPlaceholder="상품코드, 상품명, 규격"
        @click="store.dispatch('confirmBomRegistActive')"
      >
        <template #option="slotProps">
          <div class="flex justify-content-between mb-1">
            <div>
              {{
                `(${
                  slotProps.option.purchase_code
                    ? slotProps.option.purchase_code
                    : slotProps.option.product_code
                })${slotProps.option.product_name}`
              }}
            </div>
            <div class="flex align-items-center justify-content-center">
              <span class="text-green-500 font-medium">
                {{ slotProps.option.standard_name }}
              </span>
            </div>
          </div>
        </template>
      </Listbox>
    </div>
    <div class="pb-2">
      <span class="text-lg font-bold">원가 : </span>
      <span
        v-if="store.state.bomManageGetdata.confirmRegistedBomCost"
        class="text-lg font-medium text-blue-500"
        >{{
          $wonCurrency(Math.round(store.state.bomManageGetdata.confirmRegistedBomCost[0]))
        }}</span
      >
      <span class="text-lg font-bold"> / 원가율 : </span>
      <span
        v-if="store.state.bomManageGetdata.confirmRegistedBomCost"
        class="text-lg font-medium text-blue-500"
        >{{ store.state.bomManageGetdata.confirmRegistedBomCost[1] }}%
      </span>
    </div>
    <div class="pb-3">
      <DataTable
        :value="store.state.bomManageGetdata.confirmRegistedBomBySurgery"
        :scrollable="true"
        scrollHeight="250px"
      >
        <Column style="flex: 0 0 5rem">
          <template #body="slotProps">
            <!-- <template #body="slotProps"> -->
            <Button
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text p-button-sm"
              @click="
                store.dispatch('confirmRemoveProductRow', {
                  productCode: slotProps.data.product_code,
                })
              "
            ></Button>
          </template>
        </Column>
        <Column style="flex: 0 0 15rem" field="product_name" header="품목"></Column>
        <Column style="flex: 0 0 10rem" field="standard_name" header="규격"></Column>
        <Column
          style="flex: 0 0 10rem"
          field="unit_when_input"
          header="입력기준"
        ></Column>
        <Column style="flex: 0 0 10rem" field="use_cnt" header="사용량">
          <template #body="{ data }">
            <InputNumber
              :inputStyle="{ width: '60px' }"
              v-model="data.use_cnt"
              :class="{
                'p-invalid': !data.use_cnt,
              }"
              @input="inputChangeEvent($event, data.product_code)"
              inputId="minmaxfraction"
              mode="decimal"
              :minFractionDigits="0"
              :maxFractionDigits="3"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-content-end">
      <Button
        label="수정"
        icon="pi pi-check"
        class="p-button-sm"
        @click="store.dispatch('confirmProductSaveConfirm')"
        :disabled="
          !store.state.bomManageGetdata.confirmRegistedBomBySurgery ||
          !store.state.bomManageGetdata.confirmRegistedBomBySurgery.length
        "
      />
    </div>
  </div>
</template>
<script setup>
import { computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Listbox from 'primevue/listbox';

const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const store = useStore();
const bomConfirmSelected = computed({
  get: () => {
    return store.state.bomManageGetdata.confirmBomConfirmSelected;
  },
  set: (value) => {
    store.commit('confirmBomConfirmSelectedCommit', value);
  },
});

async function inputChangeEvent(e, productCode) {
  store.dispatch('confirmCalculateCost', {
    event: e,
    productCode: productCode,
  });
}
</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }
}
</style>
