import axios from 'axios';

const auth = {
  state() {
    return {
      authList: '',
      errorHandler: '',
    };
  },
  mutations: {
    authListCommit(state, payload) {
      state.authList = payload;
    },
    errorCommit(state, payload) {
      state.errorHandler = payload;
    },
  },
  actions: {
    async authListGet({ rootState, commit }) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.get(rootState.URL + '/auth/auth-list');
        commit('authListCommit', result.data);
        commit('loadingChangeValue', false);
      } catch (err) {
        commit('errorCommit', err);
      }
    },
    async authExecution({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        await axios.post(rootState.URL + '/auth/auth-execution', payload);
        commit('loadingChangeValue', false);
      } catch (err) {
        commit('errorCommit', err);
      }
    },
  },
};

export default auth;
