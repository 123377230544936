<template>
  <LoadingComponent />
  <router-view />
</template>

<script setup>
import LoadingComponent from '@/components/common/Loading.vue';
import { usePrimeVue } from 'primevue/config';
import { onMounted, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useIdle } from '@vueuse/core';
import Swal from 'sweetalert2';

const { idle } = useIdle(1000 * 60 * 60 * 4);
const router = useRouter();
const store = useStore();
const setDataTableFilterCondition = () => {
  const primevue = usePrimeVue();
  primevue.config.locale.contains = '포함';
  primevue.config.locale.notContains = '미포함';
  primevue.config.locale.dateIs = '해당 날짜';
  primevue.config.locale.dateIsNot = '해당 날짜 제외';
  primevue.config.locale.equals = '일치';
  primevue.config.locale.notEquals = '불일치';
  primevue.config.locale.lte = '이하';
  primevue.config.locale.gte = '이상';
  primevue.config.locale.clear = '초기화';
  primevue.config.locale.apply = '적용';
  primevue.config.locale.matchAll = 'AND';
  primevue.config.locale.matchAny = 'OR';
  primevue.config.locale.today = '오늘';
  primevue.config.locale.dayNames = ['일', '월', '화', '수', '목', '금', '토'];
  primevue.config.locale.dayNamesShort = ['일', '월', '화', '수', '목', '금', '토'];
  primevue.config.locale.dayNamesMin = ['일', '월', '화', '수', '목', '금', '토'];
  primevue.config.locale.monthNames = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];
  primevue.config.locale.monthNamesShort = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];
};

onMounted(() => {
  setDataTableFilterCondition();
});

watchEffect(async () => {
  if (idle.value) {
    store.commit('boardModalStateFalse');
    store.commit('clearTimeout');
    window.location.href =
      process.env.VUE_APP_NODE_STATUS === 'production'
        ? process.env.VUE_APP_FRONT_PROD_HOST
        : process.env.VUE_APP_FRONT_DEV_HOST;
    if (router.currentRoute._value.name !== 'first') {
      Swal.fire(
        '대기시간 초과',
        '대기시간이 초과하여 로그아웃 되었습니다.',
        'warning'
      ).then(async () => {
        await axios.get(store.state.URL + '/logout');
      });
    }
  }
});
</script>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
.swal2-container {
  z-index: 11000 !important;
}
</style>
