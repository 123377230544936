<template>
  <div>
    <ToggleButton
      v-model="misooState"
      onLabel="제외"
      offLabel="미수"
      onIcon="pi pi-times"
      offIcon="pi pi-check"
      :class="{ 'p-button-danger': misooState }"
      style="height: 10px; width: 6rem; font-size: 12px"
      @change="changeState"
      v-if="authData()"
    />
  </div>
</template>

<script setup>
import Swal from 'sweetalert2';
import { ref, defineProps, watchEffect, defineEmits } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['reloadPrice']);
const store = useStore();
const props = defineProps({
  data: Object,
});

const misooState = ref(props.data.misoo);

watchEffect(() => {
  misooState.value = props.data.misoo;
});

function authData() {
  const auth = store.state.userAuthData;

  if (auth.includes('000') || auth.includes('112')) {
    return true;
  } else {
    return false;
  }
}

function changeState() {
  Swal.fire({
    icon: 'info',
    title: misooState.value
      ? '해당 미수금을 제외하시겠습니까?'
      : '제외 금액을 다시 미수금으로 하시겠습니까?',
    showDenyButton: true,
    confirmButtonText: '네',
    denyButtonText: '아니요',
  }).then(async (result) => {
    if (misooState.value && !result.isConfirmed) {
      misooState.value = false;
    } else if (!misooState.value && !result.isConfirmed) {
      misooState.value = true;
    } else if (result.isConfirmed) {
      await store.dispatch('paymentMisoo', {
        counseling_surgery_id: props.data.counseling_surgery_id,
        misooState: misooState.value,
        counsel_state: props.data.counsel_state,
      });
      emit('reloadPrice');
    }
  });
}
</script>
<script>
export default {
  name: 'MisooComponent',
};
</script>

<style lang="scss" scoped>
::v-deep(.p-togglebutton.p-button.p-highlight) {
  background: #ef4444;
  border: 1px solid #ef4444;
  &:hover {
    background: #dc2626;
    border: 1px solid #dc2626;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
}
</style>
