<template>
  <div class="surface-section">
    <h4>내 정보</h4>
    <form @submit.prevent="store.dispatch('handleSubmit', v$.$invalid)">
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> E-mail </span>
        <InputText
          placeholder="E-mail"
          v-model="v$.email.$model"
          :class="{
            'p-invalid': v$.email.$invalid && modifySubmitted,
          }"
          name="email"
          :disabled="true"
        />
      </div>
      <div class="p-inputgroup mb-2" v-show="onModify">
        <span class="p-inputgroup-addon w-8rem text-sm"> 기존 비밀번호 </span>
        <Password
          :class="{
            'p-invalid': v$.oldPassValue.$invalid && modifySubmitted,
          }"
          v-model="v$.oldPassValue.$model"
          name="password"
          :disabled="!onModify"
          :feedback="false"
        />
        <Button
          label="비밀번호 변경"
          v-show="!isChangePass"
          @click="store.commit('isChangePassCommit', !isChangePass)"
        />
        <Button
          label="취소"
          v-if="isChangePass"
          @click="store.commit('isChangePassCommit', !isChangePass)"
        />
      </div>
      <div class="p-inputgroup mb-2" v-show="onModify && isChangePass">
        <span class="p-inputgroup-addon w-8rem text-base"> 새 비밀번호 </span>
        <Password
          :class="{
            'p-invalid': v$.passValue1.$invalid && modifySubmitted,
          }"
          v-model="v$.passValue1.$model"
          name="password"
          :disabled="!onModify"
        />
      </div>
      <div class="p-inputgroup mb-2" v-show="onModify && isChangePass">
        <span class="p-inputgroup-addon w-8rem text-sm"> 비밀번호 확인 </span>
        <Password
          v-model="v$.passValue2.$model"
          :class="{
            'p-invalid': v$.passValue2.$invalid && modifySubmitted,
          }"
          :feedback="false"
        />
      </div>
      <hr />
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> 소속병원 </span>
        <Dropdown
          v-model="v$.selectedHospital.$model"
          :class="{
            'p-invalid': v$.selectedHospital.$invalid && modifySubmitted,
          }"
          :options="hospital"
          optionLabel="name"
          optionValue="name"
          placeholder="소속선택"
          name="hospital"
          :disabled="!onModify"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> 소속부서 </span>
        <Dropdown
          v-model="v$.selectedDepartment.$model"
          :class="{
            'p-invalid': v$.selectedDepartment.$invalid && modifySubmitted,
          }"
          :options="department"
          optionLabel="name"
          optionValue="name"
          placeholder="부서선택"
          name="department"
          :disabled="!onModify"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> 이름 </span>
        <InputText
          placeholder="한글로만 기재해주세요."
          v-model="v$.username.$model"
          :class="{
            'p-invalid': v$.username.$invalid && modifySubmitted,
          }"
          name="name"
          :disabled="!onModify"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> 생년월일 </span>
        <Calendar
          id="date"
          v-model="v$.birth.$model"
          :class="{
            'p-invalid': v$.birth.$invalid && modifySubmitted,
          }"
          :showIcon="true"
          dateFormat="yy-mm-dd"
          name="birth"
          :disabled="!onModify"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> 핸드폰번호 </span>
        <InputText
          placeholder="핸드폰번호"
          v-model="v$.phone.$model"
          :class="{
            'p-invalid': v$.phone.$invalid && modifySubmitted,
          }"
          @input="changePhone"
          name="phone"
          :disabled="!onModify"
        />
      </div>
      <div class="flex justify-content-end">
        <Button
          v-show="!onModify"
          @click="store.commit('onModifyCommit', !onModify)"
          label="수정"
        />
        <Button
          class="p-button-text mr-1"
          v-show="onModify"
          @click="store.dispatch('resetForm', v$)"
          label="초기화"
        />
        <Button
          v-show="onModify"
          @click="modifySubmitted = true"
          type="submit"
          label="수정완료"
        />
      </div>
    </form>
  </div>
  <template>
    <div>
      <!-- <Toast /> -->
      <ConfirmDialog></ConfirmDialog>
    </div>
  </template>
</template>

<script setup>
import {
  onBeforeMount,
  watchEffect,
  computed,
  ref,
  reactive,
  getCurrentInstance,
} from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import ConfirmDialog from 'primevue/confirmdialog';
import { email, required, sameAs } from '@vuelidate/validators';
import _ from 'lodash';

let cookieData = ref();
const store = useStore();
const passValue1Ref = computed(() => modifyMemberState.passValue1);
const onModify = ref(false);
const modifySubmitted = ref(false);
const isChangePass = ref(false);
const authList = ref();
const authListCompare = ref([]);
const hospital = ref();
const department = ref();

const app = getCurrentInstance();
const $autoHypenPhone = app.appContext.config.globalProperties.$autoHypenPhone;
const $router = app.appContext.config.globalProperties.$router;

// 입력할 이름 및 비밀번호 정규패턴
const nameReg = (value) => /^[가-힣]{2,6}$/.test(value);
const phoneReg = (value) => /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/.test(value);

const modifyMemberState = reactive({
  email: null,
  oldPassValue: null,
  passValue1: null,
  passValue2: null,
  selectedHospital: null,
  selectedDepartment: null,
  username: null,
  birth: null,
  phone: null,
});

const rules = {
  email: {
    required,
    email,
  },
  oldPassValue: {
    required,
  },
  passValue1: {},
  passValue2: {
    sameAsPassword: sameAs(passValue1Ref),
  },
  selectedHospital: {
    required,
  },
  selectedDepartment: {
    required,
  },
  username: {
    required,
    nameReg,
  },
  birth: {
    required,
  },
  phone: {
    required,
    phoneReg,
  },
};
const v$ = useVuelidate(rules, modifyMemberState);

onBeforeMount(async () => {
  await init();
});

watchEffect(async () => {
  cookieData.value = store.state.myPageGetData.cookieData;
  onModify.value = store.state.myPageGetData.onModify;
  modifySubmitted;
  isChangePass.value = store.state.myPageGetData.isChangePass;
  store.commit('myInfoDataCommit', v$.value);
  await init();
});

function uniqArray(value, value2) {
  return _.uniqWith(
    value.map((a) => ({ name: a[value2] })),
    _.isEqual
  );
}

// input입력창에 불러온 내 정보 넣어주는 함수
async function init() {
  await store.dispatch('myPageAuthListGet');
  const oldData = authListCompare.value;
  const newData = store.state.authStore.authList;

  if (!_.isEqual([...oldData], [...newData])) {
    authList.value = _.cloneDeep(store.state.authStore.authList);
    authListCompare.value = _.cloneDeep(store.state.authStore.authList);
    hospital.value = _.cloneDeep(uniqArray(store.state.authStore.authList, 'hospital'));
    department.value = _.cloneDeep(
      uniqArray(store.state.authStore.authList, 'department')
    );
  }
  if (!onModify.value) {
    store.commit('cookieIsCommit', cookieData.value.is);
    if (cookieData.value.is && cookieData.value.userData[0]) {
      v$.value.email.$model = cookieData.value.userData[0].user_id;
      v$.value.selectedHospital.$model = cookieData.value.userData[0].hospital;
      v$.value.selectedDepartment.$model = cookieData.value.userData[0].department;
      v$.value.username.$model = cookieData.value.userData[0].name;
      v$.value.birth.$model = cookieData.value.userData[0].birth;
      v$.value.phone.$model = cookieData.value.userData[0].phone;
    } else if (cookieData.value.is && !cookieData.value.userData[0]) {
      $router.push({ name: 'status-board' });
    }
  }
}

// const confirm = useConfirm();
// const hospital = [
//   { name: '리팅' },
//   { name: '플란' },
//   { name: '라미트' },
//   { name: '다이트' },
//   { name: '모모' },
// ];
// const department = [
//   { name: '고객서비스' },
//   { name: '고객서비스(관리자)' },
//   { name: '상담' },
//   { name: '상담(관리자)' },
//   { name: '간호' },
//   { name: '간호(관리자)' },
//   { name: '의료' },
//   { name: '의료지원' },
//   { name: '경영지원' },
//   { name: '병원운영' },
// ];

// 연락처 변경시 자동 하이픈 적용 버튼
function changePhone(e) {
  const inputValue = e.target.value;
  const inputReplace = inputValue.replace(/[^\d]/g, '');
  v$.value.phone.$model = $autoHypenPhone(inputReplace);
}
</script>

<style scoped>
hr {
  border: solid 1px lightgray;
}
.swal2-container {
  z-index: 1500;
}
</style>
