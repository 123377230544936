<template>
  <div class="border-bottom-1 border-400 relative pb-3">
    <h4 class="mb-2">완료</h4>
    <div class="absolute right-0" style="top: -8px">
      <Button
        v-if="isIncentiveAuth()"
        label="실적분배"
        class="p-button-outlined p-button-help p-button-sm mr-2"
        @click="openIncentiveDialog"
      ></Button>
      <Button
        label="환불대기"
        class="p-button-outlined p-button-danger p-button-sm mr-2"
        @click="refundWaiting"
      />
    </div>
  </div>
  <div class="border-bottom-1 border-400 relative pb-3 mt-3 flex">
    <div class="col-6 align-itmes-center flex">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-chart-line"></i>
        </span>
        <div class="flex justify-content-center align-items-center input-group-div">
          <span v-if="topView().paymentCount" class="text-blue-600"
            >{{ topView().paymentCount }}건</span
          >
          <span v-if="topView().paymentCount && topView().refundCount"
            >&nbsp;/&nbsp;</span
          >
          <span v-if="topView().refundCount" class="text-red-600"
            >{{ topView().refundCount }}건</span
          >
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"> ₩ </span>
        <div
          class="flex justify-content-center align-items-center input-group-div flex-column"
        >
          <span v-if="topView().paymentSum" class="text-blue-600">{{
            topView().paymentSum
          }}</span>
          <span v-if="topView().refundSum" class="text-red-600">{{
            topView().refundSum
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <Accordion
      class="payment_accordiaon relative"
      v-model:active-index="store.state.paymentStore.paymentPaymentActiveIndex"
    >
      <AccordionTab v-for="(a, i) in initHeader" :key="i">
        <template #header>
          <div class="flex flex-column w-full">
            <div class="flex justify-content-between align-items-center w-full mb-3">
              <ChangeDate
                class="absolute"
                style="top: 2%; left: 1%"
                @click="changeDateTransfer(a)"
                v-if="!waitingGropuArray.includes(a.payment_group_id)"
              ></ChangeDate>
              <div
                class="xl:text-base md:text-xs text-center w-3"
                :class="{
                  'text-orange-500':
                    waitingGropuArray.includes(a.payment_group_id) && !a.refund_active,
                  'text-red-500': a.refund_active,
                }"
              >
                {{ a.refund_active ? a.refund_date : a.payment_approval_date }}
              </div>
              <div
                class="xl:text-base md:text-sm text-center w-3"
                :class="{
                  'text-orange-500':
                    waitingGropuArray.includes(a.payment_group_id) && !a.refund_active,
                  'text-red-500': a.refund_active,
                }"
              >
                {{ a.deposit_or_balance }}
              </div>
              <div
                class="xl:text-base md:text-sm text-center w-3"
                :class="{
                  'text-orange-500':
                    waitingGropuArray.includes(a.payment_group_id) && !a.refund_active,
                  'text-red-500': a.refund_active,
                }"
              >
                {{
                  a.refund_active
                    ? a.payment_type.replace(/결제/g, '환불완료')
                    : waitingGropuArray.includes(a.payment_group_id)
                    ? a.payment_type.replace(/결제/g, '환불대기')
                    : a.payment_type
                }}
              </div>
              <div
                class="xl:text-base md:text-sm text-center w-3"
                :class="{
                  'text-orange-500':
                    waitingGropuArray.includes(a.payment_group_id) && !a.refund_active,
                  'text-red-500': a.refund_active,
                }"
              >
                {{ initDataHeaderSubSum(a.payment_group_id) }}
              </div>
            </div>
            <div class="flex justify-content-between align-items-center w-full">
              <div class="xl:text-base md:text-sm text-center w-3" v-if="a.refund_active">
                {{ a.payment_approval_date }}
              </div>
              <div class="xl:text-base md:text-xs text-center w-3">
                {{
                  `${a.soogi ? '수기' : ''}${
                    a.payment_type === '현금결제' ? '' : a.payment_type
                  }${
                    a.payment_type === '현금결제'
                      ? a.buycompany
                      : '(' + a.buycompany + ')'
                  }`
                }}
              </div>
              <div
                class="xl:text-base md:text-sm text-center w-3 flex align-items-center justify-content-center"
                @dblclick="changeApproveNumber($event, a, i)"
              >
                <div v-show="approveNumberIndex !== i">
                  {{ a.payment_approval_number }}
                </div>
                <div v-show="approveNumberIndex === i">
                  <div
                    class="p-inputgroup absolute"
                    style="width: 200px; height: 10px; bottom: 30%; right: 1%"
                  >
                    <InputText
                      v-model="approveNumber"
                      ref="approveNumberRef"
                      @keyup="changeApproveNumberInput($event, a, i)"
                      :id="`payment-approve-number${i}`"
                    />
                    <Button
                      icon="pi pi-check"
                      class="p-button-success"
                      style="width: 20px"
                      @click="changeApproveNumberExecution(a)"
                    />
                  </div>
                </div>
              </div>
              <div class="xl:text-base md:text-sm text-center w-3">
                {{
                  a.pay_in_installments === '00' || a.pay_in_installments === '0'
                    ? '일시불'
                    : a.pay_in_installments + '개월'
                }}
              </div>
            </div>
          </div>
          <div class="absolute" style="top: 6%; right: 1%">
            <Checkbox
              id="binary"
              :binary="true"
              v-model="a.active"
              @click="checkIndex(a.active, i)"
              :disabled="a.soogi && !soogi()"
            />
          </div>
        </template>
        <Fieldset
          v-for="(b, i) in initDataHeaderSub(a.payment_group_id)"
          v-show="b.payment_price"
          :key="i"
          :class="{ 'mt-2': i > 0 }"
          >{{ b.menu_name }}
          <template #legend>
            <div class="relative">
              {{ b.payment_price }}
              <div
                v-if="b.menu_id_before_change"
                class="absolute"
                style="top: -2rem; right: -3rem"
              >
                <Badge
                  :value="b.menu_id_before_change === b.menu_id ? '변경 전' : '변경 후'"
                  class="mr-2"
                  style="font-size: 13px"
                  :severity="b.menu_id_before_change === b.menu_id ? 'warning' : 'danger'"
                ></Badge>
              </div>
            </div>
          </template>
        </Fieldset>
      </AccordionTab>
    </Accordion>
  </div>
  <div v-if="store.state.paymentStore.incentiveDialogState">
    <IncentiveDialog />
    <IncentiveUpdateDialog />
    <IncentiveDeleteDialog />
  </div>
</template>

<script setup>
import { watchEffect, ref, getCurrentInstance, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import IncentiveDialog from './IncentiveDialog.vue';
import IncentiveUpdateDialog from './IncentiveUpdateDialog.vue';
import IncentiveDeleteDialog from './IncentiveDeleteDialog.vue';
import _ from 'lodash';
import Swal from 'sweetalert2';
import ChangeDate from './ChangeDate.vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;

const store = useStore();

const initDataCompare = ref([]);
const initData = ref();
const initHeader = ref();
const waitingGropuArray = ref([]);
const authList = ref();
const approveNumberIndex = ref();
const approveNumber = ref();
const approveNumberRef = ref();

watchEffect(() => {
  const oldValue = initDataCompare.value;
  const newValue = store.state.paymentStore.paymentInitPaymentData;

  if (!_.isEqual({ ...oldValue }, { ...newValue })) {
    initData.value = store.state.paymentStore.paymentInitPaymentData;
    initDataCompare.value = store.state.paymentStore.paymentInitPaymentData;
    initHeader.value = store.state.paymentStore.paymentInitPaymentHeadData;
  }

  if (
    !_.isEqual(
      [...waitingGropuArray.value],
      [_.map(store.state.paymentStore.paymentInitWaitingHeadData, 'payment_group_id')]
    )
  ) {
    waitingGropuArray.value = _.map(
      store.state.paymentStore.paymentInitWaitingHeadData,
      'payment_group_id'
    );
  }
});

onMounted(() => {
  authList.value = store.state.userAuthData;
});

onUnmounted(() => {
  initData.value = '';
  initHeader.value = '';
});

// 수기결제 버튼 권한
function soogi() {
  return (
    _.includes(authList.value, '000') ||
    _.includes(authList.value, '100') ||
    _.includes(authList.value, '110')
  );
}

// 실적분배 버튼 권한
function isIncentiveAuth() {
  return (
    _.includes(authList.value, '000') ||
    _.includes(authList.value, '100') ||
    _.includes(authList.value, '111')
  );
}

function initDataHeaderSubSum(value) {
  const filterValue = _.filter(initData.value, ['payment_group_id', value]);
  return $wonCurrency(_.sumBy(filterValue, 'payment_price'));
}

function initDataHeaderSub(value) {
  const filterValue = _.filter(initData.value, ['payment_group_id', value]);
  const filterMap = _.map(filterValue, (a) => {
    return {
      menu_name: a.menu_name,
      payment_price: $wonCurrency(a.payment_price),
      menu_id_before_change: a.menu_id_before_change,
      menu_id: a.menu_id,
    };
  });
  return filterMap;
}

function checkIndex(a, i) {
  _.forEach(initHeader.value, (a) => {
    a.active = false;
  });
  if (a) {
    initHeader.value[i].active = true;
  }
}

function topView() {
  return {
    paymentCount: _.filter(initHeader.value, (a) => {
      return !a.refund_active;
    }).length,
    paymentSum: $wonCurrency(
      _.sumBy(
        _.filter(initData.value, (a) => {
          return !a.refund_active;
        }),
        'payment_price'
      )
    ),
    refundCount: _.filter(initHeader.value, (a) => {
      return a.refund_active;
    }).length,
    refundSum: $wonCurrency(
      _.sumBy(
        _.filter(initData.value, (a) => {
          return a.refund_active;
        }),
        'payment_price'
      )
    ),
  };
}

const refundWaiting = _.debounce(async () => {
  const selectedFilter = _.filter(initHeader.value, (a) => a.active === true);
  const waiting = _.map(
    store.state.paymentStore.paymentInitWaitingHeadData,
    'payment_group_id'
  );

  const now = dayjs().tz('Asia/Seoul');
  const paymentDate = dayjs(selectedFilter[0].payment_approval_date);
  const datediff = selectedFilter[0].payment_approval_date
    ? now.diff(paymentDate, 'day')
    : 0;

  if (selectedFilter.length === 0) {
    Swal.fire('환불할 결제건을 선택해주세요', '', 'warning');
  } else if (datediff >= 14 && !soogi()) {
    Swal.fire('14일이 경과한 결제건은 운영팀을 통해 진행해주세요', '', 'warning');
  } else if (waiting.includes(selectedFilter[0].payment_group_id)) {
    Swal.fire('이미 환불대기에 있습니다.', '', 'warning');
  } else if (selectedFilter[0].refund_active) {
    Swal.fire('이미 환불되었습니다.', '', 'warning');
  } else {
    const payload = selectedFilter[0].payment_group_id;
    store.commit('paymentIndexActiveInit');

    await store.dispatch('paymentRefundWaiting', payload);
    Swal.fire('해당 결제건이 삭제대기로 이동되었습니다.', '', 'success');
  }
}, 250);

function isWaitingRefund() {
  const checkedValue = _.find(initHeader.value, { active: true });
  if (checkedValue.refund_active) {
    return true;
  }
  if (!waitingGropuArray.value.includes(checkedValue.payment_group_id)) {
    return true;
  }
  return false;
}

const openIncentiveDialog = _.debounce(async () => {
  // 인센티브 dialog 창 open
  const paymentHeadData = store.state.paymentStore.paymentInitPaymentHeadData.filter(
    (data) => data.active === true
  );
  if (paymentHeadData.length === 0) {
    Swal.fire('실적분배할 결제건을 선택해주세요', '', 'warning');
  } else {
    if (isWaitingRefund()) {
      authList.value = await store.state.userAuthData;

      if (isIncentiveAuth()) {
        store.commit('incentiveDialogStateCommit', true);
      } else {
        Swal.fire('권한 오류', '권한이 없습니다. 권한을 신청해주세요.', 'warning');
        store.commit('incentiveDialogStateCommit', false);
      }
    } else {
      Swal.fire('실적분배 오류', '환불대기중에는 실적분배를 할 수 없습니다.', 'warning');
    }
  }
});

function changeDateTransfer(data) {
  store.commit('paymentChangeDataCommit', data);
}

async function changeApproveNumber(e, data, index) {
  if (soogi() && data.soogi) {
    approveNumberIndex.value = index;
    approveNumber.value = data.payment_approval_number;
    setTimeout(() => {
      approveNumberRef.value[index].$el.focus();
    }, 100);
  }
}

function changeApproveNumberInput(e, data, index) {
  approveNumber.value = e.target.value.replace(/[^\d]/g, '');

  if (e.key === 'Enter') {
    changeApproveNumberExecution(data, index);
  } else if (e.key === 'Escape') {
    approveNumberIndex.value = '';
  }
}

async function changeApproveNumberExecution(data, index) {
  Swal.fire({
    icon: 'warning',
    title: '승인번호 변경',
    html: `${approveNumber.value} 해당 승인번호로 변경하시겠습니까?`,
    showDenyButton: true,
    confirmButtonText: '네',
    denyButtonText: `아니오`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      approveNumberIndex.value = '';
      await store.dispatch('paymentApproveNumberChange', {
        payment_id: data.payment_id,
        approve_number: approveNumber.value,
      });
    } else if (result.isDenied) {
      approveNumberIndex.value = '';
    }
  });
}
</script>

<script>
export default {
  name: 'PaymentSection',
  components: { IncentiveUpdateDialog, IncentiveDeleteDialog, ChangeDate },
};
</script>

<style scoped lang="scss">
.input-group-div {
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0;
  margin: 0;
  flex: 1 1 auto;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

::v-deep(.p-accordion .p-accordion-tab) {
  margin-bottom: 10px;
}

::v-deep(.p-badge.p-badge-lg) {
  font-size: 12px;
}
</style>
