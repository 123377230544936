<template>
  <Toast position="top-right" group="reception-modal" />
  <div class="grid">
    <div class="col-2"></div>
    <div class="col-8">
      <div
        class="pb-2 mb-3 border-bottom-1 border-400 flex justify-content-between align-items-end"
      >
        <span class="text-2xl">접수</span>
        <div class="inline" v-if="recepitonDataDate?.id">
          <span class="text-indigo-600"> 접수가 완료되었습니다.</span>
        </div>
        <div class="inline" v-else-if="reserveDataDate?.reservation_date">
          <span class="text-pink-600"> 해당 날짜에 예약된 데이터를 불러왔습니다.</span>
        </div>
      </div>
      <div class="grid">
        <div class="col-2 w-1-5" v-if="calendarShow()">
          <Calendar
            v-model="reserveDataDate.reservation_time"
            @date-select="reserveTimeSelected($event)"
            :timeOnly="true"
            hourFormat="24"
            class="w-full"
            :stepMinute="10"
            disabled
          />
        </div>
        <div class="col-2 w-1-5">
          <div class="p-inputgroup mb-2">
            <InputMask
              id="startButton"
              mask="99:99"
              placeholder="24시:분"
              v-model="applyTime"
              :disabled="!isModifyTime"
            />
            <Button
              v-if="!isModifyTime"
              icon="pi pi-stopwatch"
              v-tooltip.top="'접수시간입력'"
              @click="isModifyTime = true"
            />
            <Button
              v-if="isModifyTime && !recepitonDataDate.reception_time"
              class="p-button-secondary"
              icon="pi pi-replay"
              v-tooltip.top="'현시간으로 복구'"
              @click="
                isModifyTime = false;
                applyTimeFunction();
              "
            />
            <Button
              v-if="isModifyTime && recepitonDataDate.reception_time"
              class="p-button-success"
              icon="pi pi-check"
              v-tooltip.top="'시간수정'"
              @click="modifyReceptionTime()"
            />
          </div>
        </div>
        <div
          :class="{
            'col-4 w-3-5': !calendarShow(),
            'col-3': calendarShow(),
          }"
        >
          <div class="p-inputgroup mb-2">
            <span class="p-inputgroup-addon">
              <i class="pi pi-pencil"></i>
            </span>
            <Dropdown
              v-model="v$.reception_reason.$model"
              @change="addCounselorSelect()"
              :options="receptionReason()"
              optionLabel="name"
              optionValue="code"
              placeholder="접수사유선택"
              :disabled="disabledIs() && !isModifyState"
              :class="{
                'p-invalid': v$.reception_reason.$invalid && receptionSummit,
              }"
            />
          </div>
        </div>
        <div
          :class="{
            'col-4 w-3-5': !calendarShow(),
            'col-3': calendarShow(),
          }"
        >
          <div class="p-inputgroup mb-2">
            <span class="p-inputgroup-addon">
              <i class="pi pi-directions"></i>
            </span>
            <Dropdown
              v-model="v$.after_process.$model"
              :options="afterProcess"
              optionLabel="name"
              optionValue="code"
              placeholder="예상절차"
              :disabled="disabledIs() && !isModifyState"
              :class="{
                'p-invalid': v$.after_process.$invalid && receptionSummit,
              }"
            />
          </div>
        </div>
        <div
          :class="{
            'col-3 w-3-5': !calendarShow(),
            'col-3': calendarShow(),
          }"
        >
          <div class="p-inputgroup mb-2">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user" :disabled="!addCounselorSelect()"></i>
            </span>
            <Dropdown
              v-model="v$.counselor_id.$model"
              :options="receptionCounselorData"
              optionLabel="name"
              optionValue="id"
              placeholder="상담실장"
              :disabled="(disabledIs() && !isModifyState) || !addCounselorSelect()"
              :class="{
                'p-invalid': v$.counselor_id.$invalid && receptionSummit,
              }"
            />
          </div>
        </div>
      </div>
      <div class="mb-3">
        <Editor
          @text-change="
            memoStateChange($event);
            receptionMemoChange($event);
          "
          @selection-change="memoSelection($event)"
          v-if="!memoEditState"
          v-model="receptionMemoData.memo"
          editorStyle="height: 130px;"
        >
          <template v-slot:toolbar>
            <span class="ql-formats">
              <div>접수메모</div>
            </span>
          </template>
        </Editor>
        <div v-else>
          <Editor
            v-model="receptionMemoData.memo"
            editorStyle="height: 145px;"
            :readonly="true"
          >
            <template v-slot:toolbar>
              <span class="ql-formats">
                <div class="relative">
                  <div>접수메모</div>
                  <Message
                    class="absolute"
                    severity="info"
                    :closable="false"
                    style="
                      top: -21px;
                      right: -178px;
                      width: 172px;
                      height: 31px;
                      display: flex;
                    "
                    >{{ receptionMemoData.name }} 편집 중</Message
                  >
                </div>
              </span>
            </template>
          </Editor>
        </div>
        <div class="flex justify-content-end mt-3">
          <Button
            label="수정"
            icon="pi pi-check"
            class="p-button-sm"
            :class="isDeleteState"
            @click="isModifyState = true"
            v-if="!isModifyState && modifyReceptionAuth() && disabledIs()"
          />

          <Button
            label="수정확인"
            icon="pi pi-check"
            severity="success"
            class="p-button-sm"
            :class="isDeleteState"
            @click="displayModifyConfirmation = true"
            v-if="isModifyState && modifyReceptionAuth()"
          />

          <Button
            label="삭제"
            icon="pi pi-times"
            class="p-button-sm p-button-danger"
            @click="displayConfirmation = true"
            v-if="disabledIs() && deleteButton()"
          />

          <Button
            label="저장"
            icon="pi pi-check"
            class="p-button-sm"
            @click="saveReceiption"
            v-if="!disabledIs()"
          />
        </div>
      </div>
    </div>

    <div class="col-2"></div>
  </div>

  <Dialog
    header="접수 삭제 확인"
    v-model:visible="displayConfirmation"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content flex align-items-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>삭제하시겠습니까?</span>
    </div>
    <template #footer>
      <Button
        label="아니오"
        icon="pi pi-times"
        @click="displayConfirmation = false"
        class="p-button-text"
      />
      <Button
        label="네"
        icon="pi pi-check"
        @click="deleteReserve"
        class="p-button-text"
      />
    </template>
  </Dialog>

  <Dialog
    header="접수 수정 확인"
    v-model:visible="displayModifyConfirmation"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content flex align-items-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>수정하시겠습니까?</span>
    </div>
    <template #footer>
      <Button
        label="아니오"
        icon="pi pi-times"
        @click="
          displayModifyConfirmation = false;
          isModifyState = false;
        "
        class="p-button-text"
      />
      <Button
        label="네"
        icon="pi pi-check"
        @click="modifyReception"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script setup>
import axios from 'axios';
import _ from 'lodash';
import {
  ref,
  onBeforeMount,
  getCurrentInstance,
  onBeforeUnmount,
  watchEffect,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import omniList from './OmniList';
import { useIdle } from '@vueuse/core';
import dayjs from 'dayjs';

const { idle } = useIdle(1000 * 2);
const receptionData = ref([]);
const recepitonDataCompare = ref([]);
const recepitonDataDate = ref([]);
const receptionMemoData = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const receptionMemoDataCompare = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const receptionCounselorData = ref();
const memoAsyncState = ref(true);
const memoEditState = ref(false);
const receptionSummit = ref(false);
const reserveData = ref([]);
const reserveDataCompare = ref([]);
const reserveDataDate = ref([]);
const displayConfirmation = ref(false);
const isModifyState = ref(false);
const displayModifyConfirmation = ref(false);
const isDeleteState = computed(() => {
  return {
    'mr-2': deleteButton(),
  };
});
const authList = ref();
const isCounselorNeeded = ref(false);

const store = useStore();
const toast = useToast();
const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

// 시간수정 기능
const applyTime = ref();
const isModifyTime = ref(false);

const reserveReason = ref([]);

const smartCRMReason = ref({});

const afterProcess = ref([]);

const rules = {
  after_process: { required },
  counselor_id: {},
  customer_id: {},
  id: {},
  reception_date: {},
  reception_reason: { required },
  reception_time: {},
  reservation_id: {},
};
const v$ = useVuelidate(rules, recepitonDataDate);

onBeforeMount(async () => {
  await refetchReception();

  reserveReason.value = omniList.reserveReason;
  smartCRMReason.value = omniList.smartCRMReason;
  afterProcess.value = omniList.afterProcess;
  const reserveFrontDate = store.state.board.boardModal.boardSendDate;
  dateSelectedFilter(reserveFrontDate);

  authList.value = await store.state.userAuthData;

  addCounselorSelect();

  // 접수시간 반영
  setInterval(async () => {
    await applyTimeFunction();
  }, 1000 * 30);
});

onBeforeUnmount(() => {
  store.commit('deleteTimeout', 'modal-reception');
  store.commit('boardSpecialDay1', []);
  store.commit('boardSpecialDay2', []);
  applyTime.value = '';
  authList.value = '';
  isCounselorNeeded.value = false;
});

watchEffect(() => {
  memoEditState.value =
    store.state.loginUserData.user_id === receptionMemoData.value.user_id
      ? false
      : receptionMemoData.value.editing;
});

function deleteButton() {
  const filter = _.filter(
    store.state.modalTop.modalTopBoardCompare.receiption,
    (a) => a.process !== '접수'
  );
  const counselFilter = _.filter(
    store.state.modalTop.modalTopBoardCompare.receiption,
    (a) => a.process === '상담'
  );

  if (filter.length === 1 && filter[0].process === null) {
    return true;
  } else if (
    counselFilter.length >= 1 &&
    counselFilter[0].start_time === '00:00' &&
    !counselFilter[0].end_time
  ) {
    return true;
  } else {
    return filter.length === 0;
  }
}

async function applyTimeFunction() {
  // 30초마다 업데이트
  if (!isModifyTime.value) {
    applyTime.value = recepitonDataDate.value.reception_time
      ? recepitonDataDate.value.reception_time
      : dayjs().format('HH:mm');
  }
  return applyTime.value;
}

async function receiptionInit(result) {
  const receptionDataOld = recepitonDataCompare.value;
  const receptionComare = !_.isEqual([...receptionDataOld], [...result.reception]);
  if (receptionComare) {
    receptionData.value = _.cloneDeep(result.reception);
    recepitonDataCompare.value = _.cloneDeep(result.reception);

    store.commit(
      'boardSpecialDay1',
      receptionData.value.map((a) => a.reception_date)
    );
  }

  const reserveDataOld = reserveDataCompare.value;
  const reserveCompare = !_.isEqual([...reserveDataOld], [...result.reserve]);
  const receptionDates = store.state.board.boardModal.specailDay1;
  if (reserveCompare) {
    reserveData.value = _.cloneDeep(result.reserve);
    reserveDataCompare.value = _.cloneDeep(result.reserve);
    store.commit(
      'boardSpecialDay2',

      _.difference(
        reserveData.value.map((a) => a.reservation_date),
        [...receptionDates]
      )
    );
  }

  const reserveFrontDate = store.state.board.boardModal.boardSendDate;
  dateSelectedFilter(reserveFrontDate);

  const oldMemoValue = receptionMemoDataCompare.value;
  const newMemoValue =
    result.memo.length > 0
      ? result.memo[0]
      : {
          memo: null,
          customer_id: null,
          editing: null,
        };
  if (!_.isEqual(oldMemoValue, newMemoValue)) {
    receptionMemoData.value = result.memo.length > 0 ? _.cloneDeep(result.memo[0]) : '';
    receptionMemoDataCompare.value =
      result.memo.length > 0 ? _.cloneDeep(result.memo[0]) : '';
  }

  const oldCounselorValue = receptionCounselorData.value;
  const newCounselorValue = result.counselor;
  if (!_.isEqual(oldCounselorValue, newCounselorValue)) {
    receptionCounselorData.value = result.counselor.length > 0 ? newCounselorValue : null;
  }
}

function clearFunc() {
  store.commit('boardModalStateFalse');
}

const calendarShow = () => {
  return recepitonDataDate.value?.id
    ? false
    : reserveDataDate.value?.reservation_time
    ? true
    : false;
};

function dateSelectedFilter(selected) {
  const receiptionDataFilter = _.cloneDeep(receptionData.value).filter(
    (a) => a.reception_date === $formatDate('date', selected)
  );
  const reserveDataFilter = _.cloneDeep(reserveData.value).filter(
    (a) => a.reservation_date === $formatDate('date', selected)
  );

  recepitonDataDate.value =
    receiptionDataFilter.length > 0
      ? receiptionDataFilter[0]
      : reserveDataFilter.length > 0
      ? {
          after_process: reserveDataFilter[0].after_process,
          customer_id: '',
          id: '',
          reception_date: $formatDate('date', selected),
          reception_reason: reserveDataFilter[0].reservation_reason,
          reception_time: '',
          reservation_id: '',
        }
      : {
          after_process: '',
          customer_id: '',
          id: '',
          reception_date: $formatDate('date', selected),
          reception_reason: '',
          reception_time: '',
          reservation_id: '',
        };
  reserveDataDate.value = reserveDataFilter.length > 0 ? reserveDataFilter[0] : {};
  applyTimeFunction();
}

const receptionReason = () => {
  if (reserveDataDate.value?.reserve_reason_data) {
    const reserveSmartCRMReason = reserveDataDate.value?.reserve_reason_data;
    return smartCRMReason.value[reserveSmartCRMReason]
      ? smartCRMReason.value[reserveSmartCRMReason]
      : reserveReason.value;
  }

  if (!recepitonDataDate.value.reception_reason) {
    recepitonDataDate.value.reception_reason = reserveDataDate.value.reservation_reason;
    recepitonDataDate.value.after_process = reserveDataDate.value.after_process;
  }

  return reserveReason.value;
};

const addCounselorSelect = () => {
  if (v$.value.reception_reason.$model) {
    if (/성형상담|쁘띠상담/.test(v$.value.reception_reason.$model)) {
      isCounselorNeeded.value = true;
      return true;
    } else {
      v$.value.counselor_id.$model = null;
      isCounselorNeeded.value = false;
      return false;
    }
  } else {
    isCounselorNeeded.value = false;
    return false;
  }
};

const disabledIs = () => {
  if (recepitonDataDate.value.id) {
    return true;
  } else {
    return false;
  }
};

// 시간 맞게 작성하였는지 검사
function isValidTime(timeStr) {
  // 정규식을 이용하여 24시간 HH:mm 형식의 문자열인지 확인
  const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(timeStr)) {
    return false;
  }

  // 시간과 분을 추출
  const [hours, minutes] = timeStr.split(':');

  // 시간과 분이 각각 0부터 23, 0부터 59 사이의 값인지 확인
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return false;
  }

  return true;
}

async function modifyReceptionTime() {
  const { id } = recepitonDataDate.value;
  const reception_time = applyTime.value
    ? applyTime.value
    : `timezone('KST'::text, now())`;

  if (!isValidTime(reception_time)) {
    toast.add({
      severity: 'error',
      summary: '접수시간 양식 오류',
      detail: '접수시간을 양식에 맞게 작성해주세요.',
      life: 3000,
    });
  } else {
    const sendData = {
      id: id ? id : 0,
      reception_time,
    };

    try {
      store.commit('loadingChangeValue', true);
      await axios.post(
        store.state.URL + `/registration-modal/update_reception_time`,
        sendData
      );
      isModifyTime.value = false;
      toast.add({
        severity: 'success',
        summary: '접수시간 수정완료',
        detail: '접수시간 수정이 완료되었습니다.',
        life: 3000,
      });

      refetchReception();
    } catch (error) {
      $errorHandling(null, error, clearFunc);
    }
  }
}

async function saveReceiption() {
  receptionSummit.value = true;
  const { id, after_process, reception_date, reception_reason, counselor_id } =
    recepitonDataDate.value;
  const customer_id = store.state.board.boardModal.boardSendCutomerCode;
  const reserve_id = reserveDataDate.value?.id ? reserveDataDate.value?.id : null;
  const reception_time = applyTime.value;

  if (!isValidTime(reception_time)) {
    toast.add({
      severity: 'error',
      summary: '접수시간 양식 오류',
      detail: '접수시간을 양식에 맞게 작성해주세요.',
      life: 3000,
    });
  } else {
    const sendData = {
      id: id ? id : 0,
      after_process,
      reception_date,
      reception_time,
      reception_reason,
      counselor_id: counselor_id ? counselor_id : null,
      customer_id,
      reserve_id,
    };

    if (
      [!v$.value.after_process.$invalid, !v$.value.reception_reason.$invalid].every(
        (a) => a
      )
    ) {
      try {
        store.commit('loadingChangeValue', true);
        await axios.post(
          store.state.URL + `/registration-modal/update_reception_info`,
          sendData
        );
        receptionSummit.value = false;
        isModifyTime.value = false;
        toast.add({
          severity: 'success',
          summary: '접수완료',
          detail: '접수가 완료되었습니다.',
          life: 3000,
        });

        refetchReception();
      } catch (error) {
        $errorHandling(null, error, clearFunc);
      }
    }
  }
}

async function modifyReception() {
  receptionSummit.value = true;
  const { id, after_process, start_time, reception_reason, counselor_id } =
    recepitonDataDate.value;
  const reception_time = applyTime.value === start_time ? start_time : applyTime.value;

  if (!isValidTime(reception_time)) {
    toast.add({
      severity: 'error',
      summary: '접수시간 양식 오류',
      detail: '접수시간을 양식에 맞게 작성해주세요.',
      life: 3000,
    });
  } else {
    if (!modifyReceptionAuth()) {
      Swal.fire('권한 오류', '권한이 없습니다. 권한을 신청해주세요.', 'warning');
    } else {
      const sendData = {
        receptionId: id ? id : 0,
        receptionTime: reception_time,
        receptionReason: reception_reason,
        afterProcess: after_process,
        counselorId: counselor_id ? counselor_id : null,
      };

      if (
        [!v$.value.after_process.$invalid, !v$.value.reception_reason.$invalid].every(
          (a) => a
        )
      ) {
        try {
          store.commit('loadingChangeValue', true);
          await axios.put(
            store.state.URL + `/registration-modal/modify-reception`,
            sendData
          );
          receptionSummit.value = false;
          isModifyTime.value = false;
          isModifyState.value = false;
          displayModifyConfirmation.value = false;

          toast.add({
            severity: 'success',
            summary: '접수수정완료',
            detail: '접수수정이 완료되었습니다.',
            life: 3000,
          });

          refetchReception();
        } catch (error) {
          $errorHandling(null, error, clearFunc);
        }
      }
    }
  }
}

async function deleteReserve() {
  const { id } = recepitonDataDate.value;
  try {
    store.commit('loadingChangeValue', true);
    await axios.post(store.state.URL + '/registration-modal/delete_reception_info', {
      id,
    });

    refetchReception();
    displayConfirmation.value = false;
    toast.add({
      severity: 'success',
      summary: '삭제완료',
      detail: '삭제가 완료되었습니다.',
      life: 3000,
    });
  } catch (error) {
    $errorHandling(null, error, clearFunc);
  }
}

async function memoStateChange() {
  if (memoAsyncState.value) {
    memoAsyncState.value = false;
    const customerCode = store.state.board.boardModal.boardSendCutomerCode;

    await axios.post(store.state.URL + '/registration-modal/memo-state', {
      state: true,
      customerCode,
      classification: '접수',
    });
  }
}

function memoSelection(e) {
  // eslint-disable-next-line
  let stayMemo = setTimeout(function tick() {
    if (idle.value) {
      e.instance.root.blur();
    } else {
      stayMemo = setTimeout(tick, 1000);
    }
  }, 1000);
}

const receptionMemoChange = _.debounce(async (e) => {
  const memoValue = e.htmlValue;
  const customerCode = store.state.board.boardModal.boardSendCutomerCode;
  memoAsyncState.value = true;

  const result = await axios.put(store.state.URL + '/registration-modal/memo-upsert', {
    memoValue,
    customerCode,
    classification: '접수',
  });

  if (!result.data.is) {
    toast.add({
      severity: 'error',
      summary: '메모가 저장에러',
      detail: '메모가 저장되지 않았습니다.',
      life: 3000,
    });
  }
}, 1000);

// 접수수정 버튼 권한
function modifyReceptionAuth() {
  return (
    _.includes(authList.value, '000') ||
    _.includes(authList.value, '100') ||
    _.includes(authList.value, '114')
  );
}

// 접수 조회 초기화 함수
async function refetchReception() {
  store.commit('loadingChangeValue', true);

  const result = await axios.get(
    store.state.URL +
      `/registration-modal/today_reception_info?code=${store.state.board.boardModal.boardSendCutomerCode}`
  );

  receiptionInit(result.data);
  store.commit('loadingChangeValue', false);
}
</script>

<style>
.reception-special-day {
  color: red;
  font-weight: 800;
  text-decoration: underline;
}

.reservation-special-day {
  color: blue;
  font-weight: 800;
  text-decoration: underline;
}
</style>
<style lang="scss">
.w-1-5 {
  width: 12.5% !important;
}
.w-3-5 {
  width: 29.1667% !important;
}
</style>
