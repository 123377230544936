<!-- 예약 -->
<template>
  <Toast position="top-right" group="reserve-modal" />

  <div class="grid">
    <div class="col-2"></div>
    <div class="col-8">
      <div
        class="pb-2 mb-3 border-bottom-1 border-400 flex justify-content-between align-items-end"
      >
        <span class="text-2xl">예약</span>
        <span v-if="receptionState()" class="text-pink-600">
          해당 예약건은 접수가 완료되었습니다.</span
        >
        <span
          class="text-blue-600"
          v-if="reserveInput.id && !receptionState() && reserveInput.reserve_reason_data"
          >SmartCRM 예약건을 불러왔습니다.</span
        >
      </div>
      <div class="grid">
        <div class="col-4">
          <Calendar
            v-model="v$.reservation_time.$model"
            @date-select="reserveTimeSelected($event)"
            :timeOnly="true"
            hourFormat="24"
            class="w-full"
            :stepMinute="10"
            :class="{
              'p-invalid': v$.reservation_time.$invalid && reserveSummit,
            }"
            :disabled="receptionState() ? true : false"
          />
        </div>
        <div class="col-4">
          <div v-if="smartcrm_notexist()">
            <div class="p-inputgroup mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-pencil"></i>
              </span>
              <Dropdown
                v-model="v$.reservation_reason.$model"
                :options="smartcrm_exist()"
                optionLabel="name"
                optionValue="code"
                placeholder="상담사유선택"
                :class="{
                  'p-invalid': v$.reservation_reason.$invalid && reserveSummit,
                }"
                :disabled="receptionState() ? true : false"
              />
            </div>
          </div>
          <div v-else>
            <div class="p-inputgroup mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-pencil"></i>
              </span>
              <Dropdown
                v-model="v$.reservation_reason.$model"
                :options="reserveReason"
                optionLabel="name"
                optionValue="code"
                placeholder="예약사유선택"
                :class="{
                  'p-invalid': v$.reservation_reason.$invalid && reserveSummit,
                }"
                :disabled="receptionState() ? true : false"
              />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="p-inputgroup mb-2">
            <span class="p-inputgroup-addon"> <i class="pi pi-directions"></i> </span>
            <Dropdown
              v-model="v$.after_process.$model"
              :options="afterProcess"
              optionLabel="name"
              optionValue="code"
              placeholder="예상절차"
              :class="{
                'p-invalid': v$.after_process.$invalid && reserveSummit,
              }"
              :disabled="receptionState() ? true : false"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <Editor
          @text-change="
            memoStateChange($event);
            ReserveMemoChange($event);
          "
          @selection-change="memoSelection($event)"
          v-if="!memoEditState"
          v-model="reserveMemo.memo"
          editorStyle="height: 130px;"
        >
          <template v-slot:toolbar>
            <span class="ql-formats">
              <div>예약메모</div>
            </span>
          </template>
        </Editor>
        <div v-else>
          <Editor
            v-model="reserveMemo.memo"
            editorStyle="height: 145px;"
            :readonly="true"
          >
            <template v-slot:toolbar>
              <span class="ql-formats">
                <div class="relative">
                  <div>예약메모</div>
                  <Message
                    class="absolute"
                    severity="info"
                    :closable="false"
                    style="
                      top: -21px;
                      right: -178px;
                      width: 172px;
                      height: 31px;
                      display: flex;
                    "
                    >{{ reserveMemo.name }} 편집 중</Message
                  >
                </div>
              </span>
            </template>
          </Editor>
        </div>
      </div>
      <div class="flex justify-content-end">
        <Button
          label="날짜변경"
          icon="pi pi-times"
          class="p-button-sm p-button-secondary mr-2"
          v-if="receptionState() === true ? false : reserveDeleteButtonIs()"
          aria-haspopup="true"
          aria-controls="reserve_change_panel"
          @click="reserve_change_toggle"
        />
        <OverlayPanel ref="reserve_change_op" appendTo="body" id="reserve_change_panel">
          <Calendar
            dateFormat="yy-mm-dd"
            :inline="true"
            :showWeek="true"
            @date-select="reserveChangeSelected($event)"
          >
            <template #date="slotProps">
              <strong v-if="reservationDateDeco(slotProps)" class="special-day">{{
                slotProps.date.day
              }}</strong>
              <template v-else>{{ slotProps.date.day }}</template>
            </template>
          </Calendar>
        </OverlayPanel>
        <Button
          @click="displayConfirmation = true"
          label="삭제"
          icon="pi pi-times"
          class="p-button-sm p-button-danger mr-2"
          v-if="receptionState() === true ? false : reserveDeleteButtonIs()"
        />
        <Button
          @click="reserveUpdate"
          :label="reserveDeleteButtonIs() ? '수정' : '저장'"
          icon="pi pi-check"
          class="p-button-sm"
          v-if="!receptionState()"
        />
      </div>
    </div>
    <div class="col-2"></div>
  </div>

  <Dialog
    header="예약 삭제 확인"
    v-model:visible="displayConfirmation"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content flex align-items-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>삭제하시겠습니까?</span>
    </div>
    <template #footer>
      <Button
        label="아니오"
        icon="pi pi-times"
        @click="displayConfirmation = false"
        class="p-button-text"
      />
      <Button
        label="네"
        icon="pi pi-check"
        @click="reserveDelete"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script setup>
import {
  ref,
  onBeforeMount,
  getCurrentInstance,
  onBeforeUnmount,
  watchEffect,
} from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import axios from 'axios';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import omniList from './OmniList';
import { useIdle } from '@vueuse/core';

const { idle } = useIdle(1000 * 2);
const reserveMemo = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const reserveMemoCompare = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const memoAsyncState = ref(true);
const memoEditState = ref(false);
const reserveInput = ref([]);
const reserveData = ref([]);
const reserveDataCompare = ref([]);
const receptionData = ref([]);
const receptionDataCompare = ref([]);
const reserveSummit = ref(false);
const displayConfirmation = ref(false);
const reserve_change_op = ref();
const toggleEvnet = ref();

const store = useStore();
const toast = useToast();
const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

const reserveReason = omniList.reserveReason;
const smartCRMReason = omniList.smartCRMReason;
const afterProcess = omniList.afterProcess;

onBeforeMount(async () => {
  const timeoutData = {
    name: 'modal-reserve',
    url: '/registration-modal/today_reservation_info?code=$customer_id',
    module: 'modalReserve',
    compareData: 'modalReserveCompare',
  };

  await store.dispatch('timeoutCommonFunc', timeoutData);
});

onBeforeUnmount(() => {
  store.commit('deleteTimeout', 'modal-reserve');
  store.commit('boardSpecialDay1', []);
  reserveSummit.value = false;
});

watchEffect(() => {
  const result = store.state.modalReserve.modalReserveCompare;
  result && reserveInit(result);
});

watchEffect(() => {
  const selectedDate = store.state.board.boardModal.boardSendDate;
  const filterLoadData = _.cloneDeep(reserveData.value).filter((a) => {
    if (a.reservation_date) {
      return $formatDate('date', a.reservation_date) === selectedDate;
    } else false;
  });

  if (filterLoadData.length > 0) {
    reserveInput.value = filterLoadData[0];
  } else {
    reserveInitAfter();
  }
});

watchEffect(() => {
  memoEditState.value =
    store.state.loginUserData.user_id === reserveMemo.value.user_id
      ? false
      : reserveMemo.value.editing;
});

async function reserveInit(result) {
  const reserveLoadData = result.reserve;
  const reserveLoadMemo = result.memo;
  const receptionlLoadData = result.reception;
  const oldValue = reserveDataCompare.value;

  if (!_.isEqual([...oldValue], [...reserveLoadData])) {
    store.commit(
      'boardSpecialDay1',
      reserveLoadData.length > 0 ? reserveLoadData.map((a) => a.reservation_date) : []
    );

    reserveData.value = _.cloneDeep(reserveLoadData);
    reserveDataCompare.value = _.cloneDeep(reserveLoadData);
  }

  const oldMemoValue = reserveMemoCompare.value;
  const newMemoValue =
    reserveLoadMemo.length > 0
      ? reserveLoadMemo[0]
      : {
          memo: null,
          customer_id: null,
          editing: null,
        };
  if (!_.isEqual(oldMemoValue, newMemoValue)) {
    reserveMemo.value = _.cloneDeep(newMemoValue);
    reserveMemoCompare.value = _.cloneDeep(reserveMemo.value);
  }

  const oldReceptionData = receptionData.value;

  if (!_.isEqual(oldReceptionData, receptionlLoadData)) {
    receptionData.value = _.cloneDeep(receptionlLoadData);
    receptionDataCompare.value = _.cloneDeep(receptionlLoadData);
  }
}

function reserveInitAfter() {
  const firstData = _.cloneDeep(reserveData.value).filter(
    (a) =>
      a.reservation_date ===
      $formatDate('date', store.state.board.boardModal.boardSendDate)
  )[0];

  reserveInput.value =
    reserveData.value.length > 0
      ? firstData
        ? firstData
        : {
            after_process: '',
            reservation_date: store.state.board.boardModal.boardSendDate,
            reservation_reason: '',
            reservation_time: '',
            reserve_reason_data: '',
          }
      : {
          after_process: '',
          reservation_date: store.state.board.boardModal.boardSendDate,
          reservation_reason: '',
          reservation_time: '',
          reserve_reason_data: '',
        };
}

function clearFunc() {
  store.commit('boardModalStateFalse');
}

const rules = {
  after_process: { required },
  reservation_date: {},
  reservation_reason: { required },
  reservation_time: { required },
  reserve_reason_data: {},
};

const v$ = useVuelidate(rules, reserveInput);

const smartcrm_exist = () => {
  const smartcrm_reason = reserveInput.value.reserve_reason_data;
  return smartCRMReason[smartcrm_reason]
    ? smartCRMReason[smartcrm_reason]
    : omniList.reserveReason;
};

function smartcrm_notexist() {
  if (reserveInput.value && reserveInput.value.reserve_reason_data) {
    return true;
  } else {
    return false;
  }
}

function reserveTimeSelected(e) {
  const selectedTime = $formatDate('time', new Date(e));
  selectedTime;
}

const ReserveMemoChange = _.debounce(async (e) => {
  const memoValue = e.htmlValue;
  const customerCode = store.state.board.boardModal.boardSendCutomerCode;
  const result = await axios.put(store.state.URL + '/registration-modal/memo-upsert', {
    memoValue,
    customerCode,
    classification: '예약',
  });
  if (!result.data.is) {
    toast.add({
      severity: 'error',
      summary: '메모가 저장에러',
      detail: '메모가 저장되지 않았습니다.',
      life: 3000,
    });
  }
}, 1000);

const reserveUpdate = _.debounce(async () => {
  reserveSummit.value = true;

  if (!v$.value.$invalid) {
    const sendData = {
      ...reserveInput.value,
      reservation_time: reserveInput.value.reservation_time
        ? /^\d{2}:\d{2}$/.test(reserveInput.value.reservation_time)
          ? reserveInput.value.reservation_time
          : $formatDate('time', new Date(reserveInput.value.reservation_time))
        : reserveInput.value.reservation_time,
      customer_id: store.state.board.boardModal.boardSendCutomerCode,
    };

    try {
      store.commit('loadingChangeValue', true);
      const result = await axios.post(
        store.state.URL + '/registration-modal/update_reservation_info',
        sendData
      );
      if (result.data.reserve) {
        toast.add({
          severity: 'success',
          summary: '예약저장이 완료',
          detail: '예약저장이 완료 되었습니다.',
          group: 'reserve-modal',
          life: 3000,
        });
      }
    } catch (error) {
      $errorHandling(null, error, clearFunc);
    }
  }
}, 500);

const reserveDelete = _.debounce(async () => {
  const reserveCode = reserveInput.value?.id;

  try {
    store.commit('loadingChangeValue', true);
    await axios.post(store.state.URL + '/registration-modal/reservation_delete', {
      reserveCode,
    });
    displayConfirmation.value = false;
    toast.add({
      severity: 'success',
      summary: '삭제완료',
      detail: '삭제가 완료되었습니다.',
      life: 3000,
    });
  } catch (error) {
    $errorHandling(null, error, clearFunc);
  }
}, 500);

const reserveDeleteButtonIs = () => {
  if (reserveInput.value?.id) {
    if (reserveInput.value.reserve_reason_data) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const reserve_change_toggle = (event) => {
  reserve_change_op.value.toggle(event);
  toggleEvnet.value = event;
};

async function reserveChangeSelected(e) {
  const changeData = $formatDate('date', e);
  const spcialDates = store.state.board.boardModal.specailDay1;

  if (spcialDates.includes(changeData)) {
    toast.add({
      severity: 'warn',
      summary: '날짜오류',
      detail: '해당날짜에는 이미 예약이 있습니다.',
      life: 3000,
    });
  } else {
    const reserveCode = reserveInput.value.id;

    try {
      store.commit('loadingChangeValue', true);
      await axios.post(store.state.URL + '/registration-modal/reservation_date_change', {
        reserveCode,
        changeData,
      });
      reserveInput.value.reservation_date = changeData;
      reserveSummit.value = false;

      toast.add({
        severity: 'success',
        summary: '날짜변경 완료',
        detail: '날짜변경이 완료되었습니다.',
        life: 3000,
      });
    } catch (error) {
      $errorHandling(null, error, clearFunc);
    }
  }

  reserve_change_toggle(toggleEvnet.value);
}

function reservationDateDeco(slotProps) {
  const dataFormat = `${slotProps.date.year}-${_.padStart(
    String(slotProps.date.month + 1),
    2,
    '0'
  )}-${_.padStart(String(slotProps.date.day), 2, '0')}`;
  const spcialDate1 = store.state.board.boardModal.specailDay1;

  return spcialDate1.includes(dataFormat);
}

async function memoStateChange() {
  if (memoAsyncState.value) {
    memoAsyncState.value = false;
    const customerCode = store.state.board.boardModal.boardSendCutomerCode;
    await axios.post(store.state.URL + '/registration-modal/memo-state', {
      state: true,
      customerCode,
      classification: '예약',
    });
  }
}

function memoSelection(e) {
  // eslint-disable-next-line
  let stayMemo = setTimeout(function tick() {
    if (idle.value) {
      e.instance.root.blur();
    } else {
      stayMemo = setTimeout(tick, 1000);
    }
  }, 1000);
}

function receptionState() {
  const modalDate = store.state.board.boardModal.boardSendDate;
  return receptionData.value.some((a) => a === modalDate);
}
</script>

<style scoped>
.special-day {
  color: red;
  font-weight: 800;
  text-decoration: underline;
}
</style>
