<template>
  <div>
    <Editor
      v-model="treatMemo.memo"
      editorStyle="height: 170px"
      @text-change="
        memoStateChange($event);
        treatMemoChange($event);
      "
      @selection-change="memoSelection($event)"
      v-if="!memoEditState"
    >
      <template v-slot:toolbar>
        <span class="mb-2">디자인(진료)메모 </span>
      </template>
    </Editor>
    <div v-else>
      <Editor v-model="treatMemo.memo" editorStyle="height: 170px;" :readonly="true">
        <template v-slot:toolbar>
          <span class="ql-formats">
            <div class="relative">
              <div>디자인(진료)메모</div>
              <Message
                class="absolute"
                severity="info"
                :closable="false"
                style="
                  top: -21px;
                  right: -178px;
                  width: 172px;
                  height: 31px;
                  display: flex;
                "
                >{{ treatMemo.name }} 편집 중</Message
              >
            </div>
          </span>
        </template>
      </Editor>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, watchEffect, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import _ from 'lodash';
import { useIdle } from '@vueuse/core';
import axios from 'axios';

const { idle } = useIdle(1000 * 2);
const memoAsyncState = ref(true);
const memoEditState = ref(false);
const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
const treatMemo = computed({
  get: () => {
    return store.state.treatStore.treatMemo;
  },
  set: (value) => {
    store.commit('setTreatMemo', value);
  },
});

watchEffect(() => {
  const memo = store.state.treatStore.treatMemoCompare;

  let memoValue;
  if (memo) {
    memoValue = !_.isEqual(memo.data, [])
      ? memo.data[0]
      : {
          memo: null,
          customer_id: null,
          editing: null,
        };
  } else {
    memoValue = {
      memo: null,
      customer_id: null,
      editing: null,
    };
  }

  store.commit('setTreatMemo', memoValue);
});

watchEffect(() => {
  memoEditState.value =
    store.state.loginUserData.user_id === treatMemo.value.user_id
      ? false
      : treatMemo.value.editing;
});

const treatMemoChange = _.debounce(async (e) => {
  const memoValue = e.htmlValue;
  // memo data upsert 함
  await store.dispatch('memoUpsert', {
    memoValue: memoValue,
    classification: '진료',
    commitName: 'setTreatMemoCheck',
  });

  // memo axios 통신 후 잘 저장됐는지 check
  const treatMemoCheck = storeGetters.value.getTreatMemoCheck;

  if (!treatMemoCheck?.is) {
    toast.add({
      severity: 'error',
      summary: '메모 저장에러',
      detail: '메모가 저장되지 않았습니다.',
      life: 3000,
    });
  }
}, 1000);

async function memoStateChange() {
  if (memoAsyncState.value) {
    memoAsyncState.value = false;
    const customerCode = store.state.board.boardModal.boardSendCutomerCode;

    await axios.post(store.state.URL + '/registration-modal/memo-state', {
      state: true,
      customerCode,
      classification: '진료',
    });
  }
}

function memoSelection(e) {
  // eslint-disable-next-line
  let stayMemo = setTimeout(function tick() {
    if (idle.value) {
      e.instance.root.blur();
    } else {
      stayMemo = setTimeout(tick, 1000);
    }
  }, 1000);
}
</script>

<style></style>
