<template>
  <Button
    label="삭제"
    class="p-button-raised p-button-danger"
    style="height: 60px; width: 200px; font-size: 30px"
  />
</template>

<script>
export default {
  name: 'DeleteButton',
};
</script>

<style></style>
