import axios from 'axios';
// import global from '@/util/global';

const treatStore = {
  state() {
    return {
      treatModalRouter: 'treat',
      treatModalIsShow: false, // false 이면 시작 버튼 있는 진입점, true 이면 진료 모달
      treatHasStarted: false,
      treatCompare: null,
      treatId: null,
      treatSelectedPatientState: null,
      treatMemoCompare: {
        memo: null,
        customer_id: null,
        editing: null,
      },
      treatMemo: {
        memo: null,
        customer_id: null,
        editing: null,
      },
      treatMemoCheck: null,
      soogaListInBom: [],
      treatSidebarVisible: false,
    };
  },
  mutations: {
    setTreatHasStarted(state, payload) {
      state.treatHasStarted = payload;
    },
    setTreatModalShow(state, payload) {
      state.treatModalIsShow = payload;
    },
    setTreatId(state, payload) {
      state.treatId = payload;
    },
    setTreatSelectedPatientState(state, payload) {
      state.treatSelectedPatientState = payload;
    },
    setTreatMemo(state, payload) {
      state.treatMemo = payload;
    },
    setTreatMemoCheck(state, payload) {
      state.treatMemoCheck = payload;
    },
    pushSoogaDataInBom(state, soogaData) {
      state.soogaListInBom = [...state.soogaListInBom, soogaData];
    },
    changeSoogaDataInBom(state, payload) {
      state.soogaListInBom = payload;
    },
    setTreatSidebarVisible(state, payload) {
      state.treatSidebarVisible = payload;
    },
  },
  actions: {
    async getTreatBoardData({ getters, commit, state }) {
      // board 테이블의 진료 데이터를 보고, 진료 시작여부를 체크하는 함수
      // 진료 시작여부, 진료 진행한 의사(원장) 값 또한 가져옴
      const receptionId = getters.getBoardModalReceptionId;
      const customerId = getters.getBoardModalCustomerId;
      const URL = getters.getUrl;

      const treatModalRouter = state.treatModalRouter;
      const process = '진료';
      if (receptionId && customerId) {
        commit('loadingChangeValue', true);
        const res = await axios.get(
          URL +
            `/${treatModalRouter}/board?receptionid=${receptionId}&customerid=${customerId}&process=${process}`
        );

        // 만약 process start_time 이 있다면
        // 진료는 시작했음 (treat has started)

        if (res.data.data.length > 0) {
          const boardProcess = res.data.data[0];
          if (boardProcess.process_start_timestamp) {
            commit('setTreatHasStarted', true);
          }

          if (boardProcess.writer) {
            commit('setIsDoctor', true);
            commit('setSelectedDoctor', boardProcess.writer);
          }
        }
      } else {
        commit('setTreatHasStarted', false);
        commit('setIsDoctor', true);
        commit('setSelectedDoctor', null);
      }
    },
    async treatInsert({ getters, commit, state }, payload) {
      // 첫 진료 저장(insert) 시 사용하는 함수
      const { writer, counselerIsDoctor, patientState } = payload;
      const receptionId = getters.getBoardModalReceptionId;
      const customerId = getters.getBoardModalCustomerId;
      const URL = getters.getUrl;

      const treatModalRouter = state.treatModalRouter;

      // 기존 상담에 없고 디자인(진료) 때 추가되는 수가표
      const insertSoogaSurgeries = getters.getSelectedSoogaList.filter((soogaSurgery) => {
        return !soogaSurgery.counseling_id;
      });

      // 기존 상담에서 등록된 수가표
      const updateSoogaSurgeries = getters.getSelectedSoogaList.filter((soogaSurgery) => {
        return soogaSurgery.counseling_id && !soogaSurgery.is_paid;
      });

      // treat id 만들기
      commit('loadingChangeValue', true);
      const res = await axios.post(URL + `/${treatModalRouter}/treatment`, {
        data: {
          receptionId: receptionId,
          customerId: customerId,
          writer: writer,
          patientState: patientState,
          counselerIsDoctor: counselerIsDoctor,
        },
      });

      const treatId = res.data.counselingId;

      // insert 새로 추가된 수가표
      if (insertSoogaSurgeries.length > 0) {
        await axios.post(URL + `/${treatModalRouter}/treatment/sooga`, {
          data: {
            treatCounselingId: treatId,
            writer: writer,
            counselerIsDoctor: counselerIsDoctor,
            soogaSurgeries: insertSoogaSurgeries,
          },
        });
      }

      // // update 기존 수가표
      if (updateSoogaSurgeries.length > 0) {
        await axios.put(URL + `/${treatModalRouter}/treatment`, {
          data: {
            treatCounselingId: treatId,
            writer: writer,
            counselerIsDoctor: counselerIsDoctor,
            soogaSurgeries: updateSoogaSurgeries,
          },
        });
      }

      commit('setTreatId', treatId);
    },
    async treatUpdate({ getters, state, commit }, payload) {
      // 진료 수정(update) 시 사용하는 함수
      const { writer, counselerIsDoctor } = payload;
      const URL = getters.getUrl;

      const treatModalRouter = state.treatModalRouter;

      // 기존 상담에 없고 디자인(진료) 때 추가되는 수가표
      const insertSoogaSurgeries = getters.getSelectedSoogaList.filter((soogaSurgery) => {
        return !soogaSurgery.counseling_id;
      });

      // 기존 상담에서 등록된 수가표
      const updateSoogaSurgeries = getters.getSelectedSoogaList.filter((soogaSurgery) => {
        return soogaSurgery.counseling_id && !soogaSurgery.is_paid;
      });
      commit('loadingChangeValue', true);

      // insert 새로 추가된 수가표
      if (insertSoogaSurgeries.length > 0) {
        await axios.post(URL + `/${treatModalRouter}/treatment/sooga`, {
          data: {
            treatCounselingId: state.treatId,
            writer: writer,
            counselerIsDoctor: counselerIsDoctor,
            soogaSurgeries: insertSoogaSurgeries,
          },
        });
      }

      // // update 기존 수가표
      if (updateSoogaSurgeries.length > 0) {
        commit('loadingChangeValue', true);
        await axios.put(URL + `/${treatModalRouter}/treatment`, {
          data: {
            treatCounselingId: state.treatId,
            writer: writer,
            counselerIsDoctor: counselerIsDoctor,
            soogaSurgeries: updateSoogaSurgeries,
          },
        });
      }
    },

    async treatTimeUpdate({ getters, commit, state }, payload) {
      // 디자인(진료)탭에서 시간 수정 시, 실행되는 함수
      const { writer, timeType, timestamp, process } = payload;
      const receptionId = getters.getBoardModalReceptionId;
      const customerId = getters.getBoardModalCustomerId;
      const URL = getters.getUrl;

      const treatModalRouter = state.treatModalRouter;

      // treat id 만들기
      commit('loadingChangeValue', true);
      const res = await axios.put(URL + `/${treatModalRouter}/treatment/time`, {
        data: {
          receptionId: receptionId,
          customerId: customerId,
          writer: writer,
          timeType: timeType,
          time: timestamp,
          process: process,
        },
      });

      const treatId = res.data.counselingId;

      commit('setTreatId', treatId);
    },
  },
  getters: {
    getTreatMemoCheck(state) {
      return state.treatMemoCheck;
    },
  },
};

export default treatStore;
