import _ from 'lodash';

import { errorHandling } from '@/util/errorHandler';
import editBomState from './edit/state';
import editBomMutations from './edit/mutations';
import editBomActions from './edit/actions';
import editBomGetters from './edit/getters';

import confirmBomState from './confirm/state';
import confirmBomMutations from './confirm/mutations';
import confirmBomActions from './confirm/actions';
import confirmBomGetters from './confirm/getters';

import copyBomState from './copy/state';
import copyBomMutations from './copy/mutations';
import copyBomActions from './copy/actions';
import copyBomGetters from './copy/getters';

const bomManageGetdata = {
  state: {
    // 공통으로 들어가는 기본 데이터
    bomManageGetdataCompare: '',
    totalData: '',
    compareTotalData: '',
    surgeryData: '',
    menuCountData: '',
    doctorData: '',
    bomData: '',
    bomDeleteState: false,
    bomDeleteData: '',
    bomDeleteCategory: '',
    isBomEditing: false,
    doctorCountData: [],
    bomSubmitted: false,
    bomRegistCategory: '',
    bomCopyState: false,
    ...editBomState,
    ...confirmBomState,
    ...copyBomState,
  },
  mutations: {
    // 공통
    bomManageGetdataCompareCommit(state, payload) {
      state.bomManageGetdataCompare = payload;
    },
    totalDataCommit(state, payload) {
      state.totalData = payload;
    },
    compareTotalDataCommit(state, payload) {
      state.compareTotalData = payload;
    },
    surgeryDataCommit(state, payload) {
      state.surgeryData = payload;
    },
    menuCountDataCommit(state, payload) {
      state.menuCountData = payload;
    },
    doctorDataCommit(state, payload) {
      state.doctorData = payload;
    },
    bomDataCommit(state, payload) {
      state.bomData = payload;
    },
    bomDeleteStateCommit(state, payload) {
      state.bomDeleteState = payload;
    },
    bomDeleteDataCommit(state, payload) {
      state.bomDeleteData = payload;
    },
    bomDeleteCategoryCommit(state, payload) {
      state.bomDeleteCategory = payload;
    },
    isBomEditingCommit(state, payload) {
      state.isBomEditing = payload;
    },
    doctorCountDataCommit(state, payload) {
      state.doctorCountData = payload;
    },
    bomSubmittedCommit(state, payload) {
      state.bomSubmitted = payload;
    },
    bomRegistCategoryCommit(state, payload) {
      state.bomRegistCategory = payload;
    },
    bomCopyStateCommit(state, payload) {
      state.bomCopyState = payload;
    },

    bomManageInit(state) {
      state.bomManageGetdataCompare = '';
      state.totalData = '';
      state.compareTotalData = '';
      state.surgeryData = '';
      state.menuCountData = '';
      state.doctorData = '';
      state.bomData = '';
      state.bomDeleteState = false;
      state.bomDeleteData = '';
      state.bomDeleteCategory = '';
      state.isBomEditing = false;
      state.doctorCountData = [];
      state.bomSubmitted = false;
      state.bomRegistCategory = '';
      state.bomCopyState = false;
      // BOM 입력수정
      state.editSurgeryToDoctor = '';
      state.editSurgeryToDoctorSeen = false;
      state.editDoctorToBomSeen = false;
      state.editSurgeryToDoctorSelected = [];
      state.editDoctorToBomSelected = [];
      state.editBomRegistSelected = [];
      state.editSurgeryToDoctorUniqueDocId = '';
      state.editRegistedBomByDoctor = ''; // 등록된 상품 데이터
      state.editRegistedBomCost = ''; // 등록된 상품들 전체 원가 및 원가율
      // BOM 확인
      state.confirmSurgeryToDoctor = '';
      state.doctorMenuConfirmData = '';
      state.confirmDoctorToSurgerySeen = false;
      state.confirmSurgeryToBomSeen = false;
      state.confirmSurgeryFilteredData = [];
      state.confirmDoctorToSurgerySelected = [];
      state.confirmSurgeryToBomSelected = [];
      state.confirmBomConfirmSelected = '';
      state.confirmDoctorToSurgeryUniqueMenuId = '';
      state.confirmDoctorToSurgery = '';
      state.confirmRegistedBomCost = '';
      state.confirmDoctorToSurgerySelectedInfo = '';
      state.confirmRegistedBomBySurgery = [];
      // BOM 이동/복사
      state.copySurgeryToDoctor = '';
      state.copySurgeryFilteredData = [];
      state.copySourceDoctorToSurgerySeen = false;
      state.copySurgeryToTargetDoctorSeen = false;
      state.copyRecentSelectedBom = [];
      state.copySourceDoctorSelected = '';
      state.copySurgerySelected = '';
      state.copyTargetDoctorSelected = '';
    },
    ...editBomMutations,
    ...confirmBomMutations,
    ...copyBomMutations,
  },
  actions: {
    // 초기 데이터 입력 함수
    async bomInit({ state, commit }, payload) {
      try {
        const oldValue = state.compareTotalData;

        if (!_.isEqual({ ...oldValue }, { ...payload })) {
          commit('compareTotalDataCommit', _.cloneDeep(payload.data));
          commit('totalDataCommit', _.cloneDeep(payload.data));
          commit('surgeryDataCommit', payload.data.surgeryData);
          commit('menuCountDataCommit', payload.data.menuCountData);
          commit('doctorDataCommit', payload.data.doctorData);
          commit('bomDataCommit', payload.data.bomData);
        }

        // 공통BOM 원장 데이터 입력
        if (state.doctorData.length > 0) {
          const commonBomData = state.doctorData.filter((a) => a.doc_nm === '공통BOM')[0];
          commit('confirmDoctorToSurgerySelectedCommit', {
            doc_nm: commonBomData.doc_nm,
            doctor_id: commonBomData.id,
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    //// BOM 입력수정
    // active 창 초기화 함수
    async activeInit({ state, dispatch }) {
      try {
        if (state.editSurgeryToDoctorSelected && !state.editDoctorToBomSelected) {
          dispatch('surgeryToDoctorActive');
        }
        if (
          state.editSurgeryToDoctorSelected &&
          state.editDoctorToBomSelected &&
          !state.editBomRegistSelected &&
          !state.isBomEditing
        ) {
          dispatch('doctorToBomActive');
        }
        if (state.confirmDoctorToSurgerySelected && !state.confirmSurgeryToBomSelected) {
          dispatch('doctorToSurgeryActive');
        }
        if (
          state.confirmDoctorToSurgerySelected &&
          state.confirmSurgeryToBomSelected &&
          !state.confirmRegistedBomBySurgery
        ) {
          dispatch('surgeryToBomActive');
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    ...editBomActions,
    ...confirmBomActions,
    ...copyBomActions,
  },
  getters: {
    // doctorCountData 입력
    getDoctorCountData(state) {
      const uniqueDocMenu = _.uniqBy(state.menuCountData, function (elem) {
        return [elem.doctor_id, elem.menu_id].join();
      });
      const countByDoctor = _.countBy(uniqueDocMenu, 'doctor_id');
      const countByDoctorResult = Object.keys(countByDoctor).map(function (x) {
        return {
          doc_nm: state.doctorData.filter((data) => data.id === parseInt(x))[0].doc_nm,
          doctor_id: parseInt(x),
          menu_count: countByDoctor[x],
        };
      });

      // const store = useStore();
      // store.commit(
      //   'confirmDoctorToSurgerySelectedCommit',
      //   countByDoctorResult.filter((data) => {
      //     data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id;
      //   })
      // );
      return countByDoctorResult;
    },
    ...editBomGetters,
    ...confirmBomGetters,
    ...copyBomGetters,
  },
};

export default bomManageGetdata;
