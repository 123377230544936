<template>
  <div>
    <TopNavigation class="mb-4"></TopNavigation>
    <div>
      <h2>BOM 관리/확인</h2>
      <div class="content-section implementation">
        <!-- <TabView @tabClick="onTabClick($event)"> -->
        <!-- <TabPanel header="BOM 입력/수정">
              <div v-if="tabIndex === 0">
                <div class="card">
                  <div class="flex">
                    <EditSurgery />
                    <BomDivider
                      :dividerSeen="store.state.bomManageGetdata.editSurgeryToDoctorSeen"
                    ></BomDivider>
                    <EditDoctor />
                    <BomDivider
                      :dividerSeen="store.state.bomManageGetdata.editDoctorToBomSeen"
                    ></BomDivider>
                    <EditBom />
                  </div>
                </div>
              </div>
            </TabPanel> -->
        <!-- <TabPanel header="BOM 확인"> -->
        <div class="card">
          <div class="flex">
            <ConfirmDoctor />
            <!-- <BomDivider
              :dividerSeen="store.state.bomManageGetdata.confirmDoctorToSurgerySeen"
            ></BomDivider> -->
            <BomDivider :dividerSeen="true"> </BomDivider>
            <ConfirmSurgery />
            <BomDivider
              :dividerSeen="store.state.bomManageGetdata.confirmSurgeryToBomSeen"
            ></BomDivider>
            <ConfirmBom />
          </div>
        </div>
        <!-- </TabPanel> -->
        <!-- <TabPanel header="BOM 이동/복사">
              <div v-if="tabIndex === 2">
                <div class="card">
                  <div class="flex">
                    <CopySourceDoctor />
                    <BomDivider
                      :dividerSeen="
                        store.state.bomManageGetdata.copySourceDoctorToSurgerySeen
                      "
                    ></BomDivider>
                    <CopySurgery />
                    <BomDivider
                      :dividerSeen="
                        store.state.bomManageGetdata.copySurgeryToTargetDoctorSeen
                      "
                    ></BomDivider>
                    <CopyTargetDoctor />
                  </div>
                </div>
              </div>
            </TabPanel> -->
        <!-- </TabView> -->
      </div>
    </div>
  </div>
  <BomRegistDialog />
  <BomDeleteDialog />
  <BomCopyDialog />
  <!-- 이동/복사 확인 dialog 여기다 넣기 -->
</template>

<script setup>
import TopNavigation from '@/components/common/TopNavigation.vue';

// import EditSurgery from '@/components/bom/EditSurgery.vue';
// import EditDoctor from '@/components/bom/EditDoctor.vue';
// import EditBom from '@/components/bom/EditBom.vue';

import ConfirmDoctor from '@/components/bom/ConfirmDoctor.vue';
import ConfirmSurgery from '@/components/bom/ConfirmSurgery.vue';
import ConfirmBom from '@/components/bom/ConfirmBom.vue';

import BomDivider from '@/components/bom/BomDivider.vue';
// import CopySourceDoctor from '@/components/bom/CopySourceDoctor.vue';
// import CopySurgery from '@/components/bom/CopySurgery.vue';
// import CopyTargetDoctor from '@/components/bom/CopyTargetDoctor.vue';

import BomDeleteDialog from '@/components/bom/BomDeleteDialog.vue';
import BomRegistDialog from '@/components/bom/BomRegistDialog.vue';
import BomCopyDialog from '@/components/bom/BomCopyDialog.vue';

// import TabView from 'primevue/tabview';
// import TabPanel from 'primevue/tabpanel';
import { onBeforeMount, watchEffect, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

onBeforeMount(async () => {
  const timeoutData = {
    name: 'bom-manage-getdata',
    url: '/bom-manage/data',
    module: 'bomManageGetdata',
    compareData: 'bomManageGetdataCompare',
  };
  store.dispatch('timeoutCommonFunc', timeoutData);
  store.dispatch('doctorToSurgeryActive');
  store.commit('confirmDoctorToSurgerySelectedCommit', {
    doc_nm: '공통BOM',
    doctor_id: 1,
  });
});

watchEffect(async () => {
  const resultData = store.state.bomManageGetdata.bomManageGetdataCompare
    ? store.state.bomManageGetdata.bomManageGetdataCompare
    : [];
  if (resultData) {
    await store.dispatch('bomInit', { data: resultData });
    await store.dispatch('activeInit');
  }
});

onUnmounted(() => {
  store.commit('bomManageInit');
  store.commit('deleteTimeout', 'bom-manage-getdata');
});

// const tabIndex = ref(0);

// const onTabClick = (event) => {
//   tabIndex.value = parseInt(event.index);
// };
</script>

<style lang="scss" scoped>
::v-deep(.p-listbox-item.p-highlight) {
  background: #a8cfff !important;
}
</style>
