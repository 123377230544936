<template>
  <!-- <div v-show="store.state.bomManageGetdata.confirmDoctorToSurgerySeen"> -->
  <div v-show="true">
    <div class="flex justify-content-between">
      <h5>(2) 시/수술 선택</h5>
      <div
        class="flex align-items-center justify-content-end"
        style="padding: 0.5rem 0rem"
      >
        <SelectButton
          v-model="store.state.bomManageGetdata.confirmSurgeryFilterSelected"
          :options="store.state.bomManageGetdata.confirmSurgeryFilterOptions"
          aria-labelledby="single"
          @change="store.dispatch('surgeryFilterChangeEvent')"
        />
      </div>
    </div>
    <Listbox
      v-model="surgeryToBomSelected"
      :options="surgeryFilteredData"
      optionLabel="menu_name"
      optionValue="menu_id"
      :multiple="false"
      :filter="true"
      listStyle="max-height:550px"
      style="width: 35rem"
      :filterFields="['menu_name', 'abbreviation']"
      filterPlaceholder="시/수술명, 약어"
      @click="store.dispatch('surgeryToBomActive')"
    >
      <template #option="slotProps">
        <div class="surgery-item surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <div class="font-semibold text-l">
                {{ slotProps.option.menu_name }}
              </div>
              <span class="block text-600 font-medium mb-3">
                {{ slotProps.option.abbreviation }}
              </span>
            </div>
            <div
              class="flex align-items-center justify-content-center border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <Button
                type="button"
                class="p-button-sm p-button-info"
                icon="pi pi-search"
                @click="doctorToSurgeryToggle($event, slotProps.option.menu_id)"
                aria-haspopup="true"
                aria-controls="surgery_to_doc_overlay_panel"
              />
              <OverlayPanel
                ref="doctorToSurgery"
                :showCloseIcon="true"
                id="surgery_to_doc_overlay_panel"
                style="width: 450px"
                :breakpoints="{ '960px': '75vw' }"
              >
                <div class="surface-section p-2">
                  <div class="font-medium text-2xl text-900 mb-3">
                    {{ doctorToSurgery?.data?.menu_name }}
                  </div>
                  <ul class="list-none p-0 m-0">
                    <li
                      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                    >
                      <div class="text-500 w-6 md:w-2 font-medium">약어</div>
                      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                        {{ doctorToSurgery?.data?.abbreviation }}
                      </div>
                    </li>
                    <li
                      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                    >
                      <div class="text-500 w-6 md:w-2 font-medium">정상가</div>
                      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                        {{ $wonCurrency(doctorToSurgery?.data?.price) }}
                      </div>
                    </li>
                    <li
                      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                    >
                      <div class="text-500 w-6 md:w-2 font-medium">이벤트가</div>
                      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                        {{ $wonCurrency(doctorToSurgery?.data?.event_price) }}
                      </div>
                    </li>
                    <li
                      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                    >
                      <div class="text-500 w-6 md:w-2 font-medium">VAT 포함</div>
                      <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                        {{ $wonCurrency(doctorToSurgery?.data?.price_include_vat) }}
                      </div>
                    </li>
                    <li
                      class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
                    >
                      <div class="text-500 w-6 md:w-2 font-medium">내용</div>
                      <div
                        class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3"
                      >
                        {{ doctorToSurgery?.data?.medical_menu_content }}
                      </div>
                    </li>
                    <li
                      class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap"
                    >
                      <div class="text-500 w-6 md:w-2 font-medium">비고</div>
                      <div
                        class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3"
                      >
                        {{ doctorToSurgery?.data?.note }}
                      </div>
                    </li>
                  </ul>
                </div>
              </OverlayPanel>
            </div>
          </div>
          <div>
            <span class="text-500 font-medium"
              >{{ slotProps.option.medical_menu_content }}
            </span>
          </div>
          <div class="flex justify-content-between mt-3">
            <div
              v-if="
                isTrue(
                  doctorToSurgeryUniqueMenuId
                    ? doctorToSurgeryUniqueMenuId.has(slotProps.option.menu_id)
                    : true
                )
              "
            >
              <span
                class="product-badge status-instock align-content-center justify-content-start"
                >등록</span
              >
            </div>
            <div v-else>
              <span
                class="product-badge status-outofstock align-content-center justify-content-start"
                >미등록</span
              >
            </div>
            <div class="flex align-content-center justify-content-end">
              <InputSwitch
                :modelValue="getters.getConfirmBydoctor(slotProps.option?.menu_id)"
                @change="
                  store.dispatch('confirmChangeEvent', {
                    event: $event,
                    menuId: slotProps.option?.menu_id,
                  })
                "
              />
            </div>
          </div>
        </div>
      </template>
    </Listbox>
  </div>
</template>
<script setup>
import { ref, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Listbox from 'primevue/listbox';
import OverlayPanel from 'primevue/overlaypanel';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';

const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const store = useStore();
const getters = computed(() => store.getters);
const doctorToSurgery = ref([]);
const surgeryToBomSelected = computed({
  get: () => {
    return store.state.bomManageGetdata.confirmSurgeryToBomSelected;
  },
  set: (value) => {
    store.commit('confirmSurgeryToBomSelectedCommit', value);
  },
});
const surgeryFilteredData = computed({
  get: () => {
    return store.state.bomManageGetdata.confirmSurgeryFilteredData;
  },
  set: (value) => {
    store.commit('confirmSurgeryFilteredDataCommit', value);
  },
});
const doctorToSurgeryUniqueMenuId = computed({
  get: () => {
    return store.state.bomManageGetdata.confirmDoctorToSurgeryUniqueMenuId;
  },
  set: (value) => {
    store.commit('confirmDoctorToSurgeryUniqueMenuIdCommit', value);
  },
});

// 시/수술 상세정보 모달 토글 함수
function doctorToSurgeryToggle(event, menuId) {
  const surgeryInfo = store.state.bomManageGetdata.surgeryData.filter(
    (data) => data.menu_id === menuId
  )[0];
  doctorToSurgery.value.data = surgeryInfo;
  doctorToSurgery.value.toggle(event);
}

const isTrue = (data) => {
  if (data) {
    return true;
  } else {
    return false;
  }
};
</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }
}
</style>
