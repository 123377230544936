<template>
  <div>
    <Dialog
      v-model:visible="store.state.bomManageGetdata.bomSubmitted"
      :style="{ width: '450px' }"
      header="BOM 등록"
      :modal="true"
      @hide="closeRegistModal"
    >
      <div class="confirmation-content flex flex-wrap align-items-center">
        <i class="pi pi-info-circle mr-2" style="font-size: 2rem" />
        <span class="vertical-align-text-bottom">
          선택하신 품목을 등록하시겠습니까?
        </span>
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeRegistModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="registExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
const store = useStore();
const isSubmitted = ref(false);

watchEffect(() => {
  isSubmitted.value = store.state.bomManageGetdata.bomSubmitted;
});

// 등록 확인 모달 닫을 시, 이벤트
function closeRegistModal() {
  store.commit('bomSubmittedCommit', false);
}

// 등록 진행 시, 이벤트
function registExecution() {
  if (isSubmitted.value) {
    if (store.state.bomManageGetdata.bomRegistCategory === 'edit') {
      store.dispatch('editRegistProduct');
    } else if (store.state.bomManageGetdata.bomRegistCategory === 'confirm') {
      store.dispatch('confirmRegistProduct');
    } else {
      Swal.fire({
        icon: 'warning',
        title: '에러',
        text: '예상치 못한 에러가 발생하였습니다.',
      });
    }
  }
}
</script>
<script>
export default {
  name: 'BomRegistDialog',
};
</script>

<style></style>
