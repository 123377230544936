<template>
  <Button
    label="종 료"
    class="p-button-raised p-button-warning"
    style="height: 100px; width: 200px; font-size: 40px"
  />
</template>

<script>
export default {
  name: 'EndButton',
};
</script>

<style></style>
