export const leftPad = (value) => {
  if (value >= 10) {
    return value;
  }

  return `0${value}`;
};

export const toStringByFormatting = (source, delimiter = '-') => {
  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());

  return [year, month, day].join(delimiter);
};

export const formatDate = (is, sort) => {
  var today = sort ? new Date(sort) : new Date();
  today.setHours(today.getHours() + 9);
  if (is === 'date') {
    // 2022-10-17
    return today
      .toISOString()
      .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/, '$1-$2-$3');
  } else if (is === 'timestamp') {
    // 2022-10-17T12:04:24.000Z
    return today
      .toISOString()
      .replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/,
        '$1-$2-$3T$4:$5:$6.000Z'
      );
  } else if (is === 'datetime') {
    // 2022-10-17 12:04:24
    return today
      .toISOString()
      .replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/,
        '$1-$2-$3 $4:$5:$6'
      );
  } else if (is === 'code') {
    // 20221017120424
    return today
      .toISOString()
      .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/, '$1$2$3$4$5$6');
  } else if (is === 'time') {
    // 12:04
    return today
      .toISOString()
      .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/, '$4:$5');
  } else if (is === 'codedate') {
    // 20221017120424
    return today
      .toISOString()
      .replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.([\d]+)Z/,
        '$1$2$3$4$5$6'
      );
  } else if (is == 'codemi') {
    // 496
    return today
      .toISOString()
      .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.([\d]+)Z/, '$7');
  }
};
