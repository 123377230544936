<template>
  <div id="loading" v-if="$store.state.loadingState">
    <div
      class="flex justify-content-center align-items-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.5);
      "
    >
      <ProgressSpinner class="" style="z-index: 1500" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent',
};
</script>

<style></style>
