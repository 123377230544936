<template>
  <div class="relative mb-4">
    <h2>현황판</h2>
    <div class="absolute top-0 left-50" :style="{ marginLeft: '-20vw' }">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <Dropdown
          v-model="searchConditionName"
          :options="searchConditionList"
          style="min-width: 120px"
          placeholder="검색조건"
          @change="customerName = ''"
        />
        <Dropdown
          @input="inputFunc($event)"
          v-model="customerName"
          :options="customerList"
          optionLabel="info"
          optionValue="id"
          :editable="true"
          :style="{ width: widthSize.toString() + 'vw', marginLeft: '0.3rem' }"
          :placeholder="
            searchConditionName === '고객명'
              ? '전체 고객 검색 (이름 or 핸드폰번호 뒷 4자리)'
              : `전체 전화번호 검색('-' 없이)`
          "
          @change="changedInput($event)"
          id="board-customer-search-input"
          :showClear="true"
          scrollHeight="400px"
        />
        <!-- <Button :style="{ marginLeft: '0.3rem' }" @click="openCreateCustomerDialog">
          신규고객 등록
        </Button> -->
        <!-- <Button
          label="메타CRM DB 연동"
          icon="pi pi-server"
          class="p-button-secondary ml-2"
          @click="uploadMetaCrmDb"
        /> -->
      </span>
    </div>
  </div>
  <Dialog
    header="신규고객 등록"
    v-model:visible="isDialogShow"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :modal="true"
    :closeOnEscape="false"
    @hide="closeCreateCustomerDialog"
    :baseZIndex="1000"
    :autoZIndex="false"
  >
    <div class="p-fluid">
      <div class="p-inputgroup mb-3 gap-3">
        <div
          v-for="category in foreignerCategories"
          :key="category.name"
          class="flex align-items-center"
        >
          <RadioButton
            v-model="isForeigner"
            :inputId="category.name"
            :value="category.value"
            @click="initIsForeigner()"
          />
          <label :for="category.value" class="ml-2">{{ category.name }}</label>
        </div>
      </div>
      <div v-if="isForeigner" class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-flag"></i>
        </span>
        <Dropdown
          v-model="createdCustomerState.country"
          editable
          :options="countryList"
          optionLabel="name"
          optionValue="name"
          placeholder="소속국가(필수)"
          :class="{
            'p-invalid': v$.country.$invalid && customerSubmmit,
          }"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-camera"></i>
        </span>
        <Dropdown
          v-model="createdCustomerState.marketingLabel"
          :options="marketingLabelList"
          showClear
          optionLabel="description"
          optionValue="name"
          placeholder="마케팅 분류코드(선택)"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <InputText
          :class="{
            'p-invalid': v$.name.$invalid && customerSubmmit,
          }"
          v-model="v$.name.$model"
          placeholder="이름(필수)"
        >
        </InputText>
      </div>

      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-phone"></i>
        </span>
        <InputMask
          :class="{
            'p-invalid': v$.phone.$invalid && customerSubmmit,
          }"
          v-model="v$.phone.$model"
          mask="999-9999-9999"
          placeholder="연락처(필수)"
        />
      </div>

      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon">
          <i class="pi pi-calendar"></i>
        </span>
        <InputMask
          :class="{
            'p-invalid': v$.birth.$invalid && customerSubmmit,
          }"
          mask="9999-99-99"
          v-model="v$.birth.$model"
          placeholder="생년월일(필수)"
          slotChar="yyyy-mm-dd"
        />
      </div>

      <div class="p-inputgroup mb-3">
        <span
          class="p-inputgroup-addon"
          style="cursor: pointer"
          @click="getCustomerAddress"
        >
          <i class="pi pi-home"></i>
        </span>
        <InputText
          v-model="createdCustomerState.zipCode"
          style="max-width: 5vw"
          disabled
          placeholder="우편번호"
        >
        </InputText>
        <InputText
          v-model="createdCustomerState.address"
          disabled
          placeholder="주소"
        ></InputText>
      </div>

      <div class="grid mb-1">
        <div class="col-6">
          <div class="p-inputgroup">
            <div class="text-xs p-inputgroup-addon w-30rem">개인정보 활용동의</div>
            <div
              class="w-full flex justify-content-center align-items-center border-left-none border-1 border-gray-300 border-round-right-md"
            >
              <Checkbox v-model="createdCustomerState.privacyPolicy" :binary="true" />
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="p-inputgroup">
            <div class="text-xs p-inputgroup-addon w-30rem">마케팅 활용동의</div>
            <div
              class="w-full flex justify-content-center align-items-center border-left-none border-1 border-gray-300 border-round-right-md"
            >
              <Checkbox v-model="createdCustomerState.marketingPolicy" :binary="true" />
              <label for="binary"></label>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <span class="p-float-label">
          <Editor v-model="createdCustomerState.memo" editorStyle="height: 130px;">
            <template v-slot:toolbar>
              <span class="ql-formats">
                <div>고객 메모</div>
              </span>
            </template>
          </Editor>
        </span>
      </div>

      <Dropdown
        @input="inputFunc($event)"
        v-model="createdCustomerState.recommederId"
        :options="customerList"
        optionLabel="info"
        optionValue="id"
        :editable="true"
        placeholder="추천인 검색"
        @change="clearRcommenderId"
        :showClear="true"
      />
    </div>
    <!-- </div> -->
    <template #footer>
      <Button label="등록" icon="pi pi-check" @click="createCustomer" autofocus></Button>
      <Button
        label="취소"
        icon="pi pi-times"
        @click="closeCreateCustomerDialog"
        class="p-button-text"
      ></Button>
    </template>
  </Dialog>
  <Toast />
</template>
<script>
export default {
  name: 'BoardSearch',
  data() {
    return {
      widthSize: 30,
    };
  },
};
</script>

<script setup>
import {
  ref,
  computed,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  nextTick,
} from 'vue';
import _ from 'lodash';
import axios from 'axios';
import { useStore } from 'vuex';
import customerService from './CustomerService.js';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Swal from 'sweetalert2';
import { errorHandling } from '@/util/errorHandler.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import omniList from '../modal/modal_components/OmniList';
dayjs.extend(isBetween);

const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
// const $autoHypenDate = app.appContext.config.globalProperties.$autoHypenDate;
const marketingLabelList = ref();
const countryList = ref();
const isForeigner = ref(false);
const store = useStore();
const toast = useToast();

const storeGetters = computed(() => store.getters);

// created customer 신규 접수
const isDialogShow = ref(false);

const createdCustomerState = reactive({
  name: null,
  country: null,
  phone: null,
  birth: null,
  zipCode: null,
  address: null,
  privacyPolicy: false,
  marketingPolicy: false,
  recommederId: null,
  vipLevel: 0,
  marketingLabel: null,
  memo: null,
});

const phoneReg = helpers.regex(/^01([0|1|6|7|8|9])-[0-9]{3,4}-[0-9]{4}$/);

const rules = computed(() => {
  const countryRules = isForeigner.value ? { country: { required } } : {};

  return {
    ...countryRules,
    name: {
      required,
    },
    phone: {
      required,
      phoneReg,
    },
    birth: {
      required,
    },
  };
});

let v$ = useVuelidate(rules, createdCustomerState);

onBeforeMount(() => {
  marketingLabelList.value = omniList.marketingCustomerList;
  countryList.value = omniList.foreignCountryList;
});

// login 한 user
const loginUser = ref();

const extraAddress = ref();
const address = ref();

// 외국인 유무 리스트
const foreignerCategories = ref([
  { name: '내국인', value: false },
  { name: '외국인', value: true },
]);
// dropdown 검색
const searchConditionName = ref('고객명');
const searchConditionList = ref(['고객명', '전화번호']);
const customerName = ref();
const customerList = ref([]);
const resultData = ref([]);

const customerSubmmit = ref(false);

// async function uploadMetaCrmDb() {
//   try {
//     store.commit('isMetaCrmConfirmCommit', true);
//   } catch (err) {
//     console.error(err);
//   }
// }

function changedInput(e) {
  if (customerName.value) {
    const filterData = resultData.value.filter((a) => {
      return a.id === customerName.value;
    });
    if (filterData.length > 0) {
      const customerNmme = filterData[0].customer_name;
      store.commit('boardCustomerDataInput', {
        customer_id: customerName.value,
        customer_name: customerNmme,
        marketing_label: filterData[0].marketing_label,
      });
      store.commit('boardModalStateChange');
      customerName.value = '';
    }
  } else {
    customerList.value = [];
  }
}

// function changeDate(e) {
//   const inputValue = e.target.value;
//   const inputReplace = inputValue.replace(/[^\d]/g, '');
//   v$.value.birth.$model = $autoHypenDate(inputReplace);
// }

let inputFunc = _.debounce(async (e) => {
  const inputValue = e.target.value;
  const inputSplit = inputValue
    .toString()
    .split(/\s/g)
    .filter((a) => a);

  if (inputValue) {
    store.state.loadingState = true;
    const postResult = await CustomerPost(
      inputSplit[0],
      inputSplit[1],
      searchConditionName.value
    );

    customerList.value = postResult;
    await nextTick(async () => {
      store.state.loadingState = false;
    });
  } else {
    customerList.value = [];
  }
}, 1000);

async function CustomerPost(value1, value2, searchCondition) {
  const result = await axios.post(store.state.URL + '/status-board/customer-search', {
    value1,
    value2,
    searchCondition,
  });

  resultData.value = result.data;
  const resultMap = resultData.value.map((a) => ({
    info: `${a.customer_name} / ${a.birth} / ${a.phone}`,
    id: a.id,
  }));

  return resultMap;
}

function initIsForeigner() {
  if (isForeigner.value === false) {
    createdCustomerState.country = null;
  }
}

// const openCreateCustomerDialog = () => {
//   isDialogShow.value = true;
// };

const closeCreateCustomerDialog = () => {
  // re-init 신규고객등록 모달
  createdCustomerState.marketingLabel = null;
  createdCustomerState.country = null;
  createdCustomerState.name = null;
  createdCustomerState.phone = null;
  createdCustomerState.birth = null;
  createdCustomerState.zipCode = null;
  createdCustomerState.address = null;
  createdCustomerState.privacyPolicy = false;
  createdCustomerState.marketingPolicy = false;
  createdCustomerState.recommederId = null;
  createdCustomerState.vipLevel = 0;
  createdCustomerState.memo = null;

  isForeigner.value = false;

  // 모달 close
  isDialogShow.value = false;
  customerSubmmit.value = false;
};

const clearRcommenderId = () => {
  if (!createdCustomerState.recommederId) {
    customerList.value = [];
  }
};

const createCustomer = _.debounce(async () => {
  customerSubmmit.value = true;
  const country = createdCustomerState.country ? createdCustomerState.country : null;
  const marketingLabel = createdCustomerState.marketingLabel;
  const name = createdCustomerState.name;
  const phone = createdCustomerState.phone;
  const birth = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(
    createdCustomerState.birth
  )
    ? $formatDate('date', createdCustomerState.birth)
    : '';
  const birthDayjs = dayjs(birth);
  const zipCode = createdCustomerState.zipCode;
  const address = createdCustomerState.address;
  const privacyPolicy = createdCustomerState.privacyPolicy;
  const marketingPolicy = createdCustomerState.marketingPolicy;
  const recommederId = createdCustomerState.recommederId;
  const vipLevel = createdCustomerState.vipLevel;
  const memo = createdCustomerState.memo;

  const writer = loginUser.value;

  const checkRequired = [name, phone, birth].includes(null);

  try {
    if (checkRequired) {
      toast.add({
        severity: 'error',
        summary: '입력 에러',
        detail: '고객의 이름, 연락처, 생년월일을 입력하세요',
        life: 3000,
      });

      throw new Error(`
      고객의 이름, 연락처, 생년월일이 null입니다.
    `);
    }

    const isValidateError =
      v$.value.name.$error ||
      v$.value.phone.$error ||
      v$.value.birth.$error ||
      !/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(
        createdCustomerState.birth
      ) ||
      !birthDayjs.isBetween('1900-01-01', $formatDate('date'));

    if (v$.value.name.$error) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '이름을 다시 입력해주세요.',
        life: 3000,
      });
    }
    if (v$.value.phone.$error) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '연락처를 다시 입력해주세요.',
        life: 3000,
      });
    }
    if (
      v$.value.birth.$error ||
      !/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(
        createdCustomerState.birth
      ) ||
      !birthDayjs.isBetween('1900-01-01', $formatDate('date'))
    ) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '생년월일을 다시 입력해주세요.',
        life: 3000,
      });
    }

    if (isValidateError) {
      throw new Error(`
       고객의 이름, 연락처, 생년월일이 비어 있음
    `);
    }

    if (!createdCustomerState.country && isForeigner.value === true) {
      toast.add({
        severity: 'error',
        summary: '소속국가 입력 에러',
        detail: '외국인일 경우, 고객의 소속 국가를 입력하세요',
        life: 3000,
      });

      throw new Error(`
      외국인 고객의 국가 선택 값이 null입니다.
    `);
    }

    // 이후에 고객 등급(Vip Level)이 구현되면 삼항 연산자 지워야함
    const customerInfos = {
      marketingLabel: marketingLabel,
      country: createdCustomerState.country && isForeigner.value ? country : '한국',
      name: name,
      phone: phone,
      birth: birth,
      zipCode: zipCode,
      address: address,
      privacyPolicy: privacyPolicy,
      marketingPolicy: marketingPolicy,
      recommederId: recommederId ? recommederId : null,
      vipLevel: vipLevel ? vipLevel : 0,
      memo,
      writer: writer ? writer : 'test_user',
    };

    try {
      // create customer api call

      await customerService.createCustomer(storeGetters.value.getUrl, customerInfos);

      Swal.fire({
        title: '신규고객 등록 성공',
        text: '신규고객을 등록했습니다.',
        timer: 2000,
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: '신규 고객을 등록하는 중 에러가 발생했습니다.',
        text: `개발팀에 문의해주세요. ${error}`,
        timer: 2000,
        icon: 'error',
      });
    } finally {
      closeCreateCustomerDialog();
    }
  } catch (error) {
    errorHandling(toast, error);
  }
}, 500);

const getCustomerAddress = () => {
  new window.daum.Postcode({
    oncomplete: (data) => {
      if (extraAddress.value !== '') {
        extraAddress.value = '';
      }
      if (data.userSelectedType === 'R') {
        // 사용자가 도로명 주소를 선택했을 경우
        address.value = data.roadAddress;
      } else {
        // 사용자가 지번 주소를 선택했을 경우(J)
        address.value = data.jibunAddress;
      }

      // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
      if (data.userSelectedType === 'R') {
        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraAddress.value += data.bname;
        }
        // 건물명이 있고, 공동주택일 경우 추가한다.
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraAddress.value +=
            extraAddress.value !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
        if (extraAddress.value !== '') {
          extraAddress.value = `(${extraAddress.value})`;
        }
      } else {
        extraAddress.value = '';
      }
      // 우편번호를 입력한다.
      createdCustomerState.zipCode = data.zonecode;
      createdCustomerState.address = `${address.value} ${extraAddress.value}`;
    },
  }).open();
};
</script>

<style>
.valid {
  border: 1px solid green;
}

.error {
  border: 1px solid red;
}
</style>
