<template>
  <h2>원장 관리</h2>
  <div>
    <Toolbar class="mb-4">
      <template #start>
        <Button
          label="원장 등록"
          icon="pi pi-plus"
          class="p-button-success mr-2"
          @click="openNewRegist"
        />
        <Button
          label="원장 삭제"
          icon="pi pi-trash"
          class="p-button-danger"
          :disabled="selectedCheckHead() === 0"
          @click="selectedDelete"
        />
      </template>
    </Toolbar>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Toolbar from 'primevue/toolbar';
import { useStore } from 'vuex';

const store = useStore();

function openNewRegist() {
  // store.dispatch('getSoogaCategory'); // 등록모달 수가표 대분류 가져오기
  store.commit('doctorDialogCommit', true);
}

function selectedCheckHead() {
  const selectedValues = store.state.doctorGetdata.selectedDoctors;
  return _.reduce(
    selectedValues,
    (a, b) => {
      return (a += 1);
    },
    0
  );
}

function selectedDelete() {
  store.commit('doctorDeleteStateCommit', true);
}
</script>
<script>
export default {
  name: 'DoctorToolbar',
};
</script>

<style></style>
