import axios from 'axios';

const getTodayReceptions = async (URL, todayDate) => {
  try {
    const receptionsData = await axios.get(
      URL + `/status-board/receptions?todayDate=${todayDate}`
    );

    if (receptionsData.status === 200) {
      return receptionsData.data;
    }
  } catch (error) {
    throw new Error(`
      오늘 접수자가 없습니다.
      접수 데이터를 가져오는 중 에러가 발생했습니다.
      ${error.name}: ${error.message}
      ${error.stack}
    `);
  }
};

const createCustomer = async (URL, customerInfos) => {
  try {
    const customerData = await axios.post(URL + `/customers`, {
      data: customerInfos,
    });
    if (customerData.status === 201) {
      return customerData.data;
    }
  } catch (error) {
    if (error.status === 404) {
      throw new Error(`
      신규 고객을 등록하는 중 에러가 발생했습니다.
      ${error.name}: ${error.message}
      ${error.stack}
    `);
    } else {
      throw new Error(`
    이미 등록된 고객입니다.
    ${error.name}: ${error.message}
    ${error.stack}
  `);
    }
  }
};

export default { getTodayReceptions, createCustomer };
