const modalTop = {
  state() {
    return {
      modalTopCustomerCompare: '',
      modalTopBoardCompare: '',
      modalTopCustomerVisitCompare: '',
    };
  },
  mutations: {
    modalTopBoardTreatStartTimeCommit(state, payload) {
      state.modalTopBoardCompare.receiption.filter(
        (a) => a.process === '진료'
      ).start_time = payload;
    },
    modalTopBoardTreatEndTimeCommit(state, payload) {
      state.modalTopBoardCompare.receiption.filter((a) => a.process === '진료').end_time =
        payload;
    },
  },
  actions: {},
};

export default modalTop;
