<template>
  <div>
    <TopNavigation></TopNavigation>
    <BoardSearch />
    <BoardToolbar />
    <BoardTableDataTable />
    <ReserveMetaCrmDialog />
    <StatusBoardRegistrationModal
      v-model:visible="$store.state.board.boardModal.boardModalState"
    ></StatusBoardRegistrationModal>
  </div>
</template>

<script setup>
import BoardSearch from '@/components/board/BoardSearch.vue';
import BoardTableDataTable from '@/components/board/BoardTableDataTable.vue';
import BoardToolbar from '@/components/board/BoardToolbar.vue';
import TopNavigation from '@/components/common/TopNavigation.vue';
import ReserveMetaCrmDialog from '@/components/excel/ReserveMetaCrmDialog.vue';
import StatusBoardRegistrationModal from '@/components/modal/StatusBoardRegistrationModal.vue';

import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const init = ref();

async function run() {
  if (store.state.board.boardModal.frontBoardDate) {
    store.commit('boardLoadingCommit', true);
    const timeoutBoardData = {
      name: 'board-table',
      url: '/status-board/receptions?todayDate=$boardFrontDate',
      module: 'board',
      compareData: 'boardInitDataCompare',
    };
    await store.dispatch('timeoutCommonFunc', timeoutBoardData);
    store.commit('boardLoadingCommit', false);

    clearTimeout(init);
  } else {
    init.value = setTimeout(run);
  }
}

watchEffect(async () => {
  if (store.state.board.boardModal.boardModalState) {
    store.commit('deleteTimeout', 'board-table');
  } else {
    if (!Object.keys(store.state.TimeOutSet).includes('board-table')) {
      init.value = setTimeout(run);
    }
  }
});
</script>

<style scoped></style>
