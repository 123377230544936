<template>
  <div>
    <Dialog
      v-model:visible="store.state.paymentStore.incentiveDeleteState"
      :style="{ width: '450px' }"
      header="삭제확인"
      :modal="true"
      @hide="closeDeleteModal"
    >
      <div class="confirmation-content flex flex-wrap align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span class="vertical-align-text-bottom">
          선택하신 실적 데이터를 삭제하시겠습니까?</span
        >
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDeleteModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const deleteId = ref();

watchEffect(() => {
  if (store.state.paymentStore.incentiveDeleteState) {
    deleteId.value = store.state.paymentStore.incentiveDeleteId;
  }
});

// 모달창 닫을 시, 이벤트
function closeDeleteModal() {
  store.commit('incentiveDeleteStateCommit', false);
}

// 삭제확인 버튼 누를 시, 이벤트
async function deleteExecution() {
  const result = await store.dispatch('deleteIncentiveInfo', {
    incentiveId: deleteId.value,
  });
}
</script>
<script>
export default {
  name: 'IncentiveDeleteDialog',
};
</script>

<style></style>
