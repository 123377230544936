import _ from 'lodash';
import axios from 'axios';
import { errorHandling } from '@/util/errorHandler';
import Swal from 'sweetalert2';

const soogaGetdata = {
  state() {
    return {
      soogaGetdataCompare: '',
      productDialog: false,
      productSelected: '',
      productCategory: [],
      productPurchase: '',
      productHospital: '',
      productStandard: '',
      productUnitWhenInput: '',
      soogaSelectedProducts: [],
      soogaDeleteState: false,
      updateProducts: null,
      soogaUpdateState: false,
      soogaDt: '',
    };
  },
  mutations: {
    productDialogCommit(state, payload) {
      state.productDialog = payload;
    },
    productSelectedCommit(state, payload) {
      state.productSelected = payload;
    },
    productCategoryCommit(state, payload) {
      state.productCategory = payload;
    },
    soogaSelectedProductsCommit(state, payload) {
      state.soogaSelectedProducts = payload;
    },
    soogaDeleteStateCommit(state, payload) {
      state.soogaDeleteState = payload;
    },
    updateProductsCommit(state, payload) {
      state.updateProducts = payload;
    },
    soogaUpdateStateCommit(state, payload) {
      state.soogaUpdateState = payload;
    },
    soogaDtCommit(state, payload) {
      state.soogaDt = payload;
    },
    soogainit(state) {
      state.soogaGetdataCompare = '';
      state.productDialog = false;
      state.productSelected = '';
      state.productCategory = [];
      state.productSooga = '';
      state.productHospital = '';
      state.productStandard = '';
      state.productUnitWhenInput = '';
      state.soogaSelectedProducts = [];
      state.soogaDeleteState = false;
      state.updateProducts = null;
      state.soogaUpdateState = false;
      state.soogaDt = '';
    },
  },
  actions: {
    // 수가표 관리 신규등록 모달 대분류 카테고리 가져오는 함수
    async getSoogaCategory({ rootState, commit }) {
      try {
        const URL = rootState.URL;

        commit('loadingChangeValue', true);
        const result = await axios.get(URL + `/sooga-manage/category`);
        commit('loadingChangeValue', false);

        if (result.status === 200) {
          commit('productCategoryCommit', result.data);
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async soogaProductSave({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.post(URL + '/sooga-manage', payload.value);
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          Swal.fire('저장이 완료되었습니다.', '', 'success').then(() => {
            payload.callFn();
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async soogaDelete({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        const codeFilter = _.map(payload, (a) => {
          return a.id;
        });
        commit('loadingChangeValue', true);
        commit('soogaDeleteStateCommit', false);
        const result = await axios.delete(URL + '/sooga-manage', {
          data: codeFilter,
        });
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          Swal.fire('삭제가 완료되었습니다.', '', 'success').then(() => {
            commit('soogaDeleteStateCommit', false);
            commit('soogaSelectedProductsCommit', null);
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async soogaUpdate({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.put(URL + '/sooga-manage', { data: payload.value });
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          Swal.fire('수정이 완료되었습니다.', '', 'success').then(() => {
            commit('soogaUpdateStateCommit', false);
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
  },
  getters: {
    productCategoryGetter(state) {
      const productCategory = state.productCategory;
      return productCategory;
    },
  },
};

export default soogaGetdata;
