<template>
  <div>
    <Dialog
      v-model:visible="store.state.doctorGetdata.doctorDialog"
      :style="{ width: '550px' }"
      header="원장 등록"
      :modal="true"
      :closeOnEscape="false"
      class="p-fluid text-lg"
      @hide="closeSaveModal"
      :baseZIndex="1000"
      :autoZIndex="false"
    >
      <div class="field">
        <label class="font-semibold" for="name">회원 선택</label>
        <small class="text-red-600"> (원장 등록 되어있지 않은 회원 중 선택 가능)</small>
        <AutoComplete
          v-model="selectedMember"
          :suggestions="filteredMembers"
          @complete="searchMember($event)"
          :dropdown="true"
          optionLabel="name"
          placeholder="회원 이름을 입력 및 선택해주세요."
          :class="{ 'p-invalid': doctorSubmit && !selectedMember }"
          forceSelection
        >
          <template #item="slotProps">
            <div class="member-item">
              <div class="ml-2">
                {{ slotProps.item.name }}({{ slotProps.item.birth }})
              </div>
            </div>
          </template>
        </AutoComplete>
        <small class="p-error" v-if="doctorSubmit && !selectedMember"
          >회원 이름을 입력 및 선택하세요</small
        >
      </div>
      <ul class="list-none p-0 m-0">
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-600 w-6 md:w-3 font-medium">회원 ID</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ selectedMember?.user_id }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-600 w-6 md:w-3 font-medium">생년월일</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ selectedMember?.birth }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-600 w-6 md:w-3 font-medium">소속 병원</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ selectedMember?.hospital }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-600 w-6 md:w-3 font-medium">소속 부서</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            {{ selectedMember?.department }}
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-600 w-6 md:w-3 font-medium">소속 파트 및 직책</div>
          <div class="flex justify-content-center">
            <Dropdown
              v-model="v$.selectedPartTitle.$model"
              :options="doctorPartTitles"
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              placeholder="직책을 선택하세요"
              class="text-900 w-full md:w-20rem md:flex-order-0 flex-order-1"
              :class="{ 'p-invalid': v$.selectedPartTitle.$invalid && doctorSubmit }"
            >
              <template #optiongroup="slotProps">
                <div class="flex align-items-center">
                  <div>{{ slotProps.option.label }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
        </li>
        <li
          class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"
        >
          <div class="text-600 w-6 md:w-3 font-medium">입사일자</div>
          <Calendar
            v-model="v$.hiredDate.$model"
            showButtonBar
            dateFormat="yy-mm-dd"
            placeholder="yyyy-mm-dd"
            class="text-900 w-full md:w-20rem md:flex-order-0 flex-order-1"
            :class="{ 'p-invalid': v$.hiredDate.$invalid && doctorSubmit }"
          />
        </li>
      </ul>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeSaveModal"
        />
        <Button
          label="저장"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { useStore } from 'vuex';
import { ref, watchEffect, onBeforeMount } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AutoComplete from 'primevue/autocomplete';
import omniList from '../modal/modal_components/OmniList';

const selectedMember = ref();
const doctorSubmit = ref(false);
const filteredMembers = ref();

// 상품 등록 대분류
const unregisteredMember = ref();

// 원장 파트, 직책 분류
const doctorPartTitles = ref();
const addedOptions = ref({});

// Vuelidate 규칙
const rules = {
  hiredDate: { required },
  selectedPartTitle: { required },
};

const v$ = useVuelidate(rules, addedOptions);
const store = useStore();
// const getters = computed(() => store.getters);

// store의 unregisteredMember 변화 있을 경우, 변경 (처음 mount 될때도, 입력)
watchEffect(() => {
  unregisteredMember.value = store.state.doctorGetdata.unregisteredMember;
});

onBeforeMount(async () => {
  doctorPartTitles.value = omniList.doctorPartTitles;
});

// 중복 클릭 방지
const closeSaveModal = _.debounce(() => {
  store.commit('doctorDialogCommit', false);
  selectedMember.value = '';
  doctorSubmit.value = false;
  v$.value.selectedPartTitle = '';
  v$.value.hiredDatevalue = '';
}, 100);

//// 신규등록 모달
// 원장 등록에서, 회원명 입력시 연결 가능 회원 보여주는 이벤트 함수
function searchMember(event) {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredMembers.value = [...unregisteredMember.value];
    } else {
      filteredMembers.value = unregisteredMember.value.filter((member) => {
        return member.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }
  }, 250);
}

// 신규등록 저장
const saveConfirm = _.debounce(async () => {
  doctorSubmit.value = true;
  const registMember = {};
  const selectedPartTitle = v$.value.selectedPartTitle.$model?.value;
  const hiredDate = v$.value.hiredDate.$model;

  if (selectedMember.value && selectedPartTitle && hiredDate) {
    const obj = doctorPartTitles.value;
    let result;
    // 객체의 모든 키에 대해 반복하며 검색
    for (let key in obj) {
      if (key in obj) {
        if (typeof obj[key] === 'object') {
          for (let subKey in obj[key]) {
            for (let secondSubKey in obj[key][subKey]) {
              if (obj[key][subKey][secondSubKey].label === selectedPartTitle) {
                result = obj[key].label;
                break;
              }
            }
          }
        }
      }
    }
    selectedMember.value.hired_date = await store.dispatch('formattingDate', hiredDate);

    registMember.value = {
      part: result,
      title: selectedPartTitle,
      ...selectedMember.value,
    };
    store.commit('loadingChange');
    store.dispatch('doctorRegistSave', {
      value: registMember.value,
      callFn: closeSaveModal,
    });
  }
}, 500);
</script>
<script>
export default {
  name: 'DoctorDialog',
};
</script>

<style></style>
