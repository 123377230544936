<template>
  <Toast />
  <div class="mt-3">
    <DataTable
      :value="authList"
      rowGroupMode="rowspan"
      :groupRowsBy="[
        'user_id',
        'hospital',
        'department',
        'name',
        'birth',
        'phone',
        'auth_category',
      ]"
      :paginator="true"
      :rows="20"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 30]"
      responsiveLayout="scroll"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      removableSort
      dataKey="id"
      v-model:filters="filters1"
      filterDisplay="menu"
      :globalFilterFields="['user_id', 'hospital', 'department', 'name', 'is_valid']"
      @row-dblclick="allCheck($event)"
      :row-hover="true"
    >
      <template #header>
        <div class="flex justify-content-between align-items-end">
          <div>
            <h4>권한관리</h4>
          </div>
          <div>
            <Button label="실행" class="p-button-sm mr-2" @click="execution()" />
          </div>
        </div>
      </template>
      <Column
        field="user_id"
        header="아이디"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        :sortable="true"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.user_id }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="hospital"
        header="소속병원"
        :sortable="true"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.hospital }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            v-model="filterModel.value"
            :options="hospitalList"
            optionLabel="name"
            optionValue="name"
            @change="filterCallback()"
          >
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="department"
        header="소속부서"
        :sortable="true"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.department }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <MultiSelect
            v-model="filterModel.value"
            :options="departmentList"
            optionLabel="name"
            optionValue="name"
            @change="filterCallback()"
          >
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="name"
        header="이름"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        :sortable="true"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.name }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="birth" header="생년월일" :sortable="true">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.birth }}
          </div>
        </template></Column
      >
      <Column field="phone" header="번호" :sortable="true">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.phone }}
          </div>
        </template></Column
      >
      <Column field="auth_category" header="요청카테고리" :sortable="true">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.auth_category }}
          </div>
        </template></Column
      >

      <Column field="auth_content" header="요청권한" :sortable="true">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.auth_content }}
          </div>
        </template>
      </Column>
      <Column
        field="is_valid"
        header="권한승인"
        :sortable="true"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <Checkbox v-model="data.is_valid" :binary="true" @click="oneCheck(data)" />
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Checkbox
            v-model="filterModel.value"
            @change="filterCallback()"
            :binary="true"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import {
  ref,
  onBeforeMount,
  getCurrentInstance,
  watchEffect,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import _ from 'lodash';
import { useToast } from 'primevue/usetoast';
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';

const router = useRouter();
const toast = useToast();
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

const store = useStore();
const authList = ref([]);
const authListCompare = ref([]);
const hospitalList = ref();
const departmentList = ref();
const selectdValues = ref([]);
const errorIs = ref();
const authInterval = ref();

const filters1 = ref({
  user_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  hospital: { value: null, matchMode: FilterMatchMode.IN },
  department: { value: null, matchMode: FilterMatchMode.IN },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  is_valid: { value: null, matchMode: FilterMatchMode.EQUALS },
});

watchEffect(() => {
  errorIs.value = store.state.authStore.errorHandler;
  if (errorIs.value) {
    $errorHandling(toast, errorIs.value, clearAuth);
    store.commit('errorCommit', '');
  }
});

onBeforeMount(async () => {
  await init();
  authInterval.value = setInterval(() => {
    init();
  }, 2000);
});

onBeforeUnmount(() => {
  clearInterval(authInterval.value);
});

async function init() {
  await store.dispatch('authListGet');
  const oldData = authListCompare.value;
  const newData = store.state.authStore.authList;

  if (!_.isEqual([...oldData], [...newData])) {
    authList.value = _.cloneDeep(store.state.authStore.authList);
    authListCompare.value = _.cloneDeep(store.state.authStore.authList);
    hospitalList.value = _.cloneDeep(
      uniqArray(store.state.authStore.authList, 'hospital')
    );
    departmentList.value = _.cloneDeep(
      uniqArray(store.state.authStore.authList, 'department')
    );
  }
}

function clearAuth() {
  store.commit('errorCommit', '');
  router.push({ name: 'status-board' });
}

function uniqArray(value, value2) {
  return _.uniqWith(
    value.map((a) => ({ name: a[value2] })),
    _.isEqual
  );
}

function allCheck(e) {
  const user_id = e.data.user_id;
  const valid = authList.value
    .filter((b) => b.user_id === user_id)
    .map((c) => c.is_valid)
    .every((d) => d);

  authList.value.forEach((a) => {
    if (a.user_id == user_id) {
      if (valid) {
        a.is_valid = false;
      } else {
        a.is_valid = true;
      }
      const index = selectdValues.value.findIndex((b) => b.id === a.id);
      if (index >= 0) {
        selectdValues.value.splice(index, 1, a);
      } else {
        selectdValues.value.push(a);
      }
    }
  });
}

function oneCheck(e) {
  const index = selectdValues.value.findIndex((b) => b.id === e.id);

  if (index >= 0) {
    selectdValues.value.splice(index, 1, e);
  } else {
    selectdValues.value.push(e);
  }
}

const execution = _.debounce(async () => {
  if (selectdValues.value.length > 0) {
    await store.dispatch('authExecution', selectdValues.value);
    Swal.fire('권한이 승인되었습니다.', '', 'success');
  } else {
    Swal.fire('실행할 내용을 선택하고 실행을 클릭해주세요.', '', 'warning');
  }
}, 250);
</script>
<script>
export default {
  name: 'AuthManage',
};
</script>

<style lang="scss" scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
