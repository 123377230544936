<template>
  <Sidebar
    v-model:visible="store.state.treatStore.treatSidebarVisible"
    class="p-sidebar-lg"
    @hide="stateFalse"
    :autoZIndex="true"
    position="full"
  >
    <template #header>
      <div class="absolute top-0 left-0 ml-5">
        <h4>수/시술 진행된 수가표</h4>
      </div>
    </template>
    <div>
      <DataTable
        :value="store.state.treatStore.soogaListInBom"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]"
        currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      >
        <template #empty> No choice Sooga List </template>
        <template #loading> Loading data. Please wait. </template>

        <!-- 수가표 헤더 -->
        <ColumnGroup type="header">
          <Row>
            <Column header="대분류"></Column>
            <Column header="중분류"></Column>
            <Column header="소분류"></Column>
            <Column header="약어"></Column>
            <Column header="내용"></Column>
            <Column header="정상가"></Column>
            <Column header="이벤트가"></Column>
            <Column header="VAT 포함"></Column>
            <Column header="개수"></Column>
            <Column header="판매 총액"></Column>
            <Column header="할인율"></Column>
            <Column header="할인 및 서비스"></Column>
            <Column header="작성자"></Column>
            <Column header=""></Column>
          </Row>
        </ColumnGroup>

        <!-- 수가표 데이터 -->
        <Column header="대분류" field="large_category">
          <template #body="{ data }">
            <div class="flex justify-content-center" style="min-width: 50px">
              {{ data.large_category }}
            </div>
          </template>
        </Column>
        <Column header="중분류" field="middle_category">
          <template #body="{ data }">
            <div class="flex justify-content-center" style="min-width: 80px">
              {{ data.middle_category }}
            </div>
          </template>
        </Column>
        <Column header="소분류" field="small_category">
          <template #body="{ data }">
            <div class="flex justify-content-center" style="min-width: 100px">
              {{ data.small_category }}
            </div>
          </template>
        </Column>
        <Column header="약어">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.abbreviation }}
            </div>
          </template>
        </Column>
        <Column header="내용">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.medical_menu_content }}
            </div>
          </template>
        </Column>
        <Column header="정상가">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ formatCurrency(data.price) }}
            </div>
          </template>
        </Column>
        <Column header="이벤트가">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ formatCurrency(data.event_price) }}
            </div>
          </template>
        </Column>
        <Column header="VAT 포함">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ formatCurrency(data.price_include_vat) }}
            </div>
          </template>
        </Column>
        <Column header="개수">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.menu_count }}
            </div>
          </template>
        </Column>
        <Column header="판매 총액">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ formatCurrency(data.sales_price) }}
            </div>
          </template>
        </Column>
        <Column header="할인율">
          <template #body="{ data }">
            <div class="flex justify-content-center">{{ data.discount_rate }}%</div>
          </template>
        </Column>
        <Column header="할인 및 서비스">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.discount_name }}
            </div>
          </template>
        </Column>
        <Column header="작성자">
          <template #body="{ data }">
            <Tag
              style="min-width: 90px"
              icon="pi pi-user"
              severity="warning"
              :value="
                data.writer_name
                  ? data.writer_name
                  : getters.getUserName
                  ? getters.getUserName
                  : 'test_user'
              "
              v-tooltip.top="
                data.update_writer_name
                  ? `${showUpdateTimestamp(data.update_timestamp)}\n편집됨\n${
                      data.update_writer_name
                    }`
                  : ''
              "
            ></Tag>
          </template>
        </Column>
        <Column header="삭제">
          <template #body="{ data }">
            <div class="flex justify-content-center">
              <Button
                style="min-width: 60px"
                class="p-button-sm p-button-danger"
                v-show="!data.is_paid"
                @click="
                  deleteSoogaSurgery(
                    data.id,
                    data.counseling_id ?? null,
                    data.counseling_surgery_id ?? null
                  )
                "
                :disabled="data.is_in_bom"
              >
                삭제</Button
              >
              <Button
                style="min-width: 60px"
                class="p-button-sm p-button-warning"
                v-show="data.is_paid"
                :disabled="true"
              >
                수납</Button
              >
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </Sidebar>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
// import _ from 'lodash';
// import { useToast } from 'primevue/usetoast';

const store = useStore();
const getters = computed(() => store.getters);

// const toast = useToast();
// const app = getCurrentInstance();
// 금액 표현 형식
const formatCurrency = (value) => {
  if (value) {
    return value.toLocaleString('ko-KR');
  } else {
    return '';
  }
};
// const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

function stateFalse() {
  store.commit('setTreatSidebarVisible', false);
}
</script>

<style></style>
