<template>
  <div>
    <Toolbar class="mb-4">
      <template #start>
        <Button
          label="신규등록"
          icon="pi pi-plus"
          class="p-button-success mr-2"
          @click="openNewRegist"
        />
        <Button
          label="선택삭제"
          icon="pi pi-trash"
          class="p-button-danger"
          :disabled="selectedCheckHead() === 0"
          @click="selectedDelete"
        />
      </template>

      <template #end>
        <Button
          label="CSV다운"
          icon="pi pi-upload"
          class="p-button-help"
          @click="exportCSV($event)"
        />
      </template>
    </Toolbar>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Toolbar from 'primevue/toolbar';
import { useStore } from 'vuex';

const store = useStore();

function openNewRegist() {
  store.commit('productDialogCommit', true);
  store.commit('productSelectedCommit', {
    active: true,
    byactive: false,
    cost: null,
    cost_when_input: null,
    divider: null,
    hospital: null,
    id: null,
    minimum: null,
    product_code: null,
    product_name: null,
    purchase: null,
    standard_name: null,
    unit_name: null,
    unit_when_input: null,
    newProduct: true,
  });
}

function selectedCheckHead() {
  const selectedValues = store.state.purchaseGetdata.selectedProducts;
  return _.reduce(
    selectedValues,
    (a, b) => {
      return (a += 1);
    },
    0
  );
}

function selectedDelete() {
  store.commit('purchaseDeleteStateCommit', true);
}

const exportCSV = () => {
  store.state.purchaseGetdata.purchaseDt.exportCSV();
};
</script>
<script>
export default {
  name: 'PurchaseToolbar',
};
</script>

<style></style>
