<template>
  <div class="col-3"></div>
  <div class="col-6">
    <Card class="w-full">
      <template #title>
        <div class="gird flex justify-content-center align-items-center">
          <div class="col-7 cursor-pointer" @click="showContentsModal">
            {{ componentName }} 내용 입력
          </div>
          <div class="col-5">
            <div
              v-if="store.state.treatStore.treatHasStarted"
              class="p-inputgroup"
              style="height: 20px"
            >
              <span class="p-inputgroup-addon" style="font-size: 12px">시작</span>
              <InputMask
                v-model="start_time"
                mask="99:99"
                placeholder="시작시간 수정"
                :inputStyle="{ 'text-align': 'center' }"
                :disabled="!store.state.treatStore.treatHasStarted"
              />
              <Button
                icon="pi pi-check"
                :disabled="!store.state.treatStore.treatHasStarted"
                @click="modifyTreatTime('start_time')"
              />
            </div>
            <div
              v-if="
                store.state.treatStore.treatHasStarted &&
                store.state.modalTop.modalTopBoardCompare.receiption.filter(
                  (a) => a.process === '진료'
                )[0]?.end_time
              "
              class="p-inputgroup mt-2"
              style="height: 20px"
            >
              <span class="p-inputgroup-addon" style="font-size: 12px">종료</span>
              <InputMask
                v-model="end_time"
                mask="99:99"
                placeholder="종료시간 수정"
                :inputStyle="{ 'text-align': 'center' }"
                :disabled="!store.state.treatStore.treatHasStarted"
              />
              <Button
                icon="pi pi-check"
                :disabled="!store.state.treatStore.treatHasStarted"
                @click="modifyTreatTime('end_time')"
              />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
  <div class="col-3"></div>
  <div class="col-12">
    <TabView :scrollable="true" @tab-click="clickTab($event)">
      <TabPanel v-for="data in counselData" :key="data.id" :header="data.counseling_date">
        <div v-if="data.soogaDataList">
          <DataTable
            :value="dataTableData"
            :paginator="true"
            :rows="10"
            dataKey="id"
            :rowHover="true"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
            responsiveLayout="scroll"
          >
            <ColumnGroup type="header">
              <Row>
                <Column header="구분"></Column>
                <Column header="약어"></Column>
                <Column header="VAT 포함"></Column>
                <Column header="개수"></Column>
                <Column header="판매 총액"></Column>
                <Column header="할인율"></Column>
                <Column header="작성자"></Column>
                <Column></Column>
              </Row>
            </ColumnGroup>

            <Column header="구분" field="구분">
              <template #body="{ data }">
                <div class="flex justify-content-center">
                  {{ data?.large_category
                  }}{{ data.middle_category ? `▶${data.middle_category}` : ''
                  }}{{ data.small_category ? `▶${data.small_category}` : '' }}
                </div>
                <div
                  v-if="data?.menu_id_before_change"
                  class="text-sm text-green-400 flex justify-content-center"
                >
                  {{ beforeMenu(data.menu_id_before_change).name }}
                </div>
              </template>
            </Column>
            <Column header="약어">
              <template #body="{ data }">
                <div class="flex justify-content-center">
                  {{ data.abbreviation }}
                </div>
                <div
                  v-if="data?.menu_id_before_change"
                  class="text-sm text-green-400 flex justify-content-center"
                >
                  {{ beforeMenu(data.menu_id_before_change).abb }}
                </div>
              </template>
            </Column>
            <Column header="VAT 포함">
              <template #body="{ data }">
                <div class="flex justify-content-center">
                  {{ formatCurrency(data.price_include_vat) }}
                </div>
                <div
                  v-if="data?.menu_id_before_change"
                  class="text-sm text-green-400 flex justify-content-center"
                >
                  {{ formatCurrency(beforeMenu(data.menu_id_before_change).vat) }}
                </div>
              </template>
            </Column>
            <Column header="개수">
              <template #body="{ data }">
                <div class="flex justify-content-center">
                  {{ data.menu_count }}
                </div>
              </template>
            </Column>
            <Column header="판매 총액">
              <template #body="{ data }">
                <div class="flex justify-content-center">
                  {{ formatCurrency(data.sales_price) }}
                </div>
              </template>
            </Column>
            <Column header="할인율">
              <template #body="{ data }">
                <div class="flex justify-content-center">{{ data.discount_rate }}%</div>
              </template>
            </Column>
            <Column header="할인율">
              <template #body="{ data }">
                <div class="flex justify-content-center">{{ data.writer_name }}</div>
              </template>
            </Column>
          </DataTable>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';
import {
  ref,
  watchEffect,
  onBeforeMount,
  onBeforeUnmount,
  computed,
  getCurrentInstance,
} from 'vue';
import axios from 'axios';

// const router = useRouter();
const toast = useToast();
const store = useStore();
const start_time = ref();
const end_time = ref();
const isDoctor = ref();
const selectedDoctor = ref();
const componentName = ref();
const counselData = ref({ data: [], reception: [] });
const dateValidateData = ref();
const compareSelectedDate = ref();
const dataTableData = ref([{}]);
const getters = computed(() => store.getters);
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const storeGetters = computed(() => store.getters);

onBeforeMount(() => {
  if (store.state.counselingAndTreatStore.isCounseling) {
    componentName.value = '상담';
  } else {
    componentName.value = '진료';
  }

  loadTimeValue();
});

onBeforeMount(async () => {
  try {
    const URL = getters.value.getUrl;
    const customerCode = getters.value.getBoardModalCustomerId;

    const result = await axios.get(`${URL}/counsel?customerid=${customerCode}`);
    const data = result.data.data;
    data.sort((a, b) => b.id - a.id);

    dateValidateData.value = data;

    counselData.value = data;

    dataTableData.value = counselData.value[0]?.soogaDataList
      ? counselData.value[0]?.soogaDataList
      : [{}];
  } catch (err) {
    $errorHandling(null, err);
  }
});

onBeforeUnmount(() => {
  store.commit('setCounselingHasStarted', false);
  store.commit('setTreatHasStarted', false);
});

watchEffect(() => {
  isDoctor.value = store.state.counselingAndTreatStore.isDoctor;
  selectedDoctor.value = store.state.counselingAndTreatStore.selectedDoctor;

  // 시작버튼 활성화 / 비활성화
  compareSelectedDate.value = store.state.board.boardModal.boardSendDate;
  for (let i in dateValidateData.value) {
    if (dateValidateData.value[i].counseling_date == compareSelectedDate.value) {
      if (dateValidateData.value[i].counseling_or_treat === 0) {
        store.commit('setCounselingHasStarted', true);
      } else {
        store.commit('setCounselingHasStarted', true);
        store.commit('setTreatHasStarted', true);
        loadTimeValue();
      }
      break;
    } else {
      store.commit('setCounselingHasStarted', false);
      store.commit('setTreatHasStarted', false);
    }
  }
});

// const start_time = computed({
//   get: () => {
//     return store.state.modalTop.modalTopBoardCompare.receiption.filter(
//       (a) => a.process === '진료'
//     )[0]?.start_time;
//   },
//   set: (value) => {
//     if (
//       store.state.modalTop.modalTopBoardCompare.receiption.filter(
//         (a) => a.process === '진료'
//       )
//     ) {
//       store.commit('modalTopBoardTreatStartTimeCommit', value);
//     }
//   },
// });

// const end_time = computed({
//   get: () => {
//     return store.state.modalTop.modalTopBoardCompare.receiption.filter(
//       (a) => a.process === '진료'
//     )[0]?.end_time;
//   },
//   set: (value) => {
//     if (
//       store.state.modalTop.modalTopBoardCompare.receiption.filter(
//         (a) => a.process === '진료'
//       )
//     ) {
//       store.commit('modalTopBoardTreatEndTimeCommit', value);
//     }
//   },
// });

const formatCurrency = (value) => {
  if (value) {
    return value.toLocaleString('ko-KR');
  } else {
    return '';
  }
};

function loadTimeValue() {
  start_time.value = store.state.modalTop.modalTopBoardCompare.receiption.filter(
    (a) => a.process === '진료'
  )[0]?.start_time;
  end_time.value = store.state.modalTop.modalTopBoardCompare.receiption.filter(
    (a) => a.process === '진료'
  )[0]?.end_time;
}

function beforeMenu(id) {
  const soogaData = store.state.counselingAndTreatStore.soogaData;
  const soogaFilter = soogaData.filter((a) => parseInt(a.id) === parseInt(id));

  if (soogaFilter.length > 0) {
    const zeroData = soogaFilter[0];

    return {
      name: `${zeroData.large_category}${
        zeroData?.middle_category ? `▶${zeroData?.middle_category}` : ''
      }${zeroData?.small_category ? `▶${zeroData?.small_category}` : ''}`,
      abb: zeroData.abbreviation,
      vat: zeroData.price_include_vat,
    };
  }

  return [];
}

function clickTab(e) {
  dataTableData.value = counselData.value[e.index]?.soogaDataList
    ? counselData.value[e.index]?.soogaDataList
    : [{}];
}

function showContentsModal() {
  if (store.state.counselingAndTreatStore.isCounseling) {
    // 상담에서 들어왔다면
    if (store.state.counselingStore.counselingHasStarted) {
      // 상담 시작이 있어야 modal이 show 하도록 함
      store.commit('setCounselingModalShow', true);
    } else {
      alertClickButton();
    }
  } else {
    // 진료에서 들어왔다면
    if (store.state.treatStore.treatHasStarted) {
      // 상담 시작이 있어야 modal이 show 하도록 함
      store.commit('setTreatModalShow', true);
    } else {
      alertClickButton();
    }
  }
}

function alertClickButton() {
  toast.add({
    severity: 'warn',
    summary: `${componentName.value} 시작 필요`,
    detail: `${componentName.value} 시작 버튼을 눌러주세요.`,
    life: 3000,
  });
}
function clearFunc() {
  store.commit('boardModalStateFalse');
}

// 시간 맞게 작성하였는지 검사
function isValidTime(timeStr) {
  // 정규식을 이용하여 24시간 HH:mm 형식의 문자열인지 확인
  const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(timeStr)) {
    return false;
  }

  // 시간과 분을 추출
  const [hours, minutes] = timeStr.split(':');

  // 시간과 분이 각각 0부터 23, 0부터 59 사이의 값인지 확인
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return false;
  }

  return true;
}

function modifyTreatTime(timeType) {
  const { isChecked, writer } = storeGetters.value.getDoctorCheckboxChecked;
  const process = '진료';
  const boardDate = store.state.board.boardModal.boardSendDate;

  let time = null;
  let timestamp = null;
  if (timeType === 'start_time') {
    time = start_time.value;
    timestamp = boardDate + ' ' + start_time.value;
  } else {
    time = end_time.value;
    timestamp = boardDate + ' ' + end_time.value;
  }
  if (!isValidTime(time)) {
    toast.add({
      severity: 'error',
      summary: '진료시간 양식 오류',
      detail: '진료시간을 양식에 맞게 작성해주세요.',
      life: 3000,
    });
  } else {
    if (isChecked) {
      try {
        store.dispatch('treatTimeUpdate', {
          writer,
          timeType,
          timestamp,
          process,
        });
        toast.add({
          severity: 'success',
          summary: '진료시간 수정완료',
          detail: '진료시간 수정이 완료되었습니다.',
          life: 3000,
        });
      } catch (error) {
        $errorHandling(null, error, clearFunc);
      }
    }
  }
}

// async function upsertBoardTime(timeValue, isStart) {
//   // 이 함수는 v-show = false 로 되어있기 때문에 사실 상 동작하지 않음
//   // 추후에 사용될 수 있어 살려놓은 함수
//   try {
//     // 시간이 입력되면
//     if (timeValue) {
//       let writer;
//       let counselerIsDoctor = null;

//       if (isDoctor.value) {
//         // 의사 체크박스를 체크한 경우 -> 원장 상담
//         if (selectedDoctor.value) {
//           // 체크박스에 체크하고, 의사를 고른 경우
//           writer = selectedDoctor.value;
//           counselerIsDoctor = true;

//           // 상담 시작 board 입력
//           upsertBoardFromCounseling(
//             isStart,
//             writer,
//             $formatDate('time', timeValue),
//             counselerIsDoctor
//           );
//         } else {
//           // 체크박스에 체크했지만, 의사를 고르지 않은 경우
//           toast.add({
//             severity: 'warn',
//             summary: '의사 선택',
//             detail: '의사 선생님을 입력해주세요.',
//             life: 3000,
//           });
//         }
//       } else {
//         // 의사 체크박스를 체크하지 않은 경우 -> 상담실장 상담
//         writer = null;
//         counselerIsDoctor = false;

//         // 상담 시작 board 입력
//         upsertBoardFromCounseling(
//           isStart,
//           writer,
//           $formatDate('time', timeValue),
//           counselerIsDoctor
//         );
//       }
//     }
//   } catch (error) {
//     router.push({ name: 'status-board' });
//     $errorHandling(toast, error);
//   }
// }

// function upsertBoardFromCounseling(isStart, writer, time, counselerIsDoctor) {
//   // 사용 안함 => 정민님 요청으로 일단 대기 => 삭제 말것
//   store.dispatch('upsertBoard', {
//     isStart: isStart,
//     writer: writer,
//     time: time,
//     process: componentName.value,
//     counselerIsDoctor: counselerIsDoctor,
//   });

//   toast.add({
//     severity: 'success',
//     summary: `${componentName.value} 시작`,
//     detail: `${componentName.value} 시작 시간을 입력했습니다.`,
//     life: 3000,
//   });
// }
</script>

<style></style>
