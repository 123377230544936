import axios from 'axios';
import _ from 'lodash';

const surgeryStore = {
  state() {
    return {
      customerCode: '',
      sugeryData: '',
      roomList: '',
      room: '',
      surgeryState: false,
      bomSelectedDoc: '',
      bomSelectedDocName: '',
      bomMenuState: false,
      docWholeBom: '',
      docWholeBomMenu: '',
      commonBom: '',
      commonBomMenu: '',
      docCustomerBom: '',
      docCustomerBomMenu: '',
      surgeryFrontDataTable: [],
      surgeryFrontDataTableCompare: [],
      largeSelectIndex: '',
      bom_id: '',
      selectedIndex: [],
      deleteSurgeryProductData: '',
      sugeryModiInitErrorData: '',
      sugeryMemoCompare: '',
    };
  },
  mutations: {
    sugeryCustomerCode(state, payload) {
      state.customerCode = payload;
    },
    sugeryInitDataSend(state, payload) {
      state.sugeryData = payload;
    },
    sugeryRoomList(state, payload) {
      state.roomList = payload;
    },
    sugeryRoom(state, payload) {
      state.room = payload;
    },
    surgeryStateUpdate(state) {
      state.surgeryState = !state.surgeryState;
    },
    bomSelectedDocCommit(state, payload) {
      state.bomSelectedDoc = payload;
    },
    surgerybomSelectedDoc(state, payload) {
      state.bomSelectedDoc = payload.id;
      state.bomSelectedDocName = payload.name;
    },
    surgeryFrontDataTableCommit(state, payload) {
      state.surgeryFrontDataTable = payload;
    },
    surgeryBomMenuState(state) {
      state.bomMenuState = !state.bomMenuState;
    },
    surgeryBomDataReset(state) {
      state.docWholeBom = '';
      state.docWholeBomMenu = '';
      state.commonBom = '';
      state.commonBomMenu = '';
      state.docCustomerBom = '';
      state.docCustomerBomMenu = '';
    },
    surgeryBomMenuData(state, payload) {
      // if (payload.docWholeBom && payload.docWholeBom.length > 0) {
      //   state.docWholeBom = payload.docWholeBom;
      // }
      if (payload.commonBom && payload.commonBom.length > 0) {
        state.commonBom = payload.commonBom;
      }
      // if (payload.docCustomerBom && payload.docCustomerBom.length > 0) {
      //   state.docCustomerBom = payload.docCustomerBom;
      // }
    },

    surgeryBomId(state, payload) {
      state.bom_id = payload;
    },
    surgerylargeSelectIndex(state, paylod) {
      state.largeSelectIndex = paylod;
    },
    surgeryReset(state) {
      state.customerCode = '';
      state.sugeryData = '';
      state.roomList = '';
      state.room = '';
      state.surgeryState = false;
      state.bomSelectedDoc = '';
      state.bomSelectedDocName = '';
      state.bomMenuState = false;
      state.docWholeBom = '';
      state.docWholeBomMenu = '';
      state.commonBom = '';
      state.commonBomMenu = '';
      state.docCustomerBom = '';
      state.docCustomerBomMenu = '';
      state.surgeryFrontDataTable = [];
      state.surgeryFrontDataTableCompare = [];
      state.largeSelectIndex = '';
      state.bom_id = '';
      state.selectedIndex = [];
      state.deleteSurgeryProductData = '';
      state.sugeryModiInitErrorData = '';
    },
    sugerySideSelected(state, payload) {
      if (typeof payload === 'number') {
        state.selectedIndex.push(payload);
        state.selectedIndex = _.uniq([...state.selectedIndex]);
      } else {
        state.selectedIndex = _.uniq([...state.selectedIndex, ...payload]);
      }
    },
    sugerysideUnSelected(state, payload) {
      if (typeof payload === 'number') {
        const index = state.selectedIndex.findIndex((a) => a === payload);
        state.selectedIndex.splice(index, 1);
      } else {
        const indexs = payload.map((a) => state.selectedIndex.findIndex((b) => b === a));
        indexs.sort((a, b) => b - a);
        indexs.forEach((a) => state.selectedIndex.splice(a, 1));
      }
    },
    selectedIndexReset(state) {
      state.selectedIndex = [];
    },
    sugeryModiInitCommit(state, payload) {
      if (!_.isEqual([...state.surgeryFrontDataTableCompare], [...payload])) {
        state.surgeryFrontDataTable = _.cloneDeep([...payload]);
        state.surgeryFrontDataTableCompare = _.cloneDeep([...payload]);
      }
    },
    sugeryProductDelete(state, payload) {
      state.surgeryFrontDataTable.splice(payload, 1);
    },
    sugeryDeleteSurgeryProduct(state, payload) {
      state.deleteSurgeryProductData = payload;
    },
    sugeryModiInitError(state, payload) {
      state.sugeryModiInitErrorData = payload;
    },
  },
  actions: {
    async surgeryTime({ rootState, commit }, payload) {
      const URL = rootState.URL;
      commit('loadingChangeValue', true);
      await axios.put(URL + `/registration-modal/put-surgery-time`, payload);
    },
    async sugeryInitData({ commit, rootState }) {
      const result = async () => {
        const sergeryDate = rootState.board.boardModal.boardSendDate;
        const customerCode = rootState.board.boardModal.boardSendCutomerCode;
        const URL = rootState.URL;

        const result = await axios.get(
          URL +
            `/registration-modal/today_surgery_info?code=${customerCode}&date=${sergeryDate}`
        );

        commit('sugeryInitDataSend', result.data);
      };

      await result();
      commit('setTimeout', {
        name: 'modal-surgery-room',
        fn: result,
      });
    },

    async sugeryRoomApi({ commit, rootState }) {
      const URL = rootState.URL;
      commit('loadingChangeValue', true);
      const result = await axios.get(URL + `/registration-modal/surgery-room`);
      commit('sugeryRoomList', result.data.result);
    },

    async surgeryRoomRegist({ rootState, commit }, payload) {
      const URL = rootState.URL;
      const surgeryDate = rootState.board.boardModal.boardSendDate;
      const customerCode = rootState.board.boardModal.boardSendCutomerCode;
      const receptionCode = rootState.board.boardModal.boardReceptionCode;
      const sendData = { surgeryDate, customerCode, receptionCode, ...payload };

      commit('loadingChangeValue', true);
      const result = await axios.post(
        URL + '/registration-modal/surgery-room-regist',
        sendData
      );
      return result;
    },

    async surgeryBomMenuLoad({ state, rootState, commit, getters }) {
      const URL = rootState.URL;
      const customerCode = rootState.board.boardModal.boardSendCutomerCode;
      const docCode = state.bomSelectedDoc;

      commit('loadingChangeValue', true);
      const result = await axios.get(
        URL +
          `/registration-modal/surger-bom-menu?customercode=${customerCode}&doccode=${docCode}`
      );

      commit('surgeryBomMenuData', result.data);
      getters.surgeryBomMenuMapping;
    },

    async sugeryModiInit({ state, rootState, commit, dispatch }) {
      const result = async () => {
        const URL = rootState.URL;
        const bom_id = state.bom_id;

        const result = await axios.get(
          URL + `/registration-modal/sugery-modi-init?bomid=${bom_id}`
        );

        result?.data?.result && commit('sugeryModiInitCommit', result.data.result);

        const product_menu_id = state.deleteSurgeryProductData;
        if (product_menu_id) {
          const restData = state.surgeryFrontDataTable.filter(
            (a) => a.bom_product_menu_id === product_menu_id
          );

          if (restData.length === 0) {
            await dispatch(
              'sugeryDeleteProductMenuExecution',
              _.cloneDeep(product_menu_id)
            );
          }
          commit('sugeryDeleteSurgeryProduct', '');
        }
      };

      result();
      await commit('setTimeout', {
        name: 'modal-surgery',
        fn: result,
      });
    },

    async deleteSurgeryProduct({ rootState, commit }, payload) {
      const URL = rootState.URL;
      commit('loadingChangeValue', true);
      const result = await axios.post(URL + '/registration-modal/delete-product', {
        bom_product_id: payload.bom_product_id,
      });
      const bom_product_menu_id = result?.data?.data[0][0]
        ? result?.data?.data[0][0].bom_product_menu_id
        : '';

      commit('sugeryDeleteSurgeryProduct', bom_product_menu_id);
    },

    async sugeryDeleteProductMenuExecution({ rootState, commit }, payload) {
      const URL = rootState.URL;
      commit('loadingChangeValue', true);
      await axios.post(URL + '/registration-modal/delete-product-menu', {
        bom_product_menu_id: payload,
      });

      commit('sugeryDeleteSurgeryProduct', '');
    },

    async sugeryRoomDelete({ rootState, commit }, payload) {
      const URL = rootState.URL;
      commit('loadingChangeValue', true);

      // 시/수술 등록 데이터 삭제
      await axios.delete(URL + '/registration-modal/delete-room', {
        data: { bom_id: payload },
      });
    },
  },
  getters: {
    sugeryInit(state, getters, rootState) {
      const initDate = rootState.board.boardModal.boardSendDate;
      const initData = state.sugeryData;

      const dataSort = initData?.result;
      if (dataSort) {
        const initFilter = dataSort.filter((a) => a.sugery_date === initDate);
        const initObj = {};
        const initFilterUniq = _.uniqBy(
          initFilter.map((a) => {
            if (!initObj[a.id]) {
              initObj[a.id] = [a.menu_name];
            } else {
              initObj[a.id].push(a.menu_name);
            }

            return {
              id: a.id,
              floor: a.floor,
              surgery_room_id: a.surgery_room_id,
              start_time: a.start_time,
              end_time: a.end_time,
            };
          }),
          'id'
        );

        return {
          initDataTitle: initFilterUniq,
          initDataBody: initObj,
          initExistRoom: initFilterUniq.map((a) => a.surgery_room_id),
        };
      } else {
        return {
          initDataTitle: [],
          initDataBody: {},
          initExistRoom: [],
        };
      }
    },
    surgeryBomMenuMapping(state) {
      // if (state.docWholeBom) {
      //   const menu = state.docWholeBom.map((a) => ({
      //     sort: a.sort,
      //     abbreviation: a.abbreviation,
      //     counseling_date: a.counseling_date,
      //     counseling_id: a.counseling_id,
      //     discount_name: a.discount_name,
      //     discount_rate: a.discount_rate,
      //     doc_nm: a.doc_nm,
      //     event_price: a.event_price,
      //     large_category: a.large_category,
      //     medical_menu_content: a.medical_menu_content,
      //     menu_id: a.menu_id,
      //     menu_name: a.menu_name,
      //     menu_table_id: a.menu_table_id,
      //     note: a.note,
      //     price: a.price,
      //     price_include_vat: a.price_include_vat,
      //     sales_price: a.sales_price,
      //   }));
      //   state.docWholeBomMenu = _.uniqWith(menu, _.isEqual);
      // }

      // if (state.commonBom) {
      const menu = state.commonBom.map((a) => ({
        sort: a.sort,
        abbreviation: a.abbreviation,
        counseling_date: a.counseling_date,
        counseling_id: a.counseling_id,
        discount_name: a.discount_name,
        discount_rate: a.discount_rate,
        doc_nm: a.doc_nm,
        event_price: a.event_price,
        large_category: a.large_category,
        medical_menu_content: a.medical_menu_content,
        menu_id: a.menu_id,
        menu_name: a.menu_name,
        note: a.note,
        price: a.price,
        price_include_vat: a.price_include_vat,
        sales_price: a.sales_price,
        is_paid: a.is_paid,
      }));
      state.commonBomMenu = _.uniqWith(menu, _.isEqual);
      // }

      // if (state.docCustomerBom) {
      //   const menu = state.docCustomerBom.map((a) => ({
      //     sort: a.sort,
      //     abbreviation: a.abbreviation,
      //     counseling_date: a.counseling_date,
      //     counseling_id: a.counseling_id,
      //     discount_name: a.discount_name,
      //     discount_rate: a.discount_rate,
      //     doc_nm: a.doc_nm,
      //     event_price: a.event_price,
      //     large_category: a.large_category,
      //     medical_menu_content: a.medical_menu_content,
      //     menu_id: a.menu_id,
      //     menu_name: a.menu_name,
      //     menu_table_id: a.menu_table_id,
      //     note: a.note,
      //     price: a.price,
      //     price_include_vat: a.price_include_vat,
      //     sales_price: a.sales_price,
      //     menu_count: a.menu_count,
      //   }));
      //   state.docCustomerBomMenu = _.uniqWith(menu, _.isEqual);
      // }
    },
    surgerySelectedSubFilter(state) {
      // const index = state.largeSelectIndex ? state.largeSelectIndex : 1;
      const selectedIndexs = [...state.selectedIndex];
      let selectedValue = [];
      // if (index === 1) {
      //   const selectedValue1 = _.cloneDeep(
      //     state.docCustomerBomMenu.filter((a, ai) => selectedIndexs.includes(ai))
      //   );
      //   const unSelectedValue = _.cloneDeep(
      //     state.docCustomerBomMenu.filter((a, ai) => !selectedIndexs.includes(ai))
      //   );

      //   state.docCustomerBomMenu = _.cloneDeep(unSelectedValue);
      //   selectedValue1.forEach((a) => {
      //     const innerValue = _.filter([...state.docCustomerBom], { ...a });
      //     innerValue.forEach((a) => {
      //       a.is_service = false;
      //       a.doc_id = state.bomSelectedDoc;
      //     });
      //     selectedValue = [...selectedValue, ...innerValue];
      //   });
      // } else if (index === 2) {
      const selectedValue1 = _.cloneDeep(
        state.commonBomMenu.filter((a, ai) => selectedIndexs.includes(ai))
      );
      // const unSelectedValue = _.cloneDeep(
      //   state.commonBomMenu.filter((a, ai) => !selectedIndexs.includes(ai))
      // );

      // state.commonBomMenu = _.cloneDeep(unSelectedValue);
      selectedValue1.forEach((a) => {
        const innerValue = _.filter([..._.cloneDeep(state.commonBom)], (item) => {
          return (
            item.menu_id === a.menu_id &&
            (!a.counseling_id || item.counseling_id === a.counseling_id)
          );
        });
        innerValue.forEach((a) => {
          a.is_service = false;
          a.doctor_id = state.bomSelectedDoc;
          a.doc_id = state.bomSelectedDoc;
          a.doc_nm = state.bomSelectedDocName;
          a.product_name_is = true;
          a.activeTab = 0;
        });
        selectedValue = [...selectedValue, ...innerValue];
      });
      // }
      // } else {
      //   const selectedValue1 = _.cloneDeep(
      //     state.docWholeBomMenu.filter((a, ai) => selectedIndexs.includes(ai))
      //   );
      //   const unSelectedValue = _.cloneDeep(
      //     state.docWholeBomMenu.filter((a, ai) => !selectedIndexs.includes(ai))
      //   );

      //   state.docWholeBomMenu = _.cloneDeep(unSelectedValue);
      //   selectedValue1.forEach((a) => {
      //     const innerValue = _.filter([...state.docWholeBom], a);
      //     innerValue.forEach((a) => {
      //       a.is_service = true;
      //       a.doc_id = state.bomSelectedDoc;
      //     });
      //     selectedValue = [...selectedValue, ...innerValue];
      //   });
      // }

      const equlValue = state.surgeryFrontDataTable.map((a) => ({
        doc_id: a.doc_id,
        menu_id: a.menu_id,
        product_id: a.product_id,
      }));

      selectedValue.forEach((a) => {
        const val = {
          doc_id: a.doc_id,
          menu_id: a.menu_id,
          product_id: a.product_id,
        };

        const ff = _.filter([...equlValue], { ...val });
        if (ff.length === 0) {
          const e1 = equlValue.map((a) => ({ doc_id: a.doc_id, menu_id: a.menu_id }));
          const v1 = {
            doc_id: a.doc_id,
            menu_id: a.menu_id,
          };
          const findInd = _.findLastIndex([...e1], { ...v1 });
          if (findInd >= 0) {
            state.surgeryFrontDataTable.splice(findInd + 1, 0, a);
          } else {
            state.surgeryFrontDataTable.push(a);
          }
        }
      });
    },
  },
};

export default surgeryStore;
