<template>
  <Dialog
    header="실적분배"
    v-model:visible="store.state.paymentStore.incentiveDialogState"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw !important' }"
    @hide="store.commit('incentiveDialogStateCommit', false)"
    :baseZIndex="1000"
    :autoZIndex="false"
  >
    <DataTable
      :value="incentiveData"
      dataKey="id"
      editMode="cell"
      @cell-edit-complete="onCellEditComplete"
      class="editable-cells-table"
      responsiveLayout="scroll"
    >
      <Column style="width: 2%">
        <template #body="slotProps">
          <!-- <template #body="slotProps"> -->
          <Button
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text p-button-sm"
            @click="removeProductRow(slotProps.data)"
          ></Button>
        </template>
      </Column>
      <Column field="member_department" header="부서" style="width: 20%">
        <template #editor="{ data, field }">
          <!-- <InputText v-model="data[field]" /> -->
          <Dropdown
            v-model="data[field]"
            editable
            :options="uniqueDepartment?.filter((element) => element !== undefined)"
            placeholder="부서 선택"
          >
          </Dropdown>
        </template>
      </Column>
      <Column field="member_name" header="이름" style="width: 20%">
        <template #editor="{ data, field }">
          <!-- <InputText v-model="data[field]" /> -->
          <Dropdown
            v-model="data[field]"
            editable
            :options="memberNameByDepartment?.filter((element) => element !== undefined)"
            :optionLabel="members"
            placeholder="이름 선택"
            :disabled="!data['member_department'] || !data['member_department'].length"
            @focus="nameFilterByDepartment(data['member_department'])"
            style="width: 100%"
          >
          </Dropdown>
        </template>
      </Column>
      <Column field="incentive_price" header="실적" style="width: 20%">
        <template #editor="{ data, field }">
          <InputNumber v-model="data[field]" inputId="integeronly">{{
            data[field]
          }}</InputNumber>
        </template>
        <template #body="slotProps">
          {{ $wonCurrency(slotProps?.data?.incentive_price) }}
        </template>
      </Column>
    </DataTable>
    <div class="flex justify-content-center flex-wrap">
      <Button
        label="인원 추가"
        icon="pi pi-plus"
        class="p-button-text text-lg"
        @click="addEmptyRow()"
      />
    </div>
    <template #footer>
      <Button
        label="취소"
        icon="pi pi-times"
        class="p-button-text"
        @click="store.commit('incentiveDialogStateCommit', false)"
      />
      <Button
        label="저장"
        icon="pi pi-check"
        class="p-button-text"
        @click="incentiveDataSave"
      />
    </template>
  </Dialog>
</template>

<script setup>
import { onMounted, onUnmounted, watchEffect, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import _ from 'lodash';

const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const store = useStore();
const incentiveData = ref(null);
const memberData = ref(null);
const paymentForIncentiveData = ref();
const uniqueDepartment = ref();
const memberNameByDepartment = ref();
const members = (member) => member.name + '(' + member.birth + ')';

watchEffect(async () => {
  incentiveData.value = (await store.state.paymentStore.incentiveData)
    ? store.state.paymentStore.incentiveData
    : null;
  memberData.value = (await store.state.paymentStore.memberData)
    ? store.state.paymentStore.memberData
    : '';

  // 신규입력 데이터 구분하기 위한 컬럼 추가
  for (let i = 0; i < incentiveData.value?.length; i++) {
    incentiveData.value[i].isNew = false;
  }
  // 멤버 데이터 관리자 구분없도록 통합
  for (let i = 0; i < memberData.value?.length; i++) {
    memberData.value[i].department = memberData.value[i].department.replace(
      '(관리자)',
      ''
    );
  }
  // 멤버 데이터 이름, 생년월일 순으로 정렬
  memberData.value = _.orderBy(memberData.value, ['name', 'birth'], ['asc', 'asc']);
});

onMounted(async () => {
  await dataInit();
});

onUnmounted(() => {
  store.commit('incentiveDialogStateCommit', false);
});

// mount된 경우, 초기화 함수
async function dataInit() {
  // 결제 완료 그룹별 데이터
  const paymentHeadData = store.state.paymentStore.paymentInitPaymentHeadData.filter(
    (data) => data.active === true
  )?.[0];

  // 결제 완료 전체 개별 데이터
  const paymentData = store.state.paymentStore.paymentInitPaymentData;
  if (paymentData) {
    paymentForIncentiveData.value = paymentData.filter(
      (data) => data.payment_id === paymentHeadData.payment_id
    );
  } else {
    paymentForIncentiveData.value = [];
  }
  await store.dispatch('getIncentiveInfo', {
    paymentId: paymentForIncentiveData.value[0].payment_id,
  });

  memberData.value = await store.state.paymentStore.memberData;

  if (memberData.value) {
    // 멤버 데이터 관리자 구분없도록 통합
    for (let i = 0; i < memberData.value?.length; i++) {
      memberData.value[i].department = memberData.value[i].department.replace(
        '(관리자)',
        ''
      );
    }
    // 멤버 데이터 이름, 생년월일 순으로 정렬
    memberData.value = _.orderBy(memberData.value, ['name', 'birth'], ['asc', 'asc']);

    // 등록된 멤버 소속 부서 중 고유값 호출 (null 제외)
    uniqueDepartment.value = Array.from(
      new Set(
        memberData.value.map((item) => {
          if (item.department) {
            return item.department;
          }
        })
      )
    );
  }
}

// 신규 실적분배 인원 추가
function addEmptyRow() {
  const newRow = {
    active: true,
    incentive_price: null,
    member_department: null,
    member_name: null,
    payment_id: paymentForIncentiveData.value[0].payment_id,
    isNew: true,
  };
  if (incentiveData.value) {
    incentiveData.value.push(newRow);
  } else {
    incentiveData.value = [newRow];
  }
}

// 부서 선택에 따른 부서 소속 멤버명 필터링 기능
function nameFilterByDepartment(department) {
  memberNameByDepartment.value = Array.from(
    new Set(
      memberData.value.map((item) => {
        if (item.department === department) {
          return item;
        }
      })
    )
  );
}

// 삭제 이벤트
function removeProductRow(data) {
  if (incentiveData.value.length > 1) {
    incentiveData.value = incentiveData.value.filter((element) => element !== data);
  } else {
    incentiveData.value = null;
  }
}

// 기존에 등록된 실적데이터의 경우
// async function deleteOldIncentiveData(data) {
//   if (data) {
//     try {
//       store.commit('incentiveDeleteIdCommit', data.incentive_id);
//       store.commit('incentiveDeleteStateCommit', true);
//     } catch (error) {
//       return;
//     }
//   }
// }

// 데이터 편집 이벤트
function onCellEditComplete(event) {
  let { data, newValue, field } = event;
  if (isNaN(newValue)) {
    if (field === 'member_name') {
      if (!data['member_department']) {
        Swal.fire('부서 선택 오류', '부서를 선택해 주세요.', 'warning');
      } else {
        if (newValue.name?.trim().length > 0) {
          data[field] = newValue.name;
          data['member_id'] = newValue.id;
        } /* else {
          Swal.fire('입력 오류', '이름을 선택해 주세요.', 'warning');
        }*/
      }
    } else {
      if (newValue?.trim().length > 0) {
        data[field] = newValue;
      } /* else {
        Swal.fire('입력 오류', '값을 모두 입력해 주세요.', 'warning');
      }*/
    }
  } else {
    if (newValue) {
      data[field] = newValue;
    } /* else {
      Swal.fire('입력 오류', '값을 모두 입력해 주세요.', 'warning');
    }*/
  }
}

// 데이터 결측치 확인 함수
function dataNullCheck(data) {
  let isNull = false;
  // TODO 2중 for 문
  data.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (key === 'update_writer' || key === 'update_timestamp') {
        return;
      }
      if (obj[key] === undefined || obj[key] === null) {
        isNull = true;
      }
    });
  });
  return isNull;
}

// 실적 데이터 저장 이벤트
async function incentiveDataSave() {
  if (!dataNullCheck(incentiveData.value)) {
    const sum = await _.sumBy(incentiveData.value, 'incentive_price');
    if (sum !== _.sumBy(paymentForIncentiveData.value, 'payment_price')) {
      Swal.fire(
        '분배 금액 오류',
        '입력하신 실적 값의 합이 총액과 맞지 않습니다.',
        'warning'
      );
    } else {
      store.commit('incentiveDataCommit', incentiveData.value);
      store.commit('incentiveUpdateStateCommit', true);
    }
  } else {
    Swal.fire('입력 오류', '항목 값을 모두 입력해 주세요.', 'warning');
  }
}
</script>

<style lang="scss" scoped></style>
