<!-- 시/수술 -->
<template>
  <Toast position="top-right" group="surgery-modal" />
  <div v-if="!store.state.surgeryStore.surgeryState">
    <div class="grid align-items-center" style="height: 55vh">
      <div
        class="col-4 justify-content-center flex align-items-center border-right-2 border-400 flex-column"
        style="height: inherit"
      >
        <StartButton @click="clickStart" />
        <Dropdown
          v-model="v$.room.$model"
          :options="roomList"
          optionLabel="floor"
          optionValue="id"
          placeholder="수/시술 방선택"
          class="mt-4"
          @change="selectedRoom($event)"
          :class="{
            'p-invalid': v$.room.$invalid && roomState,
          }"
        />
      </div>
      <div class="col-8">
        <div class="grid">
          <ExistsSurgery></ExistsSurgery>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <BomInput />
  </div>
</template>

<script setup>
import StartButton from './ModalSEButtons/StartButton.vue';
import ExistsSurgery from './SurgerComponents/ExistsSurgery.vue';
import BomInput from './SurgerComponents/BomInput.vue';

import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  computed,
  getCurrentInstance,
  onMounted,
} from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const toast = useToast();
const roomList = ref([]);
const roomState = ref(false);
const validationData = ref({
  room: '',
});
const store = useStore();
const storeComputed = computed(() => store.getters);

const rules = {
  room: { required },
};
const v$ = useVuelidate(rules, validationData);

onBeforeMount(async () => {
  try {
    await store.dispatch('sugeryRoomApi');
    roomList.value = store.state.surgeryStore.roomList;
  } catch (error) {
    store.commit('deleteTimeout', 'modal-surgery-room');
    $errorHandling(null, error, clearFunc);
  }
});

function clearFunc() {
  store.commit('boardModalStateFalse');
}

onBeforeUnmount(() => {
  store.commit('sugeryRoom', '');
  roomState.value = false;
  store.commit('boardSpecialDay1', []);
  store.commit('surgerybomSelectedDoc', { id: '', name: '' });
});

onMounted(() => {
  store.commit('loadingChangeValue', false);
});

function selectedRoom(e) {
  store.commit('sugeryRoom', e.value);
}

async function clickStart() {
  roomState.value = true;

  if (!v$.value.$invalid) {
    const sendData = { ...validationData.value };
    const existsRoomList = storeComputed.value.sugeryInit.initExistRoom;
    const roomList = store.state.surgeryStore.roomList;

    if (existsRoomList.includes(sendData.room)) {
      toast.add({
        severity: 'warn',
        summary: '기등록 경고',
        detail: `${
          roomList.filter((a) => a.id === sendData.room)[0].floor
        }은 이미 등록되어 있습니다.`,
        life: 3000,
      });
    } else {
      try {
        store.commit('loadingChangeValue', true);
        const result = await store.dispatch('surgeryRoomRegist', sendData);
        toast.add({
          severity: result.data.is ? 'success' : 'error',
          summary: result.data.is ? '등록완료' : '등록에러',
          detail: result.data.is
            ? '수술방을 클릭하여 BOM을 입력해주세요.'
            : '다시 시도해주세요.',
          life: 3000,
        });
      } catch (error) {
        $errorHandling(toast, error, clearFunc);
      }
    }
    roomState.value = false;
    validationData.value.room = '';
  }
}
</script>

<style scoped></style>
