<template>
  <div v-if="!store.state.treatStore.treatModalIsShow">
    <CounselingTreatStartEnd></CounselingTreatStartEnd>
  </div>
  <div v-if="store.state.treatStore.treatModalIsShow">
    <TreatContents></TreatContents>
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import CounselingTreatStartEnd from './counselingAndTreat/counseling_treat_start.vue';
import TreatContents from './counselingAndTreat/treat_contents.vue';

const store = useStore();
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

onBeforeMount(async () => {
  try {
    store.commit('setIsCounseling', false);

    // 수가표 전체 정보 가져오기
    await store.dispatch('getSoogaData');

    // 의사 데이터 가져오기
    await store.dispatch('getDoctors'); // 의사 데이터를 가져와야 작성자 선택할 때 의사 상담인지 체크할 수 있으므로 await 추가

    // 진료 시작 여부를 알기 위해 borad table에서 체크
    await store.dispatch('getTreatBoardData');
    store.commit('loadingChangeValue', false);

    // 원장 체크박스 초기화
    store.commit('setIsDoctor', true);

    // treat => setTimeout 용 data 가져오기
    const timeoutData = {
      name: 'modal-treat',
      url: '/treat/treatment?customerid=$customer_id&receptionid=$reception_id',
      module: 'treatStore',
      compareData: 'treatCompare',
    };

    await store.dispatch('timeoutCommonFunc', timeoutData);

    // counseling memo 데이터 가져오기
    const classification = '진료';
    const treatMemoTimeoutData = {
      name: 'modal-treat-memo',
      url: `/treat/memo?customerid=$customer_id&classification=${classification}`,
      module: 'treatStore',
      compareData: 'treatMemoCompare',
    };

    await store.dispatch('timeoutCommonFunc', treatMemoTimeoutData);
  } catch (error) {
    $errorHandling(undefined, error, clearFunc);
  }
});

function clearFunc() {
  // 모달 닫기
  store.commit('boardModalStateFalse');
}

onBeforeUnmount(() => {
  store.commit('deleteTimeout', 'modal-treat'); // setTimeout 삭제
  store.commit('deleteTimeout', 'modal-treat-memo'); // setTimeout 삭제
  store.commit('setTreatId', null); // 진료 아이디 삭제
  store.commit('setTreatModalShow', false); // 진료 contents modal 초기화
  store.commit('setTreatHasStarted', false); // 진료 시작 여부 false로 초기화
  store.commit('selectedSoogaListInit'); // SoogaList 빈 배열([])로 초기화
  store.commit('setIsDoctor', false); // 원장 체크박스 초기화
  store.commit('setSelectedDoctor', null); // 원장 선택 드락박스 초기화
});
</script>

<style lang="scss" scoped></style>
