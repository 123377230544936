<template>
  <div class="grid">
    <div class="col-4">
      <TicketingSection />
    </div>
    <div class="col-4">
      <WaitingSection />
    </div>
    <div class="col-4">
      <PaymentSection />
    </div>
  </div>
</template>

<script setup>
import TicketingSection from './payment/TicketingSection.vue';
import WaitingSection from './payment/WaitingSection.vue';
import PaymentSection from './payment/PaymentSection.vue';
import { useStore } from 'vuex';
import { watchEffect, ref, onBeforeMount, onBeforeUnmount, computed } from 'vue';

const store = useStore();
const storeError = ref();

const getters = computed(() => store.getters);

watchEffect(() => {
  storeError.value = store.state.paymentStore.paymentError;
  const result = store.state.paymentStore.paymentCompare;
  if (result) {
    store.commit('paymentInitCommit', result.result);
    getters.value.paymentHeaderData;
  }
});

onBeforeMount(() => {
  const timeoutData = {
    name: 'modal-payment',
    url: '/registration-modal/get_payment_init?code=$customer_id',
    module: 'paymentStore',
    compareData: 'paymentCompare',
  };

  store.dispatch('timeoutCommonFunc', timeoutData);

  // store.dispatch('paymentInitAction');
});

onBeforeUnmount(() => {
  store.commit('paymentResetCommit');
  store.commit('deleteTimeout', 'modal-payment');
  // store.commit('clearTimeout');
});
</script>
<script>
export default {
  name: 'PaymentModi',
};
</script>

<style></style>
