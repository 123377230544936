<template>
  <div
    class="grid mt-1 flex flex-wrap align-items-center justify-content-center"
    id="focus"
  >
    <div class="col-4" style="height: inherit">
      <div
        class="grid border-400 border-1 border-solid border-round-sm"
        style="height: inherit"
      >
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold border-bottom-1 border-400"
        >
          전화번호
        </div>
        <div
          class="col-9 text-center font-medium border-bottom-1 border-400 flex align-items-center justify-content-center"
        >
          {{ customerData?.phone }}
        </div>
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold border-bottom-1 border-400"
        >
          생년월일
        </div>
        <div
          class="col-9 text-center font-medium border-bottom-1 border-400 flex align-items-center justify-content-center"
        >
          {{ customerData?.birth }}
        </div>
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold border-bottom-1 border-400"
        >
          주소
        </div>
        <div
          class="col-9 text-center font-medium border-bottom-1 border-400 flex align-items-center justify-content-center"
          :class="{ 'text-base': addressLen <= 19, 'text-xs': addressLen > 19 }"
        >
          {{ customerData?.address }}
        </div>
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold border-bottom-1 border-400"
        >
          개인정보<br />활용동의
        </div>
        <div
          class="col-3 text-center font-medium border-bottom-1 border-400 flex align-items-center justify-content-center"
        >
          <Checkbox v-model="customerData.privacy_policy" :binary="true" disabled />
        </div>
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold border-bottom-1 border-400"
        >
          마케팅<br />활용동의
        </div>
        <div
          class="col-3 text-center font-medium border-bottom-1 border-400 flex align-items-center justify-content-center"
        >
          <Checkbox v-model="customerData.marketing_policy" :binary="true" disabled />
        </div>
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold"
        >
          소개자
        </div>
        <div
          class="col-3 text-center font-medium flex align-items-center justify-content-center"
        >
          <div v-if="customerData.recommender_id">
            <Button
              :label="customerData.recommender_name"
              class="p-button-secondary p-button-text"
              @click="recommend_toggle"
              aria-haspopup="true"
              aria-controls="overlay_panel"
              style="height: 10px"
            />
            <OverlayPanel ref="recoomend_op" appendTo="body" id="overlay_panel">
              <DataTable :value="[customerData]">
                <Column field="recommender_name" header="이름"></Column>
                <Column field="recommender_birth" header="생년월일"></Column>
                <Column field="recommender_phone" header="전화번호"></Column>
              </DataTable>
            </OverlayPanel>
          </div>
          <div v-else>X</div>
        </div>
        <div
          class="col-3 text-center surface-300 flex align-items-center justify-content-center font-semibold"
        >
          피소개자
        </div>
        <div
          class="col-3 text-center font-medium flex align-items-center justify-content-center"
        >
          <div v-if="customerRecommendData[0]">
            <Button
              label="List"
              class="p-button-secondary p-button-text"
              @click="recommended_toggle"
              aria-haspopup="true"
              aria-controls="overlay_panel"
              style="height: 10px"
            />
            <OverlayPanel ref="recoomended_op" appendTo="body" id="overlay_panel">
              <DataTable :value="customerRecommendData">
                <Column field="recommender_name" header="이름"></Column>
                <Column field="recommender_birth" header="생년월일"></Column>
                <Column field="recommender_phone" header="전화번호"></Column>
              </DataTable>
            </OverlayPanel>
          </div>
          <div v-else>X</div>
        </div>
      </div>
    </div>

    <div class="col-8">
      <div v-if="memo.smartcrm">
        <Editor v-model="memoValue" editorStyle="height: 145px;" :readonly="true">
          <template v-slot:toolbar>
            <span class="ql-formats">
              <div class="flex">
                <div>고객메모</div>
              </div>
            </span>
          </template>
        </Editor>
      </div>
      <!-- <div v-else-if="memoEditState">
        <Editor
          @text-change="
            memoStateChange($event);
            memoChange($event);
          "
          @selection-change="memoSelection($event)"
          v-if="!memoEditState"
          v-model="memoValue"
          editorStyle="height: 145px;"
        >
          <template v-slot:toolbar>
            <span class="ql-formats">
              <div class="flex">
                <div>고객메모</div>
              </div>
            </span>
          </template>
        </Editor>
      </div> -->
      <div v-else>
        <!-- <Editor v-model="memoValue" editorStyle="height: 145px;" :readonly="true">
          <template v-slot:toolbar>
            <span class="ql-formats">
              <div class="relative">
                <div>고객메모</div>
                <Message
                  class="absolute"
                  severity="info"
                  :closable="false"
                  style="
                    top: -21px;
                    right: -178px;
                    width: 172px;
                    height: 31px;
                    display: flex;
                  "
                  >{{ memo.name }} 편집 중</Message
                >
              </div>
            </span>
          </template>
        </Editor> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
// import axios from 'axios';
import _ from 'lodash';
// import { useToast } from 'primevue/usetoast';
// import { useIdle } from '@vueuse/core';

// const { idle } = useIdle(1000 * 2);
// const toast = useToast();
const store = useStore();
const customerData = ref({});
const customerDataCompare = ref({});
const customerRecommendData = ref([]);
const addressLen = ref();
const memo = ref({
  memo: null,
  customer_id: null,
  editing: null,
});
const memoValue = ref();
const memoCompare = ref();
// const memoAsyncState = ref(true);
const memoEditState = ref(false);
const recoomend_op = ref();
const recoomended_op = ref();

onBeforeMount(async () => {
  // modal-top에서 고객부분에 대한 실시간업데이트 데이터 설정하는 곳
  const timeoutData = {
    name: 'modal-top-customer',
    url: '/registration-modal/board_modal_init_customer_data?code=$customer_id',
    module: 'modalTop',
    compareData: 'modalTopCustomerCompare',
  };
  store.dispatch('timeoutCommonFunc', timeoutData);
});

onBeforeUnmount(() => {
  // 실행되던 부분을 없애는 곳
  store.commit('deleteTimeout', 'modal-top-customer');
});

watchEffect(() => {
  // store에 저장되는 부분을 이곳에서 현제 페이지 변수로 넣기
  const result = store.state.modalTop.modalTopCustomerCompare;
  result && topCustomerInit(result);
});

watchEffect(() => {
  memoEditState.value =
    store.state.loginUserData.user_id === memo.value.user_id ? false : memo.value.editing;
});

// function readonlyMemo() {
//   return memo.value.smartcrm === 'true' || memo.value.smartcrm === true ? true : false;
// }

function topCustomerInit(result) {
  const oldCustomerData = customerDataCompare.value;
  if (!_.isEqual({ ...oldCustomerData }, { ...result.customer[0] })) {
    customerData.value = result.customer[0];
    customerDataCompare.value = _.cloneDeep(customerData.value);
    customerRecommendData.value = result.recommend;
    addressLen.value =
      result.customer[0]?.address === null ? 0 : result.customer[0].address.length;
  }

  const newMemoValue =
    result.memo.length > 0
      ? result.memo[0]
      : {
          memo: null,
          customer_id: null,
          editing: null,
        };
  const oldMemoValue = memoCompare.value;
  if (!_.isEqual(oldMemoValue, newMemoValue)) {
    memo.value = newMemoValue;
    memoCompare.value = _.cloneDeep(memo.value);
    memoValue.value = _.cloneDeep(memo.value.memo);
  }
}

const recommend_toggle = (event) => {
  recoomend_op.value.toggle(event);
};

const recommended_toggle = (event) => {
  recoomended_op.value.toggle(event);
};

// async function memoStateChange(e) {
//   if (memoAsyncState.value) {
//     memoAsyncState.value = false;
//     const customerCode = store.state.board.boardModal.boardSendCutomerCode;

//     await axios.post(store.state.URL + '/registration-modal/memo-state', {
//       state: true,
//       customerCode,
//       classification: '고객',
//     });
//   }
// }

// const memoChange = _.debounce(async (e) => {
//   const memoValue = e.htmlValue;
//   const customerCode = store.state.board.boardModal.boardSendCutomerCode;
//   memoAsyncState.value = true;

//   const result = await axios.put(store.state.URL + '/registration-modal/memo-upsert', {
//     memoValue,
//     customerCode,
//     classification: '고객',
//   });

//   if (!result.data.is) {
//     toast.add({
//       severity: 'error',
//       summary: '메모가 저장에러',
//       detail: '메모가 저장되지 않았습니다.',
//       life: 3000,
//     });
//   }
// }, 1000);

// function memoSelection(e) {
//   let stayMemo = setTimeout(function tick() {
//     if (idle.value) {
//       e.instance.root.blur();
//     } else {
//       stayMemo = setTimeout(tick, 1000);
//     }
//   }, 1000);
// }
</script>

<script>
export default {
  name: 'ModalTopCustomer',
};
</script>
<style></style>
