const copyBomState = {
  // BOM 이동/복사
  copySurgeryToDoctor: '',
  copySurgeryFilteredData: [],
  copySourceDoctorToSurgerySeen: false,
  copySurgeryToTargetDoctorSeen: false,
  copyRecentSelectedBom: [],
  copySourceDoctorSelected: '',
  copySurgerySelected: '',
  copyTargetDoctorSelected: '',
};

export default copyBomState;
