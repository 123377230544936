import { errorHandling } from '@/util/errorHandler';
import axios from 'axios';
import _ from 'lodash';
import Swal from 'sweetalert2';

const confirmBomActions = {
  // 의사 선택 -> 시/수술 선택 펼치기 + 등록 데이터 넣어주는 함수
  async doctorToSurgeryActive({ state, commit, dispatch }) {
    if (state.confirmDoctorToSurgerySelected) {
      commit('confirmDoctorToSurgerySeenCommit', true);
      commit('confirmSurgeryToBomSeenCommit', false);
      commit('confirmSurgeryToBomSelectedCommit', undefined);
      commit('confirmBomConfirmSelectedCommit', []);

      commit('confirmSurgeryFilteredDataCommit', state.surgeryData);
      const doctorToSurgeryFilter = state.doctorMenuConfirmData
        ? state.doctorMenuConfirmData.filter(
            (data) => data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id
          )
        : '';
      commit('confirmDoctorToSurgerySelectedInfoCommit', doctorToSurgeryFilter);
      commit(
        'confirmDoctorToSurgeryUniqueMenuIdCommit',
        doctorToSurgeryFilter
          ? new Set(doctorToSurgeryFilter.map((row) => row.menu_id))
          : new Set()
      );
      dispatch('surgeryFilterChangeEvent');
    } else if (!state.confirmDoctorToSurgerySelected) {
      commit('confirmDoctorToSurgerySeenCommit', false);
      commit('confirmSurgeryToBomSeenCommit', false);
      commit('confirmBomConfirmSelectedCommit', []);

      // 공통BOM 확인
      const doctorToSurgeryFilter = state.doctorMenuConfirmData.filter(
        (data) => data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id
      );
      commit('confirmDoctorToSurgerySelectedInfoCommit', doctorToSurgeryFilter);
      commit(
        'confirmDoctorToSurgeryUniqueMenuIdCommit',
        doctorToSurgeryFilter
          ? new Set(doctorToSurgeryFilter.map((row) => row.menu_id))
          : new Set()
      );
      dispatch('surgeryFilterChangeEvent');
    }
  },
  // 시/수술 선택 -> BOM 관련 정보 펼치는 기능
  surgeryToBomActive({ state, commit, dispatch }) {
    if (state.confirmSurgeryToBomSelected) {
      commit('confirmSurgeryToBomSeenCommit', true);

      if (state.menuCountData) {
        const surgeryToBomSelectedInfo = state.menuCountData.filter(
          // (data) => data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id
          (data) => data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id
        );
        const bomGroupBySurgery = _.groupBy(surgeryToBomSelectedInfo, 'menu_id');
        commit(
          'confirmRegistedBomBySurgeryCommit',
          bomGroupBySurgery[state.confirmSurgeryToBomSelected]
        );
      }

      if (state.confirmRegistedBomBySurgery) {
        // 기존과 새로 추가된 데이터 구분
        for (let i = 0; i < state.confirmRegistedBomBySurgery.length; i++) {
          state.confirmRegistedBomBySurgery[i].isNew = false;
        }
      }
      dispatch('confirmCalculateCost');
    } else if (!state.confirmSurgeryToBomSelected) {
      commit('confirmSurgeryToBomSeenCommit', false);
      commit('confirmBomConfirmSelectedCommit', []);
    }
  },
  // 스위치 토글 변경
  bomRegistConfirmToggle({ state }, payload) {
    if (state.doctorMenuConfirmData && state.confirmDoctorToSurgerySelected) {
      state.doctorMenuConfirmData.map((data) => {
        if (
          data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id &&
          data.menu_id === payload.menuId
        ) {
          data.confirm = payload.confirm;
        }
      });
    }
  },
  // 컨펌 스위치 버튼 클릭 시 발생하는 이벤트 함수
  async confirmChangeEvent({ rootState, state, commit, dispatch }, payload) {
    const URL = rootState.URL;
    commit('loadingChange');
    if (payload.menuId && state.confirmDoctorToSurgerySelected) {
      const result = state.menuCountData.filter(
        ({ menu_id, doctor_id }) =>
          menu_id === payload.menuId &&
          doctor_id === state.confirmDoctorToSurgerySelected.doctor_id
      );

      if (result) {
        const confirmIdList = {};
        confirmIdList.id = [];

        result.map((data) => {
          confirmIdList.id.push(data.bm_id);
          confirmIdList.confirm = !data.confirm;
        });

        if (!(confirmIdList.id.length === 0)) {
          try {
            await axios
              .put(URL + `/bom-manage/bom-confirm`, {
                data: confirmIdList,
              })
              .then((res) => {
                commit('loadingChange');
                if (res.data) {
                  Swal.fire({
                    icon: 'success',
                    title: `${confirmIdList.confirm ? '컨펌' : '컨펌 취소'} 완료`,
                    text: `선택하신 상품이 ${
                      confirmIdList.confirm ? '컨펌' : '컨펌 취소'
                    }되었습니다.`,
                  });
                  // 스위치 토글 변경
                  dispatch('bomRegistConfirmToggle', {
                    confirm: confirmIdList.confirm,
                    menuId: payload.menuId,
                  });
                  dispatch('refreshConfirmEvent');
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: '오류 발생. 데이터 설계팀에 문의 해주세요.',
                  });
                  commit('loadingChange');
                }
              });
          } catch (error) {
            errorHandling(null, error);
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: '미등록된 상품은 컨펌하실 수 없습니다.',
          });
          commit('loadingChange');
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: '미등록된 상품은 컨펌하실 수 없습니다.',
          life: 3000,
        });
        commit('loadingChange');
      }
    } else {
      commit('loadingChange');
    }
  },

  // 컨펌데이터 갱신 후, 새로고침 이벤트 함수
  refreshConfirmEvent({ state, commit, dispatch }) {
    if (state.confirmDoctorToSurgerySelected) {
      commit('confirmDoctorToSurgerySeenCommit', true);
      commit('confirmBomConfirmSelectedCommit', '');
      commit('confirmSurgeryFilteredDataCommit', state.surgeryData);

      commit(
        'confirmDoctorToSurgerySelectedInfoCommit',
        state.doctorMenuConfirmData.filter(
          (data) => data.doctor_id === state.confirmDoctorToSurgerySelected.doctor_id
        )
      );

      commit(
        'confirmDoctorToSurgeryUniqueMenuIdCommit',
        new Set(state.confirmDoctorToSurgerySelectedInfo.map((row) => row.menu_id))
      );

      dispatch('surgeryFilterChangeEvent');
    } else if (!state.confirmDoctorToSurgerySelected) {
      commit('confirmDoctorToSurgerySeenCommit', false);
      commit('confirmSurgeryToBomSeenCommit', false);
      commit('confirmBomConfirmSelectedCommit', '');
    }
  },

  confirmBomRegistActive({ state, commit }) {
    if (
      state.confirmBomConfirmSelected &&
      state.confirmDoctorToSurgerySelected &&
      state.confirmSurgeryToBomSelected
    ) {
      const registBomData = state.bomData.filter(
        (data) => data.product_code === state.confirmBomConfirmSelected
      )[0];

      // 중복 상품일 경우 추가 X
      if (
        state.confirmRegistedBomBySurgery &&
        state.confirmRegistedBomBySurgery.filter(
          (data) => data.product_code === registBomData.product_code
        )[0]
      ) {
        Swal.fire({
          icon: 'error',
          title: '기존 상품이 존재합니다.',
        }).then(commit('confirmBomConfirmSelectedCommit', ''));
      } else {
        const newProductRow = {
          bm_id: null,
          menu_id: state.confirmSurgeryToBomSelected,
          doctor_id: state.confirmDoctorToSurgerySelected.doctor_id,
          doc_nm: state.confirmDoctorToSurgerySelected.doc_nm,
          product_code: registBomData.product_code,
          product_name: registBomData.product_name,
          standard_name: registBomData.standard_name,
          cost_when_input: registBomData.cost_when_input,
          unit_when_input: registBomData.unit_when_input,
          use_cnt: null,
          isNew: true,
        };
        if (state.confirmRegistedBomBySurgery) {
          commit('confirmRegistedBomBySurgeryCommitPush', new Proxy(newProductRow, {}));
          commit('confirmBomConfirmSelectedCommit', '');
        } else {
          commit('confirmRegistedBomBySurgeryCommit', new Array(newProductRow));
          commit('confirmBomConfirmSelectedCommit', '');
        }
      }
    }
  },

  // 상품 제거 기능 (새로운 상품, 기존 등록 상품인지 확인)
  confirmRemoveProductRow({ state, commit, dispatch }, payload) {
    const filtered = state.confirmRegistedBomBySurgery.filter(
      (element) => element.product_code == payload.productCode
    )[0];

    if (!filtered.isNew) {
      commit('bomDeleteStateCommit', true);
      commit('bomDeleteCategoryCommit', 'confirm');
      commit('bomDeleteDataCommit', filtered);
    } else {
      commit(
        'confirmRegistedBomBySurgeryCommit',
        state.confirmRegistedBomBySurgery.filter(
          (element) => element.product_code !== filtered.product_code
        )
      );
    }
    dispatch('confirmCalculateCost');
  },

  // 원가, 원가율 계산 기능
  async confirmCalculateCost({ state, commit }, payload) {
    // _.debounce(async (e, productCode)
    if (state.confirmRegistedBomBySurgery) {
      // 원가(VAT 포함)
      if (state.surgeryData) {
        const selectedVatCost = state.surgeryData.filter(
          (data) => data.menu_id === state.confirmSurgeryToBomSelected
        )[0].price_include_vat;
        // 원가 계산
        var sumCost = 0;
        sumCost = [...state.confirmRegistedBomBySurgery].reduce((before, present) => {
          var input_cost = Number(present.cost_when_input);
          var modal_use_cnt;
          if (present.use_cnt) {
            modal_use_cnt = Number(present.use_cnt);
          } else {
            modal_use_cnt = 0;
          }

          if (payload) {
            const { event, productCode } = payload;
            if (present.product_code === productCode) {
              modal_use_cnt = Number(event.value);
            }
          }
          // 사용량 입력 시, 초기화 함수 중지
          commit('isBomEditingCommit', true);
          return before + input_cost * modal_use_cnt;
        }, 0);

        const costRate = Math.floor((sumCost / selectedVatCost) * 10000) / 100;
        commit('confirmRegistedBomCostCommit', [sumCost, costRate]);
      }
    } else {
      commit('confirmRegistedBomCostCommit', [0, 0]);
    }
  },

  async confirmRemoveOldProduct({ rootState, state, commit, dispatch }, filtered) {
    const URL = rootState.URL;
    const deleteId = state.confirmRegistedBomBySurgery.filter(
      (element) => element.bm_id == filtered.data.bm_id
    )[0].bm_id;
    if (deleteId) {
      try {
        await axios
          .delete(URL + `/bom-manage`, {
            data: [deleteId],
          })
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: 'success',
                title: '삭제 완료',
                text: '선택하신 상품이 삭제되었습니다.',
              });
              commit(
                'confirmRegistedBomBySurgeryCommit',
                state.confirmRegistedBomBySurgery.filter(
                  (element) => element.bm_id !== deleteId
                )
              );
              commit('bomDeleteStateCommit', false);
              commit('bomDeleteCategoryCommit', '');
              dispatch('doctorToSurgeryActive');
            } else {
              Swal.fire({
                icon: 'error',
                title: '다시 시도해주세요.',
              });
            }
          });
      } catch (error) {
        errorHandling(null, error);
      }
    }
  },

  confirmProductSaveConfirm({ state, commit }) {
    if (
      state.confirmRegistedBomBySurgery.find(
        (data) => data.use_cnt === null || data.use_cnt === undefined
      )
    ) {
      Swal.fire({
        icon: 'error',
        title: '상품 사용량을 입력해주세요.',
      });
    } else {
      commit('bomRegistCategoryCommit', 'confirm');
      commit('bomSubmittedCommit', true);
    }
  },

  // 상품 등록 전, DB에 맞는 데이터 형식으로 변경하는 함수
  confirmConvertProduct({ state }) {
    if (state.confirmRegistedBomBySurgery) {
      const newInputData = [];

      state.confirmRegistedBomBySurgery.forEach((data) => {
        newInputData.push({
          menu_id: data.menu_id,
          doctor_id: data.doctor_id,
          product_id: data.product_code,
          use_cnt: data.use_cnt ? data.use_cnt : null,
        });
      });

      return newInputData;
    }
  },

  // 상품 등록 기능
  async confirmRegistProduct({ rootState, state, commit, dispatch }) {
    const URL = rootState.URL;
    if (state.bomSubmitted && state.confirmRegistedBomBySurgery) {
      try {
        const registData = await dispatch('confirmConvertProduct');
        await axios
          .post(URL + `/bom-manage`, {
            data: registData,
          })
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: 'success',
                title: '등록 완료',
                text: '선택하신 상품이 등록되었습니다.',
              });
              commit('bomSubmittedCommit', false);
              dispatch('doctorToSurgeryActive');
            } else {
              Swal.fire({
                icon: 'error',
                title: '다시 시도해주세요.',
              });
            }
          });
      } catch (error) {
        errorHandling(null, error);
      }
    }
  },

  // 시/수술 등록/미등록 필터링 버튼 이벤트 함수
  surgeryFilterChangeEvent({ state, commit }) {
    commit('confirmSurgeryToBomSeenCommit', false);
    if (state.confirmSurgeryFilterSelected === '미등록') {
      commit(
        'confirmSurgeryFilteredDataCommit',
        state.surgeryData.filter(
          (data) => !state.confirmDoctorToSurgeryUniqueMenuId?.has(data.menu_id)
        )
      );
    } else if (state.confirmSurgeryFilterSelected === '등록') {
      commit(
        'confirmSurgeryFilteredDataCommit',
        state.surgeryData.filter((data) =>
          state.confirmDoctorToSurgeryUniqueMenuId?.has(data.menu_id)
        )
      );
    } else {
      commit('confirmSurgeryFilteredDataCommit', state.surgeryData);
    }
  },
};

export default confirmBomActions;
