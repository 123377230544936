<template>
  <div>
    <Calendar
      v-model="modalDate"
      class="mr-2"
      dateFormat="yy-mm-dd"
      :showIcon="true"
      :showButtonBar="true"
      @date-select="modalChangeDate($event)"
      @today-click="calendarClear($event)"
      @clear-click="calendarClear($event)"
      @input="calendarInput($event)"
    >
      <template #date="slotProps">
        <strong v-if="special1dateDeco(slotProps)" class="special-day1">{{
          slotProps.date.day
        }}</strong>
        <strong v-else-if="special2dateDeco(slotProps)" class="special-day2">{{
          slotProps.date.day
        }}</strong>
        <template v-else>{{ slotProps.date.day }}</template>
      </template>
    </Calendar>
  </div>
</template>

<script setup>
import { getCurrentInstance, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

const store = useStore();
const modalDate = ref();

const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;

onBeforeMount(() => {
  modalDate.value = store.state.board.boardModal.boardSendDate;
});

function special1dateDeco(slotProps) {
  // 빨간색
  const dataFormat = `${slotProps.date.year}-${_.padStart(
    String(slotProps.date.month + 1),
    2,
    '0'
  )}-${_.padStart(String(slotProps.date.day), 2, '0')}`;
  const spcialDate1 = store.state.board.boardModal.specailDay1;

  return spcialDate1.includes(dataFormat);
}

function special2dateDeco(slotProps) {
  // 파란색
  const dataFormat = `${slotProps.date.year}-${_.padStart(
    String(slotProps.date.month + 1),
    2,
    '0'
  )}-${_.padStart(String(slotProps.date.day), 2, '0')}`;
  const spcialDate2 = store.state.board.boardModal.specailDay2;

  return spcialDate2.includes(dataFormat);
}

function modalChangeDate(e) {
  store.commit('loadingChangeValue', true);
  store.commit('boardDateDataInput', { selectedDate: $formatDate('date', new Date(e)) });
  setTimeout(() => {
    store.commit('loadingChangeValue', false);
  }, 2000);
}

function calendarClear(e) {
  store.commit('loadingChangeValue', true);
  modalDate.value = $formatDate('date', new Date());
  store.commit('boardDateDataInput', { selectedDate: $formatDate('date', new Date()) });
  setTimeout(() => {
    store.commit('loadingChangeValue', false);
  }, 2000);
}

function calendarInput(e) {
  if (!e.target.value) {
    store.commit('loadingChangeValue', true);
    modalDate.value = new Date();
    store.commit('boardDateDataInput', { selectedDate: $formatDate('date', new Date()) });
    setTimeout(() => {
      store.commit('loadingChangeValue', false);
    }, 2000);
  }
}
</script>
<script>
export default {
  name: 'ModalTopCalendar',
};
</script>

<style>
.special-day1 {
  color: red;
  font-weight: 800;
  text-decoration: underline;
}

.special-day2 {
  color: blue;
  font-weight: 800;
  text-decoration: underline;
}
</style>
