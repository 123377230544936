<template>
  <h2>수가표 관리</h2>
  <div>
    <Toolbar class="mb-4">
      <template #start>
        <Button
          label="신규등록"
          icon="pi pi-plus"
          class="p-button-success mr-2"
          @click="openNewRegist"
        />
        <Button
          label="선택삭제"
          icon="pi pi-trash"
          class="p-button-danger"
          :disabled="selectedCheckHead() === 0"
          @click="selectedDelete"
        />
      </template>

      <template #end>
        <Button
          label="CSV다운"
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
      </template>
    </Toolbar>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Toolbar from 'primevue/toolbar';
import { useStore } from 'vuex';

const store = useStore();

function openNewRegist() {
  store.dispatch('getSoogaCategory'); // 등록모달 수가표 대분류 가져오기
  store.commit('productDialogCommit', true);
  store.commit('productSelectedCommit', {
    large_category: null,
    menu_id: null,
    middle_category: null,
    small_category: null,
    abbreviation: null,
    medical_menu_content: null,
    price: null,
    event_price: null,
    price_include_vat: null,
    note: null,
    discount_limit: 100,
    counseling_necessity: true,
  });
}

function selectedCheckHead() {
  const selectedValues = store.state.soogaGetdata.soogaSelectedProducts;
  return _.reduce(
    selectedValues,
    (a, b) => {
      return (a += 1);
    },
    0
  );
}

function selectedDelete() {
  store.commit('soogaDeleteStateCommit', true);
}

function exportCSV() {
  store.state.soogaGetdata.soogaDt.exportCSV();
}
</script>
<script>
export default {
  name: 'SoogaToolbar',
};
</script>

<style></style>
