import { createStore } from 'vuex';
import loginStore from './modules/login';
import boardStore from './modules/board';
import surgeryStore from './modules/surgery';
import paymentStore from './modules/modal_payment';
import authStore from './modules/auth';
import counselingStore from './modules/modal_counseling';
import modalTop from './modules/modal_top';
import modalCustomer from './modules/modal_customer';
import modalResception from './modules/modal_reception';
import modalReserve from './modules/modal_reserve';
import purchaseGetdata from './modules/purchase';
import soogaGetdata from './modules/sooga';
import doctorGetdata from './modules/doctor';
import myPageGetData from './modules/mypage';
// import bomManageGetdata from './modules/bomManage';
import bomManageGetdata from './modules/bomManage';
import counselingAndTreatStore from './modules/modal_counselingAndTreat';
import treatStore from './modules/modal_treat';
import metaCrmData from './modules/meta_crm';
import { FilterMatchMode } from 'primevue/api';
import _ from 'lodash';
import axios from 'axios';
import { errorHandling } from '../util/errorHandler';
import Swal from 'sweetalert2';

const store = createStore({
  state() {
    return {
      URL:
        process.env.VUE_APP_NODE_STATUS === 'dev'
          ? process.env.VUE_APP_DEV_HOST
          : process.env.VUE_APP_PROD_HOST,
      // : 'https://www.litingcrm.com',
      delayTime: 500,
      TimeOutSet: {},
      TimeOutIs: '',
      TimeOutIdle: true,
      loadingState: false,
      sidebarVisible: false,
      loginUserData: null,
      userAuthData: null,
      matchModeOptions: [
        { label: '포함', value: FilterMatchMode.CONTAINS },
        { label: '미포함', value: FilterMatchMode.NOT_CONTAINS },
      ],
      dateMatchModeOptions: [
        { label: '해당 날짜', value: FilterMatchMode.DATE_IS },
        { label: '해당 날짜 제외', value: FilterMatchMode.DATE_IS_NOT },
      ],
      numberMatchModeOptions: [
        { label: '일치', value: FilterMatchMode.EQUALS },
        { label: '불일치', value: FilterMatchMode.NOT_EQUALS },
        { label: '이상', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        { label: '이하', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
      ],
      paymentLoadingState: false,
    };
  },
  modules: {
    login: loginStore,
    board: boardStore,
    surgeryStore,
    authStore,
    counselingStore,
    counselingAndTreatStore,
    treatStore,
    paymentStore,
    modalTop,
    modalCustomer,
    modalResception,
    modalReserve,
    purchaseGetdata,
    soogaGetdata,
    doctorGetdata,
    myPageGetData,
    bomManageGetdata,
    metaCrmData,
  },
  mutations: {
    loadingChange(state) {
      state.loadingState = !state.loadingState;
    },
    loadingChangeValue(state, payload) {
      state.loadingState = payload;
    },
    sidebarVisible(state) {
      state.sidebarVisible = true;
    },
    sidebarInvisible(state) {
      state.sidebarVisible = false;
    },
    setUserData(state, payload) {
      state.loginUserData = payload.nameTag[0];
      state.userAuthData = payload.authTag;
    },
    setTimeout(state, payload) {
      state.TimeOutSet[payload.name] = payload.fn;
    },
    deleteTimeout(state, payload) {
      delete state.TimeOutSet[payload];
    },
    clearTimeout(state) {
      clearTimeout(state.TimeOutIs);
      state.TimeOutIs = '';
      _.forEach(_.keys(state.TimeOutSet), (a) => {
        delete state.TimeOutSet[a];
      });
    },
    clearMoment(state, payload) {
      state.TimeOutIdle = payload;
    },
    paymentLoadingStateCommit(state, payload) {
      state.paymentLoadingState = payload;
    },
  },
  actions: {
    /**
     * @typedef payload
     * @property {string} name
     * @property {string} url
     * @property {string} module
     * @property {string} compareData
     */

    /**
     * @description settimeout를 모아주는 함수
     * @param {*} param0
     * @param {payload} payload
     */
    async timeoutCommonFunc({ rootState, commit, state }, payload) {
      const result = async () => {
        const passKeys = [
          'modal-top-board',
          'modal-top-customer',
          'modal-top-customer-visit',
        ];

        const common = {
          $customer_id: rootState.board.boardModal.boardSendCutomerCode,
          $reception_id: rootState.board.boardModal.boardReceptionCode,
          $boardDate: rootState.board.boardModal.boardSendDate,
          $boardFrontDate: rootState.board.boardModal.frontBoardDate,
        };

        let subURL = payload.url;
        _.forEach(_.keys(common), (a) => {
          const reg = new RegExp('\\' + a, 'g');
          subURL = subURL.replace(reg, common[a]);
        });

        const result = await axios.get(rootState.URL + subURL);

        if (!state.paymentLoadingState) {
          if (!passKeys.includes(payload.name)) {
            state.loadingState = false;
          }
        }

        if (
          !_.isEqual(
            { ...rootState[payload.module][payload.compareData] },
            { ...result.data }
          )
        ) {
          rootState[payload.module][payload.compareData] = _.cloneDeep(result.data);
        }
      };

      try {
        await result();
        commit('setTimeout', {
          name: payload.name,
          fn: result,
        });
      } catch (error) {
        state.loadingState = false;
        commit('deleteTimeout', payload.name);
        errorHandling(null, error, () => {
          commit('boardModalStateFalse');
        });
      }
    },
    // 모아놓은 settimeout을 실행
    async wholeTimeOutForeach({ state, dispatch }) {
      try {
        // _.forEach(_.values(state.TimeOutSet), async (a, i) => {
        //   if (typeof a === 'function') {
        //     if (i === 0) {
        //       state.TimeOutIdle && (await a());
        //     } else {
        //       state.TimeOutIdle && (await a());
        //     }
        //   }
        // });

        const nap = () => {
          return new Promise((resolve) => setTimeout(resolve, state.delayTime));
        };

        for (let value in state.TimeOutSet) {
          if (typeof state.TimeOutSet[value] === 'function') {
            if (Object.keys(state.TimeOutSet).indexOf(value) === 0) {
              state.TimeOutIdle && (await state.TimeOutSet[value]());
            } else {
              await nap();
              state.TimeOutIdle &&
                state.TimeOutSet[value] &&
                (await state.TimeOutSet[value]());
            }
          }
        }

        state.TimeOutIs = setTimeout(() => {
          dispatch('wholeTimeOutForeach');
        }, state.delayTime);
      } catch (error) {
        clearTimeout(state.TimeOutIs);
        const status = error.response?.status;

        if (status && status == 401) {
          Swal.fire('로그인을 해주세요.', error.message, 'error').then(() =>
            window.location.replace('/')
          );
        } else {
          Swal.fire(
            `${error} 에러가 발생하였습니다.`,
            `자동업데이트 시간이 ${(state.delayTime * 2) / 1000} 초가 되었습니다.`,
            'error'
          ).then(() => {
            state.delayTime = state.delayTime * 2;

            state.TimeOutIs = setTimeout(() => {
              dispatch('wholeTimeOutForeach');
            }, state.delayTime);
          });
        }
      }
    },
    // settimeout 최초실행
    wholeTimeOut({ state, dispatch }) {
      state.TimeOutIs = setTimeout(() => {
        dispatch('wholeTimeOutForeach');
      }, state.delayTime);
    },
  },
  getters: {
    getUrl(state) {
      return state.URL;
    },
    getSidebarVisible(state) {
      return state.sidebarVisible;
    },
    getUserName(state) {
      return state.loginUserData.name;
    },
    getUserId(state) {
      return state.loginUserData.user_id;
    },
    setDelayTime(state) {
      state.delayTime = state.delayTime * 2;
    },
  },
});

export default store;
