<template>
  <div class="surface-card shadow-2 border-round">
    <Toolbar>
      <!-- <template #start>
        <div class="field-checkbox m-0">
          <TriStateCheckbox
            inputId="sortCheckbox"
            v-model="sortState"
            @change="ascOrDesc"
          ></TriStateCheckbox>
          <label class="font-semibold" for="sortCheckbox">{{
            sortState === null
              ? '접수 순'
              : sortState
              ? '대기시간 오름차순'
              : '대기시간 내림차순'
          }}</label>
        </div>
      </template> -->

      <template #end>
        <div>
          <Calendar
            class="h-3rem font-bold"
            inputId="icon"
            :showIcon="true"
            dateFormat="yy-mm-dd"
            :showButtonBar="true"
            v-model="todayDate"
            @date-select="dateSelect($event)"
            @today-click="todaySelect()"
            @clear-click="todaySelect()"
          >
          </Calendar>
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script setup>
// import _ from 'lodash';
import { watchEffect, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;

const todayDate = ref(new Date());
const sortState = ref(null);

watchEffect(() => {
  store.commit('boardFrontDateCommit', $formatDate('date', todayDate.value));
  sortState.value = store.state.board.sortState;

  if (!store.state.board.boardModal.boardSendDate) {
    store.commit('boardDateDataInput', {
      selectedDate: $formatDate('date', todayDate.value),
    });
  }
});

function dateSelect(e) {
  store.commit('loadingChangeValue', true);
  store.commit('boardSortStateCommit', null);
  store.commit('boardFrontDateCommit', $formatDate('date', todayDate.value));
  store.commit('boardDateDataInput', {
    selectedDate: $formatDate('date', todayDate.value),
  });
}

function todaySelect() {
  store.commit('loadingChangeValue', true);
  store.commit('boardSortStateCommit', null);
  todayDate.value = new Date();
  store.commit('boardDateDataInput', {
    selectedDate: $formatDate('date', new Date()),
  });
}

// function ascOrDesc() {
//   store.commit('boardSortStateCommit', sortState.value);
//   const boardData = _.cloneDeep(store.state.board.boardInitDataCompare);
//   const sortData = boardData;

//   store.commit('boardInitSortCommit', sortData);
// }
</script>
<script>
export default {
  name: 'BoardToolbar',
};
</script>

<style></style>
