<template>
  <div v-if="props.dividerSeen">
    <Divider layout="vertical">
      <b>
        <Button icon="pi pi-chevron-right" class="p-button-rounded p-button-info" />
      </b>
    </Divider>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  dividerSeen: Boolean,
});
</script>
<style lang="scss" scoped>
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
}
</style>
