<template>
  <Dialog
    header="결제"
    v-model:visible="paymentDialogState"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '25vw' }"
    :modal="true"
    @hide="closeDialog"
    :baseZIndex="1000"
    :autoZIndex="false"
  >
    <template #header>
      <h3>결제</h3>
      <div class="absolute" style="right: 12%">
        <Button
          v-if="
            soogi() &&
            !/바코드/.test(selectedPaymentValue.payment_type) &&
            !selectedPaymentValue.refund_active
          "
          :label="soogiState ? '수기결제취소' : '수기결제'"
          class="p-button-sm p-button-outlined"
          :class="soogiState ? 'p-button-danger' : ''"
          @click="soogiButton"
        />
      </div>
    </template>
    <div class="grid" style="padding-top: 7px">
      <div
        v-if="!soogiStateVan"
        class="col-4 py-3 text-center border-top-1 border-bottom-1 border-300 surface-200"
      >
        단말기 연결상태
      </div>
      <div
        v-if="!soogiStateVan"
        class="col-8 py-3 text-center border-top-1 border-bottom-1 border-300"
      >
        <div
          v-if="store.state.paymentStore.isConnectedToCat"
          class="text-green-500 font-semibold"
        >
          연결 성공
        </div>
        <div v-else class="text-red-500 font-semibold">연결 실패</div>
      </div>

      <div
        class="col-4 py-3 text-center border-bottom-1 border-300 surface-200"
        :class="{ 'border-top-1': !soogiState }"
      >
        대기날짜
      </div>
      <div
        class="col-8 py-3 text-center border-bottom-1 border-300"
        :class="{ 'border-top-1': !soogiState }"
      >
        {{ selectedPaymentValue.creation_timestamp }}
      </div>
      <div class="col-4 py-3 text-center border-bottom-1 border-300 surface-200">
        실행날짜
      </div>
      <div class="col-8 py-3 text-center border-bottom-1 border-300">{{ today() }}</div>
      <div class="col-4 py-3 text-center border-bottom-1 border-300 surface-200">
        수납구분
      </div>
      <div class="col-8 py-3 text-center border-bottom-1 border-300">
        {{ selectedPaymentValue.deposit_or_balance }}
      </div>
      <div
        class="col-4 py-3 border-bottom-1 border-300 flex justify-content-center align-items-center surface-200"
      >
        결제방법
      </div>
      <div
        class="col-8 py-3 border-bottom-1 border-300 flex justify-content-center align-items-center"
      >
        {{ selectedPaymentValue.payment_type }}
        <Dropdown
          v-if="selectedPaymentValue.payment_type === '현금결제' ? false : true"
          placeholder="할부선택"
          :inputStyle="{ height: '20px', width: '60px' }"
          class="ml-2"
          :options="installment"
          optionLabel="name"
          optionValue="code"
          id="van-installment"
          v-model="installSelected"
          :disabled="selectedPaymentValue.refund_active || paymentPrice < 50000"
        />
      </div>
      <div class="gird"></div>
      <div
        class="col-4 py-3 text-center border-bottom-1 surface-200"
        :class="{
          'border-300': !selectedPaymentValue.refund_active,
          'border-red-300': selectedPaymentValue.refund_active,
        }"
      >
        금액
      </div>
      <div
        class="col-8 py-3 text-center border-bottom-1"
        :class="{
          'border-300': !selectedPaymentValue.refund_active,
          'border-red-300': selectedPaymentValue.refund_active,
        }"
      >
        {{ paymentTotal() }}
      </div>
    </div>
    <div v-if="soogiState">
      <div class="grid pt-2">
        <div
          class="col-4 py-3 flex border-bottom-1 border-300 surface-200 justify-content-center align-items-center"
        >
          {{ /카드/.test(selectedPaymentValue.payment_type) ? '카드사' : '현금영수증' }}
        </div>
        <div
          class="col-8 py-3 text-center border-bottom-1 border-300 flex justify-content-center align-items-center soogi"
        >
          <Dropdown
            v-model="v$.cardCompayValue.$model"
            :options="/카드/.test(selectedPaymentValue.payment_type) ? cardCompay : cash"
            optionLabel="name"
            optionValue="name"
            :class="{
              'p-invalid': v$.cardCompayValue.$invalid && soogiValid,
            }"
          />
        </div>

        <div
          class="col-4 py-3 flex border-bottom-1 border-300 surface-200 justify-content-center align-items-center"
          v-if="/카드/.test(selectedPaymentValue.payment_type)"
        >
          카드번호
        </div>
        <div
          v-if="/카드/.test(selectedPaymentValue.payment_type)"
          class="col-8 py-3 text-center border-bottom-1 border-300 flex justify-content-center align-items-center"
        >
          <InputText
            v-model="v$.cardNumber.$model"
            class="p-inputtext-sm soogi"
            :class="{
              'p-invalid': v$.cardNumber.$invalid && soogiValid,
            }"
            style="width: 150px; height: 15px"
            @input="inputTextChange($event)"
          />
        </div>

        <div
          v-if="
            /카드/.test(selectedPaymentValue.payment_type) ||
            /현금/.test(selectedPaymentValue.payment_type)
          "
          class="col-4 py-3 flex border-bottom-1 border-300 surface-200 justify-content-center align-items-center"
        >
          승인번호
        </div>
        <div
          v-if="
            /카드/.test(selectedPaymentValue.payment_type) ||
            /현금/.test(selectedPaymentValue.payment_type)
          "
          class="col-8 py-3 text-center border-bottom-1 border-300 flex justify-content-center align-items-center"
        >
          <InputText
            v-model="v$.approveNumber.$model"
            class="p-inputtext-sm soogi"
            :class="{
              'p-invalid': v$.approveNumber.$invalid && soogiValid,
            }"
            style="width: 150px; height: 15px"
            @input="inputTextAprove($event)"
          />
        </div>
      </div>
    </div>
    <div v-if="selectedPaymentValue.refund_active">
      <div class="grid pt-2">
        <div
          class="col-4 py-3 flex border-bottom-1 border-300 surface-200 justify-content-center align-items-center"
        >
          결제날짜
        </div>
        <div class="col-8 py-3 text-center border-bottom-1 border-300">
          {{ selectedPaymentValue.payment_approval_date }}
        </div>
      </div>
      <div
        class="grid"
        v-if="
          (selectedPaymentValue.refund_active &&
            selectedPaymentValue.soogi &&
            !/현금/.test(selectedPaymentValue.buycompany) &&
            selectedPaymentValue.buycompany) ||
          (!selectedPaymentValue.soogi && selectedPaymentValue.refund_active)
        "
      >
        <div class="col-4 py-3 text-center border-bottom-1 border-300 surface-200">
          결제수단상세
        </div>
        <div class="col-8 py-3 text-center border-bottom-1 border-300">
          {{ selectedPaymentValue.buycompany }}
        </div>
        <div class="col-4 py-3 text-center border-bottom-1 border-300 surface-200">
          카드번호
        </div>
        <div class="col-8 py-3 text-center border-bottom-1 border-300">
          {{ selectedPaymentValue.cardnumber }}
        </div>
        <div class="col-4 py-3 text-center border-bottom-1 border-300 surface-200">
          결제번호
        </div>
        <div class="col-8 py-3 text-center border-bottom-1 border-300">
          {{ selectedPaymentValue.payment_approval_number }}
        </div>
      </div>
      <div
        class="grid"
        v-if="
          (selectedPaymentValue.refund_active &&
            selectedPaymentValue.soogi &&
            /현금/.test(selectedPaymentValue.payment_type)) ||
          (!selectedPaymentValue.soogi && selectedPaymentValue.refund_active)
        "
      >
        <div class="col-4 py-3 text-center border-bottom-1 border-300 surface-200">
          결제번호
        </div>
        <div class="col-8 py-3 text-center border-bottom-1 border-300">
          {{ selectedPaymentValue.payment_approval_number }}
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="취소"
        icon="pi pi-times"
        class="p-button-text p-button-sm"
        @click="closeDialog"
      />
      <Button
        :label="selectedPaymentValue.refund_active ? '환불' : '결제'"
        icon="pi pi-check"
        class="p-button-sm"
        :class="{ 'p-button-danger': selectedPaymentValue.refund_active }"
        @click="paymentExecute"
      />
    </template>
  </Dialog>
</template>

<script setup>
import {
  onBeforeMount,
  ref,
  watchEffect,
  getCurrentInstance,
  reactive,
  onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import Swal from 'sweetalert2';
import moduleSend from '../../../../util/van';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

const selectedPaymentValue = ref();
const selectedPaymentSubValue = ref();
const paymentDialogState = ref(false);
const store = useStore();
const installment = ref();
const installSelected = ref();
const payCodeObj = ref({
  카드결제: 'D1',
  카드환불: 'D2',
  바코드결제: 'F3',
  바코드환불: 'F4',
  현금결제: 'D3',
  현금환불: 'D4',
});
const paymentPrice = ref();
const authList = ref([]);
const soogiState = ref(false);
const soogiStateVan = ref(false);
const soogiData = reactive({
  cardCompayValue: '',
  approveNumber: null,
  cardNumber: null,
});
const rules = {
  cardCompayValue: { required },
  approveNumber: { required },
  cardNumber: { required },
};

const cashSoogiData = reactive({
  approveNumber: null,
});
const cashRules = {
  approveNumber: { required },
};
const v$ = useVuelidate(rules, soogiData);
const cv$ = useVuelidate(cashRules, cashSoogiData);
const soogiValid = ref(false);
const cardCompay = [
  { name: 'KB국민카드' },
  { name: '롯데카드' },
  { name: '신한카드' },
  { name: '하나카드' },
  { name: 'BC카드' },
  { name: '삼성카드' },
  { name: '현대카드' },
  { name: '우리카드' },
  { name: 'NH농협카드' },
  { name: '씨티카드' },
  { name: '카카오뱅크카드' },
  { name: '토스뱅크카드' },
  { name: '외환은행카드' },
  { name: '광주은행카드' },
  { name: '전북은행카드' },
  { name: '케이뱅크카드' },
  { name: '기타(은행/증권)카드' },
  { name: '해외카드' },
  { name: '카카오페이' },
  { name: '제로페이' },
  { name: '서울페이' },
];
const cash = [{ name: '소득공제' }, { name: '자진발급' }, { name: '지출증빙' }];

watchEffect(() => {
  selectedPaymentValue.value = store.state.paymentStore.selectedPaymentValue;
  selectedPaymentSubValue.value = store.state.paymentStore.selectedPaymentSubValue;
  paymentDialogState.value = store.state.paymentStore.paymentDialogState;

  paymentPrice.value = _.sumBy(selectedPaymentSubValue.value, (a) =>
    parseInt(a.payment_price.replace(/,/g, ''))
  );

  if (selectedPaymentValue.value.refund_active) {
    installSelected.value = _.padStart(
      _.toString(selectedPaymentValue.value.pay_in_installments),
      2,
      '0'
    );
  } else {
    if (
      selectedPaymentValue.value.payment_type === '현금결제' ||
      paymentPrice.value < 50000
    ) {
      installSelected.value = '00';
    } else {
      installSelected.value = '';
    }
  }

  if (selectedPaymentValue.value?.soogi) {
    soogiStateVan.value = selectedPaymentValue.value.soogi;
  }
});

onBeforeMount(() => {
  const install = _.filter(
    _.range(24).map((a, i) => {
      if (i !== 0) {
        return { name: `${a + 1}개월`, code: _.padStart(_.toString(a + 1), 2, '0') };
      }
    }),
    (a) => a
  );
  const result = _.concat([{ name: '일시불', code: '00' }], install);
  installment.value = result;

  authList.value = store.state.userAuthData;
});

onUnmounted(() => {
  closeDialog();
});

function inputTextChange(e) {
  const inputValue = e.target.value.replace(/[^\d]/g, '');
  v$.value.cardNumber.$model = inputValue;
}

function inputTextAprove(e) {
  const inputValue = e.target.value.replace(/[^\d]/g, '');
  v$.value.approveNumber.$model = inputValue;
}

function closeDialog() {
  store.commit('paymentDialogStateCommit', false);
  soogiValid.value = false;
  soogiState.value = false;
  soogiStateVan.value = false;
  if (!soogiState.value) {
    soogiData.approveNumber = null;
    soogiData.cardCompayValue = null;
    soogiData.cardNumber = null;
    cashSoogiData.approveNumber = null;
  }
  store.commit('isConnectedToCatCommit', false);
}

function paymentTotal() {
  const booho = selectedPaymentValue.value.refund_active ? -1 : 1;
  return $wonCurrency(
    _.sumBy(selectedPaymentSubValue.value, (a) => {
      return parseInt(a.payment_price.replace(/,/g, ''));
    }) * booho
  );
}

function today() {
  return $formatDate('datetime', new Date());
}

function soogi() {
  return (
    _.includes(authList.value, '000') ||
    _.includes(authList.value, '100') ||
    _.includes(authList.value, '110')
  );
}

function soogiButton() {
  if (soogiState.value) {
    soogiState.value = false;
  } else {
    soogiState.value = true;
  }
}

function paymentExecuteData(is) {
  if (is === '단말기오류') {
    return {
      success: false,
      header: selectedPaymentValue.value,
      receptionCode: store.state.board.boardModal.boardReceptionCode,
      customerCode: store.state.board.boardModal.boardSendCutomerCode,
      soogiState: soogiState.value,
    };
  } else if (is === '단말기결제') {
    return {
      success: true,
      install: installSelected.value,
      header: selectedPaymentValue.value,
      refund: selectedPaymentValue.value.refund_active,
      receptionCode: store.state.board.boardModal.boardReceptionCode,
      customerCode: store.state.board.boardModal.boardSendCutomerCode,
      soogiState: soogiState.value,
    };
  } else if (is === '수기결제') {
    return {
      success: true,
      soogiState: soogiState.value,
      install: installSelected.value,
      header: selectedPaymentValue.value,
      refund: selectedPaymentValue.value.refund_active,
      receptionCode: store.state.board.boardModal.boardReceptionCode,
      customerCode: store.state.board.boardModal.boardSendCutomerCode,
      ...soogiData,
    };
  } else if (is === '날짜경과수기결제') {
    return {
      success: true,
      soogiState: true,
      install: installSelected.value,
      header: selectedPaymentValue.value,
      refund: selectedPaymentValue.value.refund_active,
      receptionCode: store.state.board.boardModal.boardReceptionCode,
      customerCode: store.state.board.boardModal.boardSendCutomerCode,
      cardCompayValue: selectedPaymentValue.value.buycompany,
      approveNumber: selectedPaymentValue.value.payment_approval_number,
      approvenumber: selectedPaymentValue.value.payment_approval_number,
      cardNumber: selectedPaymentValue.value.cardnumber,
    };
  }
}

const paymentExecute = _.debounce(_paymentExecute, 500);

async function _paymentExecute() {
  const now = dayjs().tz('Asia/Seoul');
  const paymentDate = dayjs(selectedPaymentValue.value.payment_approval_date);
  const datediff = selectedPaymentValue.value.payment_approval_date
    ? now.diff(paymentDate, 'day')
    : 0;
  const refundIs = selectedPaymentValue.value.refund_active;

  const aa = 2;
  if (aa === 2) {
    if (datediff < 14) {
      store.commit('loadingChangeValue', true);
      const paymentType = selectedPaymentValue.value.refund_active
        ? selectedPaymentValue.value.payment_type.replace('결제', '환불')
        : selectedPaymentValue.value.payment_type;
      const vanCode = payCodeObj.value[paymentType];

      let reqData = new Object();
      // 환불 시 추가 데이터 입력
      if (vanCode === 'D2' || vanCode === 'D4' || vanCode === 'F4') {
        reqData = {
          approveNumber: selectedPaymentValue.value.payment_approval_number.trim(),
          paymentDate: selectedPaymentValue.value.payment_approval_date,
        };
        if (vanCode === 'F4') {
          reqData.barcodeNumber = selectedPaymentValue.value.barcode;
        } else if (vanCode == 'D4') {
          reqData.buycode = selectedPaymentValue.value.buycode;
        }
      }
      const installment = installSelected.value;
      const paymentPrice = _.sumBy(selectedPaymentSubValue.value, (a) =>
        parseInt(a.payment_price.replace(/,/g, ''))
      );

      if (installment === '') {
        Swal.fire('할부개월을 선택해주세요.', '', 'warning');
        document.querySelector('#van-installment') &&
          document.querySelector('#van-installment').classList.add('p-invalid');
      } else {
        document.querySelector('#van-installment') &&
          document.querySelector('#van-installment').classList.remove('p-invalid');

        if (!soogiState.value && !selectedPaymentValue.value.soogi) {
          const vanExeCute = async () => {
            store.commit('paymentLoadingStateCommit', true);

            const result = await moduleSend(vanCode, installment, paymentPrice, reqData);
            const vanData = {
              result: result.result,
              rtn: result.rtn,
              ...result?.recvBuf,
            };

            if (!result) {
              Swal.fire(
                '단말기가 인식되지 않습니다.',
                '단말기를 연결하거나 단말기 프로그램을 실행해주세요.',
                'warning'
              );
            } else if (
              result.rtn !== '정상' ||
              result?.recvBuf?.rejectcode === 'B' ||
              (result.rtn === '정상' && !result.recvBuf.approvenumber)
            ) {
              const sendData = {
                van: vanData,
                ...paymentExecuteData('단말기오류'),
              };
              await store.dispatch('paymentExecute', sendData);
              Swal.fire(
                result.rtn === '정상'
                  ? '결제가 되지 않았습니다.'
                  : result.rtn !== '정상'
                  ? result.rtn
                  : result.recvBuf.approvemessage,
                '',
                'error'
              );
            } else {
              const sendData = {
                van: vanData,
                ...paymentExecuteData('단말기결제'),
              };

              await store.dispatch('paymentExecute', sendData);
              Swal.fire(`${paymentType}이(가) 완료되었습니다.`, '', 'success').then(
                () => {
                  store.commit('paymentDialogStateCommit', false);
                  store.commit('paymentIndexActiveInit');
                }
              );
            }

            store.commit('paymentLoadingStateCommit', false);
            store.commit('loadingChangeValue', false);
          };

          if (soogi() && refundIs) {
            Swal.fire({
              icon: 'question',
              title: '수기환불 여부확인',
              html: '수기환불 여부에 관한 알림창입니다.<br>수기환불의 경우 재무팀에서 관리하는 시트에 기재해야<br>고객에게 환불됩니다.',
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonText: '단말기환불',
              denyButtonText: '수기환불',
            }).then(async (result) => {
              if (result.isConfirmed) {
                // 단말기환불
                await vanExeCute();
              } else if (result.isDenied) {
                // 수기환불
                Swal.fire({
                  title: '수기환불 하시겠습니까?',
                  text: '환불을 실행하면 되돌릴 수 없습니다.',
                  icon: 'warning',
                  showConfirmButton: true,
                  showDenyButton: true,
                  confirmButtonText: '실행',
                  denyButtonText: '취소',
                }).then(async (result2) => {
                  if (result2.isConfirmed) {
                    const sendData = paymentExecuteData('수기결제');

                    await store.dispatch('paymentExecute', sendData);
                    Swal.fire(
                      `수기환불이(가) 완료되었습니다.`,
                      '재무팀 환불시트에 해당내용 기재해주세요.',
                      'success'
                    ).then(() => {
                      closeDialog();
                      store.commit('paymentIndexActiveInit');
                    });
                  }
                });
              }
            });
          } else {
            await vanExeCute();
          }
        } else {
          soogiValid.value = true;

          if (!soogiValidfnc() || selectedPaymentValue.value.refund_active) {
            soogiValid.value = false;

            const soogiExecution = async () => {
              try {
                const sendData = paymentExecuteData('수기결제');
                const result = await store.dispatch('paymentExecute', sendData);
                if (result) {
                  Swal.fire(
                    `수기${paymentType}이(가) 완료되었습니다.`,
                    '',
                    'success'
                  ).then(() => {
                    closeDialog();
                    store.commit('paymentIndexActiveInit');
                  });
                }
              } catch (err) {
                $errorHandling(null, err);
              }
            };

            if (selectedPaymentValue.value.refund_active) {
              Swal.fire({
                icon: 'warning',
                title: '수기환불 경고',
                html: '해당 환불건은 수기환불 입니다.<br>해당 결제건은 재무팀 환불 시트에 정보를 입력해주셔야<br>고객에게 환불 됩니다.',
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonText: '실행',
                denyButtonText: '취소',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await soogiExecution();
                }
              });
            } else {
              await soogiExecution();
            }
          }
        }
      }
    } else {
      if (soogi()) {
        Swal.fire({
          icon: 'warning',
          title: '수기환불 경고',
          html: '해당 결제건은 결제 이후 14일이 지났습니다.<br>단말기에서 취소 되지 않아 수기결제 되고<br>해당 결제건은 재무팀 환불 시트에 정보를 입력해주셔야<br>고객에게 환불 됩니다.',
          showDenyButton: true,
          confirmButtonText: '수기환불실행',
          denyButtonText: '취소',
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const sendData = paymentExecuteData('날짜경과수기결제');

            await store.dispatch('paymentExecute', sendData);
            Swal.fire(
              `수기환불이(가) 완료되었습니다.`,
              '재무팀 환불시트에 해당내용 기재해주세요.',
              'success'
            ).then(() => {
              closeDialog();
              store.commit('paymentIndexActiveInit');
            });
          }
        });
      } else {
        Swal.fire({
          title: `수기 권한이 없습니다.`,
          html: '해당 결제건은 결제 이후 14일이 지났습니다.<br>운영팀에 수기환불을 요청해주세요.',
          icon: 'warning',
        });
      }
    }
  }
}

function soogiValidfnc() {
  if (/카드/.test(selectedPaymentValue.value.payment_type)) {
    return v$.value.$invalid;
  } else if (/현금/.test(selectedPaymentValue.value.payment_type)) {
    cv$.value.approveNumber.$model = v$.value.approveNumber.$model;
    return cv$.value.$invalid;
  } else {
    return v$.value.cardCompayValue.$invalid;
  }
}
</script>
<script>
export default {
  name: 'WaitingDialog',
};
</script>

<style scoped lang="scss">
::v-deep(.p-inputtext) {
  padding: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.soogi {
  ::v-deep(.p-inputtext) {
    height: 20px;
    width: 120px;
  }
}
</style>
