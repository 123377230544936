<template>
  <Toast position="top-center" group="sooga" />
  <div>
    <TopNavigation class="mb-4"></TopNavigation>
    <div>
      <DoctorToolbar />
      <DoctorDataTable />
    </div>
  </div>
  <DoctorDialog />
  <DoctorUpdateDialog />
  <DoctorDeleteDialog />
</template>

<script setup>
import TopNavigation from '@/components/common/TopNavigation.vue';
import DoctorToolbar from '@/components/doctor/DoctorToolbar.vue';
import DoctorDataTable from '@/components/doctor/DoctorDataTable.vue';
import DoctorDialog from '@/components/doctor/DoctorDialog.vue';
import DoctorUpdateDialog from '@/components/doctor/DoctorUpdateDialog.vue';
import DoctorDeleteDialog from '@/components/doctor/DoctorDeleteDialog.vue';

import { onBeforeMount, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

onBeforeMount(async () => {
  const timeoutData = {
    name: 'doctor-getdata',
    url: '/doctor-manage/data',
    module: 'doctorGetdata',
    compareData: 'doctorGetdataCompare',
  };
  await store.dispatch('timeoutCommonFunc', timeoutData);
});

onUnmounted(() => {
  store.commit('doctorInit');
  store.commit('deleteTimeout', 'doctor-getdata');
});
</script>

<style scoped></style>
