// import _ from 'lodash';
import axios from 'axios';
import { errorHandling } from '@/util/errorHandler';
import Swal from 'sweetalert2';

const metaCrmData = {
  state() {
    return {
      isMetaCrmConfirm: false,
    };
  },
  mutations: {
    isMetaCrmConfirmCommit(state, payload) {
      state.isMetaCrmConfirm = payload;
    },
  },
  actions: {
    async synchronizeMetaCrmDb({ rootState, commit }) {
      try {
        commit('loadingChangeValue', true);
        const URL = rootState.URL;
        const result = await axios.get(URL + '/meta/reserve-meta-data');
        commit('loadingChangeValue', false);

        if (result.status === 200) {
          commit('isMetaCrmConfirmCommit', false);
          Swal.fire('메타CRM 예약 데이터 연동이 완료되었습니다.', '', 'success').then(
            () => {
              commit('loadingChangeValue', false);
            }
          );
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
  },
  getters: {
    // unregisteredMemberDataGetter(state) {
    //   const unregisteredMember = state.unregisteredMember;
    //   return unregisteredMember;
    // },
  },
};

export default metaCrmData;
