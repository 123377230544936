<template>
  <div>
    <TopNavigation> </TopNavigation>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import TopNavigation from '@/components/common/TopNavigation.vue';
export default {
  name: 'ReserveExcelPage',
  components: { TopNavigation },
};
</script>

<style></style>
