/*eslint-disable*/
/** @type {string} 소켓에 보내는 데이터 */
let sendbuf;

String.prototype.NCbyteLength = function () {
  let l = 0;

  for (let idx = 0; idx < this.length; idx++) {
    const c = escape(this.charAt(idx));

    if (c.length == 1) l++;
    else if (c.indexOf('%u') != -1) l += 3;
    else if (c.indexOf('%') != -1) l += c.length / 3;
  }

  return l;
};

// 단말기에 입력할 데이트 포맷팅(YYYYMMDDhhmmss)
function makeDateFormat(date) {
  const result = new Date(date);
  const year = result.getFullYear().toString().slice(-2); // 뒤에서 2자리만 추출
  const month = ('0' + (result.getMonth() + 1)).slice(-2); // 뒤에서 2자리만 추출, 1월은 0부터 시작하므로 +1
  const day = ('0' + result.getDate()).slice(-2); // 뒤에서 2자리만 추출
  const hours = ('0' + result.getHours()).slice(-2); // 뒤에서 2자리만 추출
  const minutes = ('0' + result.getMinutes()).slice(-2); // 뒤에서 2자리만 추출
  const seconds = ('0' + result.getSeconds()).slice(-2); // 뒤에서 2자리만 추출
  const dateString = year + month + day + hours + minutes + seconds;

  return dateString;
}

// 통상적인 결제 요청 시에 반영하는 데이터 포멧
function makeCommonSendBuf(service_code, installment, cash) {
  const FS = '\x1C';
  const sendbuf =
    service_code +
    FS +
    FS +
    FS +
    installment +
    FS +
    FS +
    FS +
    cash +
    FS +
    FS +
    FS +
    FS +
    FS;
  return sendbuf;
}

// 현금환불 시에 반영하는 데이터 포멧
function makeCashRefundSendBuf(service_code, installment, cash, req_data) {
  const { approveNumber, paymentDate, buycode } = req_data;
  const formatingDate = makeDateFormat(paymentDate);
  const sendbuf = `${service_code}\x1C${buycode.toString()}\x1C\x1C${installment}\x1C\x1C\x1C${cash}\x1C${'1'}\x1C${formatingDate}\x1C${approveNumber}\x1C\x1C`;
  return sendbuf;
}

// 카드환불 시에 반영하는 데이터 포멧
function makeCardRefundSendBuf(service_code, installment, cash, req_data) {
  const { approveNumber, paymentDate } = req_data;
  const formatingDate = makeDateFormat(paymentDate);
  const sendbuf = `${service_code}\x1C\x1C\x1C${installment}\x1C\x1C\x1C${cash}\x1C${approveNumber}\x1C${formatingDate}\x1C\x1C\x1C`;
  return sendbuf;
}

// 바코드환불 시에 반영하는 데이터 포멧
function makeBarcodeRefundSendBuf(service_code, installment, cash, req_data) {
  const { approveNumber, paymentDate, barcodeNumber } = req_data;
  const formatingDate = makeDateFormat(paymentDate);
  const sendbuf = `${service_code}\x1C\x1C${barcodeNumber}\x1C${installment}\x1C\x1C\x1C${cash}\x1C${approveNumber}\x1C${formatingDate}\x1C\x1C\x1C`;
  return sendbuf;
}

async function moduleSend(service_code, installment, cash, req_data) {
  let sendbuf = null;
  if (req_data) {
    if (service_code === 'D1' || service_code === 'D3' || service_code === 'F3') {
      sendbuf = makeCommonSendBuf(service_code, installment, cash);
    } else if (service_code === 'D2') {
      // 카드 환불
      sendbuf = makeCardRefundSendBuf(service_code, installment, cash, req_data);
    } else if (service_code === 'D4') {
      // 현금 환불
      sendbuf = makeCashRefundSendBuf(service_code, installment, cash, req_data);
    } else if (service_code === 'F4') {
      // 바코드 환불
      sendbuf = makeBarcodeRefundSendBuf(service_code, installment, cash, req_data);
    }
  } else {
    sendbuf = makeCommonSendBuf(service_code, installment, cash);
  }

  try {
    if (sendbuf) {
      return await POSSEND('8088', 'PCAT', sendbuf, await RecvData);
    } else {
      Swal.fire(
        '단말기 전송 오류 발생',
        `개발팀에 문의해주세요. \n sendbuf 오류`,
        'error'
      );
    }
  } catch (error) {
    Swal.fire('단말기 전송 오류 발생', `개발팀에 문의해주세요. \n ${error}`, 'error');
  }
}

function RecvData(rtn, recvBuf) {
  return new Promise((resolve) => {
    const rtnResult = {
      1: '정상',
      '-1': '포트 오픈 실패',
      '-2': '포트 이미 오픈된 상태',
      '-3': 'ACK 수신 실패 (단말기 연결 실패)',
      '-4': 'LRC오류 OR 종료버튼',
      '-14': '요청 전문 데이터 오류',
    };

    if (rtn == 1) {
      resolve({
        rtn: rtnResult[rtn.toString()],
        result: '성공',
        recvBuf: {
          serviecode: recvBuf.split(/\\x1C|\\x02|||/g)[1],
          rejectcode: recvBuf.split(/\\x1C|\\x02|||/g)[2],
          approvemessage: recvBuf.split(/\\x1C|\\x02|||/g)[3],
          approvedate: recvBuf.split(/\\x1C|\\x02|||/g)[5],
          totalamount: recvBuf.split(/\\x1C|\\x02|||/g)[6],
          surtax: recvBuf.split(/\\x1C|\\x02|||/g)[6],
          approvenumber: recvBuf.split(/\\x1C|\\x02|||/g)[8],
          issuecode: recvBuf.split(/\\x1C|\\x02|||/g)[9],
          buycode: recvBuf.split(/\\x1C|\\x02|||/g)[10],
          issuecompany: recvBuf.split(/\\x1C|\\x02|||/g)[11],
          buycompany: recvBuf.split(/\\x1C|\\x02|||/g)[12],
          shopcode: recvBuf.split(/\\x1C|\\x02|||/g)[13],
          cardnumber: recvBuf.split(/\\x1C|\\x02|||/g)[14],
          installment: recvBuf.split(/\\x1C|\\x02|||/g)[15],
          proccessnumber: recvBuf.split(/\\x1C|\\x02|||/g)[16],
          approvecatid: recvBuf.split(/\\x1C|\\x02|||/g)[17],
          connection: recvBuf,
        },
      });
    } else {
      resolve({
        rtn: rtnResult[rtn.toString()],
        result: '실패',
      });
    }
  });
}

/** @type {number} 결제상태 내용 */
let status = 5;

async function POSSEND(port, type, senddata, callback) {
  return new Promise((resolve) => {
    status = 0;
    sendbuf = make_send_data(type, senddata);
    // const conninfo = 'ws://' + ip + ':' + port + '/' + type;
    const conninfo = 'ws://localhost:' + port + '/' + type;
    console.log('VAN CONN INFO :: ' + conninfo);
    try {
      const ws = new WebSocket(conninfo);
      ws.onopen = function () {
        status = 1;

        //소켓으로 데이터 보내기
        status = 2;
        ws.send(sendbuf);
      };

      ws.onerror = function () {
        status = 6;
        resolve(false);
      };

      ws.onmessage = async (event) => {
        status = 3;

        // 소켓 닫기
        status = 4;
        ws.close();

        resolve(parse_recv_data(event.data, await callback));
      };

      ws.onclose = function () {
        status = 5;
      };
    } catch (e) {
      console.log('에러 발생 ' + e);
    }
  });
}

async function parse_recv_data(recvdata, callback) {
  const bodylen = recvdata.substr(12, 4);

  if (recvdata.substr(8, 4) != '0000') {
    const errcd = recvdata.substr(8, 4) * -1;
    return await callback(errcd, '');
  } else {
    return await callback(1, recvdata.substr(16, bodylen));
  }
}

function make_send_data(type, senddata) {
  const NCpad = (n, width) => {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  };

  const m_bodylen = senddata.NCbyteLength();
  const m_totlen = 12 + m_bodylen;

  return NCpad(m_totlen, 4) + type + '    ' + NCpad(m_bodylen, 4) + senddata;
}

module.exports = moduleSend;
