<template>
  <h2>내 정보 관리</h2>
  <div v-if="!isPassConfirm" class="content-section implementation">
    <div class="card col-12 md:col-3">
      <div class="font-medium text-2xl text-900 mb-3">비밀번호 확인</div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem text-base"> 비밀번호 </span>
        <Password
          placeholder="비밀번호를 입력하세요."
          v-model="passValue"
          :class="{
            'p-invalid': passValue && !passValueSubmitted,
          }"
          name="passValue"
          :feedback="false"
          @keyup.enter="passValueConfirm"
        />
        <Button v-if="passValue" @click="passValueConfirm" label="확인" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';

const store = useStore();
const toast = useToast();
const cookieData = ref();
// const myAuthData = ref();
const passValue = ref(null);
const passValueSubmitted = ref(false);
const isPassConfirm = ref(false);

// 비밀번호 확인을 위한 로그인 계정 정보(userId) 가져오기
onBeforeMount(async () => {
  cookieData.value = await store.dispatch('getCookieData');
});

watchEffect(() => {
  isPassConfirm.value = store.state.myPageGetData.isPassConfirm;
});

// 비밀번호 확인 함수
async function passValueConfirm() {
  if (passValue.value && cookieData.value) {
    passValueSubmitted.value = true;
    const confirmData = {
      userId: cookieData.value.userData[0].user_id,
      passValue: passValue.value,
    };
    if (confirmData.userId && confirmData.passValue) {
      try {
        await store.dispatch('confirmPassword', confirmData);
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: `오류 발생.`,
          detail: `${error.response.data.message ? error.response.data.message : error}`,
          life: 3000,
        });
      }
    }
  }
}
</script>
<script>
export default {
  name: 'MyPageConfirmPassword',
};
</script>

<style></style>
