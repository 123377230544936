<template>
  <div>
    <div class="grid align-items-center" style="height: 55vh">
      <div
        class="col-4 justify-content-center flex align-items-center border-right-2 border-400 flex-column"
        style="height: inherit"
      >
        <div>
          <div class="p-inputgroup mb-3" style="height: fit-content">
            <span class="p-inputgroup-addon">
              <Checkbox
                v-model="isDoctor"
                :binary="true"
                :disabled="!store.state.counselingAndTreatStore.isCounseling"
              />
            </span>
            <Dropdown
              v-model="selectedDoctor"
              :options="store.state.counselingAndTreatStore.doctors"
              optionLabel="doc_nm"
              optionValue="member_id"
              :placeholder="
                store.state.counselingAndTreatStore.isCounseling
                  ? '의사 상담시 선택'
                  : '의사 선택'
              "
              :disabled="!store.state.counselingAndTreatStore.isDoctor"
            />
          </div>
          <div class="justify-content-center align-items-center">
            <StartButton
              class="mb-3"
              @click="boardStart"
              :disabled="isStarted()"
            ></StartButton>
          </div>
          <!-- <div class="p-inputgroup justify-content-center align-items-center mb-2">
            <Button
              label="시작"
              class="p-button-secondary"
              :disabled="
                !store.state.counselingStore.counselingHasStarted ||
                !store.state.treatStore.treatHasStarted
              "
            />
            <InputMask
              id="endButton"
              v-model="counselingStartTime"
              mask="99:99"
              placeholder="24시:분"
              :disabled="
                !store.state.counselingStore.counselingHasStarted ||
                !store.state.treatStore.treatHasStarted
              "
            />
          </div>
          <div class="p-inputgroup justify-content-center align-items-center">
            <Button
              label="종료"
              class="p-button-secondary"
              :disabled="
                !store.state.counselingStore.counselingHasStarted ||
                !store.state.treatStore.treatHasStarted
              "
            />
            <InputMask
              id="endButton"
              v-model="counselingEndTime"
              mask="99:99"
              placeholder="24시:분"
              :disabled="
                !store.state.counselingStore.counselingHasStarted ||
                !store.state.treatStore.treatHasStarted
              "
            />
          </div> -->
        </div>
      </div>
      <div class="col-8 mt-6" style="height: inherit">
        <div class="grid">
          <ExistsCounselingTreat></ExistsCounselingTreat>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import StartButton from '../ModalSEButtons/StartButton.vue';
import ExistsCounselingTreat from './ExistsCounselingTreat.vue';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import { computed, getCurrentInstance, watchEffect, ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { errorHandling } from '../../../../util/errorHandler.js';

const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
// const counselingStartTime = ref(null);
// const counselingEndTime = ref(null);
const componentName = ref();
const router = useRouter();
const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;

onBeforeMount(() => {
  if (store.state.counselingAndTreatStore.isCounseling) {
    componentName.value = '상담';
  } else {
    componentName.value = '진료';
  }
});

watchEffect(async () => {
  // axios로 가져온 해당 고객의 모든 접수, 상담 데이터 => store 에 저장된 변수
  const allCounselingAndReceptionData = store.state.counselingStore.counselingCompare;

  if (
    store.state.counselingAndTreatStore.isCounseling &&
    allCounselingAndReceptionData?.data
  ) {
    // 상담이고, data 가 있다면 실행

    // 해당 고객의 모든 상담 데이터
    const allCounselingData = allCounselingAndReceptionData.data;

    // 상담 일자 처리
    paintCounselingDates(allCounselingData);
  }
  if (store.state.counselingAndTreatStore.isCounseling) {
    // 상담 시작 여부를 알기 위해 borad table에서 체크
    await store.dispatch('getDoctors');
    await store.dispatch('getCounselingBoardData');
  } else {
    // 진료 시작 여부를 알기 위해 borad table에서 체크
    await store.dispatch('getTreatBoardData');
  }
});

function isStarted() {
  if (
    componentName.value === '상담' &&
    store.state.counselingStore.counselingHasStarted
  ) {
    return true;
  } else if (componentName.value === '진료' && store.state.treatStore.treatHasStarted) {
    return true;
  } else {
    return false;
  }
}

function paintCounselingDates(counselingDataByReception) {
  // 상담일자만 get
  const counselingDates = counselingDataByReception.map(({ counseling_date }) => {
    return counseling_date;
  });
  // 상담일자가 있으면 파란색으로 표시
  store.commit('boardSpecialDay2', counselingDates);
}

// 의사 상담 여부 checkbox
const isDoctor = computed({
  get: () => {
    return store.state.counselingAndTreatStore.isDoctor;
  },
  set: (value) => {
    store.commit('setIsDoctor', value);
  },
});

// 선택한 원장
const selectedDoctor = computed({
  get: () => {
    return store.state.counselingAndTreatStore.selectedDoctor;
  },
  set: (value) => {
    store.commit('setSelectedDoctor', value);
  },
});

async function boardStart() {
  try {
    if (storeGetters.value.getBoardModalReceptionId) {
      const { isChecked, writer, counselerIsDoctor } =
        storeGetters.value.getDoctorCheckboxChecked;

      if (!isChecked) {
        // fasle 일 경우 save 멈춤
        toast.add({
          severity: 'warn',
          summary: '의사 선택',
          detail: '의사 선생님을 입력해주세요.',
          life: 3000,
        });
        return -1;
      }

      const startTime = $formatDate('time');

      if (store.state.counselingAndTreatStore.isCounseling) {
        // 상담 시작 board 입력
        store.commit('loadingChangeValue', true);
        await upsertBoardStart(true, writer, startTime, counselerIsDoctor, '상담');
      } else {
        // 진료 시작 board 입력
        store.commit('loadingChangeValue', true);
        await upsertBoardStart(true, writer, startTime, counselerIsDoctor, '진료');
      }
    } else {
      toast.add({
        severity: 'warn',
        summary: '접수 아이디 없음',
        detail: '접수 아이디가 없습니다. 접수 후 진행해주세요.',
        life: 3000,
      });
    }
  } catch (error) {
    router.push({ name: 'status-board' });
    errorHandling(toast, error);
  }
}

async function upsertBoardStart(isStart, writer, time, counselerIsDoctor, process) {
  await store.dispatch('upsertBoard', {
    isStart: isStart,
    writer: writer,
    date: store.state.board.boardModal.boardSendDate,
    time: time,
    process: process,
    counselerIsDoctor: counselerIsDoctor,
  });

  if (process === '상담') {
    store.commit('setCounselingHasStarted', true);
  } else if (process === '진료') {
    store.commit('setTreatHasStarted', true);
  }

  toast.add({
    severity: 'success',
    summary: `${process} 시작`,
    detail: `${process} 시작 시간을 입력했습니다.`,
    life: 3000,
  });
}
</script>

<style></style>
