<template>
  <div>
    <Sidebar
      :showCloseIcon="false"
      class="p-sidebar-lg"
      v-model:visible="store.state.sidebarVisible"
      :autoZIndex="true"
      position="full"
    >
      <div v-if="store.state.counselingAndTreatStore.isUpselling">
        <h5>기존 수가표</h5>
        <div class="grid">
          <div class="flex align-items-center justify-content-center">
            <DataTable :value="existingSoogaData">
              <template #header>
                {{ existingSoogaData[0].large_category }} ▶
                {{ existingSoogaData[0].middle_category }} ▶
                {{ existingSoogaData[0].small_category }} ||
                {{ existingSoogaData[0].abbreviation }}
              </template>

              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 정상가 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.price) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 이벤트가 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.event_price) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> VAT 포함 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.price_include_vat) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 개수 </template>
                <template #body="{ data }">
                  {{ data.menu_count }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 판매 총액 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.sales_price) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 할인율 </template>
                <template #body="{ data }">
                  {{ data.discount_rate }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 할인 및 서비스 </template>
                <template #body="{ data }">
                  {{ data.discount_name }}
                </template>
              </Column>
            </DataTable>
          </div>
          <div>
            <Divider layout="vertical"> ▶ </Divider>
          </div>
          <div class="flex align-items-center justify-content-center">
            <DataTable :value="changedSoogaData">
              <template #header>
                <div v-if="changedSoogaData?.[0]">
                  {{ changedSoogaData[0].large_category }} ▶
                  {{ changedSoogaData[0].middle_category }} ▶
                  {{ changedSoogaData[0].small_category }} ||
                  {{ changedSoogaData[0].abbreviation }}
                </div>
              </template>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 정상가 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.price) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 이벤트가 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.event_price) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> VAT 포함 </template>
                <template #body="{ data }">
                  {{ $wonCurrency(data.price_include_vat) }}
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 개수 </template>
                <template #body="{ data }">
                  <InputNumber
                    v-model="data.menu_count"
                    class="p-inputnumber-sm"
                    mode="decimal"
                    showButtons
                    style="height: min-content"
                    :min="1"
                    :inputStyle="{ width: '40px' }"
                    @input="changeMenuCountFromSidebar($event)"
                  >
                  </InputNumber>
                </template>
              </Column>
              <!-- <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 판매 총액 </template>
                <template #body="{ data }">
                  <div class="p-inputgroup">
                    <InputNumber
                      :disabled="data.sales_price_is_disabled"
                      class="p-inputnumber-sm"
                      inputStyle="width: 70px"
                      v-model="data.sales_price"
                      @input="calculateDiscountRateFromSidebar($event)"
                      mode="decimal"
                      :min="0"
                    />
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-sm"
                      @click="editableCheck(true)"
                      :disabled="data.is_in_bom"
                    ></Button>
                  </div>
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 할인율 </template>
                <template #body="{ data }">
                  <div class="p-inputgroup">
                    <InputNumber
                      :disabled="data.discount_rate_is_disabled"
                      class="p-inputnumber-sm"
                      inputStyle="width: 50px"
                      v-model="data.discount_rate"
                      @input="calculateSalesPriceFromSidebar($event)"
                      mode="decimal"
                      :min="0"
                      :max="100"
                      suffix="%"
                    />
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-sm"
                      @click="editableCheck(false)"
                      :disabled="data.is_in_bom"
                    ></Button>
                  </div>
                </template>
              </Column>
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 할인 및 서비스 </template>
                <template #body="{ data }">
                  <Dropdown
                    class="w-full"
                    v-model="data.discount_name"
                    :options="ticketingDiscountNames"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="해당없음"
                    @change="selectDiscount(data.discount_name)"
                    :disabled="data.is_in_bom"
                    inputStyle="width: 70px"
                  />
                </template>
              </Column> -->
              <Column
                :bodyStyle="`text-align: center; height: ${changedSoogaDataTableBodyHeight};`"
              >
                <template #header> 변경완료 </template>
                <template #body>
                  <Button
                    class="p-button-sm p-button-warning"
                    label="변경완료"
                    @click="changedSoogaDataComplete"
                  ></Button>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>

      <DataTable
        :value="soogaData"
        :paginator="true"
        :rows="50"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100]"
        currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
        responsiveLayout="scroll"
        v-model:filters="filters"
        :globalFilterFields="['middle_category', 'small_category', 'abbreviation']"
      >
        <template #header>
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center">
              <div class="align-items-center">
                <h5 class="mr-4">수가표</h5>
              </div>
              <div class="align-items-center">
                <Dropdown
                  class="mr-2"
                  v-model="filters['large_category'].value"
                  optionLabel="name"
                  optionValue="name"
                  :options="largeCategories"
                  style="max-height: 43px; min-width: 150px"
                  placeholder="대분류 선택"
                  :showClear="true"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.name }}
                  </template>
                </Dropdown>
              </div>
              <div class="align-items-center">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters['global'].value"
                    placeholder="중분류, 소분류, 약어 검색"
                  />
                </span>
              </div>
            </div>

            <Button
              icon="pi pi-times"
              class="p-button-rounded p-button-secondary p-button-text"
              @click="closeSidebar"
            ></Button>
          </div>
        </template>
        <template #empty> No customers found. </template>
        <template #loading> Loading customers data. Please wait. </template>

        <!-- 수가표 데이터 -->
        <Column field="large_category">
          <template #header>
            <div class="flex-1 text-center">대분류</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center" style="min-width: 70px">
              {{ data.large_category }}
            </div>
          </template>
        </Column>
        <Column field="middle_category">
          <template #header>
            <div class="flex-1 text-center">중분류</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center" style="min-width: 80px">
              {{ data.middle_category }}
            </div>
          </template>
        </Column>
        <Column field="small_category">
          <template #header>
            <div class="flex-1 text-center">소분류</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center" style="min-width: 100px">
              {{ data.small_category }}
            </div>
          </template>
        </Column>
        <Column field="abbreviation">
          <template #header>
            <div class="flex-1 text-center">약어</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.abbreviation }}
            </div>
          </template>
        </Column>
        <Column field="medical_menu_content">
          <template #header>
            <div class="flex-1 text-center">내용</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.medical_menu_content }}
            </div>
          </template>
        </Column>
        <Column field="note">
          <template #header>
            <div class="flex-1 text-center">비고</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ data.note }}
            </div>
          </template>
        </Column>
        <Column field="price">
          <template #header>
            <div class="flex-1 text-center">정상가</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ $wonCurrency(data.price) }}
            </div>
          </template>
        </Column>
        <Column field="event_price">
          <template #header>
            <div class="flex-1 text-center">이벤트가</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ $wonCurrency(data.event_price) }}
            </div>
          </template>
        </Column>
        <Column field="price_include_vat">
          <template #header>
            <div class="flex-1 text-center">VAT 포함</div>
          </template>
          <template #body="{ data }">
            <div class="flex justify-content-center">
              {{ $wonCurrency(data.price_include_vat) }}
            </div>
          </template>
        </Column>
        <Column field="discount_limit">
          <template #header>
            <div class="flex-1 text-center">할인여부</div>
          </template>
          <template #body="{ data }">
            <div class="flex text-center justify-content-center">
              {{
                data.discount_limit == 0 ? '불가능' : '가능(' + data.discount_limit + '%)'
              }}
            </div>
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <!-- 상담일 경우는 selected 된 것은 선택 못하게 개수 inpu 삭제 -->
            <div
              v-if="store.state.counselingAndTreatStore.isCounseling && !data.is_selected"
              class="flex justify-content-center"
            >
              <InputNumber
                v-model="data.menu_count"
                mode="decimal"
                showButtons
                style="width: min-content; height: min-content"
                :min="1"
                :inputStyle="{ width: '50px' }"
              >
              </InputNumber>
            </div>
            <!-- 진료일 경우는 selected 여부 상관없이 선택 가능해야함 -->
            <div
              v-if="
                !store.state.counselingAndTreatStore.isCounseling &&
                !data.is_selected_design
              "
              class="flex justify-content-center"
            >
              <InputNumber
                v-model="data.menu_count"
                mode="decimal"
                showButtons
                style="width: min-content; height: min-content"
                :min="1"
                :inputStyle="{ width: '50px' }"
              >
              </InputNumber>
            </div>
          </template>
        </Column>
        <Column>
          <template #header>
            <div class="flex-1 text-center">선택</div>
          </template>
          <template #body="{ data }">
            <div
              v-if="store.state.counselingAndTreatStore.isCounseling && !data.is_selected"
              class="flex justify-content-center"
              style="min-width: 60px"
            >
              <!-- 상담일 경우는 selected 된 것은 선택 못하게 선택 버튼 삭제 -->
              <Button
                v-if="!store.state.counselingAndTreatStore.isUpselling"
                class="p-button-sm"
                label="선택"
                @click="selectSoogaData(data)"
              ></Button>
              <Button
                v-if="store.state.counselingAndTreatStore.isUpselling"
                class="p-button-sm p-button-warning"
                label="변경"
                @click="selectChangedSoogaData(data)"
              ></Button>
            </div>
            <!-- 진료일 경우는 selected 된 여부 상관없이 선택 버튼 활성화 -->
            <div
              v-if="
                !store.state.counselingAndTreatStore.isCounseling &&
                !data.is_selected_design
              "
              class="flex justify-content-center"
              style="min-width: 60px"
            >
              <Button
                class="p-button-sm"
                label="선택"
                @click="selectSoogaData(data)"
              ></Button>
            </div>
          </template>
        </Column>
      </DataTable>
    </Sidebar>
  </div>
</template>

<script setup>
import Sidebar from 'primevue/sidebar';
import InputNumber from 'primevue/inputnumber';
import Divider from 'primevue/divider';
// import { discountNames, discountValues } from '../OmniList';

import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import Swal from 'sweetalert2';
import _ from 'lodash';

const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const store = useStore();
const storeGetters = computed(() => store.getters);

// 수가표 검색 데이터
const soogaData = ref();
const largeCategories = ref([]);
const existingSoogaData = ref();
const changedSoogaData = ref([]);
const changedSoogaDataTableBodyHeight = ref('5em');
// const ticketingDiscountNames = ref(discountNames);

onMounted(async () => {
  soogaData.value = storeGetters.value.getSoogaData;
  const _largeCategories = soogaData.value.map((element) => {
    return element.large_category;
  });

  const uniqLargeCategories = _.uniq(_largeCategories);
  // return { id: element.id, name: element.large_category };

  uniqLargeCategories.forEach((category) => {
    largeCategories.value.push({ name: category });
  });

  existingSoogaData.value = [store.state.counselingAndTreatStore.originalSoogaData];
});

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  large_category: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const closeSidebar = () => {
  store.commit('sidebarInvisible');
  store.commit('setIsUpselling', false);
  store.commit('setOriginalSoogaData', null);
};

// 선택된 수가표 데이터
const selectSoogaData = (data) => {
  if (storeGetters.value.getIsMenuIdChangedBefore(data.id)) {
    Swal.fire({
      icon: 'warning',
      title: '이전에 업셀링된 수가표',
      text: '이전에 업셀링된 수가표는 진료에서 추가 해주세요.',
    });
    return;
  }
  data.is_selected_design = true;
  data.is_selected = true;
  data.sales_price = data.price_include_vat * data.menu_count;
  data.discount_rate = 0;
  data.discount_name = '해당없음';
  data.sales_price_is_disabled = true;
  data.discount_rate_is_disabled = true;
  data.isModified = false;

  if (!store.state.counselingAndTreatStore.isCounseling) {
    // 진료일 때
    data.writer_name = storeGetters.value.getSelectedDoctorName;
  }

  store.commit('pushSelectedSoogaData', data);
};

function selectChangedSoogaData(data) {
  const cloneSoogaData = _.cloneDeep(data);
  changedSoogaData.value = []; // 변경할 수가표 초기화

  // 기본 값 초기화
  cloneSoogaData.is_selected = true;
  cloneSoogaData.sales_price =
    cloneSoogaData.price_include_vat * cloneSoogaData.menu_count;
  cloneSoogaData.discount_rate = 0;
  cloneSoogaData.discount_name = '해당없음';
  cloneSoogaData.sales_price_is_disabled = true;
  cloneSoogaData.discount_rate_is_disabled = true;
  cloneSoogaData.is_paid = true;
  cloneSoogaData.is_modified = true;

  // push
  changedSoogaData.value.push(cloneSoogaData);
}

// function editableCheck(isSalesPrice) {
//   if (changedSoogaData.value?.[0]) {
//     const soogaData = changedSoogaData.value[0];
//     // 할인 및 서비스
//     const discountName = soogaData.discount_name;

//     let salesPriceIsDisabled;
//     let discountRateIsDisabled;
//     if (discountName === '해당없음') {
//       Swal.fire({
//         icon: 'warning',
//         title: '할인 및 서비스 입력',
//         text: '할인 및 서비스 중 해당없음은 판매가 및 할인율을 조정할 수 없습니다.',
//       });
//       // 해당없음일 경우 판매가, 할인율 disable
//       salesPriceIsDisabled = true;
//       discountRateIsDisabled = true;
//     } else {
//       if (isSalesPrice) {
//         // 판매가에서 들어왔을 경우 할인율 disable
//         salesPriceIsDisabled = false;
//         discountRateIsDisabled = true;
//       } else {
//         // 할인율에서 들어왔을 경우 판매가 disable
//         salesPriceIsDisabled = true;
//         discountRateIsDisabled = false;
//       }
//     }
//     soogaData.sales_price_is_disabled = salesPriceIsDisabled;
//     soogaData.discount_rate_is_disabled = discountRateIsDisabled;
//   }
// }

function changeMenuCountFromSidebar(event) {
  if (changedSoogaData.value?.[0]) {
    const soogaData = changedSoogaData.value[0];

    const priceIncludeVat = soogaData.price_include_vat;
    const discountRate = soogaData.discount_rate;
    const menuCount = event.value;

    const salesPrice = ((100 - discountRate) / 100) * (priceIncludeVat * menuCount);

    soogaData.sales_price = salesPrice;
  }
}

// function calculateSalesPriceFromSidebar(event) {
//   if (changedSoogaData.value?.[0]) {
//     const soogaData = changedSoogaData.value[0];

//     const priceIncludeVat = soogaData.price_include_vat;
//     const menuCount = soogaData.menu_count;
//     let salesPrice = 0;
//     let discountRate = 0;

//     if (event.value >= 100) {
//       salesPrice = 0;
//       discountRate = 100;
//     } else if (event.value <= 0) {
//       salesPrice = priceIncludeVat * menuCount;
//       discountRate = 0;
//     } else {
//       // 판매가 계산
//       salesPrice = ((100 - event.value) / 100) * (priceIncludeVat * menuCount);
//       discountRate = event.value;
//     }

//     // set 판매가
//     soogaData.sales_price = salesPrice;

//     // set 할인율
//     soogaData.discount_rate = discountRate;
//   }
// }

// const calculateDiscountRateFromSidebar = (event) => {
//   if (changedSoogaData.value?.[0]) {
//     const soogaData = changedSoogaData.value[0];

//     // 기존 가격 가져오기(vat 포함가)
//     const priceIncludeVat = soogaData.price_include_vat;
//     const menuCount = soogaData.menu_count;
//     let salesPrice = 0;
//     let discountRate = 0;

//     if (event.value >= priceIncludeVat * menuCount) {
//       salesPrice = event.value;
//       discountRate = 0;
//     } else if (event.value <= 0) {
//       salesPrice = 0;
//       discountRate = 100;
//     } else {
//       // 할인율 계산
//       salesPrice = event.value;
//       discountRate = 100 - (event.value / (priceIncludeVat * menuCount)) * 100;
//     }

//     // set 판매가
//     soogaData.sales_price = salesPrice;

//     // set 할인율
//     soogaData.discount_rate = discountRate;
//   }
// };

// function selectDiscount(discountName) {
//   if (changedSoogaData.value?.[0]) {
//     const soogaData = changedSoogaData.value[0];

//     // 기존 가격 가져오기(vat 포함가)
//     const priceIncludeVat = soogaData.price_include_vat;
//     const menuCount = soogaData.menu_count;

//     // set 할인율
//     soogaData.discount_rate = discountValues[discountName];
//     // set 판매가
//     soogaData.sales_price =
//       priceIncludeVat * menuCount * ((100 - discountValues[discountName]) / 100);
//     // set 할인 및 서비스
//     soogaData.discount_name = discountName;
//     // set 판매가 disable
//     soogaData.sales_price_is_disabled = true;
//     // set 할인율 disable
//     soogaData.discount_rate_is_disabled = true;
//   }
// }

function changedSoogaDataComplete() {
  const originalSoogaData = store.state.counselingAndTreatStore.originalSoogaData;
  const changedSooga = changedSoogaData.value?.[0];

  if (changedSooga.sales_price >= originalSoogaData.sales_price) {
    // 업셀링 수가표 가격이 기존 수가표 보다 크거나 같을 때 동작

    const selectedSoogaDataList = _.cloneDeep(storeGetters.value.getSelectedSoogaList);

    selectedSoogaDataList.forEach((sooga) => {
      if (sooga.counseling_surgery_id === originalSoogaData.counseling_surgery_id) {
        sooga.id = changedSooga.id; // 메뉴 아이디
        sooga.large_category = changedSooga.large_category; // 대분류
        sooga.middle_category = changedSooga.middle_category; // 중분류
        sooga.small_category = changedSooga.small_category; // 소분류
        sooga.abbreviation = changedSooga.abbreviation; // 약어
        sooga.medical_menu_content = changedSooga.medical_menu_content; // 내용
        sooga.note = changedSooga.note; // 비고
        sooga.price = changedSooga.price; // 정상가
        sooga.event_price = changedSooga.event_price; // 이벤트가
        sooga.price_include_vat = changedSooga.price_include_vat; // vat 포함가
        sooga.menu_count = changedSooga.menu_count; // 수량
        sooga.sales_price = changedSooga.sales_price; // 판매가
        sooga.discount_rate = changedSooga.discount_rate; // 할인율
        sooga.discount_name = changedSooga.discount_name; // 할인 및 서비스
        sooga.is_modified = changedSooga.is_modified; // 업셀링을 구분해주는 변수
        sooga.sales_price_is_disabled = true; // set 판매가 disable
        sooga.discount_rate_is_disabled = true; // set 할인율 disable
        sooga.menu_id_before_change = originalSoogaData.id; // 변경 되기 전의 수가표 아이디
        sooga.menu_info_before_change = originalSoogaData; // 변경 되기 전의 수가표
      }
    });
    // 선택된 수가표 변경
    store.commit('changeSelectedSoogaData', selectedSoogaDataList);

    Swal.fire({
      icon: 'success',
      title: '업셀링 변경 완료',
      text: '업셀링 가격이 변경되었습니다.',
    });

    // sidebar 종료
    closeSidebar();
  } else {
    // 업셀링 수가표 가격이 기존 수가표 보다 작을 때
    Swal.fire({
      icon: 'warning',
      title: '업셀링 가격 오류',
      text: '업셀링 가격이 기존 가격보다 낮습니다.',
    });
  }
}
</script>

<style lang="scss" scoped>
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
}
</style>
