<template>
  <div class="relative">
    <Menubar :model="items"> </Menubar>
    <div class="absolute" style="top: 15px; right: 10px">
      <span>{{ topName.name }}</span
      >/<span>{{ topName.user_id }}</span>
      <i
        class="pi pi-sign-out ml-5 cursor-pointer"
        style="font-size: 1.5rem"
        @click="logout"
      ></i>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import {
  onBeforeMount,
  ref,
  getCurrentInstance,
  onBeforeUnmount,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { useIdle } from '@vueuse/core';

const { idle } = useIdle(60 * 1000);

const router = useRouter();
const toast = useToast();
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;

const store = useStore();
const topName = ref({});

const items = ref([
  {
    label: '현황판',
    icon: 'pi pi-fw pi-calendar',
    to: '/status-board',
  },
  {
    label: 'Management',
    icon: 'pi pi-fw pi-book',
    items: [
      {
        label: '수가표',
        items: [
          { label: '수가표 관리', to: '/sooga-manage' },
          { label: 'BOM 설정', to: '/bom-manage' },
        ],
      },
      {
        label: '원장',
        items: [{ label: '원장 관리', to: '/doctor-manage' }],
      },
      {
        label: '원재료',
        items: [
          { label: '원재료 관리', to: '/purchase/manage' },
          // { label: '구매팀 엑셀', to: '/purchase/excel' },
        ],
      },
      // {
      //   label: '엑셀 업로드',
      //   items: [
      //     { label: '예약 데이터', to: '/excel/reserve' },
      //     { label: '마케팅 데이터', to: '/excel/marketing' },
      //   ],
      // },
    ],
  },
  {
    label: 'settings',
    icon: 'pi pi-fw pi-cog',
    items: [
      {
        label: '내 정보',
        items: [{ label: '내 정보 관리', to: '/my-page' }],
      },
      {
        label: '관리자',
        items: [{ label: '권한 관리', to: '/auth-manage' }],
      },
    ],
  },
]);

onBeforeMount(async () => {
  // 전체 setTimeout을 topnav에서 실행
  store.dispatch('wholeTimeOut');

  try {
    const result = await axios.get(store.state.URL + '/top-nav-name');
    topName.value = result.data.result.nameTag[0];
    store.commit('setUserData', result.data.result);
  } catch (error) {
    $errorHandling(toast, error, clearFunc);
  }
});

onBeforeUnmount(() => {
  // topnav가 사라지면 clear timeout
  store.commit('clearTimeout');
});

function clearFunc() {}

async function logout() {
  await axios.get(store.state.URL + '/logout');
  router.push({ name: 'first' });
}

watchEffect(() => {
  if (idle.value) {
    store.commit('clearMoment', false);
  } else {
    store.commit('clearMoment', true);
  }
});
</script>

<style></style>
