<template>
  <div
    class="surface-card p-3 border-cyan-500 hover:border-cyan-700 border-round surface-overlay font-bold border-3 w-25rem"
  >
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">리팅성형외과 CRM</div>
    </div>

    <div>
      <form @submit.prevent="handleLogin(!v$.$invalid)" method="POST">
        <div class="p-float-label mb-4">
          <InputText
            id="login-email"
            type="text"
            class="w-full"
            :class="{ 'p-invalid': v$.email.$invalid && isSummitted }"
            v-model="v$.email.$model"
          />
          <label
            for="login-email"
            :class="{ 'p-error': v$.email.$invalid && isSummitted }"
            >Email*</label
          >
        </div>
        <div class="p-float-label mb-3">
          <InputText
            id="login-pass"
            type="password"
            class="w-full"
            :class="{ 'p-invalid': v$.password.$invalid && isSummitted }"
            v-model="v$.password.$model"
          />
          <label
            for="login-pass"
            :class="{ 'p-error': v$.password.$invalid && isSummitted }"
            >비밀번호*</label
          >
        </div>

        <div class="flex align-items-center justify-content-between mb-4">
          <div class="flex align-items-center">
            <Checkbox
              inputId="rememberme1"
              :binary="true"
              v-model="checked"
              class="mr-2"
            ></Checkbox>
            <label for="rememberme1">아이디 저장</label>
          </div>
          <div class="flex">
            <p
              class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
              @click="store.commit('generateModal', true)"
            >
              계정생성
            </p>
            <p
              class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
              @click="store.commit('findMyIdModal')"
            >
              계정찾기
            </p>
          </div>
        </div>
        <Button
          label="Sign In"
          icon="pi pi-user"
          class="w-full h-3rem"
          type="submit"
          @click="loginSubmit"
        ></Button>
      </form>
      <!-- <a
        class="border-300 surface-overlay border-1 border-round h-3rem mt-2 block flex align-items-center cursor-pointer no-underline"
        @click="googleLogin"
      >
        <img src="../../assets/google.svg" alt="google" class="h-3rem" />
        <div
          class="w-full bg-primary h-3rem flex justify-content-center align-items-center"
        >
          <div>구글 로그인</div>
        </div>
      </a> -->
    </div>
  </div>
</template>

<script setup>
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { ref, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';

const state = ref({
  email: '',
  password: '',
});
const rules = ref({
  email: { email, required },
  password: { required },
});
const v$ = useVuelidate(rules, state);
const $cookies = inject('$cookies');
const router = useRouter();
const store = useStore();

const checked = ref(false);
const isSummitted = ref(false);

onMounted(() => {
  state.value.email = $cookies.isKey('litingcrmid') ? $cookies.get('litingcrmid') : null;
  checked.value = $cookies.isKey('litingcrmid');
});

// function googleLogin() {
//   const googleURL = store.state.URL + '/auth/google';

//   window.location.href = googleURL;
// }

function handleLogin(isFormValid) {
  isSummitted.value = true;

  if (!isFormValid) {
    return;
  }
}

function emailLogin() {
  if (checked.value) {
    $cookies.set('litingcrmid', state.value.email, '1y', '', '', true, 'Secure');
  } else {
    $cookies.remove('litingcrmid');
  }
}

const loginSubmit = _.debounce(async () => {
  const sendData = {
    email: state.value.email,
    pass: state.value.password,
  };

  if (!v$.$invalid) {
    store.commit('loadingChangeValue', true);

    const result = await axios.post(store.state.URL + '/login-verify', sendData);

    if (!result.data.idis) {
      Swal.fire('해당되는 이메일이 없습니다.', '', 'warning');
    } else if (!result.data.active) {
      Swal.fire('비활성화된 계정입니다.', '', 'error');
    } else if (!result.data.mailis) {
      Swal.fire('메일이 인증되지 않았습니다.', '', 'error');
    } else if (!result.data.passis) {
      Swal.fire('비밀번호가 맞지 않습니다.', '', 'warning');
    } else {
      Swal.fire('로그인 되었습니다.', '', 'success').then(() => {
        emailLogin();
        router.push('status-board');
      });
    }
    store.commit('loadingChangeValue', false);
  }
}, 250);
</script>

<script>
export default {
  name: 'LoginPage',
};
</script>

<style scoped></style>
