<template>
  <div>
    <Editor
      v-model="counselingMemo.memo"
      editorStyle="height: 192px"
      @text-change="
        memoStateChange($event);
        counselingMemoChange($event);
      "
      @selection-change="memoSelection($event)"
      v-if="!memoEditState"
    >
      <template v-slot:toolbar>
        <span class="mb-2">상담메모</span>
      </template>
    </Editor>
    <div v-else>
      <Editor v-model="counselingMemo.memo" editorStyle="height: 192px;" :readonly="true">
        <template v-slot:toolbar>
          <span class="ql-formats">
            <div class="relative">
              <div>상담메모</div>
              <Message
                class="absolute"
                severity="info"
                :closable="false"
                style="
                  top: -21px;
                  right: -178px;
                  width: 172px;
                  height: 31px;
                  display: flex;
                "
                >{{ counselingMemo.name }} 편집 중</Message
              >
            </div>
          </span>
        </template>
      </Editor>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref, watchEffect } from 'vue';
import { useToast } from 'primevue/usetoast';
import _ from 'lodash';
import { useIdle } from '@vueuse/core';
import axios from 'axios';

const { idle } = useIdle(1000 * 2);
const memoAsyncState = ref(true);
const memoEditState = ref(false);
const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
const counselingMemo = computed({
  get: () => {
    return store.state.counselingStore.counselingMemo;
  },
  set: (value) => {
    store.commit('setCounselingMemo', value);
  },
});

watchEffect(() => {
  const memo = store.state.counselingStore.counselingMemoCompare;

  let memoValue;
  if (memo) {
    memoValue = !_.isEqual(memo.data, [])
      ? memo.data[0]
      : {
          memo: null,
          customer_id: null,
          editing: null,
        };
  } else {
    memoValue = {
      memo: null,
      customer_id: null,
      editing: null,
    };
  }

  store.commit('setCounselingMemo', memoValue);
});

watchEffect(() => {
  memoEditState.value =
    store.state.loginUserData.user_id === counselingMemo.value.user_id
      ? false
      : counselingMemo.value.editing;
});

const counselingMemoChange = _.debounce(async (e) => {
  const memoValue = e.htmlValue;
  memoAsyncState.value = true;

  // memo data upsert 함
  await store.dispatch('memoUpsert', {
    memoValue: memoValue,
    classification: '상담',
    commitName: 'setCounselingMemoCheck',
  });

  // memo axios 통신 후 잘 저장됐는지 check
  const counselingMemoCheck = storeGetters.value.getCounselingMemoCheck;

  if (!counselingMemoCheck?.is) {
    toast.add({
      severity: 'error',
      summary: '메모가 저장에러',
      detail: '메모가 저장되지 않았습니다.',
      life: 3000,
    });
  }
}, 1000);

async function memoStateChange() {
  if (memoAsyncState.value) {
    memoAsyncState.value = false;
    const customerCode = store.state.board.boardModal.boardSendCutomerCode;

    await axios.post(store.state.URL + '/registration-modal/memo-state', {
      state: true,
      customerCode,
      classification: '상담',
    });
  }
}

function memoSelection(e) {
  // eslint-disable-next-line
  let stayMemo = setTimeout(function tick() {
    if (idle.value) {
      e.instance.root.blur();
    } else {
      stayMemo = setTimeout(tick, 1000);
    }
  }, 1000);
}
</script>

<style></style>
