<template>
  <div>
    <Dialog
      v-model:visible="store.state.bomManageGetdata.bomDeleteState"
      :style="{ width: '450px' }"
      header="삭제확인"
      :modal="true"
      @hide="closeDeleteModal"
    >
      <div class="confirmation-content flex flex-wrap align-items-center">
        <i class="pi text-red-600 pi-exclamation-triangle mr-2" style="font-size: 2rem" />
        <span class="text-lg text-red-600"><b>기존에 등록된 상품입니다.</b></span>
        <span class="vertical-align-text-bottom">
          <b> {{ deleteDataContents().zero }} </b>
          품목을 삭제하시겠습니까?
        </span>
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDeleteModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Swal from 'sweetalert2';
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const deleteDatas = ref();

watchEffect(() => {
  if (store.state.bomManageGetdata.bomDeleteState) {
    deleteDatas.value = store.state.bomManageGetdata.bomDeleteData;
  }
});

// 삭제할 데이터 선택할 경우 개수 확인해주는 함수
function deleteDataContents() {
  return {
    count:
      _.reduce(
        deleteDatas.value,
        (a, b) => {
          return (a += 1);
        },
        0
      ) - 1,
    zero: deleteDatas.value.product_name,
  };
}

// 삭제확인 모달 닫을 시, 이벤트
function closeDeleteModal() {
  store.commit('bomDeleteStateCommit', false);
}

// 삭제 진행 시, 이벤트
function deleteExecution() {
  if (store.state.bomManageGetdata.bomDeleteCategory === 'edit') {
    store.dispatch('editRemoveOldProduct', { data: deleteDatas.value });
  } else if (store.state.bomManageGetdata.bomDeleteCategory === 'confirm') {
    store.dispatch('confirmRemoveOldProduct', { data: deleteDatas.value });
  } else {
    Swal.fire({
      icon: 'warning',
      title: '에러',
      text: '예상치 못한 에러가 발생하였습니다.',
    });
  }
}
</script>
<script>
export default {
  name: 'BomDeleteDialog',
};
</script>

<style></style>
