import axios from 'axios';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { errorHandling } from '../../util/errorHandler';

const paymentStore = {
  state() {
    return {
      paymentCompare: '',
      paymentInitTicketingData: '',
      paymentInitWaitingData: '',
      paymentInitPaymentData: '',
      paymentInitWaitingHeadData: '',
      paymentInitPaymentHeadData: '',
      paymentError: '',
      selectedPaymentValue: '',
      selectedPaymentSubValue: '',
      paymentDialogState: false,
      paymentWaitingActiveIndex: null,
      paymentPaymentActiveIndex: null,
      incentiveDialogState: false,
      incentiveData: '',
      memberData: '',
      incentiveUpdateState: false,
      incentiveDeleteState: false,
      incentiveDeleteId: '',
      isConnectedToCat: false,
      changeData: '',
      productState: false,
      paymentProductInitData: [],
    };
  },
  mutations: {
    paymentResetCommit(state) {
      state.paymentInitTicketingData = '';
      state.paymentInitWaitingData = '';
      state.paymentInitPaymentData = '';
      state.paymentInitPaymentHeadData = '';
      state.paymentInitWaitingHeadData = '';
    },
    paymentInitCommit(state, payload) {
      state.paymentInitTicketingData = payload.ticketing;
      state.paymentInitWaitingData = payload.waiting;
      state.paymentInitPaymentData = payload.payment;
    },
    paymentErrorCommit(state, payload) {
      state.paymentError = payload;
    },
    selectdPaymentValueCommit(state, payload) {
      state.selectedPaymentValue = payload;
    },
    paymentDialogStateCommit(state, payload) {
      state.paymentDialogState = payload;
    },
    selectdPaymentSubValueCommit(state, payload) {
      state.selectedPaymentSubValue = payload;
    },
    paymentIndexActiveInit(state) {
      state.paymentWaitingActiveIndex = null;
      state.paymentPaymentActiveIndex = null;
    },
    incentiveDialogStateCommit(state, payload) {
      state.incentiveDialogState = payload;
    },
    incentiveDataCommit(state, payload) {
      state.incentiveData = payload;
    },
    memberDataCommit(state, payload) {
      state.memberData = payload;
    },
    incentiveUpdateStateCommit(state, payload) {
      state.incentiveUpdateState = payload;
    },
    incentiveDeleteStateCommit(state, payload) {
      state.incentiveDeleteState = payload;
    },
    incentiveDeleteIdCommit(state, payload) {
      state.incentiveDeleteId = payload;
    },
    isConnectedToCatCommit(state, payload) {
      state.isConnectedToCat = payload;
    },
    paymentChangeDataCommit(state, payload) {
      state.changeData = payload;
    },
    productStateCommit(state, payload) {
      state.productState = payload;
    },
  },
  actions: {
    async paymentWaiting({ rootState, commit }, payload) {
      const URL = rootState.URL;
      const customerCode = rootState.board.boardModal.boardSendCutomerCode;

      try {
        commit('loadingChangeValue', true);
        await axios.post(URL + '/registration-modal/post_payment_waiting', {
          customerCode,
          ...payload,
        });
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async paymentWaitingDelete({ rootState, commit }, payload) {
      const URL = rootState.URL;

      try {
        commit('loadingChangeValue', true);
        await axios.delete(URL + '/registration-modal/delete_payment_waiting', {
          data: payload,
        });
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async paymentExecute({ rootState, commit }, payload) {
      const URL = rootState.URL;
      const customerCode = rootState.board.boardModal.boardSendCutomerCode;

      try {
        commit('loadingChangeValue', true);
        await axios.post(URL + '/registration-modal/post_payment_execution', {
          customerCode,
          ...payload,
        });

        return true;
      } catch (error) {
        errorHandling(null, error);
        return false;
      }
    },
    async paymentRefundWaiting({ rootState, commit }, payload) {
      const URL = rootState.URL;

      try {
        commit('loadingChangeValue', true);
        await axios.put(URL + '/registration-modal/update_waiting_refund', {
          groupCode: payload,
        });
      } catch (error) {
        errorHandling(null, error);
      }
    },
    //// 실적 분배 관련
    // 실적 데이터 불러오기
    async getIncentiveInfo({ rootState, commit }, payload) {
      const URL = rootState.URL;

      try {
        const query = payload.paymentId ? `?payment_id=${payload.paymentId}` : '';
        commit('loadingChangeValue', true);
        const result = await axios.get(
          URL + `/registration-modal/get_incentive_info${query}`
        );

        await commit('incentiveDataCommit', result.data.result.incentive_data);
        await commit('memberDataCommit', result.data.result.member_data);
      } catch (error) {
        errorHandling(null, error);
      }
    },
    // // 인센티브 데이터 DB에서 불러와 초기화
    // async refreshIncentiveData({ state, dispatch }) {
    //   const index = await state.paymentPaymentActiveIndex;
    //   const paymentData = await state.paymentInitPaymentData;
    //   let paymentForIncentiveData = '';
    //   if (paymentData?.[index]) {
    //     paymentForIncentiveData = [paymentData[index]];
    //   } else {
    //     paymentForIncentiveData = [];
    //   }
    //   dispatch('getIncentiveInfo', {
    //     paymentId: paymentForIncentiveData[0].payment_id,
    //   });
    // },
    // // 기존 실적 데이터 삭제
    // async deleteIncentiveInfo({ rootState, commit, dispatch }, payload) {
    //   const URL = rootState.URL;
    //   try {
    //     const query = payload.incentiveId ? `?incentive_id=${payload.incentiveId}` : '';
    //     const result = await axios.delete(
    //       URL + `/registration-modal/delete_incentive_info${query}`
    //     );
    //     if (result.status === 200 && result.data) {
    //       Swal.fire(
    //         '삭제 성공',
    //         '실적 데이터가 성공적으로 삭제되었습니다.',
    //         'success'
    //       ).then(await dispatch('refreshIncentiveData'));
    //       commit('incentiveDeleteStateCommit', false);
    //     }
    //   } catch (error) {
    //     errorHandling(null, error);
    //   }
    // },
    // 실적 데이터 입력
    async upsertIncentiveInfo({ rootState, commit }, payload) {
      const URL = rootState.URL;
      try {
        commit('loadingChangeValue', true);
        const result = await axios.put(
          URL + `/registration-modal/upsert_incentive_info`,
          {
            data: payload,
          }
        );
        if (result.status === 200 && result.data) {
          Swal.fire(
            '입력 성공',
            '실적 데이터가 성공적으로 입력되었습니다.',
            'success'
          ).then(() => {
            commit('incentiveUpdateStateCommit', false);
            commit('incentiveDialogStateCommit', false);
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    // 날짜변경
    async paymentDateChange({ rootState, commit }, payload) {
      const URL = rootState.URL;

      try {
        commit('loadingChangeValue', true);
        const result = await axios.post(
          URL + `/registration-modal/change_creation_date`,
          {
            ...payload,
          }
        );

        if (result.data.result) {
          Swal.fire({
            icon: 'success',
            title: '날짜변경이 완료되었습니다.',
          });
        }
      } catch (err) {
        errorHandling(null, err);
      }
    },
    // 승인번호 변경
    async paymentApproveNumberChange({ rootState, commit }, payload) {
      const URL = rootState.URL;
      const customerCode = rootState.board.boardModal.boardSendCutomerCode;

      try {
        commit('loadingChangeValue', true);
        const result = await axios.post(
          URL + '/registration-modal/change_approve_number',
          {
            customerCode,
            ...payload,
          }
        );
        if (result.data.result) {
          Swal.fire({
            icon: 'success',
            title: '승인번호 변경이 완료되었습니다.',
          });
        }
      } catch (err) {
        errorHandling(null, err);
      }
    },
    async paymentMisoo({ rootState }, payload) {
      const URL = rootState.URL;

      try {
        const result = await axios.post(URL + '/registration-modal/misoo', {
          ...payload,
        });

        if (result.data.result) {
          Swal.fire({
            icon: 'info',
            title: '변경이 완료되었습니다.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '에러가 발생하였습니다. 재시도 해주세요.',
          });
        }
      } catch (err) {
        errorHandling(null, err);
      }
    },
    async paymentProductInit({ state, rootState }) {
      const URL = rootState.URL;

      try {
        const result = await axios.get(URL + '/registration-modal/payment-product-init');
        const resultData = result.data.data;
        resultData.forEach((a) => {
          a.count = 1;
          a.activeState = true;
        });
        if (result.data.result) {
          state.paymentProductInitData = resultData;
        } else {
          Swal.fire({
            icon: 'error',
            title: '에러발생',
            html: '새로구침 후 다시 시도해주세요',
          });
        }
      } catch (err) {
        errorHandling(null, err);
      }
    },
    async paymentProductSend({ rootState }, payload) {
      const URL = rootState.URL;

      try {
        const result = await axios.post(
          URL + '/registration-modal/payment-product-send',
          payload
        );

        if (!result.data.result) {
          Swal.fire({
            icon: 'error',
            title: '에러가 발생하였습니다.',
            html: '다시 시도해주세요.',
          });

          return false;
        }
        return true;
      } catch (err) {
        errorHandling(null, err);
      }
    },
    async paymentProductDelete({ rootState, commit }, payload) {
      const URL = rootState.URL;

      try {
        commit('loadingChangeValue', true);
        const result = await axios.post(
          URL + '/registration-modal/payment-product-delete',
          { id: payload }
        );

        if (result.data.result) {
          Swal.fire({
            icon: 'info',
            title: '삭제가 완료되었습니다.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '오류가 발생하였습니다.',
            html: '다시 시도해주세요.',
          });
        }
      } catch (err) {
        errorHandling(null, err);
      }
    },
  },
  getters: {
    paymentHeaderData(state) {
      const uniqData = (value) => {
        return _.uniqWith(
          _.map(value, (a) => {
            const creationTime = new Date(a.creation_timestamp);
            creationTime.setHours(creationTime.getHours() + 9);
            return {
              payment_waiting_id: a.payment_waiting_id,
              payment_id: a.payment_id,
              payment_group_id: a.payment_group_id,
              payment_approval_date: a.payment_approval_date,
              deposit_or_balance: a.deposit_or_balance,
              payment_type: a.payment_type,
              creation_timestamp: creationTime
                .toISOString()
                .replace(
                  /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/,
                  '$1-$2-$3 $4:$5:$6'
                ),
              refund_active: a.refund_active,
              payment_approval_number: a.payment_approval_number,
              buycompany: a.payment_type === '현금결제' ? a.issuecode : a.buycompany,
              pay_in_installments: a.pay_in_installments,
              active: false,
              soogi: a.soogi,
              cardnumber: a.cardnumber,
              refund_date: a.refund_date,
              barcode: a.barcode ? a.barcode : null,
              buycode: a.buycode ? a.buycode : null,
            };
          }),
          _.isEqual
        );
      };

      state.paymentInitWaitingHeadData = uniqData(state.paymentInitWaitingData);
      state.paymentInitPaymentHeadData = uniqData(state.paymentInitPaymentData);
    },

    paymentWaitingMenu(state) {
      return state.paymentInitWaitingData.map((a) => a.menu_id);
    },
  },
};

export default paymentStore;
