<template>
  <div>
    <Dialog
      v-model:visible="store.state.myPageGetData.modifySubmitted"
      :style="{ width: '450px' }"
      header="수정확인"
      :modal="true"
      @hide="closeUpdateModal"
    >
      <div class="confirmation-content flex flejx-wrap align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span class="vertical-align-text-bottom"> 수정하신 정보를 반영하시겠습니까?</span>
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeUpdateModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="updateExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
const store = useStore();

// 모달창 닫을 시, 이벤트
function closeUpdateModal() {
  store.commit('modifySubmittedCommit', false);
}

// 수정확인 버튼 누를 시, 이벤트
function updateExecution() {
  store.dispatch('modifyAccount', store.state.myPageGetData.myInfoData);
}
</script>
<script>
export default {
  name: 'MyInfoModifyDialog',
};
</script>

<style></style>
