<template>
  <div>
    <DataTable
      ref="dt"
      v-model:selection="selectedProducts"
      :value="purchaseInitData"
      :paginator="true"
      :rows="20"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      responsiveLayout="scroll"
      removableSort
    >
      <template #header>
        <div
          class="table-header flex flex-column md:flex-row md:justiify-content-between align-items-center justify-content-between"
        >
          <div class="flex flex-row">
            <h5 class="p-as-md-center">
              구매팀 제품관리 ( <span class="text-blue-700">총 갯수</span> :
              {{ purchaseInitData.length }} /
              <span class="text-red-700">BOM 조사품목 갯수</span> :
              {{ _.filter(purchaseInitData, (a) => a.byactive).length }})
            </h5>
          </div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Search..." />
          </span>
        </div>
      </template>

      <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
      <Column field="product_code" header="상품코드" :sortable="true"></Column>
      <!-- <Column field="purchase" header="구매처" :sortable="true"></Column> -->
      <Column field="hospital" header="병원명" :sortable="true"></Column>
      <Column field="product_name" header="상품명" :sortable="true"></Column>
      <Column field="nursing_abbreviation" header="간호팀약어" :sortable="true"></Column>
      <!-- <Column field="standard_name" header="규격" :sortable="true"></Column> -->
      <!-- <Column field="unit_name" header="단위" :sortable="true"></Column> -->
      <!-- <Column field="minimum" header="최소구매단위" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.minimum ? $wonCurrency(slotProps.data.minimum) : '' }}
        </template>
      </Column> -->
      <!-- <Column field="cost" header="단가" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.cost ? $wonCurrency(slotProps.data.cost) : '' }}
        </template>
      </Column> -->
      <Column field="unit_when_input" header="BOM 입력단위" :sortable="true"> </Column>
      <!-- <Column field="divider" header="나누는" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.divider ? $wonCurrency(slotProps.data.divider) : '' }}
        </template>
      </Column> -->
      <!-- <Column field="cost_when_input" header="BOM 원가" :sortable="true">
        <template #body="slotProps">
          {{
            slotProps.data.cost_when_input
              ? $wonCurrency(slotProps.data.cost_when_input)
              : ''
          }}
        </template>
      </Column> -->
      <Column field="byactive" header="사용여부" :sortable="true">
        <template #body="slotProps">
          <Checkbox
            name="byactive"
            v-model="slotProps.data.byactive"
            :binary="true"
            disabled
          />
        </template>
      </Column>
      <!-- <Column field="active" header="사용여부" :sortable="true">
        <template #body="slotProps">
          <Checkbox
            name="active"
            v-model="slotProps.data.active"
            :binary="true"
            disabled
          />
        </template>
      </Column> -->
      <Column :exportable="false">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mr-2"
            @click="editProduct(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import _ from 'lodash';
import { useStore } from 'vuex';

const store = useStore();

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const purchaseInitData = ref();
const selectedProducts = ref();
const dt = ref();

watchEffect(() => {
  purchaseInitData.value = store.state.purchaseGetdata.purchaseGetdataCompare
    ? store.state.purchaseGetdata.purchaseGetdataCompare.filter((a) => a.byactive)
    : [];
  store.commit('selectedProductsCommit', selectedProducts.value);
  dt.value && store.commit('purchaseDtCommit', dt.value);
});

const editProduct = _.debounce((data) => {
  store.commit('productDialogCommit', true);
  data.newProduct = false;
  store.commit('productSelectedCommit', data);
}, 100);
</script>
<script>
export default {
  name: 'PurchaseDataTable',
};
</script>

<style></style>
