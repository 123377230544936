import axios from 'axios';
import Swal from 'sweetalert2';

const store = require('@/store/index');

const methods = {
  //
  autoHypenPhone(str) {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
      return str;
    } else if (str.length < 7) {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3);
      return tmp;
    } else if (str.length < 11) {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3, 3);
      tmp += '-';
      tmp += str.substr(6);
      return tmp;
    } else {
      tmp += str.substr(0, 3);
      tmp += '-';
      tmp += str.substr(3, 4);
      tmp += '-';
      tmp += str.substr(7);
      return tmp;
    }
  },
  autoHypenDate(obj) {
    // @see 숫자와 하이픈(-)기호의 값만 존재하는 경우 실행
    if (obj.replace(/[0-9 -]/g, '').length == 0) {
      // @see 하이픈(-)기호를 제거한다.
      let number = obj.replace(/[^0-9]/g, '');
      let ymd = '';
      // @see 문자열의 길이에 따라 Year, Month, Day 앞에 하이픈(-)기호를 삽입한다.
      if (number.length < 5) {
        return number;
      } else if (number.length < 6) {
        ymd += number.substr(0, 4);
        ymd += '-';
        ymd += number.substr(4);
        return ymd;
      } else {
        ymd += number.substr(0, 4);
        ymd += '-';
        ymd += number.substr(4, 2);
        ymd += '-';
        ymd += number.substr(6);
        return ymd;
      }
    }
  },
  formatDate(is, sort) {
    var today = sort ? new Date(sort) : new Date();
    today.setHours(today.getHours() + 9);
    if (is === 'date') {
      // 2022-10-17
      return today
        .toISOString()
        .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/, '$1-$2-$3');
    } else if (is === 'timestamp') {
      // 2022-10-17T12:04:24.000Z
      return today
        .toISOString()
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/,
          '$1-$2-$3T$4:$5:$6.000Z'
        );
    } else if (is === 'datetime') {
      // 2022-10-17 12:04:24
      return today
        .toISOString()
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/,
          '$1-$2-$3 $4:$5:$6'
        );
    } else if (is === 'code') {
      // 20221017120424
      return today
        .toISOString()
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/,
          '$1$2$3$4$5$6'
        );
    } else if (is === 'time') {
      // 12:04
      return today
        .toISOString()
        .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.[\d]+Z/, '$4:$5');
    } else if (is === 'codedate') {
      // 20221017120424
      return today
        .toISOString()
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.([\d]+)Z/,
          '$1$2$3$4$5$6'
        );
    } else if (is == 'codemi') {
      // 496
      return today
        .toISOString()
        .replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.([\d]+)Z/, '$7');
    }
  },
  reaplcePresentURL(str) {
    const result = str.replace(/.*\/.+\?(.*)/, '$1');
    return result;
  },
  async acceesCodeVailty() {
    if (this.$cookies.isKey('access_token')) {
      const cookieData = await axios.get(this.$store.state.URL + '/social-verify');

      if (!cookieData.data.is) {
        this.$cookies.remove('access_token');
        window.location.replace('/');
      }
    } else {
      window.location.replace('/');
    }
  },
  wonCurrency(value) {
    return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '';
  },
  errorHandling(toast, error, callback) {
    const status = error.response?.status;
    // store.commit('loadingChangeValue', true);
    store.default.commit('loadingChangeValue', false);
    if (status) {
      if (status == 404) {
        Swal.fire({
          title: '404 에러: 페이지를 찾을 수 없습니다.',
          text: `개발팀에 문의해주세요. ${error.message}`,
          icon: 'error',
          timer: 3000,
        });
      } else if (status == 400) {
        Swal.fire({
          title: '400: 페이지에 이상이 있습니다.',
          text: `개발팀에 문의해주세요. ${error.message}`,
          icon: 'error',
          timer: 3000,
        });
      } else if (status == 500) {
        Swal.fire({
          title: '서버 에러 발생했습니다.',
          text: `개발팀에 문의해주세요. ${error.message}`,
          icon: 'error',
          timer: 3000,
        });
      } else if (status == 401) {
        Swal.fire('로그인을 해주세요.', error.message, 'error').then(() =>
          window.location.replace('/')
        );
      }
      // 페이지의 권한이 없을 경우
      // 경고창을 표시하고 확인을 누르면 callback을 실행한다.
      else if (status == 406) {
        Swal.fire('권한이 없습니다.', '권한을 요청해주세요.', 'error').then(() => {
          callback && callback();
        });
      } else if (status == 403) {
        Swal.fire('에러가 발생했습니다.', `${error.response.data.message}`, 'error');
      } else if (status == 409) {
        Swal.fire('에러가 발생했습니다.', `${error.response.data.message}`, 'error');
      } else {
        Swal.fire({
          title: '원인 모를 에러가 발생했습니다.',
          text: `개발팀에 문의해주세요. ${error.message}`,
          icon: 'error',
          timer: 3000,
        });
      }
    }
  },
};
export default {
  install(Vue) {
    Vue.config.globalProperties.$autoHypenPhone = methods.autoHypenPhone;
    Vue.config.globalProperties.$autoHypenDate = methods.autoHypenDate;
    Vue.config.globalProperties.$formatDate = methods.formatDate;
    Vue.config.globalProperties.$reaplcePresentURL = methods.reaplcePresentURL;
    Vue.config.globalProperties.$wonCurrency = methods.wonCurrency;
    Vue.config.globalProperties.$errorHandling = methods.errorHandling;
  },
  methods,
};
