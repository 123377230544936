const confirmBomState = {
  // BOM 확인
  confirmSurgeryToDoctor: '',
  doctorMenuConfirmData: '',
  confirmDoctorToSurgerySeen: false,
  confirmSurgeryToBomSeen: false,
  confirmSurgeryFilteredData: [],
  confirmDoctorToSurgerySelected: [],
  confirmSurgeryToBomSelected: [],
  confirmBomConfirmSelected: [],
  confirmDoctorToSurgeryUniqueMenuId: '',
  confirmDoctorToSurgery: '',
  confirmSurgeryFilterSelected: '전체',
  confirmSurgeryFilterOptions: ['전체', '등록', '미등록'],
  confirmRegistedBomCost: '',
  confirmDoctorToSurgerySelectedInfo: '',
  confirmRegistedBomBySurgery: [],
};

export default confirmBomState;
