<template>
  <div>
    <DataTable
      :value="initData"
      :paginator="true"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 30]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      responsiveLayout="scroll"
      v-model:filters="filters"
      :globalFilterFields="['middle_category', 'small_category', 'abbreviation']"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <div class="flex align-items-center">
            <div class="align-items-center">
              <h5 class="mr-4">수가표</h5>
            </div>

            <div class="align-items-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="검색" />
              </span>
            </div>
          </div>
        </div>
      </template>
      <template #empty> No customers found. </template>
      <template #loading> Loading customers data. Please wait. </template>

      <!-- 수가표 데이터 -->
      <Column field="abbreviation">
        <template #header>
          <div class="flex-1 text-center">상품명</div>
        </template>
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.abbreviation }}
          </div>
        </template>
      </Column>
      <Column field="medical_menu_content">
        <template #header>
          <div class="flex-1 text-center">내용</div>
        </template>
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.medical_menu_content }}
          </div>
        </template>
      </Column>
      <Column field="note">
        <template #header>
          <div class="flex-1 text-center">비고</div>
        </template>
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.note }}
          </div>
        </template>
      </Column>
      <Column field="price_include_vat">
        <template #header>
          <div class="flex-1 text-center">판매가</div>
        </template>
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ $wonCurrency(data.price_include_vat) }}
          </div>
        </template>
      </Column>
      <Column style="width: 50px">
        <template #header>
          <div class="flex-1 text-center">수량</div>
        </template>
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <InputNumber
              inputId="stacked"
              v-model="data.count"
              showButtons
              style="margin-right: 20px"
            />
          </div>
        </template>
      </Column>
      <Column style="width: 10px">
        <template #header>
          <div class="flex-1 text-center">선택</div>
        </template>
        <template #body="{ data }">
          <div class="flex justify-content-center" v-if="data.activeState">
            <Button
              label=""
              class="p-button-rounded p-button-info p-button-sm pi pi-check ckeck-button"
              @click="checkClick(data)"
            ></Button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, onUnmounted, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useStore } from 'vuex';
import _ from 'lodash';
import Swal from 'sweetalert2';

const store = useStore();
const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const getter = computed(() => store.getters);

const initData = ref([]);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(() => {
  initData.value = store.state.paymentStore.paymentProductInitData;
});

onUnmounted(() => {
  initData.value.forEach((a) => {
    a.activeState = true;
    a.count = 1;
  });
});

const checkClick = _.debounce(checkClickFnc, 200);
async function checkClickFnc(data) {
  data.activeState = false;
  const sendData = {
    menu_id: data.id,
    sales_date: store.state.board.boardModal.boardSendDate,
    sales_price: data.price_include_vat * data.count,
    menu_count: data.count,
    customer_id: store.state.board.boardModal.boardSendCutomerCode,
  };

  const ticektingData = store.state.paymentStore.paymentInitTicketingData;
  const existTicektingData = ticektingData.filter((a) => a.menu_id === data.id);
  const waitingMenuData = getter.value.paymentWaitingMenu;
  const existWaitingData = waitingMenuData.includes(data.id);

  if (existTicektingData.length > 0 || existWaitingData) {
    Swal.fire({
      icon: 'info',
      title: `${
        existTicektingData.length > 0 && existWaitingData
          ? '티케팅내역과 대기내역에 모두 존재합니다.'
          : existTicektingData.length > 0
          ? '티케팅내역에 이미 존재합니다.'
          : '대기내역에 이미 존재합니다.'
      }`,
      html: `${
        existWaitingData
          ? `1.대기내역에서 <strong class="text-cyan-600">${data.abbreviation}</strong>을 삭제하고<br>2.티케팅내역에서 <strong class="text-cyan-600">${data.abbreviation}</strong>을 한번 더 삭제한 뒤<br>다시 추가해주세요.`
          : `티케팅내역에서 <strong class="text-cyan-600">${data.abbreviation}</strong>을 삭제하고 다시 추가해주세요.`
      }`,
    });
  } else {
    const result = await store.dispatch('paymentProductSend', sendData);
    if (!result) {
      data.activeState = true;
    }
  }
}
</script>

<script>
export default {
  name: 'TicketingProductDatatable',
};
</script>

<style lang="scss" scoped>
.ckeck-button {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  background-color: darkblue !important;
}
::v-deep(.p-inputnumber-input) {
  width: 50px;
  height: 10px;
  font-size: 12px;
}
::v-deep(.p-inputnumber-button-group) {
  width: 0;
}
::v-deep(.p-inputnumber-button) {
  height: 5px;
  width: 20px !important;
}
::v-deep(.p-button-label) {
  width: 0;
  height: 0;
}
</style>
