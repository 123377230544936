<template>
  <div class="col-8">
    <div class="pb-2 mb-3 border-bottom-1 border-400">
      <div class="flex flex-wrap justify-content-between align-contents-center">
        <span class="text-2xl flex justify-content-between">
          <div style="min-width: 80px">상담</div>
          <div>
            <div class="p-inputgroup" style="height: fit-content">
              <span class="p-inputgroup-addon">
                <Checkbox v-model="isDoctor" :binary="true" />
              </span>
              <Dropdown
                v-model="selectedDoctor"
                :options="store.state.counselingAndTreatStore.doctors"
                optionLabel="doc_nm"
                optionValue="member_id"
                placeholder="의사 상담시 선택"
                :disabled="!store.state.counselingAndTreatStore.isDoctor"
              />
            </div>
          </div>
        </span>
        <div class="flex justify-content-between">
          <div
            v-if="store.state.counselingStore.counselingId !== null"
            class="flex align-contents-center"
          >
            <div class="mr-2">
              <Button class="p-button-sm" @click="createCounselingMemo">
                상담 내용 복사</Button
              >
            </div>
            <div>
              <Button class="p-button-sm p-button-danger" @click="couselingDelete">
                상담 삭제</Button
              >
            </div>
          </div>
          <Button
            icon="pi pi-arrow-left"
            class="p-button-rounded p-button-text"
            @click="store.commit('setCounselingModalShow', false)"
          ></Button>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-6">
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon" :class="componentWidth">티케팅 성공</span>
          <div
            class="flex align-contents-center p-inputgroup border-1 border-solid border-300 border-round-right justify-content-around"
          >
            <div
              v-for="ticketing of ticketings"
              :key="ticketing.key"
              class="flex field-radiobutton ml-2 h-full"
            >
              <RadioButton
                :inputId="ticketing.key"
                :value="ticketing.value"
                v-model="selectedTicketing"
                :disabled="isTicketingDisabled"
              />
              <label :for="ticketing.key">{{ ticketing.name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon" :class="componentWidth">환자구분</span>
          <div
            class="flex align-contents-center p-inputgroup border-1 border-solid border-300 border-round-right justify-content-around"
          >
            <div
              v-for="patientState of patientStates"
              :key="patientState.key"
              class="flex field-radiobutton ml-2 h-full"
            >
              <RadioButton
                :inputId="patientState.key"
                :value="patientState.value"
                name="category"
                v-model="selectedPatientState"
              />

              <label :for="patientState.key">{{ patientState.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-6">
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon" :class="componentWidth">티케팅 시수술</span>
          <div
            class="flex align-contents-center p-inputgroup border-1 border-solid border-300 border-round-right justify-content-around"
          >
            <div
              v-for="surgery of ticketingSurgeries"
              :key="surgery.key"
              class="flex field-checkbox ml-2 h-full"
            >
              <Checkbox
                :inputId="surgery.key"
                name="surgery"
                :value="surgery.name"
                v-model="selectedTicketingSurgeries"
              />
              <label :for="surgery.key">{{ surgery.name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon" :class="componentWidth">티케팅 절차</span>
          <div
            class="flex align-contents-center p-inputgroup border-1 border-solid border-300 border-round-right justify-content-around"
          >
            <div
              v-for="proc of ticketingProcess"
              :key="proc.key"
              class="flex field-radiobutton ml-2 h-full"
            >
              <RadioButton
                :inputId="proc.key"
                name="category"
                :value="proc.value"
                v-model="selectedTicketingProcess"
              />
              <label :for="proc.key">{{ proc.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-6">
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon" :class="componentWidth"> 시수술 예상날짜</span>
          <Calendar
            class="w-full border-noround mr-2"
            :showButtonBar="true"
            :showIcon="true"
            dateFormat="yy-mm-dd"
            v-model="expectedDateOfSurgery"
          >
          </Calendar>
          <Button
            style="min-width: 4rem"
            icon="pi pi-check"
            class="border-round p-button-outlined p-button-success font-semiboard mr-2"
            @click="setExpectedDateOfSurgeryToday($event)"
          >
            당수
          </Button>
          <Button
            style="min-width: 4rem"
            icon="pi pi-check"
            class="border-round p-button-outlined p-button-primary font-semiboard"
            @click="setExpectedDateOfSurgeryToday($event)"
          >
            미정
          </Button>
        </div>
      </div>
      <div class="col-6">
        <Dropdown
          class="w-full"
          id="counsel-process"
          v-model="selectedExpectProcess"
          :options="expectProcess"
          optionLabel="name"
          optionValue="name"
          placeholder="예상절차 선택"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { ref, computed, watchEffect, onBeforeUnmount, getCurrentInstance } from 'vue';
import _ from 'lodash';
import omniList from '../OmniList';
import Swal from 'sweetalert2';

const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
// const $formatDate = app.appContext.config.globalProperties.$formatDate;

// component 길이
const componentWidth = 'w-6';

let counselingDataForCompare = ['render']; // 비교를 위한 변수, 첫 렌더링을 위해 값 주입

const counselingData = ref(null);
const receptionId = ref(null);
const receptionData = ref(null);
const isTicketingDisabled = ref(false);

onBeforeUnmount(() => {
  isTicketingDisabled.value = false;
  store.commit('initSelectedCounselingData'); // 상담 데이터 초기화
});

// 선택된 예상절차
const selectedExpectProcess = computed({
  get: () => {
    return store.state.counselingStore.counselingData.selectedExpectProcess;
  },
  set: (value) => {
    store.commit('setSelectedCounselingData', {
      key: 'selectedExpectProcess',
      payloadData: value,
    });
  },
});
// 선택된 티케팅 여부 => 티케팅 성공
const selectedTicketing = computed({
  get: () => {
    return store.state.counselingStore.counselingData.selectedTicketing;
  },
  set: (value) => {
    store.commit('setSelectedCounselingData', {
      key: 'selectedTicketing',
      payloadData: value,
    });
  },
});
// 선택된 티케팅 신환, 구환 => 환자구분
const selectedPatientState = computed({
  get: () => {
    return store.state.counselingStore.counselingData.selectedPatientState;
  },
  set: (value) => {
    store.commit('setSelectedCounselingData', {
      key: 'selectedPatientState',
      payloadData: value,
    });
  },
});
// 선택된 티케팅 시수술
const selectedTicketingSurgeries = computed({
  get: () => {
    return store.state.counselingStore.counselingData.selectedTicketingSurgeries;
  },
  set: (value) => {
    store.commit('setSelectedCounselingData', {
      key: 'selectedTicketingSurgeries',
      payloadData: value,
    });
  },
});
// 선택된 티케팅 절차
const selectedTicketingProcess = computed({
  get: () => {
    return store.state.counselingStore.counselingData.selectedTicketingProcess;
  },
  set: (value) => {
    store.commit('setSelectedCounselingData', {
      key: 'selectedTicketingProcess',
      payloadData: value,
    });
  },
});
// 선택된 시수술 예상날짜
const expectedDateOfSurgery = computed({
  get: () => {
    return store.state.counselingStore.counselingData.expectedDateOfSurgery;
  },
  set: (value) => {
    store.commit('setSelectedCounselingData', {
      key: 'expectedDateOfSurgery',
      payloadData: value,
    });
  },
});

// 의사 상담 여부 checkbox
const isDoctor = computed({
  get: () => {
    return store.state.counselingAndTreatStore.isDoctor;
  },
  set: (value) => {
    store.commit('setIsDoctor', value);
  },
});

// 선택한 원장
const selectedDoctor = computed({
  get: () => {
    return store.state.counselingAndTreatStore.selectedDoctor;
  },
  set: (value) => {
    store.commit('setSelectedDoctor', value);
  },
});

watchEffect(() => {
  // 접수 아이디
  receptionId.value = storeGetters.value.getBoardModalReceptionId;

  // axios로 가져온 해당 고객의 모든 접수, 상담 데이터 => store 에 저장된 변수
  const allCounselingAndReceptionData = store.state.counselingStore.counselingCompare;
  // 접수 아이디에 해당되는 상담 정보 get
  const counselingDataByReception = getCounselingDataByReception(
    allCounselingAndReceptionData
  );

  // 데이터 변경 체크
  const oldValue = counselingDataForCompare;
  const newValue = counselingDataByReception[0]; // [ {...} ] => {...}

  if (!_.isEqual({ ...oldValue }, { ...newValue })) {
    counselingData.value = _.cloneDeep(newValue);
    counselingDataForCompare = newValue;

    // render counseling Modal, 상담 모달 렌더링
    if (!_.isEqual(counselingDataByReception, [])) {
      // 상담 데이터가 있을 경우
      renderWhenHasCounselingData(counselingData.value);
    } else {
      // 상담 데이터가 없을 경우
      renderWhenHasNoCounselingData();
    }
  }
});

/**
 * 상담 모달에서 axios 로 받은 데이터를 처리
 *
 * @param {data: [], reception: []} allCounselingAndReceptionData data는 상담데이터, reception은 접수 데이터
 * @returns {array} 접수 아이디에 해당되는 상담 데이터
 */
function getCounselingDataByReception(allCounselingAndReceptionData) {
  // 해당 고객의 모든 접수 데이터
  const allReceptionData = allCounselingAndReceptionData.reception;

  // 접수 아이디에 해당되는 접수 정보 get
  receptionData.value = allReceptionData.filter((element) => {
    element.receptionCount = allReceptionData.length; // 접수 횟수를 통해 신환, 구환 파악
    return element.reception_id === receptionId.value;
  });
  // 해당 고객의 모든 상담 데이터
  const allCounselingData = allCounselingAndReceptionData.data;

  // 상담 일자 처리
  paintCounselingDates(allCounselingData);

  // 접수 아이디에 해당되는 상담 정보 return
  return allCounselingData.filter((element) => {
    return element.reception_id === receptionId.value;
  });
}

function paintCounselingDates(counselingDataByReception) {
  // 상담일자만 get
  const counselingDates = counselingDataByReception.map(({ counseling_date }) => {
    return counseling_date;
  });
  // 상담일자가 있으면 파란색으로 표시
  store.commit('boardSpecialDay2', counselingDates);
}

/**
 * 상담 데이터가 있을 경우, 렌더링
 *
 * @param {object} data counselingData.value, 접수 아이디에 해당되는 상담 데이터
 */
function renderWhenHasCounselingData(data) {
  // 상담 아이디 set
  store.commit('setCounselingId', data.id);

  // counseling data 입력
  store.commit('setAllSelectedCounselingData', {
    selectedExpectProcess: receptionData.value[0].after_process,
    selectedTicketing: data.is_ticketing,
    selectedPatientState: data.patient_state,
    ticketing_petite: data.ticketing_petite,
    ticketing_mini: data.ticketing_mini,
    ticketing_surgery: data.ticketing_surgery,
    selectedTicketingProcess: data.ticketing_process,
    expectedDateOfSurgery:
      data.reservation_surgery_date === null
        ? ''
        : new Date(data.reservation_surgery_date),
  });

  // data.soogaDataList가 있으면 원본 그대로, 아니면 []
  const soogaDataList = data.soogaDataList ?? [];

  // 선택된 수가표
  store.commit('selectedSoogaListInit');
  soogaDataList.forEach(async (soogaData) => {
    await store.dispatch('getSoogaSurgeryPaymentsChecking', {
      counselingId: data.id,
      menuId: soogaData.id,
      isCounseling: true,
    });
    const soogaDataPaymentChecking = storeGetters.value.checkPayment;

    // 해당 counseling id와 menu_id가 결제 내역에 있거나
    // menu_id_before_change에 값이 있으면 이미 결제 한 수가표로 판별
    const isPaid =
      soogaDataPaymentChecking.paid || soogaData.menu_id_before_change !== null;
    if (isPaid) {
      isTicketingDisabled.value = true; // 결제했으므로 티케팅 성공 여부 disable
    }

    if (soogaData.menu_id_before_change) {
      soogaData.menu_info_before_change = storeGetters.value.getSoogaInfo(
        soogaData.menu_id_before_change
      );
    } else {
      soogaData.menu_info_before_change = null;
    }

    soogaData.is_selected = true;
    soogaData.is_selected_design = true;
    soogaData.sales_price_is_disabled = true;
    soogaData.discount_rate_is_disabled = true;
    soogaData.is_paid = isPaid;
    soogaData.is_modified = false;

    store.commit('pushSelectedSoogaData', soogaData);
  });
}

/**
 * 상담 데이터가 없을 경우, 렌더링
 */
function renderWhenHasNoCounselingData() {
  // 상담 아이디 set
  store.commit('setCounselingId', null);

  // counseling data 초기화
  store.commit('initSelectedCounselingData');

  // 예상절차 값 입력
  if (receptionData.value.length > 0) {
    store.commit('setSelectedCounselingData', {
      key: 'selectedExpectProcess',
      payloadData: receptionData.value[0].after_process,
    });
  } else {
    // 접수 안했을 경우 예외처리
    store.commit('setSelectedCounselingData', {
      key: 'selectedExpectProcess',
      payloadData: null,
    });
  }

  // 환자 구분 값 입력
  if (receptionData.value[0].receptionCount > 1) {
    // 구환
    store.commit('setSelectedCounselingData', {
      key: 'selectedPatientState',
      payloadData: true,
    });
  } else {
    // 신환
    store.commit('setSelectedCounselingData', {
      key: 'selectedPatientState',
      payloadData: false,
    });
  }

  // 티케팅 예상절차 값 입력
  if (receptionData.value.length > 0) {
    const receptionReason = receptionData.value[0].reception_reason;

    if (receptionReason.includes('상담')) {
      store.commit('setSelectedCounselingData', {
        key: 'selectedTicketingProcess',
        payloadData: '상담',
      });
    } else {
      store.commit('setSelectedCounselingData', {
        key: 'selectedTicketingProcess',
        payloadData: '경과',
      });
    }
  }

  // 선택된 수가표 초기화
  store.commit('selectedSoogaListInit');
}

// 예상절차
const expectProcess = ref(omniList.afterProcess);

// 티케팅 여부
const ticketings = ref([
  { name: '성공', key: 'ticketingSuccess', value: 1 },
  { name: '실패', key: 'ticketingFailed', value: 0 },
  { name: '해당없음', key: 'ticketingWhatever', value: 2 },
]);

// 티케팅 신환, 구환
const patientStates = ref([
  { name: '신환', key: 'newPatient', value: false },
  { name: '구환', key: 'oldPatient', value: true },
]);

// 티케팅 시수술
const ticketingSurgeries = ref([
  { name: '쁘띠', key: 'petite' },
  { name: '미니', key: 'mini' },
  { name: '수술', key: 'surgery' },
]);

// 티케팅 절차
const ticketingProcess = ref([
  { name: '상담', key: 'newPatient', value: '상담' },
  { name: '경과', key: 'oldPatient', value: '경과' },
]);

const createCounselingMemo = _.debounce(async () => {
  // 접수 사유 가져오기
  // const receptionData = await store.dispatch('getReceptionData');
  // const reservationReason = receptionData.data.data[0].reception_reason;

  // 클립보드에 복사할 내용 soogaList 만들기
  const soogaSurgeries = storeGetters.value.getSelectedSoogaList;
  const pasteSoogaSurgeries = soogaSurgeries.map((soogaSurgery) => {
    return `${soogaSurgery.small_category} | ${soogaSurgery.abbreviation}(${
      soogaSurgery.menu_count
    }) // ${soogaSurgery.discount_rate}% // ${
      soogaSurgery.discount_name
    } // ${$wonCurrency(soogaSurgery.sales_price)}\n`;
    // return `${soogaSurgery.large_category} ▶ ${soogaSurgery.middle_category} ▶ ${soogaSurgery.small_category} § ${soogaSurgery.abbreviation} § ${soogaSurgery.medical_menu_content} § ${soogaSurgery.note} § ${soogaSurgery.price} § ${soogaSurgery.event_price} § ${soogaSurgery.price_include_vat} § ${soogaSurgery.menu_count} §${soogaSurgery.sales_price} § ${soogaSurgery.discount_rate} § ${soogaSurgery.discount_name} \n\n`;
  });

  // const reservationSurgeryDate =
  //   store.state.counselingStore.counselingData.expectedDateOfSurgery;
  // const expectProcess = store.state.counselingStore.counselingData.selectedExpectProcess;
  // const ticketing = store.state.counselingStore.counselingData.selectedTicketing;
  // const patientState = store.state.counselingStore.counselingData.selectedPatientState;
  // const ticketingSurgery =
  //   store.state.counselingStore.counselingData.selectedTicketingSurgeries;
  // const ticketingProcess =
  //   store.state.counselingStore.counselingData.selectedTicketingProcess;

  // let counselPasteData = `●●●
  //   예약사유 : ${reservationReason}
  //   시수술예상날짜 : ${ reservationSurgeryDate === '' ? '미정' : $formatDate('date', reservationSurgeryDate) }
  //   예상절차: ${expectProcess}
  //   티케팅여부: ${ticketing === 0 ? '실패' : ticketing === 1 ? '성공' : '해당없음'}
  //   환자구분: ${patientState ? '구환' : '신환'}
  //   티케팅시수술: ${ticketingSurgery}
  //   티케팅절차: ${ticketingProcess}
  //   티케팅내역: ${pasteSoogaSurgeries}`;

  let counselPasteData = `티케팅내역:
  ${pasteSoogaSurgeries}`;

  // 클립보드 복사
  navigator.clipboard.writeText(counselPasteData);

  toast.add({
    severity: 'success',
    summary: '상담 내용 복사 성공',
    detail: '상담 내용을 클립보드에 복사했습니다.',
    life: 3000,
  });
});

const couselingDelete = _.debounce(() => {
  Swal.fire({
    title: '확인 바랍니다.',
    text: '상담내역을 삭제하시겠습니까?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: '네!',
    cancelButtonText: '아니오',
  }).then(async (result) => {
    if (result.isConfirmed) {
      let isPaid = false;
      storeGetters.value.getSelectedSoogaList.forEach((soogaData) => {
        isPaid = isPaid || soogaData.is_paid;
      });
      if (isPaid) {
        Swal.fire({
          icon: 'warning',
          title: '상담 내역에 결제한 것이 있습니다.',
          text: '환불 후 수정해주세요.',
        });
      } else {
        const { isChecked, writer, counselerIsDoctor } =
          storeGetters.value.getDoctorCheckboxChecked;

        if (!isChecked) {
          // fasle 일 경우 save 멈춤
          toast.add({
            severity: 'warn',
            summary: '의사 선택',
            detail: '의사 선생님을 입력해주세요.',
            life: 3000,
          });
          return -1;
        }
        // delete counsel
        store.dispatch('counselingDelete', {
          writer: writer,
          counselerIsDoctor: counselerIsDoctor,
        });

        // delete board
        store.dispatch('deleteBoard', {
          process: '상담',
        });

        // set 상담 아이디 null
        store.commit('setCounselingId', null);

        // 상담 데이터 초기화
        store.commit('initSelectedCounselingData');

        // 상담 수가표 초기화
        store.commit('selectedSoogaListInit');

        // 상담 시작여부 false
        store.commit('setCounselingHasStarted', false);

        // 상담 start 창으로 go
        store.commit('setCounselingModalShow', false);
        Swal.fire('Deleted!', '상담 내역이 삭제되었습니다.', 'success');
      }
    }
  });
}, 250);

const setExpectedDateOfSurgeryToday = (event) => {
  if (event.target.innerText === '당수') {
    store.commit('setSelectedCounselingData', {
      key: 'expectedDateOfSurgery',
      payloadData: new Date(),
    });
  } else if (event.target.innerText === '미정') {
    store.commit('setSelectedCounselingData', {
      key: 'expectedDateOfSurgery',
      payloadData: '',
    });
  }
};
</script>

<style></style>
