<template>
  <div class="grid">
    <div class="col-12">
      <div class="pb-2 mb-3 border-bottom-1 border-400">
        <div class="flex flex-wrap justify-content-between align-contents-center">
          <span class="text-2xl flex justify-content-between">
            <div style="min-width: 80px">디자인(진료)</div>
            <div class="ml-2">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <Checkbox v-model="isDoctor" :binary="true" :disabled="true" />
                </span>
                <Dropdown
                  v-model="selectedDoctor"
                  :options="store.state.counselingAndTreatStore.doctors"
                  optionLabel="doc_nm"
                  optionValue="member_id"
                  placeholder="의사 선택"
                  :disabled="
                    !store.state.counselingAndTreatStore.isDoctor ||
                    store.state.treatStore.treatModalIsShow
                  "
                />
              </div>
            </div>
          </span>
          <div class="p-inputgroup mb-2" style="max-width: 400px">
            <span class="p-inputgroup-addon" :class="componentWidth">환자구분</span>
            <div
              class="flex align-contents-center p-inputgroup border-1 border-solid border-300 border-round-right justify-content-around"
            >
              <div
                v-for="patientState of patientStates"
                :key="patientState.key"
                class="flex field-radiobutton ml-2 h-full"
              >
                <RadioButton
                  :inputId="patientState.key"
                  name="category"
                  :value="patientState.value"
                  v-model="selectedPatientState"
                  :disabled="store.state.counselingStore.modalIsDisabled"
                />

                <label :for="patientState.key">{{ patientState.name }}</label>
              </div>
            </div>
          </div>
          <div class="flex justify-content-between">
            <!-- <div v-if="nowTreatCounselId === null" class="flex align-contents-center">
              <div v-if="store.state.counselingStore.modalIsDisabled && !inTreat">
                <Button class="p-button-sm mr-2" @click="treatStart">진료 시작</Button>
              </div>
            </div>

            <div v-if="nowTreatCounselId !== null">
              <div v-if="store.state.counselingStore.modalIsDisabled">
                <Button class="p-button-sm mr-2" @click="treatModify">진료 수정</Button>
              </div>
            </div> -->
            <Button
              icon="pi pi-arrow-left"
              class="p-button-rounded p-button-text"
              @click="store.commit('setTreatModalShow', false)"
            ></Button>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="col-12"></div>
        <div class="col-12">
          <TreatMemo></TreatMemo>
        </div>
      </div>
    </div>

    <!-- <div class="grid"> -->
    <div class="col-12">
      <CounselingTreatSelectedSooga></CounselingTreatSelectedSooga>
    </div>

    <div v-if="store.state.sidebarVisible">
      <SidebarSooga></SidebarSooga>
    </div>
  </div>

  <div class="flex justify-content-end">
    <Button label="저장" icon="pi pi-check" @click="treatSave"> </Button>
  </div>

  <div v-if="isDebug">
    <Button label="디버그용" @click="doDebug"> </Button>
  </div>
  <SidebarTreatSoogaInBom></SidebarTreatSoogaInBom>
</template>

<script setup>
import { onBeforeUnmount, ref, computed, watchEffect, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import _ from 'lodash';

import TreatMemo from './treat_memo.vue';
import SidebarSooga from './sidebar_sooga.vue';
import CounselingTreatSelectedSooga from './counseling_treat_selected_sooga.vue';
import SidebarTreatSoogaInBom from './sidebar_treat_sooga_in_bom.vue';

import Swal from 'sweetalert2';

const isDebug = false;
const doDebug = async () => {};

const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
const treatData = ref();

// 티케팅 신환, 구환
const selectedPatientState = computed({
  get: () => {
    return store.state.treatStore.treatSelectedPatientState;
  },
  set: (value) => {
    store.commit('setTreatSelectedPatientState', value);
  },
});
const patientStates = ref([
  { name: '신환', key: 'newPatient', value: false },
  { name: '구환', key: 'oldPatient', value: true },
]);

let treatDataForCompare = ['render']; // 비교를 위한 변수, 첫 렌더링을 위해 값 주입

// 의사 여부 checkbox
const isDoctor = computed({
  get: () => {
    return store.state.counselingAndTreatStore.isDoctor;
  },
  set: (value) => {
    store.commit('setIsDoctor', value);
  },
});

// 선택한 원장
const selectedDoctor = computed({
  get: () => {
    return store.state.counselingAndTreatStore.selectedDoctor;
  },
  set: (value) => {
    store.commit('setSelectedDoctor', value);
  },
});

watchEffect(async () => {
  // 진료 데이터(수가표 데이터)
  const allResponseTreat = store.state.treatStore.treatCompare;

  if (allResponseTreat) {
    const data = allResponseTreat.data;
    const treatIds = allResponseTreat.id;

    // set 진료 아이디 => 아이디 여부로 첫 진료인지, 진료 수정인지 판단
    if (treatIds.length > 0) {
      store.commit('setTreatId', treatIds[0].counseling_id);
    } else {
      store.commit('setTreatId', null);
    }

    const oldValue = treatDataForCompare;
    const newValue = data;
    if (!_.isEqual({ ...oldValue }, { ...newValue })) {
      treatData.value = _.cloneDeep(newValue);
      treatDataForCompare = newValue;
      // 진료 렌더링
      renderTreat(treatData.value);
    }
  }
});

onBeforeUnmount(() => {
  store.commit('setTreatModalShow', false);
  store.commit('changeSoogaDataInBom', []);
});

/**
 * treatId가 없다는 것은 진료가 처음
 * @param {array} data
 */
function renderTreat(data) {
  const soogaDataList = data ?? [];

  let patientState = false;
  data.forEach((element) => {
    // 1 번이라도 구환(true)이면 구환 => 방문횟수 count 로 해도 됨
    patientState = patientState || element.patient_state;
  });
  selectedPatientState.value = patientState;

  // 선택된 수가표
  store.commit('selectedSoogaListInit');
  soogaDataList.forEach(async (soogaData) => {
    // 각 수가표 별로 결제 여부 체크
    await store.dispatch('getSoogaSurgeryPaymentsChecking', {
      counselingId: soogaData.counseling_id,
      menuId: soogaData.id,
      isCounseling: false,
    });
    const soogaDataPaymentChecking = storeGetters.value.checkPayment;

    // 해당 수가표가 결제 했으면 isPaid는 true 아니면 false
    const isPaid = soogaDataPaymentChecking.paid;

    // 수가표 선택여부, false 이면 sidebar에 선택 버튼이 뜨지 않음
    soogaData.is_selected = true;
    soogaData.is_selected_design = true;
    // 판매총액 input disable
    soogaData.sales_price_is_disabled = true;
    // 할인율 input disable
    soogaData.discount_rate_is_disabled = true;
    // 결제여부
    soogaData.is_paid = isPaid;

    if (soogaData.is_in_bom) {
      // 수/시술을 했을 경우
      store.commit('pushSoogaDataInBom', soogaData);
    } else {
      // 수/시술은 아직 하지 않았을 경우
      store.commit('pushSelectedSoogaData', soogaData);
    }
  });
}

// component 길이
const componentWidth = 'w-6';

const treatSave = _.debounce(async () => {
  // treat id 가 있을 경우 update 아니면 insert
  const isInsert = store.state.treatStore.treatId === null ? true : false;
  // 체크박스 체크 여부, 의사 선택 여부 등을 체크
  const { isChecked, writer, counselerIsDoctor } =
    storeGetters.value.getDoctorCheckboxChecked;

  if (!isChecked) {
    // fasle 일 경우 save 멈춤
    toast.add({
      severity: 'warn',
      summary: '의사 선택',
      detail: '의사 선생님을 입력해주세요.',
      life: 3000,
    });
    return -1;
  }

  if (_.isEqual(storeGetters.value.getSelectedSoogaList, [])) {
    // 티케팅 성공인데 수가표가 없을 경우
    toast.add({
      severity: 'warn',
      summary: `진료 데이터 '선택된 수가표'가 없음.`,
      detail: `'선택된 수가표'가 없습니다.`,
      life: 5000,
    });

    return -1;
  }

  // 추가기능 : 수가표에 제한할인율을 넘는 경우 확인
  let validateDiscountLimit = true;
  storeGetters.value.getSelectedSoogaList.forEach((soogaData) => {
    if (soogaData.discount_limit < soogaData.discount_rate) {
      if (!/서비스/.test(soogaData.discount_name)) {
        validateDiscountLimit = false;
        Swal.fire({
          title: '할인 제한',
          text: `서비스가 아닐 경우 제한된 할인율을 초과하여 할인하실 수 없습니다.`,
          icon: 'warning',
        });
      } else if (soogaData.paid_price > soogaData.sales_price) {
        validateDiscountLimit = false;
        Swal.fire({
          title: '할인 제한',
          text: `기존 수납된 금액보다 낮은 금액을 입력하여 저장하실 수 없습니다.`,
          icon: 'warning',
        });
      }
    }
  });

  // 제한 할인율을 넘을 경우
  if (!validateDiscountLimit) {
    return -1;
  }

  // 진료 데이터가 모두 입력되었다면 서버와 통신 시작
  if (isInsert) {
    // insert, 첫 저장이라면
    await treatInsert(writer, counselerIsDoctor);

    // 기존 상담에 없고 디자인(진료) 때 추가되는 수가표
    const insertSoogaSurgeries = storeGetters.value.getSelectedSoogaList.filter(
      (soogaSurgery) => {
        return !soogaSurgery.counseling_id;
      }
    );

    // 클립보드에 복사할 내용 soogaList 만들기
    const pasteInsertSoogaSurgeries = insertSoogaSurgeries.map((soogaSurgery) => {
      return `${soogaSurgery.large_category} ▶ ${soogaSurgery.middle_category} ▶ ${soogaSurgery.small_category} § ${soogaSurgery.abbreviation} § ${soogaSurgery.medical_menu_content} § ${soogaSurgery.note} § ${soogaSurgery.price} § ${soogaSurgery.event_price} § ${soogaSurgery.price_include_vat} § ${soogaSurgery.menu_count} §${soogaSurgery.sales_price} § ${soogaSurgery.discount_rate} § ${soogaSurgery.discount_name} \n`;
    });

    // 클립보드에 복사할 원장 이름 만들기
    const selectedDoctorName = store.state.counselingAndTreatStore.doctors.filter(
      (doctor) => {
        const selectedDoctorId = store.state.counselingAndTreatStore.selectedDoctor;
        return doctor.member_id === selectedDoctorId;
      }
    );

    Swal.fire({
      icon: 'success',
      title: '진료 데이터 저장 성공',
      html: '추가된 진료 내용을 클립보드에 복사하시겠습니까?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      let counselPasteData = `진료 원장: ${selectedDoctorName?.[0].doc_nm}
진료 날짜: ${$formatDate('date')}
추가된 진료 티케팅내역: ${
        _.isEqual(pasteInsertSoogaSurgeries, [])
          ? '추가된 수가표 없음'
          : pasteInsertSoogaSurgeries
      }`;
      if (result.isConfirmed) {
        // 추가된 진료 데이터 클립보드에 복사
        navigator.clipboard.writeText(counselPasteData);
      } else {
        // 진료 start 창으로 go
        store.commit('setTreatModalShow', false);
      }
    });
  } else {
    // update, 저장 후 수정일 경우
    let isPaid = false;
    storeGetters.value.getSelectedSoogaList.forEach((soogaData) => {
      isPaid = isPaid || soogaData.is_paid;
    });

    if (isPaid) {
      toast.add({
        severity: 'warn',
        summary: '진료 내역에 결제내역이 있습니다.',
        detail: '결제 내역을 포함하여 수정했으니 주의해주세요.',
        life: 4000,
      });
    }
    // update
    await treatUpdate(writer, counselerIsDoctor);

    // update 알림
    toast.add({
      severity: 'success',
      summary: '진료 수정',
      detail: '진료 정보를 수정했습니다.',
      life: 3000,
    });

    // 진료 start 창으로 go
    store.commit('setTreatModalShow', false);
  }
}, 250);

async function treatInsert(writer, counselerIsDoctor) {
  try {
    // 상담 입력
    store.dispatch('treatInsert', {
      writer: writer,
      counselerIsDoctor: counselerIsDoctor,
      patientState: selectedPatientState.value,
    });
    // 종료시간 update
    store.dispatch('upsertBoard', {
      isStart: false,
      writer: writer,
      time: $formatDate('time'),
      process: '진료',
      counselerIsDoctor: counselerIsDoctor,
    });
  } catch (error) {
    $errorHandling(toast, error);
  }
}

async function treatUpdate(writer, counselerIsDoctor) {
  try {
    // 진료 수정
    store.dispatch('treatUpdate', {
      writer: writer,
      counselerIsDoctor: counselerIsDoctor,
    });

    // 정민님 요청으로, 수정시에는 종료시간은 제외하고 작성자만 update
    store.dispatch('upsertBoardOnlyWriter', {
      isStart: false,
      writer: writer,
      process: '진료',
      counselerIsDoctor: counselerIsDoctor,
    });
  } catch (error) {
    $errorHandling(toast, error);
  }
}
</script>

<style lang="scss" scoped></style>
