<template>
  <Toast position="top-center" group="purchase" />
  <PurchaseToolbar />
  <PurchaseDataTable />
  <PurchaseDialog />
  <PurchaseDeleteDialog />
</template>
<script setup>
import PurchaseToolbar from '@/components/purchase/PurchaseToolbar.vue';
import PurchaseDataTable from '@/components/purchase/PurchaseDataTable.vue';
import PurchaseDialog from '@/components/purchase/PurchaseDialog.vue';
import PurchaseDeleteDialog from '@/components/purchase/PurchaseDeleteDialog.vue';

import { onBeforeMount, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

onBeforeMount(async () => {
  const timeoutBoardData = {
    name: 'purchase-getdata',
    url: '/purchase/getdata',
    module: 'purchaseGetdata',
    compareData: 'purchaseGetdataCompare',
  };

  store.dispatch('timeoutCommonFunc', timeoutBoardData);
});

onUnmounted(() => {
  store.commit('purchaseinit');
  store.commit('deleteTimeout', 'purchase-getdata');
});
</script>
<script>
export default {
  name: 'PurchaseManageComponent',
};
</script>

<style></style>
