<template>
  <div>
    <Button
      type="button"
      icon="pi pi-calendar"
      class="p-button-raised p-button-help w-2rem h-1rem"
      v-if="soogi()"
      @click="toggle"
    />

    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false">
      <Calendar
        v-model="selectedDate"
        dateFormat="yy-mm-dd"
        :inline="true"
        @date-select="changeDateConfirm"
      />
    </OverlayPanel>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';

const store = useStore();

const op = ref();
const authList = ref();
const selectedDate = ref();

onMounted(() => {
  authList.value = store.state.userAuthData;
});

function soogi() {
  return (
    _.includes(authList.value, '000') ||
    _.includes(authList.value, '100') ||
    _.includes(authList.value, '110')
  );
}

function dateFormater(date) {
  return (
    new Date(date)
      .toLocaleString('en-US', {
        timeZone: 'Asia/Seoul',
        hour12: false,
      })
      .split(', ')[0]
      .split(/\//g)[2] +
    '-' +
    _.padStart(
      new Date(date)
        .toLocaleString('en-US', {
          timeZone: 'Asia/Seoul',
          hour12: false,
        })
        .split(', ')[0]
        .split(/\//g)[0],
      2,
      '0'
    ) +
    '-' +
    _.padStart(
      new Date(date)
        .toLocaleString('en-US', {
          timeZone: 'Asia/Seoul',
          hour12: false,
        })
        .split(', ')[0]
        .split(/\//g)[1],
      2,
      '0'
    )
  );
}

function toggle(event) {
  op.value.toggle(event);
}

function changeDateConfirm() {
  Swal.fire({
    icon: 'info',
    title: '해당 날짜로 결제일을 변경하시겠습니까?',
    showDenyButton: true,
    denyButtonText: '취소',
    confirmButtonText: '실행',
  }).then(async (result) => {
    if (result.isConfirmed) {
      // op.value.toggle();
      const paymentData = store.state.paymentStore.changeData;
      const paymentCreationTimeStamp = paymentData.creation_timestamp.split(/\s/g);

      const changeTimeStamp = `${dateFormater(selectedDate.value)} ${
        paymentCreationTimeStamp[1]
      }`;

      const sendData = {
        changeTimeStamp,
        payment_id: paymentData.payment_id,
        refund_active: paymentData.refund_active,
      };

      await store.dispatch('paymentDateChange', sendData);
    }
  });
}
</script>
<script>
export default { name: 'ChangeDateVue' };
</script>

<style lang="scss" scoped>
::v-deep(span) {
  font-size: 10px;
}
</style>
