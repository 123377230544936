<template>
  <TopNavigation />
  <AuthManage></AuthManage>
</template>

<script setup>
import TopNavigation from '../components/common/TopNavigation.vue';
import AuthManage from '@/components/auth/AuthManage.vue';
</script>
<script>
export default {
  name: 'AuthPage',
};
</script>

<style scoped></style>
