<template>
  <div>
    <DataTable
      class="p-datatable-customers p-datatable-sm"
      :rowHover="true"
      ref="soogaDt"
      v-model:selection="selectedProducts"
      :value="soogaInitData"
      :paginator="true"
      :rows="20"
      v-model:filters="filters"
      filterDisplay="menu"
      :globalFilterFields="[
        'large_category',
        'middle_category',
        'small_category',
        'abbreviation',
      ]"
      editMode="row"
      @row-edit-save="onRowEditSave"
      v-model:editingRows="editingRows"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 40, 60, 100]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      responsiveLayout="scroll"
      removableSort
      dataKey="id"
    >
      <template #header>
        <div class="flex align-items-center justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="대/중/소분류, 약어"
            />
          </span>
        </div>
      </template>
      <template #empty> 데이터가 없습니다. </template>
      <template #loading> 데이터를 가져오는 중입니다. 잠시만 기다려주세요. </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

      <!-- 대분류 -->
      <Column
        field="large_category"
        header="대분류"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 8rem"
      >
        <template #body="{ data }">
          {{ data.large_category }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="대분류명을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 중분류 -->
      <Column
        field="middle_category"
        header="중분류"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 8rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="중분류명을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 소분류 -->
      <Column
        field="small_category"
        header="소분류"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 8rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="소분류명을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 약어 -->
      <Column
        field="abbreviation"
        header="약어"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 10rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="약어를 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 내용 -->
      <Column
        field="medical_menu_content"
        header="내용"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 10rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="내용을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 정상가 -->
      <Column
        field="price"
        header="정상가"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.numberMatchModeOptions"
        sortable
        dataType="numeric"
        style="min-width: 8rem"
      >
        <template #body="{ data }">
          {{ $wonCurrency(data.price) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputNumber
            v-model="filterModel.value"
            mode="currency"
            currency="KRW"
            locale="ko-KR"
            placeholder="가격을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 이벤트가 -->
      <Column
        field="event_price"
        header="이벤트가"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.numberMatchModeOptions"
        sortable
        dataType="numeric"
        style="min-width: 9rem"
      >
        <template #body="{ data }">
          {{ $wonCurrency(data.event_price) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputNumber
            v-model="filterModel.value"
            mode="currency"
            currency="KRW"
            locale="ko-KR"
            placeholder="가격을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- VAT 포함 -->
      <Column
        field="price_include_vat"
        header="VAT 포함"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.numberMatchModeOptions"
        sortable
        dataType="numeric"
        style="min-width: 9rem"
      >
        <template #body="{ data }">
          {{ $wonCurrency(data.price_include_vat) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputNumber
            v-model="filterModel.value"
            mode="currency"
            currency="KRW"
            locale="ko-KR"
            placeholder="가격을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 비고 -->
      <Column
        field="note"
        header="비고"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 10rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="비고를 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 원가 -->
      <Column
        field="cost"
        header="원가"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.numberMatchModeOptions"
        sortable
        dataType="numeric"
        style="min-width: 8rem"
      >
        <template #body="{ data }">
          {{ $wonCurrency(data.cost) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputNumber
            v-model="filterModel.value"
            mode="currency"
            currency="KRW"
            locale="ko-KR"
            placeholder="원가를 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 원가율 -->
      <Column
        field="cost_rate"
        header="원가율"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.numberMatchModeOptions"
        sortable
        dataType="numeric"
        style="min-width: 8rem"
      >
        <template #body="{ data }">
          {{ data.cost_rate + '%' }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputNumber
            v-model="filterModel.value"
            inputId="percent"
            suffix="%"
            placeholder="원가율을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 할인여부 -->
      <Column
        header="할인여부"
        sortable
        :showFilterMatchModes="false"
        style="min-width: 8rem"
      >
        <template #body="{ data }">
          {{
            data.discount_limit == 0
              ? '할인불가'
              : data.discount_limit == 100
              ? '할인가능'
              : '제한적 할인가능'
          }}
        </template>
      </Column>

      <!-- 최대할인율 -->
      <Column
        field="discount_limit"
        header="최대할인율"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.numberMatchModeOptions"
        sortable
        style="min-width: 10rem"
      >
        <template #body="{ data }">
          {{ data.discount_limit ? data.discount_limit + '%' : '0%' }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="할인율을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputNumber v-model="data[field]" suffix="%" :min="0" :max="100" />
        </template>
      </Column>

      <!-- 상담필요 -->
      <Column
        filterField="counseling_necessity"
        header="상담필요여부"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        sortable
        style="min-width: 10rem"
      >
        <template #body="{ data }">
          <ToggleButton
            v-model="data.counseling_necessity"
            onLabel="필요"
            offLabel="불필요"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            class="w-auto sm:w-6rem p-button-sm"
            :disabled="true"
            aria-label="상담필요여부"
          />
        </template>
        <template #filter="{ filterModel }">
          <div class="mb-3 font-bold">상담필요여부</div>
          <MultiSelect
            v-model="filterModel.value"
            :options="counselingNecessity"
            optionLabel="name"
            placeholder="전체"
            class="p-column-filter"
          >
            <template #option="slotProps">
              <div class="p-multiselect-counselingNecessity-option">
                <span class="image-text">{{ slotProps.option.name }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
        <template #editor="{ data }">
          <ToggleButton
            v-model="data.counseling_necessity"
            onLabel="필요"
            offLabel="불필요"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            class="w-auto sm:w-6rem p-button-sm"
            aria-label="상담필요여부"
          />
        </template>
      </Column>

      <!-- 수정 -->
      <Column
        :rowEditor="true"
        style="min-width: 8rem"
        icon="pi pi-pencil"
        class="p-button-rounded p-button-success"
      ></Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, watchEffect, getCurrentInstance } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useStore } from 'vuex';
import _ from 'lodash';
import Swal from 'sweetalert2';

const store = useStore();
const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;

const editingRows = ref([]);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  large_category: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  middle_category: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  small_category: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  abbreviation: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  medical_menu_content: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  price: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  event_price: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  price_include_vat: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  note: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  cost: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  cost_rate: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  discount_limit: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  counseling_necessity: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },

  verified: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const soogaInitData = ref();
const selectedProducts = ref();
const soogaDt = ref();
const counselingNecessity = [
  { name: '필요', value: true },
  { name: '불필요', value: false },
];

watchEffect(() => {
  soogaInitData.value = store.state.soogaGetdata.soogaGetdataCompare
    ? store.state.soogaGetdata.soogaGetdataCompare
    : [];
  store.commit('soogaSelectedProductsCommit', selectedProducts.value);
  soogaDt.value && store.commit('soogaDtCommit', soogaDt.value);
});

// 데이터 수정 이벤트
function onRowEditSave(event) {
  let { newData, index } = event;

  if (newData) {
    if (_.isEqual(soogaInitData.value[findIndexById(newData.id)], newData)) {
      Swal.fire('데이터를 수정한 뒤, 클릭해주세요.', '', 'warning');
    } else {
      store.commit('updateProductsCommit', newData);
      store.commit('soogaUpdateStateCommit', true);
    }
  }
  soogaInitData.value[findIndexById(newData.id)] = newData;
}

// 데이터 인덱스를 찾아주는 함수
function findIndexById(id) {
  let index = -1;
  for (let i = 0; i < soogaInitData.value.length; i++) {
    if (soogaInitData.value[i].id === id) {
      index = i;
      break;
    }
  }

  return index;
}
</script>
<script>
export default {
  name: 'SoogaDataTable',
};
</script>

<style></style>
