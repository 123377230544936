<template>
  <div>
    <h5>(1) 의사 선택</h5>
    <Listbox
      v-model="doctorToSurgerySelected"
      :options="doctorCountData"
      optionLabel="doc_nm"
      :optionValue="setDoctorOptionValue"
      :multiple="false"
      :filter="true"
      listStyle="max-height:550px"
      style="width: 30rem"
      :filterFields="['doc_nm']"
      filterPlaceholder="의사명"
      @click="store.dispatch('doctorToSurgeryActive')"
    >
      <template #option="slotProps">
        <div
          class="flex justify-content-between p-3 border-round surface-card shadow-2 mb-1"
        >
          <div class="flex align-items-center font-bold">
            <div>{{ slotProps.option.doc_nm }}</div>
          </div>
          <div
            class="flex align-items-center justify-content-end"
            style="width: 12rem; height: 2.5rem"
          >
            <div
              v-show="
                slotProps.option.menu_count ===
                store.state.bomManageGetdata.surgeryData.length
              "
            >
              <span class="text-green-500 font-small"
                >{{
                  store.state.bomManageGetdata.surgeryData.length +
                  '개 중 ' +
                  slotProps.option.menu_count
                }}개 등록</span
              >
            </div>
            <div v-show="slotProps.option.menu_count > 0">
              <span class="text-grey-400 font-small"
                >{{
                  store.state.bomManageGetdata.surgeryData.length +
                  '개 중 ' +
                  slotProps.option.menu_count
                }}개 등록</span
              >
            </div>
            <div v-show="!(slotProps.option.menu_count > 0)">
              <span class="text-red-500 font-small"
                >{{ store.state.bomManageGetdata.surgeryData.length + '개 중 ' }}0개
                등록</span
              >
            </div>
          </div>
        </div>
      </template>
    </Listbox>
  </div>
</template>
<script setup>
import { computed, watchEffect, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import Listbox from 'primevue/listbox';

const store = useStore();
const getters = computed(() => store.getters);
// const doctorCountData = ref();
const doctorCountData = computed({
  get: () => {
    return getters.value.getDoctorCountData;
  },
  set: (value) => {
    store.commit('doctorCountDataCommit', value);
  },
});
const doctorToSurgerySelected = computed({
  get: () => {
    return store.state.bomManageGetdata.confirmDoctorToSurgerySelected;
  },
});

watchEffect(() => {
  // store.dispatch('getDoctorCountData');
  // doctorCountData.value = store.state.bomManageGetdata.doctorCountData;
  // doctorCountData.value = getters.value.getDoctorCountData;
  store.commit('doctorMenuConfirmDataCommit', getters.value.getDoctorMenuConfirmData);
});

onUnmounted(() => {
  store.commit('bomManageInit');
});

function setDoctorOptionValue(e) {
  return { doc_nm: e.doc_nm, doctor_id: e.doctor_id };
}
</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }
}
</style>
