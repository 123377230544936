import axios from 'axios';
import global from '@/util/global';
import _ from 'lodash';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ko');

const counselingStore = {
  state() {
    return {
      counselingId: null,
      counselModalRouter: 'counsel',
      counselingCompare: null,
      counselingModalIsShow: false, // false 이면 시작 버튼 있는 진입점, true 이면 상담 모달
      modalIsDisabled: true,
      counselingHasStarted: false, // 상담 시작 이후면 true, 시작 전이면 false
      counselingMemoCompare: {
        memo: null,
        customer_id: null,
        editing: null,
      },
      counselingMemo: {
        memo: null,
        customer_id: null,
        editing: null,
      },
      counselingMemoCheck: null,
      counselingData: {
        selectedExpectProcess: null, // 선택된 예상절차
        selectedTicketing: null, // 선택된 티케팅 여부 => 티케팅 성공
        selectedPatientState: null, // 선택된 티케팅 신환, 구환 => 환자구분
        selectedTicketingSurgeries: [], // 선택된 티케팅 시수술
        selectedTicketingProcess: null, // 선택된 티케팅 절차
        expectedDateOfSurgery: new Date(), // 선택된 시수술 예상날짜
      },
    };
  },
  mutations: {
    setCounselingId(state, payload) {
      state.counselingId = payload;
    },
    counselingModalDisabled(state) {
      state.modalIsDisabled = true;
    },
    counselingModalAbled(state) {
      state.modalIsDisabled = false;
    },
    setCounselingModalShow(state, payload) {
      state.counselingModalIsShow = payload;
    },
    setCounselingHasStarted(state, payload) {
      state.counselingHasStarted = payload;
    },
    setCounselingMemo(state, payload) {
      state.counselingMemo = payload;
    },
    setCounselingMemoCheck(state, payload) {
      state.counselingMemoCheck = payload;
    },
    setSelectedCounselingData(state, payload) {
      const { key, payloadData } = payload;
      state.counselingData[key] = payloadData;
    },
    initSelectedCounselingData(state) {
      state.counselingData.selectedExpectProcess = null; // 선택된 예상절차
      state.counselingData.selectedTicketing = null; // 선택된 티케팅 여부 => 티케팅 성공
      state.counselingData.selectedPatientState = null; // 선택된 티케팅 신환, 구환 => 환자구분
      state.counselingData.selectedTicketingSurgeries = []; // 선택된 티케팅 시수술
      state.counselingData.selectedTicketingProcess = null; // 선택된 티케팅 절차
      state.counselingData.expectedDateOfSurgery = new Date(); // 선택된 시수술 예상날짜
    },
    setAllSelectedCounselingData(state, payload) {
      const {
        selectedExpectProcess,
        selectedTicketing,
        selectedPatientState,
        ticketing_petite,
        ticketing_mini,
        ticketing_surgery,
        selectedTicketingProcess,
        expectedDateOfSurgery,
      } = payload;

      // 예상절차
      state.counselingData.selectedExpectProcess = selectedExpectProcess;

      // 티케팅 성공
      state.counselingData.selectedTicketing = selectedTicketing;

      // 환자 구분
      state.counselingData.selectedPatientState = selectedPatientState;

      state.counselingData.selectedTicketingSurgeries = [];
      // 티케팅 시수술
      if (ticketing_petite) {
        state.counselingData.selectedTicketingSurgeries.push('쁘띠');
      }
      if (ticketing_mini) {
        state.counselingData.selectedTicketingSurgeries.push('미니');
      }
      if (ticketing_surgery) {
        state.counselingData.selectedTicketingSurgeries.push('수술');
      }

      // 티케팅 예상절차
      state.counselingData.selectedTicketingProcess = selectedTicketingProcess;

      // 시수술 예상날짜
      state.counselingData.expectedDateOfSurgery = expectedDateOfSurgery;
    },
  },
  actions: {
    async getCounselingBoardData({ getters, commit, state }) {
      const receptionId = getters.getBoardModalReceptionId;
      const customerId = getters.getBoardModalCustomerId;
      const URL = getters.getUrl;

      const counselModalRouter = state.counselModalRouter;
      const process = '상담';

      if (receptionId && customerId) {
        const res = await axios.get(
          URL +
            `/${counselModalRouter}/board?receptionid=${receptionId}&customerid=${customerId}&process=${process}`
        );
        commit('loadingChangeValue', false);

        if (res.data?.data.length > 0) {
          const boardProcess = res.data.data[0];
          // 만약 process start_time 이 있다면
          // 상담은 시작했음 (counseling has started)
          const tokenExpDayjs = dayjs(boardProcess?.process_start_timestamp).format(
            'HH:mm:ss'
          );

          if (boardProcess.process_start_timestamp && tokenExpDayjs !== '00:00:00') {
            commit('setCounselingHasStarted', true);
          }

          if (boardProcess.writer && getters.isWriterInDoctor(boardProcess.writer)) {
            commit('setIsDoctor', true);
            commit('setSelectedDoctor', boardProcess.writer);
          }
        }
      } else {
        commit('setCounselingHasStarted', false);
        commit('setIsDoctor', false);
        commit('setSelectedDoctor', null);
      }
    },
    async counselDuplicate({ getters, state, commit }) {
      const URL = getters.getUrl;
      const date = getters.getBoardModalDate;
      const counselModalRouter = state.counselModalRouter;

      commit('loadingChangeValue', true);
      const result = await axios.get(URL + `/${counselModalRouter}/duplicate`, {
        params: {
          customerCode: getters.getBoardModalCustomerId,
          date: date,
        },
      });

      return result.data;
    },
    async counselingSave({ getters, commit, state }, payload) {
      const { writer, counselerIsDoctor } = payload;
      const URL = getters.getUrl;
      const counselModalRouter = state.counselModalRouter;

      commit('loadingChangeValue', true);
      const res = await axios.post(URL + `/${counselModalRouter}/upsert`, {
        data: {
          counseling_id: state.counselingId,
          customer_id: getters.getBoardModalCustomerId,
          reception_id: getters.getBoardModalReceptionId,
          date: getters.getBoardModalDate,
          ticketing: state.counselingData.selectedTicketing,
          ticketingProcess: state.counselingData.selectedTicketingProcess,
          patientState: state.counselingData.selectedPatientState,
          ticketingSurgery: state.counselingData.selectedTicketingSurgeries,
          reservationSurgeryDate:
            state.counselingData.expectedDateOfSurgery === ''
              ? ''
              : global.methods.formatDate(
                  'date',
                  state.counselingData.expectedDateOfSurgery
                ),
          writer: writer,
          counselerIsDoctor: counselerIsDoctor,
          soogaSurgeries: getters.getNotPaymentSoogaSurgeries,
        },
      });
      // 입력된 상담 아이디 입력
      commit('setCounselingId', res.data.counselingId);
    },
    // upsert로 바뀌면서 사용 안함
    // async counselingInsert({ getters, commit, state }, payload) {
    //   const { writer, counselerIsDoctor } = payload;
    //   const soogaSurgeries = getters.getSelectedSoogaList.map(
    //     ({ id, sales_price, discount_rate, discount_name, menu_count }) => {
    //       return { id, sales_price, discount_rate, discount_name, menu_count };
    //     }
    //   );
    //   const URL = getters.getUrl;
    //   const counselModalRouter = state.counselModalRouter;
    //   const res = await axios.post(URL + `/${counselModalRouter}`, {
    //     data: {
    //       customerId: getters.getBoardModalCustomerId,
    //       receptionId: getters.getBoardModalReceptionId,
    //       date: getters.getBoardModalDate,
    //       ticketing: state.counselingData.selectedTicketing,
    //       patientState: state.counselingData.selectedPatientState,
    //       ticketingSurgery: state.counselingData.selectedTicketingSurgeries,
    //       ticketingProcess: state.counselingData.selectedTicketingProcess,
    //       reservationSurgeryDate:
    //         state.counselingData.expectedDateOfSurgery === ''
    //           ? ''
    //           : global.methods.formatDate(
    //               'date',
    //               state.counselingData.expectedDateOfSurgery
    //             ),
    //       soogaSurgeries: soogaSurgeries,
    //       writer: writer,
    //       counselerIsDoctor: counselerIsDoctor,
    //     },
    //   });

    //   commit('setCounselingId', res.data.counselingId);
    // },
    async counselingInsertSurgery({ getters, state, commit }, payload) {
      const { writer, counselerIsDoctor } = payload;
      const counselingId = state.counselingId;
      const URL = getters.getUrl;
      const counselModalRouter = state.counselModalRouter;
      commit('loadingChangeValue', true);
      await axios.post(URL + `/${counselModalRouter}/soogasurgery`, {
        data: {
          counselingId: counselingId,
          newSoogaSurgeries: getters.getInsertSoogaSurgeries,
          writer: writer,
          counselerIsDoctor: counselerIsDoctor,
        },
      });
    },
    // upsert로 바뀌면서 사용 안함
    // async counselngUpdate({ getters, state }, payload) {
    //   const { writer, counselerIsDoctor } = payload;
    //   const counselingId = state.counselingId;
    //   const URL = getters.getUrl;
    //   const counselModalRouter = state.counselModalRouter;

    //   let isPaid = false;
    //   getters.getSelectedSoogaList.forEach((soogaData) => {
    //     isPaid = isPaid || soogaData.is_paid;
    //   });
    //   await axios.put(URL + `/${counselModalRouter}/counseling/${counselingId}`, {
    //     data: {
    //       soogaSurgeries: getters.getNotPaymentSoogaSurgeries,
    //       ticketingSurgery: state.counselingData.selectedTicketingSurgeries,
    //       ticketing: isPaid ? 1 : state.counselingData.selectedTicketing, // 상담 내역에 결제된 것이 있으므로 티케팅 성공 처리
    //       ticketingProcess: state.counselingData.selectedTicketingProcess,
    //       patientState: state.counselingData.selectedPatientState,
    //       reservationSurgeryDate: global.methods.formatDate(
    //         'date',
    //         state.counselingData.expectedDateOfSurgery
    //       ),
    //       writer: writer,
    //       counselerIsDoctor: counselerIsDoctor,
    //     },
    //   });
    // },
    async updateAfterProcess({ getters, state, commit }, payload) {
      const { writer, counselerIsDoctor } = payload;

      const URL = getters.getUrl;
      const counselModalRouter = state.counselModalRouter;

      commit('loadingChangeValue', true);
      const res = await axios.put(
        URL + `/${counselModalRouter}/receptions/afterprocess`,
        {
          data: {
            receptionId: getters.getBoardModalReceptionId,
            afterProcess: state.counselingData.selectedExpectProcess,
            writer: writer,
            counselerIsDoctor: counselerIsDoctor,
          },
        }
      );
      return res;
    },
    async getReceptionData({ getters, state, commit }) {
      const receptionId = getters.getBoardModalReceptionId;
      const URL = getters.getUrl;
      const counselModalRouter = state.counselModalRouter;
      commit('loadingChangeValue', true);
      const res = await axios.get(
        URL + `/${counselModalRouter}/receptions/${receptionId}`
      );

      return res;
    },
    async counselingDelete({ getters, state, commit }, payload) {
      const { writer, counselerIsDoctor } = payload;

      const counselingId = state.counselingId;
      const URL = getters.getUrl;
      const counselModalRouter = state.counselModalRouter;
      commit('loadingChangeValue', true);
      const res = await axios.delete(
        URL +
          `/${counselModalRouter}/counseling/${counselingId}?writer=${writer}&counselerIsDoctor=${counselerIsDoctor}`
      );

      return res;
    },
  },
  getters: {
    getCounselingModalIsDisabled(state) {
      return state.modalIsDisabled;
    },
    getCounselingMemoCheck(state) {
      return state.counselingMemoCheck;
    },
    // 수가표 수정
    getNotPaymentSoogaSurgeries(state, getters) {
      const notPaymentSoogaSurgeries = getters.getSelectedSoogaList.filter((element) => {
        // 결제되고 변경된 것(해당 시점에 업셀링한 데이터)
        // 결제되지 않고 변경되지 않은 것(업셀링 안한 데이터)
        // 결제되고 변경되지 않은 것(기존 업셀링 데이터)
        return (
          (element.is_paid && element.is_modified) ||
          (!element.is_paid && !element.is_modified) ||
          (element.is_paid && !element.is_modified)
        );
      });

      return notPaymentSoogaSurgeries.map(
        ({
          counseling_surgery_id,
          id,
          sales_price,
          discount_rate,
          discount_name,
          menu_count,
          menu_id_before_change,
          menu_info_before_change,
        }) => {
          return {
            counseling_surgery_id,
            id,
            sales_price,
            discount_rate,
            discount_name,
            menu_count,
            menu_id_before_change,
            discount_rate_before_change: menu_info_before_change?.discount_rate,
            discount_name_before_change: menu_info_before_change?.discount_name,
          };
        }
      );
    },

    getUpdateSoogaSurgeries(state, getters) {
      const updateSoogaSurgeries = getters.getSelectedSoogaList.filter((element) => {
        return element.counseling_id && !element.is_paid; // 상담 아이디가 있고 결제되지 않은 것
      });

      return updateSoogaSurgeries.map(
        ({ id, sales_price, discount_rate, discount_name, menu_count }) => {
          return { id, sales_price, discount_rate, discount_name, menu_count };
        }
      );
    },
    getInsertSoogaSurgeries(state, getters) {
      const insertSoogaSurgeries = getters.getSelectedSoogaList.filter((element) => {
        return !element.counseling_id;
      });

      return insertSoogaSurgeries.map(
        ({ id, sales_price, discount_rate, discount_name, menu_count }) => {
          return { id, sales_price, discount_rate, discount_name, menu_count };
        }
      );
    },
    getDoctorCheckboxChecked(state, getters) {
      if (getters.getIsDoctor) {
        // 의사 체크박스를 체크한 경우 -> 원장 상담
        if (getters.getSelectedDoctor) {
          // 체크박스에 체크하고, 의사를 고른 경우
          return {
            isChecked: true,
            writer: getters.getSelectedDoctor,
            counselerIsDoctor: true,
          };
        } else {
          // 체크박스에 체크했지만, 의사를 고르지 않은 경우
          return {
            isChecked: false,
          };
        }
      } else {
        // 의사 체크박스를 체크하지 않은 경우 -> 상담실장 상담
        return {
          isChecked: true,
          writer: null,
          counselerIsDoctor: false,
        };
      }
    },
    isCounselingIncludesNull(state) {
      // null 값을 체크 => null이 있다면 true와 어떤 값이 없는지 알려줌
      if (state.counselingData.selectedTicketing === null) {
        // 선택된 티케팅 여부 => 티케팅 성공
        return { isIncludesNull: true, name: '티케팅 성공' };
      } else if (state.counselingData.selectedExpectProcess === null) {
        // 선택된 예상절차
        return { isIncludesNull: true, name: '예상절차' };
      } else if (state.counselingData.selectedPatientState === null) {
        // 선택된 티케팅 신환, 구환 => 환자구분
        return { isIncludesNull: true, name: '환자구분' };
      } else if (state.counselingData.selectedTicketingProcess === null) {
        // 선택된 티케팅 절차
        return { isIncludesNull: true, name: '티케팅 절차' };
      } else if (state.counselingData.expectedDateOfSurgery === null) {
        // 선택된 시수술 예상날짜
        return { isIncludesNull: true, name: '시수술 예상날짜' };
      } else if (_.isEqual(state.counselingData.selectedTicketingSurgeries, [])) {
        // 선택된 티케팅 시수술
        // 티케팅 시수술은 default 값이 [] 이므로 비교를 isEqual 로 함
        return { isIncludesNull: true, name: '티케팅 시수술' };
      } else {
        return { isIncludesNull: false, name: 'null 값이 없습니다.' };
      }
    },
  },
};

export default counselingStore;
