<template>
  <div>
    <Sidebar
      v-model:visible="productState"
      position="right"
      class="p-sidebar-lg"
      :autoZIndex="false"
      @hide="store.commit('loadingChangeValue', true)"
    >
      <TicketingProductDatatable></TicketingProductDatatable>
    </Sidebar>
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import TicketingProductDatatable from './TicketingProductDatatable.vue';

const store = useStore();
const productState = computed({
  get: () => store.state.paymentStore.productState,
  set: (value) => {
    store.commit('productStateCommit', value);
  },
});

onBeforeMount(async () => {
  await store.dispatch('paymentProductInit');
});
</script>

<script>
export default {
  name: 'TicketingProductDialog',
};
</script>

<style lang="scss" scoped></style>
