import axios from 'axios';
import _ from 'lodash';
import { errorHandling } from '../../util/errorHandler';

const loginStore = {
  state() {
    return {
      generateModalIS: false,
      findMyId: false,
      authAxios: '',
      loginGoogleData: '',
    };
  },
  mutations: {
    generateModal(state, payload) {
      state.generateModalIS = payload;
    },
    findMyIdModal(state) {
      state.findMyId = !state.findMyId;
    },
  },
  actions: {
    async loginAuth({ state, rootState, commit }) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.get(rootState.URL + '/auth-list');
        commit('loadingChangeValue', false);

        state.authAxios = result.data.result;
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginGoogle({ state, rootState, commit }) {
      try {
        commit('loadingChangeValue', true);
        const cookieData = await axios.get(rootState.URL + '/social-verify');
        if (cookieData.data.is && cookieData?.data?.userData[0].hospital === null) {
          const result = cookieData.data.userData[0];
          state.loginGoogleData = {
            email: result.user_id,
            passValue1: 'google login',
            passValue2: 'google login',
            selectedHospital: '',
            selectedDepartment: '',
            username: result.name,
            birth: '',
            phone: '',
            selectdAuth: '',
          };
        } else {
          state.loginGoogleData = '';
        }
        commit('loadingChangeValue', false);
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginRegist({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        axios.post(rootState.URL + '/register', payload);
        commit('loadingChangeValue', false);
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async googleRegist({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        axios.post(rootState.URL + '/google-register', payload);
        commit('loadingChangeValue', false);
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginEmailDuplication({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.post(rootState.URL + '/email_duplicate', {
          email: payload,
        });
        commit('loadingChangeValue', false);
        return result;
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginLougOut({ rootState, commit }) {
      commit('loadingChangeValue', true);
      await axios.get(rootState.URL + '/logout');
      commit('loadingChangeValue', false);
    },
    async emailVeryfy({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.get(rootState.URL + '/verifying-email?' + payload);
        commit('loadingChangeValue', false);
        return result;
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginFindMyId({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.post(rootState.URL + '/find_my_id', payload);
        commit('loadingChangeValue', false);
        return result;
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginPassChange({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.post(rootState.URL + '/change_password', payload);
        commit('loadingChangeValue', false);
        return result;
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginChangePassword({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        const result = axios.post(rootState.URL + '/change-password', payload);
        commit('loadingChangeValue', false);
        return result;
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async loginChangePassFindId({ rootState, commit }, payload) {
      commit('loadingChangeValue', true);
      const result = axios.get(rootState.URL + `/change-password-id/${payload}`);
      commit('loadingChangeValue', false);
      return result;
    },
  },
  getters: {
    loginAuthList(state) {
      const result = state.authAxios;
      const authInfoResult = result.authInfoResult;
      const authInfoUniq = _.uniqWith(
        authInfoResult.map((a) => ({ label: a.auth_category, items: [] })),
        _.isEqual
      );
      authInfoUniq.forEach((b) => {
        const aaMapping = authInfoResult
          .filter((c) => c.auth_category === b.label)
          .map((a) => ({ label: a.auth_content, value: a.auth_number }));
        b.items = [...b.items, ...aaMapping];
      });

      const departmentAuth = result.authDepartmentResult;
      const departmentObj = {};
      _.uniq(departmentAuth.map((a) => a.department_name)).forEach((a) => {
        departmentObj[a] = [
          ...departmentAuth.filter((b) => b.department_name === a).map((c) => c.auth_id),
        ];
      });

      const department = _.uniqWith(
        departmentAuth.map((a) => ({ name: a.department_name })),
        _.isEqual
      );

      return {
        authInfoUniq,
        departmentObj,
        department,
      };
    },
  },
};

export default loginStore;
