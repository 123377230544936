<template>
  <div>
    <TopNavigation class="mb-3"></TopNavigation>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import TopNavigation from '@/components/common/TopNavigation.vue';
</script>
<script>
export default {
  name: 'PurchasePage',
};
</script>

<style></style>
