<template>
  <Toast position="top-center" group="sooga" />
  <div>
    <TopNavigation class="mb-4"></TopNavigation>
    <div>
      <SoogaToolbar />
      <SoogaDataTable />
    </div>
  </div>
  <SoogaDialog />
  <SoogaUpdateDialog />
  <SoogaDeleteDialog />
</template>

<script setup>
import TopNavigation from '@/components/common/TopNavigation.vue';
import SoogaToolbar from '@/components/sooga/SoogaToolbar.vue';
import SoogaDataTable from '@/components/sooga/SoogaDataTable.vue';
import SoogaDialog from '@/components/sooga/SoogaDialog.vue';
import SoogaUpdateDialog from '@/components/sooga/SoogaUpdateDialog.vue';
import SoogaDeleteDialog from '@/components/sooga/SoogaDeleteDialog.vue';

import { onBeforeMount, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

onBeforeMount(async () => {
  const timeoutData = {
    name: 'sooga-getdata',
    url: '/sooga-manage/data',
    module: 'soogaGetdata',
    compareData: 'soogaGetdataCompare',
  };
  store.dispatch('timeoutCommonFunc', timeoutData);
});

onUnmounted(() => {
  store.commit('soogainit');
  store.commit('deleteTimeout', 'sooga-getdata');
});
</script>

<style scoped></style>
