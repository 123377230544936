<template>
  <div
    class="p-4 shadow-2 border-round w-3 absolute"
    style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
  >
    <div class="text-center mb-5">
      <div class="text-900 text-3xl font-medium mb-3">비밀번호변경</div>
    </div>

    <form @submit.prevent="">
      <label for="password1" class="block text-900 font-medium mb-2">비밀번호</label>
      <div class="p-inputgroup">
        <Password
          id="password1"
          class="mb-3"
          v-model="v$.pass1.$model"
          :class="{
            'p-invalid': v$.pass1.$invalid && vali,
          }"
        />
      </div>

      <label for="password2" class="block text-900 font-medium mb-2">비밀번호 확인</label>
      <div class="p-inputgroup">
        <Password
          id="password2"
          class="mb-3"
          v-model="v$.pass2.$model"
          :class="{
            'p-invalid': v$.pass2.$invalid && vali,
          }"
          :feedback="false"
        />
      </div>

      <Button
        @click="excution"
        type="submit"
        label="비밀번호 변경"
        icon="pi pi-check"
        class="w-full mt-2"
      ></Button>
    </form>
  </div>
</template>

<script setup>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Swal from 'sweetalert2';
import { onBeforeMount, reactive, ref } from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';

const store = useStore();
const passSame = (value) => _.isEqual(state.pass1, value);
const state = reactive({
  pass1: '',
  pass2: '',
});
const rules = {
  pass1: {
    required,
  },
  pass2: {
    required,
    passSame,
  },
};
const v$ = useVuelidate(rules, state);
const vali = ref(false);

onBeforeMount(async () => {
  const id = window.location.search.replace(/^\?/, '');
  const idObj = id.split(/=/g).reduce((a, b, c, d) => {
    if (c === 0) {
      return a;
    } else if (c % 2 === 1) {
      a[d[c - 1]] = b;
      return a;
    }
  }, {});

  const result = await store.dispatch('loginChangePassFindId', idObj.code);
  if (!result?.data?.changepass_state) {
    Swal.fire(
      '유효하지 않은 링크 입니다.',
      '다시 계정찾기 -> 비밀번호 변경 -> 이메일의 순서로 진행해주세요.',
      'error'
    ).then(() => {
      window.location.href =
        process.env.VUE_APP_NODE_STATUS === 'production'
          ? process.env.VUE_APP_FRONT_PROD_HOST
          : process.env.VUE_APP_FRONT_DEV_HOST;
    });
  }
});

const excution = _.debounce(async () => {
  vali.value = true;
  const pass = state.pass1;
  const presentLoacation = window.location.search;
  const code = presentLoacation.split('=')[1];

  if (!code) {
    Swal.fire('링크의 오류가 있습니다.', '이메일 전송을 다시 해주세요.').then(() => {
      window.location.href =
        process.env.VUE_APP_NODE_STATUS === 'production'
          ? process.env.VUE_APP_FRONT_PROD_HOST
          : process.env.VUE_APP_FRONT_DEV_HOST;
    });
  } else if (!v$.value.$invalid) {
    try {
      const result = await store.dispatch('loginChangePassword', {
        pass,
        code: presentLoacation.split('=')[1],
      });
      if (result.data) {
        Swal.fire(
          '비밀번호가 변경되었습니다.',
          '변경된 비밀번호로 로그인하세요.',
          'success'
        ).then(() => {
          window.location.href =
            process.env.VUE_APP_NODE_STATUS === 'production'
              ? process.env.VUE_APP_FRONT_PROD_HOST
              : process.env.VUE_APP_FRONT_DEV_HOST;
        });
      } else {
        Swal.fire(
          '변경할 계정이 없습니다.',
          '이메일을 확인해주시거나 다시한번 이메일을 전송받으세요.',
          'error'
        ).then(() => {
          window.location.href =
            process.env.VUE_APP_NODE_STATUS === 'production'
              ? process.env.VUE_APP_FRONT_PROD_HOST
              : process.env.VUE_APP_FRONT_DEV_HOST;
        });
      }
    } catch (err) {
      Swal.fire('에러 발생', err, 'error').then(() => {
        window.location.href =
          process.env.VUE_APP_NODE_STATUS === 'production'
            ? process.env.VUE_APP_FRONT_PROD_HOST
            : process.env.VUE_APP_FRONT_DEV_HOST;
      });
    }
  }
}, 250);
</script>
<script>
export default {
  name: 'ChangePass',
};
</script>

<style scoped>
.shadow-2 {
  background-color: rgb(247, 241, 241);
}
</style>
