<template>
  <div>
    <DataTable
      :value="selectedSoogaList"
      :paginator="true"
      :rows="10"
      dataKey="key"
      :rowHover="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <h5 class="m-0">선택된 수가표</h5>

          <div>
            <div class="flex justify-content-end">
              <div class="mr-3">
                기존 금액 합계:
                {{ formatCurrency(storeGetters.getSumOfPriceOfSelectedSoogaList) }}
                / 티켓팅 금액 합계:
                {{ formatCurrency(storeGetters.getSumOfSalesPriceOfSelectedSoogaList) }}
              </div>
              <div style="color: red">
                총 할인율
                {{ storeGetters.getSumOfDiscountRateOfSelectedSoogaList }}%
              </div>
            </div>
            <div class="flex justify-content-end align-items-center">
              <div class="col-2">
                <span>일괄 할인 적용</span>
              </div>
              <div class="col-3">
                <div class="p-inputgroup">
                  <InputNumber
                    :disabled="disabledSumOfSalesPrice"
                    class="p-inputnumber-sm"
                    style="width: auto; height: 30px"
                    v-model="sumOfSalesPrice"
                    @input="calculateDiscountRateFromBatch($event)"
                    mode="decimal"
                    :min="0"
                  />

                  <Button
                    @click="editableCheckFromBatch(true)"
                    icon="pi pi-pencil"
                    class="p-button-sm"
                    style="height: 30px"
                  ></Button>
                </div>
              </div>
              <div class="col-3">
                <div class="p-inputgroup">
                  <InputNumber
                    :disabled="disabledSumOfDiscountRate"
                    class="p-inputnumber-sm"
                    style="width: auto; height: 30px"
                    v-model="sumOfDiscountRate"
                    @input="calculateSalesPriceFromBatch($event)"
                    mode="decimal"
                    :min="0"
                    :max="100"
                    suffix="%"
                  />

                  <Button
                    @click="editableCheckFromBatch(false)"
                    icon="pi pi-pencil"
                    class="p-button-sm"
                    style="height: 30px"
                  ></Button>
                </div>
              </div>
              <div class="col-3">
                <div class="p-inputgroup">
                  <Dropdown
                    class="w-full discount-dropdown"
                    v-model="discountNameFromBatch"
                    :options="ticketingDiscountNames"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="해당없음"
                    @change="selectDiscountNameFromBatch(discountNameFromBatch)"
                    style="height: 30px; display: flex; align-items: center"
                  />
                </div>
              </div>

              <Button
                @click="applyDiscountFromBatch"
                class="border-round p-button-outlined p-button-sm font-semiboard mr-2"
                style="height: 30px"
                >적용</Button
              >
            </div>
          </div>
          <div class="flex align-items-end justify-content-end">
            <div v-if="!store.state.counselingAndTreatStore.isCounseling">
              <Button
                class="flex p-button-sm p-button-warning mr-2"
                @click="store.commit('setTreatSidebarVisible', true)"
                >수/시술 된 수가표</Button
              >
            </div>
            <Button class="flex p-button-sm" @click="store.commit('sidebarVisible')"
              >수가표 검색</Button
            >
          </div>
        </div>
      </template>
      <template #empty> No choice Sooga List </template>
      <template #loading> Loading data. Please wait. </template>

      <!-- 수가표 헤더 -->
      <ColumnGroup type="header">
        <Row>
          <Column header="구분"></Column>
          <Column header="약어"></Column>
          <Column header="내용"></Column>
          <Column header="정상가"></Column>
          <Column header="이벤트가"></Column>
          <Column header="VAT 포함"></Column>
          <Column header="개수"></Column>
          <Column header="판매 총액"></Column>
          <Column header="할인율"></Column>
          <Column header="할인 및 서비스"></Column>
          <Column header="작성자"></Column>
          <Column></Column>
        </Row>
      </ColumnGroup>

      <!-- 수가표 데이터 -->
      <Column header="구분" field="구분">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.large_category }} ▶ {{ data.middle_category }} ▶
            {{ data.small_category }}
          </div>
          <div
            v-if="data.menu_id_before_change"
            class="text-sm text-green-400 flex justify-content-center"
          >
            {{ data.menu_info_before_change.large_category }} ▶
            {{ data.menu_info_before_change.middle_category }} ▶
            {{ data.menu_info_before_change.small_category }}
          </div>
        </template>
      </Column>
      <Column header="약어">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.abbreviation }}
          </div>
          <div
            v-if="data.menu_id_before_change"
            class="text-sm text-green-400 flex justify-content-center"
          >
            {{ data.menu_info_before_change.abbreviation }}
          </div>
        </template>
      </Column>
      <Column header="내용">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.medical_menu_content }}
          </div>
          <div
            v-if="data.menu_id_before_change"
            class="text-sm text-green-400 flex justify-content-center"
          >
            {{ data.menu_info_before_change.medical_menu_content }}
          </div>
        </template>
      </Column>
      <Column header="정상가">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ formatCurrency(data.price) }}
          </div>
          <div
            v-if="data.menu_id_before_change"
            class="text-sm text-green-400 flex justify-content-center"
          >
            {{ formatCurrency(data.menu_info_before_change.price) }}
          </div>
        </template>
      </Column>
      <Column header="이벤트가">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ formatCurrency(data.event_price) }}
          </div>
          <div
            v-if="data.menu_id_before_change"
            class="text-sm text-green-400 flex justify-content-center"
          >
            {{ formatCurrency(data.menu_info_before_change.event_price) }}
          </div>
        </template>
      </Column>
      <Column header="VAT 포함">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ formatCurrency(data.price_include_vat) }}
          </div>
          <div
            v-if="data.menu_id_before_change"
            class="text-sm text-green-400 flex justify-content-center"
          >
            {{ formatCurrency(data.menu_info_before_change.price_include_vat) }}
          </div>
        </template>
      </Column>
      <Column header="개수">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <!-- {{ data.menu_count }} -->
            <InputNumber
              v-model="data.menu_count"
              class="p-inputnumber-sm"
              mode="decimal"
              showButtons
              style="height: min-content"
              :min="1"
              :inputStyle="{ width: '40px' }"
              @input="changeMenuCount($event, data.id)"
            >
            </InputNumber>
          </div>
        </template>
      </Column>
      <Column header="판매 총액">
        <template #body="{ data }">
          <div class="flex justify-content-center relative">
            <div class="p-inputgroup">
              <InputNumber
                :disabled="data.sales_price_is_disabled"
                class="p-inputnumber-sm"
                :inputStyle="{ width: '70px' }"
                :min="/할인|해당없음/.test(data.discount_name) ? 1 : 0"
                v-model="data.sales_price"
                @input="calculateDiscountRate($event, data.id)"
                mode="decimal"
                @blur="minPrice($event, data.id)"
              />

              <Button
                icon="pi pi-pencil"
                class="p-button-sm"
                @click="editableCheckFromSooga(true, data.id)"
                :disabled="
                  data.is_in_bom ||
                  data.discount_limit == 0 ||
                  /서비스|인플루언서|리얼모델/.test(data.discount_name)
                "
              ></Button>
            </div>
            <Badge
              v-if="data.paid_price"
              :value="$wonCurrency(data.paid_price)"
              style="top: -30%"
              class="absolute"
              :severity="'warning'"
            />
          </div>
        </template>
      </Column>
      <Column header="할인율">
        <template #body="{ data }">
          <div class="flex justify-content-center relative">
            <div class="p-inputgroup">
              <InputNumber
                :disabled="data.discount_rate_is_disabled"
                class="p-inputnumber-sm"
                :inputStyle="{ width: '50px' }"
                v-model="data.discount_rate"
                @input="calculateSalesPrice($event, data.id)"
                mode="decimal"
                :min="0"
                :max="
                  data.dicount_limit < 100
                    ? data.dicount_limit
                    : /할인|해당없음/.test(data.discount_name)
                    ? 99
                    : 100
                "
                suffix="%"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-sm"
                @click="editableCheckFromSooga(false, data.id)"
                :disabled="
                  data.is_in_bom ||
                  data.discount_limit == 0 ||
                  /서비스|인플루언서|리얼모델/.test(data.discount_name)
                "
              ></Button>
            </div>
            <Badge
              v-if="data.discount_limit < 100"
              :value="data.discount_limit == 0 ? '할인불가' : data.discount_limit + '%'"
              style="top: -30%"
              class="absolute"
              :severity="data.discount_limit == 0 ? 'danger' : 'warning'"
            />
          </div>
        </template>
      </Column>
      <Column header="할인 및 서비스">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <Dropdown
              class="w-full"
              v-model="data.discount_name"
              :options="ticketingDiscountNames"
              optionLabel="name"
              optionValue="name"
              placeholder="해당없음"
              @change="selectDiscountNameFromSooga(data.id, data.discount_name)"
              :disabled="data.is_in_bom || data.discount_limit == 0"
              :inputStyle="{ width: '70px' }"
            />
          </div>
        </template>
      </Column>
      <Column header="작성자">
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <Tag
              style="min-width: 90px"
              icon="pi pi-user"
              :value="
                data.writer_name
                  ? data.writer_name
                  : storeGetters.getUserName
                  ? storeGetters.getUserName
                  : 'test_user'
              "
              v-tooltip="
                data.update_writer_name
                  ? `${showUpdateTimestamp(data.update_timestamp)}\n편집됨\n${
                      data.update_writer_name
                    }`
                  : ''
              "
            ></Tag>
          </div>
        </template>
      </Column>
      <Column header="삭제" bodyStyle="width: 100px">
        <template #body="{ data }">
          <div class="relative flex justify-content-center">
            <div class="ml-auto mr-auto" v-if="!data.is_paid">
              <Button
                class="p-button-sm p-button-danger"
                @click="
                  deleteSoogaSurgery(
                    data.id,
                    data.counseling_id ?? null,
                    data.counseling_surgery_id ?? null
                  )
                "
                :disabled="data.is_in_bom"
              >
                삭제</Button
              >
            </div>
            <div class="" v-if="data.is_paid">
              <Button
                label="변경"
                class="p-button-sm p-button-warning"
                @click="
                  store.commit('sidebarVisible');
                  store.commit('setIsUpselling', true);
                  store.commit('setOriginalSoogaData', data);
                "
                :disabled="
                  !store.state.counselingAndTreatStore.isCounseling ||
                  (data.paid_price && data.menu_id_before_change)
                "
              />
              <!-- :disabled="true" -->
              <Badge
                value="수납"
                severity="success"
                style="position: absolute; top: -10px; right: -10px"
              ></Badge>
            </div>
          </div>
        </template>
      </Column>

      <template #footer>
        <div class="flex justify-content-end">
          <div v-if="isDebug">
            <Button label="수가표 디버그용" @click="doDebug"> </Button>
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onBeforeUnmount,
  getCurrentInstance,
  onUpdated,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { errorHandling } from '../../../../util/errorHandler.js';
import { discountNames, discountValues } from '../OmniList';
import Swal from 'sweetalert2';

const isDebug = false;
const doDebug = () => {};

onUpdated(() => {
  sumOfSalesPrice.value = storeGetters.value.getSumOfSalesPriceOfSelectedSoogaList;
  sumOfDiscountRate.value = storeGetters.value.getSumOfDiscountRateOfSelectedSoogaList;

  if (selectedSoogaList.value.length !== 0 && sumOfSalesPrice.value === 0) {
    discountNameFromBatch.value = '실장서비스';
    sumOfDiscountRate.value = 100;
  } else {
    discountNameFromBatch.value = '해당없음';
  }
});

onBeforeUnmount(() => {
  store.commit('selectedSoogaListInit');
});

const toast = useToast();
const store = useStore();
const storeGetters = computed(() => store.getters);
const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const $formatDate = app.appContext.config.globalProperties.$formatDate;

const disabledSumOfSalesPrice = ref(true);
const disabledSumOfDiscountRate = ref(true);
const discountNameFromBatch = ref('해당없음');
const sumOfSalesPrice = ref();
const sumOfDiscountRate = ref(0);

const selectedSoogaList = ref();

watchEffect(() => {
  selectedSoogaList.value = store.state.counselingAndTreatStore.selectedSoogaList;
});

const showUpdateTimestamp = (timestamp) => {
  return $formatDate('datetime', timestamp);
};

// 할인 및 서비스
const ticketingDiscountNames = ref(discountNames);

const formatCurrency = (value) => {
  if (value) {
    return value.toLocaleString('ko-KR');
  } else {
    return '';
  }
};

function editableCheckFromSooga(isSalesPrice, menuId) {
  const soogaDataList = storeGetters.value.getSelectedSoogaList;

  const soogaData = soogaDataList.filter((soogaData) => soogaData.id === menuId)[0];

  const discountName = soogaData.discount_name;

  let salesPriceIsDisabled;
  let discountRateIsDisabled;

  if (discountName === '해당없음') {
    Swal.fire({
      icon: 'warning',
      title: '할인 및 서비스 입력',
      text: '할인 및 서비스 중 해당없음은 판매가 및 할인율을 조정할 수 없습니다.',
    });

    // 해당없음일 경우 판매가, 할인율 disable
    salesPriceIsDisabled = true;
    discountRateIsDisabled = true;
  } else if (/서비스|인플루언서|리얼모델/.test(discountName)) {
    Swal.fire({
      icon: 'warning',
      title: '서비스 할인율 및 금액 변경',
      text: '할인 및 서비스 중 서비스 항목은 판매가 및 할인율을 조정할 수 없습니다.',
    });

    // 서비스일 경우 판매가, 할인율 disable
    salesPriceIsDisabled = true;
    discountRateIsDisabled = true;
  } else {
    if (isSalesPrice) {
      // 판매가에서 들어왔을 경우 할인율 disable
      salesPriceIsDisabled = false;
      discountRateIsDisabled = true;
    } else {
      // 할인율에서 들어왔을 경우 판매가 disable
      salesPriceIsDisabled = true;
      discountRateIsDisabled = false;
    }
  }
  store.commit('setSelectedSoogaDataDetail', {
    key: 'sales_price_is_disabled',
    menuId: menuId,
    value: salesPriceIsDisabled,
  });
  store.commit('setSelectedSoogaDataDetail', {
    key: 'discount_rate_is_disabled',
    menuId: menuId,
    value: discountRateIsDisabled,
  });
}

function selectDiscountNameFromSooga(menuId, discountName) {
  // 해당 수가표의 vat 포함가 가져오기
  const priceIncludeVat = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'price_include_vat',
    menuId: menuId,
  });
  const menuCount = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'menu_count',
    menuId: menuId,
  });

  // set 할인율
  store.commit('setSelectedSoogaDataDetail', {
    key: 'discount_rate',
    menuId: menuId,
    value: discountValues[discountName],
  });
  // set 판매가
  store.commit('setSelectedSoogaDataDetail', {
    key: 'sales_price',
    menuId: menuId,
    value: priceIncludeVat * menuCount * ((100 - discountValues[discountName]) / 100),
  });
  // set 할인 및 서비스
  store.commit('setSelectedSoogaDataDetail', {
    key: 'discount_name',
    menuId: menuId,
    value: discountName,
  });

  // set 판매가 disable
  store.commit('setSelectedSoogaDataDetail', {
    key: 'sales_price_is_disabled',
    menuId: menuId,
    value: true,
  });

  // set 할인율 disable
  store.commit('setSelectedSoogaDataDetail', {
    key: 'discount_rate_is_disabled',
    menuId: menuId,
    value: true,
  });
}

function editableCheckFromBatch(isSalesPrice) {
  let salesPriceIsDisabled;
  let discountRateIsDisabled;

  if (discountNameFromBatch.value === '해당없음') {
    Swal.fire({
      icon: 'warning',
      title: '할인 및 서비스 입력',
      text: '할인 및 서비스 중 해당없음은 판매가 및 할인율을 조정할 수 없습니다.',
    });

    // 해당없음일 경우 판매가, 할인율 disable
    salesPriceIsDisabled = true;
    discountRateIsDisabled = true;
  } else {
    if (isSalesPrice) {
      // 판매가에서 들어왔을 경우 할인율 disable
      salesPriceIsDisabled = false;
      discountRateIsDisabled = true;
    } else {
      // 할인율에서 들어왔을 경우 판매가 disable
      salesPriceIsDisabled = true;
      discountRateIsDisabled = false;
    }
  }

  disabledSumOfSalesPrice.value = salesPriceIsDisabled;
  disabledSumOfDiscountRate.value = discountRateIsDisabled;
}

const selectDiscountNameFromBatch = (discountName) => {
  // set 할인율
  sumOfDiscountRate.value = discountValues[discountName];

  const originalSumOfPrice = storeGetters.value.getSumOfPriceOfSelectedSoogaList;
  // set 판매가
  sumOfSalesPrice.value =
    originalSumOfPrice * ((100 - discountValues[discountName]) / 100);

  // set 판매가 disable
  disabledSumOfSalesPrice.value = true;
  // set 할인율 disable
  disabledSumOfDiscountRate.value = true;
};

/**
 * 수가표 수량 변경에 따른 판매가 계산하기
 * @param {event} event
 * @param {number} menuId
 */
const changeMenuCount = (event, menuId) => {
  // 기존 가격 가져오기(vat 포함가)
  const priceIncludeVat = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'price_include_vat',
    menuId: menuId,
  });
  // 기존 할인율 가져오기
  const discountRate = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'discount_rate',
    menuId: menuId,
  });

  const salesPrice = ((100 - discountRate) / 100) * (priceIncludeVat * event.value);

  // set 판매가
  store.commit('setSelectedSoogaDataDetail', {
    key: 'sales_price',
    menuId: menuId,
    value: salesPrice,
  });
};

/**
 * 선택된 할인율 변경에 따른 수가표에서 판매가 계산하기
 * @param {event} event
 * @param {number} menuId
 */
const calculateSalesPrice = (event, menuId) => {
  // 기존 가격 가져오기(vat 포함가)
  const priceIncludeVat = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'price_include_vat',
    menuId: menuId,
  });

  const menuCount = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'menu_count',
    menuId: menuId,
  });

  // 제한 할인율 가져오기
  const discountLimit = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'discount_limit',
    menuId: menuId,
  });

  const discountName = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'discount_name',
    menuId: menuId,
  });

  let salesPrice = 0;
  let discountRate = 0;
  if (event.value >= 100) {
    if (/할인/.test(discountName) && event.value == 100) {
      Swal.fire({
        icon: 'warning',
        title: '할인 및 서비스 입력',
        text: '할인항목에서 할인율을 100%로 조정할 수 없습니다. 서비스항목으로 변경해주세요.',
      });
      salesPrice = priceIncludeVat * menuCount;
      discountRate = 0;
    } else {
      salesPrice = 0;
      discountRate = 100;
    }
  } else if (event.value <= 0) {
    salesPrice = priceIncludeVat * menuCount;
    discountRate = 0;
  } else {
    // 할인제한일 경우, 할인율 제한하기
    if (event.value > discountLimit) {
      event.value = discountLimit;
    }
    // 판매가 계산
    salesPrice = ((100 - event.value) / 100) * (priceIncludeVat * menuCount);
    discountRate = event.value;
  }

  // set 판매가
  store.commit('setSelectedSoogaDataDetail', {
    key: 'sales_price',
    menuId: menuId,
    value: salesPrice,
  });

  // set 할인율
  store.commit('setSelectedSoogaDataDetail', {
    key: 'discount_rate',
    menuId: menuId,
    value: discountRate,
  });
};

async function minPrice(event, menuId) {
  const priceIncludeVat = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'price_include_vat',
    menuId: menuId,
  });

  const menuCount = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'menu_count',
    menuId: menuId,
  });

  const discountLimit = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'discount_limit',
    menuId: menuId,
  });

  // 업셀링된 수가표의 경우 이미 수납된 금액보다 판매총액이 높아야 함
  const paidPrice = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'paid_price',
    menuId: menuId,
  });
  // 최대할인율 적용했을 시, 판매총액
  const minSalesPrice = priceIncludeVat * menuCount * (1 - discountLimit / 100);

  let limitPrice = 0;
  let discountRate = 0;
  if (paidPrice) {
    // 할인제한 최소 금액 vs 기존에 수납된 금액 중에 높은 금액으로 지정
    limitPrice = Math.max(minSalesPrice, paidPrice);
    discountRate = Math.round((1 - limitPrice / priceIncludeVat) * 100 * 100) / 100;
  } else {
    limitPrice = minSalesPrice;
    discountRate = discountLimit;
  }

  if (parseInt(event.value.replaceAll(',', '')) < limitPrice) {
    await new Promise(() =>
      setTimeout(() => {
        // set 판매가
        store.commit('setSelectedSoogaDataDetail', {
          key: 'sales_price',
          menuId: menuId,
          value: limitPrice,
        });

        // set 할인율
        store.commit('setSelectedSoogaDataDetail', {
          key: 'discount_rate',
          menuId: menuId,
          value: discountRate,
        });
      }, 100)
    );
  }
}

/**
 * 선택된 할인율 변경에 따른 수가표에서 판매가 계산하기
 * @param {event} event
 * @param {number} menuId
 */
const calculateDiscountRate = (event, menuId) => {
  // 기존 가격 가져오기(vat 포함가)
  const priceIncludeVat = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'price_include_vat',
    menuId: menuId,
  });

  const menuCount = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'menu_count',
    menuId: menuId,
  });

  const discountName = storeGetters.value.getSelectedSoogaListDetailValue({
    key: 'discount_name',
    menuId: menuId,
  });

  let salesPrice;
  let discountRate;
  if (event.value >= priceIncludeVat * menuCount) {
    salesPrice = event.value;
    discountRate = 0;
  } else if (event.value <= 0) {
    if (/할인/.test(discountName) && event.value == 0) {
      Swal.fire({
        icon: 'warning',
        title: '할인 및 서비스 입력',
        text: '할인항목에서 판매가를 0원으로 조정할 수 없습니다. 서비스항목으로 변경해주세요.',
      });
      salesPrice = priceIncludeVat * menuCount;
      discountRate = 0;
    } else {
      salesPrice = 0;
      discountRate = 100;
    }
  } else {
    // 할인율 계산
    salesPrice = event.value;
    discountRate = 100 - (event.value / (priceIncludeVat * menuCount)) * 100;
  }

  // set 판매가
  store.commit('setSelectedSoogaDataDetail', {
    key: 'sales_price',
    menuId: menuId,
    value: salesPrice,
  });

  // set 할인율
  store.commit('setSelectedSoogaDataDetail', {
    key: 'discount_rate',
    menuId: menuId,
    value: discountRate,
  });
};

/**
 * 일괄할인 에서 판매가를 계산하는 함수
 * @param {event} event
 */
function calculateSalesPriceFromBatch(event) {
  const discountRate = event.value;
  const originalSumOfPrice = storeGetters.value.getSumOfPriceOfSelectedSoogaList; // vat 포함 가의 총합

  if (discountRate >= 100) {
    sumOfSalesPrice.value = 0;
    sumOfDiscountRate.value = 100;
  } else if (discountRate <= 0) {
    sumOfSalesPrice.value = originalSumOfPrice;
    sumOfDiscountRate.value = 0;
  } else {
    sumOfSalesPrice.value = originalSumOfPrice * ((100 - discountRate) / 100);
  }
}

/**
 * 일괄할인 에서 할인율을 계산하는 함수
 * @param {event} event
 */
function calculateDiscountRateFromBatch(event) {
  const salesPrice = event.value;
  const originalSumOfPrice = storeGetters.value.getSumOfPriceOfSelectedSoogaList;

  if (salesPrice >= originalSumOfPrice) {
    sumOfSalesPrice.value = salesPrice;
    sumOfDiscountRate.value = 0;
  } else if (salesPrice <= 0) {
    sumOfSalesPrice.value = 0;
    sumOfDiscountRate.value = 100;
  } else {
    sumOfDiscountRate.value =
      Math.round((100 - (salesPrice / originalSumOfPrice) * 100) * 100) / 100;
  }
}

/**
 * 일괄할인 에서 할인율 및 판매가를 적용하는 함수
 */
function applyDiscountFromBatch() {
  if (discountNameFromBatch.value === '해당없음' && sumOfDiscountRate.value !== 0) {
    Swal.fire({
      icon: 'warning',
      title: '할인 및 서비스 입력',
      text: '할인 및 서비스 중 해당없음은 판매가 및 할인율을 조정할 수 없습니다.',
    });
  } else {
    // store.commit('setDiscountFromBatch', {
    //   sumOfSalesPrice: sumOfSalesPrice.value,
    //   sumOfDiscountRate: sumOfDiscountRate.value,
    //   discountNameFromBatch: discountNameFromBatch.value,
    //   toast: toast,
    // });
    store.dispatch('calculateSumOfDiscount', {
      sumOfSalesPrice: sumOfSalesPrice.value,
      sumOfDiscountRate: sumOfDiscountRate.value,
      discountNameFromBatch: discountNameFromBatch.value,
      toast: toast,
    });
  }
}

async function deleteSoogaSurgery(menuId, counselingId, counselingSurgeryId) {
  try {
    if (counselingId) {
      const { isChecked, writer, counselerIsDoctor } =
        storeGetters.value.getDoctorCheckboxChecked;

      if (!isChecked) {
        // fasle 일 경우 save 멈춤
        toast.add({
          severity: 'warn',
          summary: '의사 선택',
          detail: '의사 선생님을 입력해주세요.',
          life: 3000,
        });
        return -1;
      }

      Swal.fire({
        title: '확인 바랍니다.',
        text: '상담내역을 삭제하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '네!',
        cancelButtonText: '아니오',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // DB에 아직 저장 안된 수가표는 axio call 하면 안됨
          store.dispatch('soogaSurgeryDelete', {
            counselingId: counselingId,
            writer: writer,
            counselerIsDoctor: counselerIsDoctor,
            menuId: menuId,
            counselingSurgeryId: counselingSurgeryId,
          });

          removeSoogaData(menuId);
        }
      });
    } else {
      removeSoogaData(menuId);
    }
  } catch (error) {
    errorHandling(toast, error);
  }
}

const removeSoogaData = (menuId) => {
  const soogaDataList = storeGetters.value.getSelectedSoogaList;

  const unRemovedSoogaDataList = soogaDataList.filter((soogaData) => {
    return soogaData.id !== menuId;
  });

  store.commit('changeSelectedSoogaData', unRemovedSoogaDataList);
};

// function checkIsModified() {
//   let isModified = false;
//   selectedSoogaList.value.forEach((element) => {
//     isModified = isModified || element.is_modified;
//   });
//   // 업셀링 한 수가표가 있을경우 true return
//   return isModified;
// }
</script>

<style scoped>
::v-deep(.discount-dropdown > span) {
  padding: 0.42rem 0.75rem;
}
</style>
