<template>
  <div class="mt-4">
    <DataTable
      :value="reserveData"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      filterDisplay="menu"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <h5 class="m-0">SmartCRM 예약 엑셀파일 업로드</h5>
          <div class="flex">
            <Button
              label="등록"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="registConfirm"
            />
            <FileUpload
              chooseLabel="파일 업로드"
              mode="basic"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :maxFileSize="10000000"
              :customUpload="true"
              :auto="true"
              @uploader="onUpload"
            />
          </div>
        </div>
      </template>
      <template #empty> 예약 파일을 업로드 해주세요. </template>
      <Column field="id" header="ID" sortable>
        <template #body="{ data }">
          {{ data.id }}
        </template>
      </Column>
      <Column field="reserveDate" header="예약일" dataType="date" sortable>
        <template #body="{ data }">
          {{ data.reserveDate }}
        </template>
      </Column>
      <Column field="reserveTime" header="예약시간" sortable>
        <template #body="{ data }">
          {{ data.reserveTime }}
        </template>
      </Column>
      <Column
        header="고객명"
        sortable
        sortField="customerName"
        :showFilterMatchModes="false"
      >
        <template #body="{ data }">
          {{ data.customerName }}
        </template>
      </Column>
      <Column field="customerBirth" header="고객번호" sortable dataType="date">
        <template #body="{ data }">
          {{ data.customerBirth }}
        </template>
      </Column>
      <Column field="customerPhone" header="연락처" sortable>
        <template #body="{ data }">
          {{ data.customerPhone }}
        </template>
      </Column>
      <Column
        field="customerAddr"
        header="주소"
        sortable
        :filterMenuStyle="{ width: '14rem' }"
      >
        <template #body="{ data }">
          {{ data.customerAddr }}
        </template>
      </Column>
      <Column field="treatDepartment" header="진료부서" sortable>
        <template #body="{ data }">
          {{ data.treatDepartment }}
        </template>
      </Column>
    </DataTable>
  </div>
  <ReserveMetaCrmDialog />
</template>

<script>
export default {
  name: 'ReserveData',
};
</script>

<script setup>
import { ref } from 'vue';
import * as XLSX from 'xlsx/xlsx.mjs';
import _ from 'lodash';
import { useStore } from 'vuex';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReserveMetaCrmDialog from '@/components/excel/ReserveMetaCrmDialog.vue';

const store = useStore();
const reserveData = ref([]);

const registConfirm = _.debounce(async (e) => {
  await sendReserveData();
}, 500);

async function sendReserveData() {
  if (reserveData.value && reserveData.value.length > 0) {
    try {
      store.state.loadingState = true;
      const result = await axios.post(
        store.state.URL + '/excel/reserve-data',
        reserveData.value
      );
      store.state.loadingState = false;

      Swal.fire({
        title: 'SmartCRM 예약 데이터 업데이트 완료',
        html:
          '중복 데이터 및 불필요 데이터 제거 후' +
          '<br>' +
          `고객 ${result.data.customer}건, 예약 ${result.data.reserve}건이 등록되었습니다.`,
        icon: 'success',
      }).then(() => {
        reserveData.value = null;
      });
    } catch (err) {
      if (!err.response.auth) window.location.href = store.state.URL + '/logout';
    }
  } else {
    Swal.fire('예약 데이터가 없습니다.', '예약 데이터를 업로드해주세요.', 'warning');
  }
}

async function onUpload(e) {
  store.state.loadingState = true;
  reserveData.value = [];
  const file = e.files[0];
  const data = await file.arrayBuffer();
  const workbook = await XLSX.read(data, { cellText: false, cellDates: true });
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const datas = XLSX.utils.sheet_to_json(worksheet, {
    raw: false,
    dateNF: 'yyyy"-"mm"-"dd" "hh":"mm":"ss',
  });

  const datasMap = datas
    .map((a, ai) => {
      if (/^[가-힣A-z]/.test(a['고객명'])) {
        const name = a['고객명']
          ? a['고객명'].match(/^[가-힣A-z\s]+/)
            ? a['고객명'].match(/^[가-힣A-z\s]+/)[0]
            : ''
          : '';
        const birthPre = a['고객번호']
          ? a['고객번호'].match(/^(\d{2})(\d{2})(\d{2})/)
          : '';
        const birth = birthPre
          ? `${parseInt(birthPre[1]) <= 22 ? '20' + birthPre[1] : '19' + birthPre[1]}-${
              birthPre[2] > 12 ? 12 : birthPre[2]
            }-${
              /01|03|05|07|08|10|12/.test(birthPre[2]) && parseInt(birthPre[3]) > 31
                ? '31'
                : /04|06|09|11/.test(birthPre[2]) && parseInt(birthPre[3]) > 30
                ? '30'
                : /02/.test(birthPre[2]) && parseInt(birthPre[3]) > 28
                ? '28'
                : birthPre[3] > 31
                ? '31'
                : birthPre[3]
            }`
          : '1900-01-01';

        const phone = a['연락처']
          ? a['연락처']
              .toString()
              .replace(/^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/, '01$1-$2-$3')
          : '';
        return {
          reserveDate: a['예약일'],
          reserveTime: a['예약시간'],
          customerName: name,
          customerBirth: birth,
          customerPhone: phone,
          customerAddr: a['주소'],
          treatDepartment: a['진료부서'],
          id: a.ID,
          rid: a.IDX,
        };
      }
    })
    .filter(
      (b) =>
        b &&
        !/^휴진|마세요|원장님|치료|층|^\*|취소|Dr|dr|dR|DR|마감|^x|^X/.test(
          b.customerName
        ) &&
        !/환불|OPC|상담C|opc|상담c/.test(b.treatDepartment)
    );

  reserveData.value = datasMap;
  store.state.loadingState = false;
}
</script>

<style scoped lang="scss">
::v-deep {
  .p-accordion-header-action {
    height: 10px;
  }
}
</style>
