import axios from 'axios';
import { errorHandling } from '@/util/errorHandler';
import Swal from 'sweetalert2';
import _ from 'lodash';

const copyBomActions = {
  //// BOM 이동/복사
  async doctorRegistSave({ rootState, commit }, payload) {
    try {
      const URL = rootState.URL;
      const result = await axios.post(URL + '/doctor-manage', {
        data: payload.value,
      });
      if (result.status === 200) {
        Swal.fire('저장이 완료되었습니다.', '', 'success').then(() => {
          commit('loadingChange');
          payload.callFn();
        });
      }
    } catch (error) {
      errorHandling(null, error);
    }
  },
  copySourceDoctorToSurgeryActive({ state, commit }) {
    if (state.copySourceDoctorSelected) {
      commit('copySourceDoctorToSurgerySeenCommit', true);
      commit('copySurgeryToTargetDoctorSeenCommit', false);
      commit('copySurgerySelectedCommit', undefined);
      commit('copyTargetDoctorSelectedCommit', undefined);
      //   copyTargetDoctorSelected.value = undefined;

      const doctorfilterCondition = _.uniqBy(state.menuCountData, function (element) {
        return [element.doctor_id, element.menu_id, element.confirm].join();
      })
        .filter((data) => data.doctor_id === state.copySourceDoctorSelected.doctor_id)
        .map((row) => row.menu_id);

      const surgeryFilteredData = state.surgeryData.filter((data) =>
        doctorfilterCondition?.includes(data.menu_id)
      );

      commit('copySurgeryFilteredDataCommit', surgeryFilteredData);
    } else if (!state.copySourceDoctorSelected) {
      commit('copySourceDoctorToSurgerySeenCommit', false);
      commit('copySurgeryToTargetDoctorSeenCommit', false);
      commit('copyTargetDoctorSelectedCommit', '');
    }
  },

  // 시/수술 선택 -> 붙여넣기할 원장 펼치기
  copySurgeryToTargetDoctorActive({ state, commit }) {
    if (
      state.copySourceDoctorSelected &&
      state.copySurgerySelected &&
      state.copySurgerySelected.length !== 0 &&
      state.menuCountData
    ) {
      commit('copySurgeryToTargetDoctorSeenCommit', true);

      const sourceDoctorToSurgerySelectedInfo = state.menuCountData?.filter(
        (data) =>
          data.menu_id ===
          state.copySurgerySelected[state.copySurgerySelected.length - 1].menu_id
      );

      const surgeryGroupByDocter = _.groupBy(
        sourceDoctorToSurgerySelectedInfo,
        'doctor_id'
      );
      commit(
        'copyRecentSelectedBomCommit',
        surgeryGroupByDocter[state.copySourceDoctorSelected.doctor_id]
      );
    } else {
      commit('copyTargetDoctorSelectedCommit', '');
      commit('copySurgeryToTargetDoctorSeenCommit', false);
      commit('copyRecentSelectedBomCommit', undefined);
    }
  },

  // 이동/복사 버튼 클릭 이벤트
  copyBomConfirm({ state, commit }) {
    if (!state.copySurgerySelected || state.copySurgerySelected.length === 0) {
      Swal.fire({
        icon: 'error',
        title: '시/수술을 선택해주세요.',
      });
    } else {
      commit('bomCopyStateCommit', true);
    }
  },

  // 최종 등록할 의사 이동/복사 버튼 함수
  async copyBomToDoctor({ rootState, state, commit, dispatch }) {
    if (
      state.copySourceDoctorSelected &&
      state.copySurgerySelected.length !== 0 &&
      state.copyTargetDoctorSelected &&
      state.menuCountData
    ) {
      const filterResult = state.menuCountData.filter(
        ({ menu_id, doctor_id }) =>
          state.copySurgerySelected.map((row) => row.menu_id).includes(menu_id) &&
          doctor_id === state.copySourceDoctorSelected.doctor_id
      );

      // source 의사 BOM 데이터를 가져와 target 의사들 BOM 데이터로 만드는 작업
      const result = {};
      const targetDoc = [];
      const copiedBom = [];
      state.copyTargetDoctorSelected.map((docId) => {
        targetDoc.push(docId);
        const copyMenu = [];
        filterResult.map((data) => {
          let temp = {
            menu_id: data.menu_id,
            doctor_id: docId,
            product_id: data.product_code,
            use_cnt: data.use_cnt,
            active: true,
          };
          copyMenu.push(temp);
        });
        copiedBom.push(...copyMenu);
      });
      result.inputData = copiedBom;
      result.sourceDoc = state.copySourceDoctorSelected.doctor_id;
      result.targetDoc = targetDoc;

      if (result) {
        try {
          const URL = rootState.URL;
          await axios
            .post(URL + `/bom-manage/bom-copy`, {
              data: result,
            })
            .then((res) => {
              if (res.data) {
                Swal.fire({
                  icon: 'success',
                  title: '복사 완료',
                  text: '선택하신 상품이 복사되었습니다.',
                });
                commit('bomCopyStateCommit', false);
                dispatch('copySurgeryToTargetDoctorActive');
              } else {
                Swal.fire({
                  icon: 'error',
                  title: '오류 발생. 데이터설계팀에 문의해주세요.',
                });
              }
            });
        } catch (error) {
          errorHandling(null, error);
        }
      }
    }
  },
};

export default copyBomActions;
