<template>
  <div>
    <DataTable
      class="p-datatable-customers p-datatable-sm"
      :rowHover="true"
      v-model:selection="selectedDoctors"
      :value="doctorInitData"
      :paginator="true"
      :rows="20"
      v-model:filters="filters"
      filterDisplay="menu"
      :globalFilterFields="['user_id', 'hospital', 'doc_nm']"
      editMode="row"
      @row-edit-save="onRowEditSave"
      v-model:editingRows="editingRows"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 40, 60, 100]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      responsiveLayout="scroll"
      removableSort
      dataKey="doc_id"
    >
      <template #header>
        <div class="flex align-items-center justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="회원ID, 이름, 소속병원"
            />
          </span>
        </div>
      </template>
      <template #empty> 데이터가 없습니다. </template>
      <template #loading> 데이터를 가져오는 중입니다. 잠시만 기다려주세요. </template>
      <!-- 삭제 선택 -->
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

      <!-- 회원ID -->
      <Column
        field="user_id"
        header="회원ID"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 10rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="회원ID를 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 소속부서 -->
      <Column
        field="department"
        header="소속부서"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 10rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="소속부서를 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <Dropdown
            :options="department"
            v-model="data[field]"
            optionLabel="name"
            optionValue="name"
          />
        </template>
      </Column>

      <!-- 소속파트 -->
      <Column
        field="part"
        header="소속파트"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 6rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="소속파트를 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <Dropdown
            :options="doctorPartTitles"
            v-model="data[field]"
            optionLabel="label"
            optionValue="label"
            @change="data['title'] = null"
          />
        </template>
      </Column>

      <!-- 직책 -->
      <Column
        field="title"
        header="직책"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 8rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="직책을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <Dropdown
            :options="
              data['part']
                ? doctorPartTitles.find((part) => part.label === data['part']).items
                : []
            "
            v-model="data[field]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </Column>

      <!-- 이름 -->
      <Column
        field="doc_nm"
        header="이름"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
        style="min-width: 8rem"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="의사명을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <!-- 입사일자 -->
      <Column
        header="입사일자"
        field="hired_date"
        dataType="date"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.dateMatchModeOptions"
        sortable
      >
        <template #body="{ data }">
          {{ data.hired_date ? $formatDate('date', data.hired_date) : '미등록' }}
        </template>
        <template #editor="{ data, field }">
          <span class="p-float-label">
            <Calendar dateFormat="yy-mm-dd" v-model="data[field]" />
          </span>
        </template>
      </Column>

      <!-- 퇴사일자 -->
      <Column
        header="퇴사일자"
        field="resigned_date"
        dataType="date"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.dateMatchModeOptions"
        sortable
      >
        <template #body="{ data }">
          {{ data.resigned_date ? $formatDate('date', data.resigned_date) : '재직중' }}
        </template>
        <template #editor="{ data, field }">
          <span class="p-float-label">
            <Calendar dateFormat="yy-mm-dd" v-model="data[field]" showButtonBar />
          </span>
        </template>
      </Column>

      <!-- 생년월일 -->
      <Column
        header="생년월일"
        field="birth"
        dataType="date"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.dateMatchModeOptions"
        sortable
      >
        <template #body="{ data }">
          {{ $formatDate('date', data.birth) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar
            v-model="filterModel.value"
            dateFormat="yy-mm-dd"
            placeholder="yyyy-mm-dd"
            @keyup.enter="filterCallback()"
          />
        </template>
        <template #editor="{ data, field }">
          <span class="p-float-label">
            <Calendar dateFormat="yy-mm-dd" v-model="data[field]" />
          </span>
        </template>
      </Column>

      <!-- 작성자 -->
      <Column
        field="writer"
        header="작성자"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        sortable
      >
        <template #body="{ data }">
          {{ data.writer ? data.writer : '없음' }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="이름을 입력하세요"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 회원가입일자 -->
      <Column
        header="회원가입일자"
        field="member_regist_date"
        dataType="date"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.dateMatchModeOptions"
        sortable
        style="max-width: 7rem"
      >
        <template #body="{ data }">
          {{ $formatDate('date', data.member_regist_date) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar
            v-model="filterModel.value"
            dateFormat="yy-mm-dd"
            placeholder="yyyy-mm-dd"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 원장등록일자 -->
      <Column
        header="원장등록일자"
        field="doc_regist_date"
        dataType="date"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.dateMatchModeOptions"
        sortable
        style="max-width: 7rem"
      >
        <template #body="{ data }">
          {{ $formatDate('date', data.doc_regist_date) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar
            v-model="filterModel.value"
            dateFormat="yy-mm-dd"
            placeholder="yyyy-mm-dd"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 수정 -->
      <Column
        :rowEditor="true"
        style="min-width: 2rem"
        icon="pi pi-pencil"
        class="p-button-rounded p-button-success"
      ></Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, watchEffect, getCurrentInstance, onBeforeMount } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useStore } from 'vuex';
import _ from 'lodash';
import Swal from 'sweetalert2';
import omniList from '../modal/modal_components/OmniList';

const store = useStore();
const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;

const editingRows = ref([]);

// 파트, 직책
const doctorPartTitles = ref();
// 소속 부서
const department = ref();

// column filter
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  user_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  department: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  part: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  title: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  hired_date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  resigned_date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  doc_nm: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  birth: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  writer: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  member_regist_date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  doc_regist_date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
});

const totalData = ref();
const totalDataCompare = ref();
const doctorInitData = ref();
const selectedDoctors = ref();

onBeforeMount(() => {
  doctorPartTitles.value = omniList.doctorPartTitles;
  department.value = omniList.department;
}),
  watchEffect(async () => {
    const data = store.state.doctorGetdata.doctorGetdataCompare
      ? store.state.doctorGetdata.doctorGetdataCompare
      : [];

    selectedDoctors.value && store.commit('selectedDoctorsCommit', selectedDoctors.value);
    if (!_.isEqual(data, totalDataCompare.value)) {
      totalData.value = _.cloneDeep(data);
      totalDataCompare.value = _.cloneDeep(totalData.value);

      doctorInitData.value = await totalData.value.doctorData;
      if (doctorInitData.value) {
        // 원장 생년월일, 등록일자, 원장등록일자, 입사일자, 퇴사일자 String -> Date 타입으로 변경
        doctorInitData.value.forEach((doctor, index) => {
          if (doctor.doc_nm === '공통BOM') {
            doctorInitData.value.splice(index, 1);
          }

          doctor.birth = new Date(doctor.birth);
          doctor.hired_date = doctor.hired_date ? new Date(doctor.hired_date) : null;
          doctor.resigned_date = doctor.resigned_date
            ? new Date(doctor.resigned_date)
            : null;
          doctor.member_regist_date = new Date(doctor.member_regist_date);
          doctor.doc_regist_date = new Date(doctor.doc_regist_date);
          doctor.birth = new Date(doctor.birth);
        });
      }
      // 원장 미등록 멤버
      totalData.value.unregisteredMemberData &&
        store.commit('unregisteredMemberCommit', totalData.value.unregisteredMemberData);
    }
  });

// 데이터 수정 이벤트
async function onRowEditSave(event) {
  let { newData, index } = event;
  if (newData) {
    if (_.isEqual(doctorInitData.value[findIndexById(newData.doc_id)], newData)) {
      Swal.fire('데이터를 수정한 뒤, 클릭해주세요.', '', 'warning');
    } else {
      if (newData.title === null || newData.title === undefined) {
        Swal.fire(
          '데이터에 빈값이 있습니다.',
          '데이터를 입력하고 다시 수정해주세요.',
          'warning'
        );
      } else {
        store.commit('updateDoctorsCommit', newData);
        store.commit('doctorUpdateStateCommit', true);
        doctorInitData.value[findIndexById(newData.doc_id)] = newData;
      }
    }
  }
}

// 데이터 인덱스를 찾아주는 함수
function findIndexById(id) {
  let index = -1;
  for (let i = 0; i < doctorInitData.value.length; i++) {
    if (doctorInitData.value[i].doc_id === id) {
      index = i;
      break;
    }
  }

  return index;
}
</script>
<script>
export default {
  name: 'DoctorDataTable',
};
</script>

<style></style>
