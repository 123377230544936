import _ from 'lodash';
import axios from 'axios';
import { errorHandling } from '@/util/errorHandler';
import Swal from 'sweetalert2';

const purchaseGetdata = {
  state() {
    return {
      purchaseGetdataCompare: '',
      productDialog: false,
      productSelected: '',
      productPurchase: '',
      productHospital: '',
      productStandard: '',
      productUnitWhenInput: '',
      selectedProducts: [],
      purchaseDeleteState: false,
      purchaseDt: '',
    };
  },
  mutations: {
    productDialogCommit(state, payload) {
      state.productDialog = payload;
    },
    productSelectedCommit(state, payload) {
      state.productSelected = payload;
    },
    selectedProductsCommit(state, payload) {
      state.selectedProducts = payload;
    },
    purchaseDeleteStateCommit(state, payload) {
      state.purchaseDeleteState = payload;
    },
    purchaseDtCommit(state, payload) {
      state.purchaseDt = payload;
    },
    purchaseinit(state) {
      state.purchaseGetdataCompare = '';
      state.productDialog = false;
      state.productSelected = '';
      state.productPurchase = '';
      state.productHospital = '';
      state.productStandard = '';
      state.productUnitWhenInput = '';
      state.selectedProducts = [];
      state.purchaseDeleteState = false;
      state.purchaseDt = '';
    },
  },
  actions: {
    async purchseProductSave({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.post(URL + '/purchase/save-product', payload.value);
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          Swal.fire(`${result.data.sort} 완료되었습니다.`, '', 'success').then(() => {
            payload.callFn();
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async purchaseDelete({ rootState, commit }, payload) {
      const URL = rootState.URL;
      const codeFilter = _.map(payload, (a) => {
        return a.product_code;
      });
      commit('loadingChangeValue', true);
      const result = await axios.post(URL + '/purchase/delete-many', {
        code: codeFilter,
      });
      commit('loadingChangeValue', false);

      if (result.status === 200) {
        Swal.fire('삭제가 완료되었습니다.', '', 'success').then(() => {
          commit('purchaseDeleteStateCommit', false);
        });
      }
    },
  },
  getters: {
    productUniqGetters(state) {
      const purchase = (state.productPurchase = _.uniqBy(
        state.purchaseGetdataCompare,
        (a) => {
          return a.purchase;
        }
      ).filter((b) => b.purchase));
      const hospital = (state.productHospital = _.uniqBy(
        state.purchaseGetdataCompare,
        (a) => {
          return a.hospital;
        }
      ).filter((b) => b.hospital));
      const standard = (state.productStandard = _.uniqBy(
        state.purchaseGetdataCompare,
        (a) => {
          return a.standard_name;
        }
      ).filter((b) => b.standard_name));
      const unitwheninput = (state.productUnitWhenInput = _.uniqBy(
        state.purchaseGetdataCompare,
        (a) => {
          return a.unit_when_input;
        }
      ).filter((b) => b.unit_when_input));
      const codes = _.map(state.purchaseGetdataCompare, (a) => {
        return a.purchase_code;
      });

      return {
        purchase,
        hospital,
        standard,
        unitwheninput,
        codes,
      };
    },
  },
};

export default purchaseGetdata;
