const editBomMutations = {
  // 입력/수정
  editSurgeryToDoctorCommit(state, payload) {
    state.editSurgeryToDoctor = payload;
  },
  editSurgeryToDoctorSeenCommit(state, payload) {
    state.editSurgeryToDoctorSeen = payload;
  },
  editDoctorToBomSeenCommit(state, payload) {
    state.editDoctorToBomSeen = payload;
  },
  editSurgeryToDoctorSelectedCommit(state, payload) {
    state.editSurgeryToDoctorSelected = payload;
  },
  editDoctorToBomSelectedCommit(state, payload) {
    state.editDoctorToBomSelected = payload;
  },
  editBomRegistSelectedCommit(state, payload) {
    state.editBomRegistSelected = payload;
  },
  editSurgeryToDoctorUniqueDocIdCommit(state, payload) {
    state.editSurgeryToDoctorUniqueDocId = payload;
  },
  editRegistedBomByDoctorCommit(state, payload) {
    state.editRegistedBomByDoctor = payload;
  },
  editRegistedBomCostCommit(state, payload) {
    state.editRegistedBomCost = payload;
  },
  doctorDialogCommit(state, payload) {
    state.doctorDialog = payload;
  },
};

export default editBomMutations;
