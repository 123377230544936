<template>
  <Toast position="top-right" group="cutomer-modal" />
  <div class="mx-8">
    <div class="grid">
      <div class="col-3"></div>
      <div class="col-6">
        <div class="pb-2 mb-3 border-bottom-1 border-400">
          <span class="text-2xl">고객정보수정</span>
        </div>
        <div class="grid">
          <div class="col-6">
            <div class="p-inputgroup mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-flag"></i>
              </span>
              <Dropdown
                v-model="v$.country.$model"
                :options="countryList"
                editable
                optionLabel="name"
                optionValue="name"
                placeholder="국가 선택"
                :class="{
                  'p-invalid': v$.country.$invalid && customerSubmit,
                }"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="p-inputgroup mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-camera"></i>
              </span>
              <Dropdown
                v-model="customer_data.marketing_label"
                :options="marketingLabelList"
                showClear
                optionLabel="description"
                optionValue="name"
                placeholder="마케팅 분류코드"
              />
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col-6">
            <div class="p-inputgroup mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <InputText
                placeholder="이름"
                v-model="v$.customer_name.$model"
                :class="{
                  'p-invalid': v$.customer_name.$invalid && customerSubmit,
                }"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="p-inputgroup mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-star-fill"></i>
              </span>
              <Dropdown
                v-model="customer_data.vip_level"
                :options="levels"
                optionLabel="name"
                optionValue="code"
                placeholder="고객등급 선택"
              />
            </div>
          </div>
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon">
            <i class="pi pi-calendar"></i>
          </span>
          <InputMask
            :class="{
              'p-invalid': v$.birth.$invalid && customerSubmit,
            }"
            mask="9999-99-99"
            v-model="v$.birth.$model"
            placeholder="생년월일"
            slotChar="yyyy-mm-dd"
          />
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon">
            <i class="pi pi-phone"></i>
          </span>
          <InputMask
            mask="999-9999-9999"
            placeholder="전화번호"
            v-model="v$.phone.$model"
            :class="{
              'p-invalid': v$.phone.$invalid && customerSubmit,
            }"
          />
        </div>
        <div class="p-inputgroup mb-2">
          <span
            class="p-inputgroup-addon"
            style="cursor: pointer"
            @click="getCustomerAddress"
          >
            <i class="pi pi-home"></i>
          </span>
          <InputText
            v-model="customer_data.zip_code"
            style="max-width: 8vw"
            disabled
            placeholder="우편번호"
          >
          </InputText>
          <InputText
            v-model="customer_data.address"
            disabled
            placeholder="주소"
          ></InputText>
        </div>
        <div class="grid mb-1">
          <div class="col-6">
            <div class="p-inputgroup">
              <div class="p-inputgroup-addon w-24rem">개인정보 활용동의</div>
              <div
                class="w-full flex justify-content-center align-items-center border-left-none border-1 border-gray-300 border-round-right-md"
              >
                <Checkbox :binary="true" v-model="customer_data.privacy_policy" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="p-inputgroup">
              <div class="p-inputgroup-addon w-24rem">마케팅 활용동의</div>
              <div
                class="w-full flex justify-content-center align-items-center border-left-none border-1 border-gray-300 border-round-right-md"
              >
                <Checkbox :binary="true" v-model="customer_data.marketing_policy" />
              </div>
            </div>
          </div>
        </div>
        <div class="grid mb-3">
          <div :class="{ 'col-6': recommenderChange, 'col-12': !recommenderChange }">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user-plus"></i>
              </span>
              <InputText
                placeholder="소개자"
                v-model="customer_data.recommender_name"
                disabled=""
              />
              <span class="p-inputgroup-addon">
                <Checkbox v-model="recommenderChange" :binary="true" />
              </span>
            </div>
          </div>
          <div v-if="recommenderChange" :class="{ 'col-6': recommenderChange }">
            <div class="p-inputgroup h-full">
              <span class="p-inputgroup-addon">
                <i class="pi pi-search"></i>
              </span>
              <Dropdown
                @input="inputFunc($event)"
                v-model="createdCustomerRecommederId"
                :options="customerList"
                optionLabel="info"
                optionValue="id"
                :editable="true"
                placeholder="추천인 검색"
                @change="clearRcommenderId"
                :showClear="true"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-content-end">
          <Button
            @click="customerUpdate"
            label="수정"
            icon="pi pi-check"
            class="p-button-sm"
          />
        </div>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  getCurrentInstance,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import axios from 'axios';
import _ from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import omniList from './OmniList';
dayjs.extend(isBetween);

const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $formatDate = app.appContext.config.globalProperties.$formatDate;

const store = useStore();
const toast = useToast();
const extraAddress = ref();
const address = ref();
const createdCustomerRecommederId = ref(null);
const customerList = ref([]);
const customer_data = ref({});
const customer_data_compare = ref({});
const recommenderChange = ref(false);
const customerSubmit = ref(false);
const marketingLabelList = ref();
const countryList = ref();
const levels = ref([
  { name: 'Normal', code: 0 },
  { name: 'VIP', code: 1 },
  { name: 'VVIP', code: 2 },
  { name: 'Black', code: 3 },
]);

onBeforeMount(() => {
  const timeoutData = {
    name: 'modal-customer',
    url: '/registration-modal/today_customer_info?customer_id=$customer_id',
    module: 'modalCustomer',
    compareData: 'modalCustomerCompare',
  };

  store.dispatch('timeoutCommonFunc', timeoutData);
  countryList.value = [{ name: '한국' }, ...omniList.foreignCountryList];
  marketingLabelList.value = omniList.marketingCustomerList;
});

onBeforeUnmount(() => {
  store.commit('deleteTimeout', 'modal-customer');
});

watchEffect(() => {
  const result = store.state.modalCustomer.modalCustomerCompare;
  customer_init(result);
});

async function customer_init(result) {
  const oldValue = customer_data_compare.value;
  const newValue = result[0];

  if (!_.isEqual({ ...oldValue }, { ...newValue })) {
    customer_data.value = _.cloneDeep(result[0]); // back 서버에서 보낸 message 출력
    customer_data_compare.value = _.cloneDeep(result[0]);
  }
}

const birthReg = helpers.regex(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
const isDateBetween = (value) => {
  const birth = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(value)
    ? $formatDate('date', value)
    : null;
  if (birth) {
    const birthDayjs = dayjs($formatDate('date', birth));
    return birthDayjs.isBetween('1900-01-01', $formatDate('date'));
  } else {
    return false;
  }
};

const rules = {
  address: {},
  country: { required },
  birth: {
    required,
    birthReg,
    isDateBetween,
  },
  customer_name: { required },
  id: {},
  marketing_policy: {},
  modiRecommender: {},
  phone: { required },
  privacy_policy: {},
  recommender_birth: {},
  recommender_id: {},
  recommender_name: {},
  recommender_phone: {},
  vip_level: {},
  marketing_label: {},
  zip_code: {},
};

const v$ = useVuelidate(rules, customer_data);

const inputFunc = _.debounce(async (e) => {
  const inputValue = e.target.value;
  const inputSplit = inputValue
    .toString()
    .split(/\s/g)
    .filter((a) => a);

  const postResult = await CustomerPost(inputSplit[0], inputSplit[1]);

  customerList.value = postResult;
}, 500);

const clearRcommenderId = () => {
  if (!createdCustomerRecommederId.value) {
    customerList.value = [];
  }
};

async function CustomerPost(value1, value2) {
  store.commit('loadingChangeValue', true);
  const result = await axios.post(store.state.URL + '/status-board/customer-search', {
    value1,
    value2,
  });

  const resultData = result.data;
  const resultMap = resultData.map((a) => ({
    info: `${a.customer_name} / ${a.birth} / ${a.phone}`,
    id: a.id,
  }));

  return resultMap;
}

const getCustomerAddress = () => {
  new window.daum.Postcode({
    oncomplete: (data) => {
      if (extraAddress.value !== '') {
        extraAddress.value = '';
      }
      if (data.userSelectedType === 'R') {
        // 사용자가 도로명 주소를 선택했을 경우
        address.value = data.roadAddress;
      } else {
        // 사용자가 지번 주소를 선택했을 경우(J)
        address.value = data.jibunAddress;
      }

      // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
      if (data.userSelectedType === 'R') {
        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraAddress.value += data.bname;
        }
        // 건물명이 있고, 공동주택일 경우 추가한다.
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraAddress.value +=
            extraAddress.value !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
        if (extraAddress.value !== '') {
          extraAddress.value = `(${extraAddress.value})`;
        }
      } else {
        extraAddress.value = '';
      }
      // 우편번호를 입력한다.
      customer_data.value.zip_code = data.zonecode;
      customer_data.value.address = `${address.value} ${extraAddress.value}`;
    },
  }).open();
};

const customerUpdate = _.debounce(async () => {
  customerSubmit.value = true;
  const customerSandData = customer_data.value;
  // customerSandData.birth = $formatDate('date', customerSandData.birth);
  const birth = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(
    customerSandData.birth
  )
    ? $formatDate('date', customerSandData.birth)
    : '';
  const birthDayjs = dayjs(birth);
  customerSandData.modiRecommender = String(createdCustomerRecommederId.value);
  if (v$.value.$invalid) {
    const isValidateError =
      v$.value.country.$invalid ||
      v$.value.customer_name.$error ||
      v$.value.phone.$error ||
      v$.value.birth.$error ||
      !/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(customerSandData.birth) ||
      !birthDayjs.isBetween('1900-01-01', $formatDate('date'));

    if (v$.value.country.$invalid) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '소속 국가를 입력해주세요.',
        life: 3000,
      });
    }

    if (v$.value.customer_name.$error) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '이름을 다시 입력해주세요.',
        life: 3000,
      });
    }
    if (v$.value.phone.$error) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '연락처를 다시 입력해주세요.',
        life: 3000,
      });
    }
    if (
      v$.value.birth.$error ||
      !/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(customerSandData.birth) ||
      !birthDayjs.isBetween('1900-01-01', $formatDate('date'))
    ) {
      toast.add({
        severity: 'error',
        summary: '입력 다시 시도',
        detail: '생년월일을 다시 입력해주세요.',
        life: 3000,
      });
    }

    if (isValidateError) {
      $errorHandling(
        null,
        new Error(`
       고객의 이름, 연락처, 생년월일이 비어 있음
    `)
      );
    }
  } else {
    try {
      store.commit('loadingChangeValue', true);
      const result = await axios.put(
        store.state.URL + '/registration-modal/update_customer_info',
        customerSandData
      );
      if (result.data.is) {
        toast.add({
          severity: 'success',
          summary: '고객정보 수정완료',
          detail: '고객정보가 수정되었습니다.',
          group: 'cutomer-modal',
          life: 3000,
        });
        recommenderChange.value = false;
        createdCustomerRecommederId.value = '';
      }
    } catch (error) {
      $errorHandling(null, error);
    }
  }
}, 500);
</script>

<style scoped></style>
