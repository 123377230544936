<template>
  <div>
    <Dialog
      v-model:visible="store.state.soogaGetdata.productDialog"
      :style="{ width: '550px' }"
      header="상품 등록"
      :modal="true"
      class="p-fluid"
      @hide="closeSaveModal"
      :auto-z-index="false"
    >
      <div v-show="!isNewCategory" class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 대분류 </span>
        <Dropdown
          id="largeCategory"
          v-model="v$.productCategory.$model"
          :options="productCategory"
          optionLabel="large_category"
          placeholder="대분류를 선택하세요."
          required="true"
          :class="{ 'p-invalid': v$.productCategory.$invalid && soogaSubmit }"
        >
        </Dropdown>
        <Button
          icon="pi pi-plus"
          v-tooltip="'대분류 신규추가'"
          @click="
            isNewCategory = !isNewCategory;
            v$.productCategory.$model = null;
          "
        />
      </div>
      <div v-show="isNewCategory" class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 신규 대분류 </span>
        <InputText
          v-model="v$.productCategory.$model"
          :class="{ 'p-invalid': v$.productCategory.$invalid && soogaSubmit }"
          placeholder="신규 대분류명을 입력하세요."
        />
        <Button
          icon="pi pi-minus"
          class="p-button-secondary"
          v-tooltip="'기존 대분류 사용'"
          @click="
            isNewCategory = !isNewCategory;
            v$.productCategory.$model = null;
          "
        />
      </div>
      <div class="flex">
        <div class="p-inputgroup mb-2 mr-2">
          <span class="p-inputgroup-addon w-8rem"> 중분류 </span>
          <InputText v-model="selectedProduct.middle_category" />
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon w-8rem"> 소분류 </span>
          <InputText v-model="selectedProduct.small_category" />
        </div>
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 약어 </span>
        <InputText
          v-model="selectedProduct.abbreviation"
          required="true"
          :class="{ 'p-invalid': v$.abbreviation.$invalid && soogaSubmit }"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 내용 </span>
        <Textarea v-model="selectedProduct.medical_menu_content" rows="3" cols="20" />
      </div>
      <hr class="my-3 mb-5" />
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 정상가 </span>
        <InputNumber v-model="selectedProduct.price" inputId="integeronly" />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 이벤트가 </span>
        <InputNumber v-model="selectedProduct.event_price" inputId="integeronly" />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> VAT 포함 </span>
        <InputNumber
          v-model="selectedProduct.price_include_vat"
          :model-value="
            (selectedProduct.price_include_vat = selectedProduct.event_price * 1.1)
          "
          inputId="integeronly"
          disabled
        />
      </div>
      <hr class="my-3 mb-5" />
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 최대할인율 </span>
        <InputNumber
          v-model="selectedProduct.discount_limit"
          suffix="%"
          :min="0"
          :max="100"
          inputId="integeronly"
        />
      </div>
      <div class="flex">
        <div class="p-inputgroup mb-2 mr-2">
          <span class="p-inputgroup-addon w-8rem"> 할인여부 </span>
          <InputText
            :value="
              selectedProduct.discount_limit == 0
                ? '할인불가능'
                : selectedProduct.discount_limit == 100
                ? '할인가능'
                : '제한적 할인가능'
            "
            :disabled="true"
          />
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon w-8rem"> 상담필요여부 </span>
          <ToggleButton
            v-model="selectedProduct.counseling_necessity"
            onLabel="필요"
            offLabel="불필요"
            onIcon="pi pi-check"
            offIcon="pi pi-times"
            class="w-auto sm:w-7 border-round-right"
            aria-label="상담필요여부"
          />
        </div>
      </div>
      <hr class="my-3 mb-5" />
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-8rem"> 비고 </span>
        <Textarea v-model="selectedProduct.note" rows="3" cols="20" />
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeSaveModal"
        />
        <Button
          label="저장"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { useStore } from 'vuex';
import { ref, watchEffect, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const selectedProduct = ref({});
const soogaSubmit = ref(false);

// 상품 등록 대분류
const productCategory = ref();

// 대분류 신규 추가 여부
const isNewCategory = ref(false);
const maxNewCategoryMenuId = ref();

// Vuelidate 규칙
const rules = {
  productCategory: { required },
  abbreviation: { required },
};

const v$ = useVuelidate(rules, selectedProduct);
const store = useStore();
const getters = computed(() => store.getters);

// store의 productSelected 변화 있을 경우, 변경 (처음 mount 될때도, 입력)
watchEffect(() => {
  selectedProduct.value = store.state.soogaGetdata.productSelected;
  uniqExecute();
});

// 등록모달 대분류 카테고리 select 항목 변경사항 바꿔주는 함수
function uniqExecute() {
  productCategory.value = getters.value.productCategoryGetter;
  maxNewCategoryMenuId.value = makeNewMenuId();
}

// 중복 클릭 방지
const closeSaveModal = _.debounce(() => {
  store.commit('productDialogCommit', false);
  selectedProduct.value = '';
  soogaSubmit.value = false;
}, 100);

//// 신규등록 모달
// 신규 대분류 코드생성을 위해 대분류 menuId를 생성
function makeNewMenuId() {
  const large_menu_list = [];
  productCategory.value.forEach((category) => {
    large_menu_list.push(parseInt(category.large_menu_id));
  });

  const large_menu_id = (_.max(large_menu_list) + 1).toString().padStart(5, '0');
  const small_menu_id = '1'.padStart(5, '0');
  return large_menu_id + small_menu_id;
}

// 신규 대분류 : 신규 menuId 생성 // 기존 대분류 : 기존 + menuId 새로 생성해주는 함수
function createNewMenuId(registData) {
  // 기존 대분류
  if (!isNewCategory.value) {
    const large_menu_id = registData.value.productCategory.large_menu_id;
    const small_menu_id = (registData.value.productCategory.max_small_menu_id + 1)
      .toString()
      .padStart(5, '0');
    return large_menu_id + small_menu_id;
  } else {
    // 신규 대분류
    // 대분류 카테고리 리스트화하여 최대값 + 1로 새로운 menuId 생성
    if (!maxNewCategoryMenuId.value) {
      return makeNewMenuId();
    } else {
      return maxNewCategoryMenuId.value;
    }
  }
}

// 신규등록 저장
const saveConfirm = _.debounce(() => {
  soogaSubmit.value = true;
  const registProduct = {};
  if (!v$.value.$invalid) {
    registProduct.value = selectedProduct.value;
    registProduct.value.menu_id = createNewMenuId(selectedProduct);

    // 신규, 기존 대분류 따른 값 변경
    registProduct.value.large_category = selectedProduct.value.productCategory
      .large_category
      ? selectedProduct.value.productCategory.large_category
      : selectedProduct.value.productCategory;
    delete registProduct.value.productCategory;

    store.commit('loadingChangeValue', true);
    store.dispatch('soogaProductSave', {
      value: registProduct.value,
      callFn: closeSaveModal,
    });
    store.commit('loadingChangeValue', false);

    // 초기화
    isNewCategory.value = false;
  }
}, 500);
</script>
<script>
export default {
  name: 'SoogaDialog',
};
</script>

<style scoped lang="scss">
::v-deep(.p-inputnumber) {
  width: 1% !important;
}
</style>
