<template>
  <div class="flex justify-content-center align-items-center" id="firstpage">
    <LoginCenter />
  </div>
  <GenerateId />
  <FindId></FindId>
</template>

<script>
import LoginCenter from '@/components/login/LoginCenter.vue';
import GenerateId from '@/components/login/GenerateId.vue';
import FindId from '@/components/login/FindId.vue';

export default {
  name: 'LoginPage',
  components: { LoginCenter, GenerateId, FindId },
};
</script>

<style scoped>
#firstpage {
  background-image: url('@/assets/loginPage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: inherit;
  height: 98vh;
}
</style>
