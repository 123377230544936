<template>
  <!-- <Toast position="top-right" /> -->
  <DataTable
    :value="receptionDataTable"
    showGridlines
    v-if="receptionState"
    responsiveLayout="scroll"
    style="width: 99%"
  >
    <ColumnGroup type="header">
      <Row>
        <Column header="접수" rowspan="2" style="width: 9%"></Column>
        <Column header="상담" rowspan="2" style="width: 9%"></Column>
        <Column header="수납" rowspan="2" style="width: 9%"></Column>
        <Column header="수술준비" colspan="3"></Column>
        <Column header="디자인(진료)" rowspan="2" style="width: 9%"></Column>
        <Column header="수/시술" colspan="3"></Column>
        <Column header="귀가" rowspan="2" style="width: 9%"></Column>
      </Row>
      <Row>
        <Column header="세안" style="width: 9%"></Column>
        <Column header="사진" style="width: 9%"></Column>
        <Column header="동의서" style="width: 9%"></Column>
        <Column header="환복" style="width: 9%"></Column>
        <Column header="수/시술" style="width: 9%"></Column>
        <Column header="회복" style="width: 9%"></Column>
      </Row>
    </ColumnGroup>
    <Column v-for="item in topBoard" :key="item" class="relative">
      <template #body="data">
        <div class="text-center">
          <div v-if="data.data[item][0]">
            <span class="m-auto" v-if="data.data[item][0].floor">
              {{ data.data[item][0]['floor'] }} <br />
            </span>
          </div>
          <div class="flex justify-content-center align-items-center">
            <div
              v-if="data.data[item][0] && data.data[item][0]?.time !== '00:00'"
              class="flex flex-column"
              :class="{ 'mr-2': data.data[item][1]?.time ? true : false }"
            >
              <span class="m-auto">{{ data.data[item][0]['time'] }}</span>
            </div>
            <div v-if="data.data[item][1]?.time" class="flex flex-column">
              <span class="m-auto">{{ data.data[item][1]['time'] }}</span>
            </div>
          </div>
          <div>
            <span
              class="m-auto"
              v-if="data.data[item][0]?.name && data.data[item][0]?.time !== '00:00'"
              >{{ data.data[item][0]['name'] }}</span
            >
            <span
              class="m-auto text-blue-500 font-medium"
              v-if="
                data.data[item][0]?.name &&
                data.data[item][0]?.time === '00:00' &&
                item === '상담'
              "
              >{{ data.data[item][0]?.name + '(배정)' }}</span
            >
          </div>

          <Badge
            v-if="record[item] === '0' ? false : record[item]"
            severity="info"
            class="absolute"
            style="right: 0"
            :style="{
              top: data.data[item].length === 1 ? '-20%' : '-30%',
            }"
            >{{ record[item] }}</Badge
          >
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  getCurrentInstance,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

const app = getCurrentInstance();
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;

const store = useStore();

const receptionData = ref(null);
const receptionDataCompare = ref(null);
const receptionDataTable = ref();
const receptionState = ref(false);
const record = ref({});

const topBoard = ref([
  '접수',
  '상담',
  '수납',
  '세안',
  '사진',
  '동의서',
  '디자인(진료)',
  '환복',
  '수/시술',
  '회복',
  '귀가',
]);

onBeforeMount(async () => {
  // 실시간업데이트 데이터 설정하는 곳
  const timeoutBoardData = {
    name: 'modal-top-board',
    url: '/registration-modal/board_modal_init_customer_board_data?code=$customer_id&date=$boardDate',
    module: 'modalTop',
    compareData: 'modalTopBoardCompare',
  };
  store.dispatch('timeoutCommonFunc', timeoutBoardData);

  const timeoutCustomerVisit = {
    name: 'modal-top-customer-visit',
    url: '/registration-modal/board_modal_customer_visit?code=$customer_id&date=$boardDate',
    module: 'modalTop',
    compareData: 'modalTopCustomerVisitCompare',
  };
  store.dispatch('timeoutCommonFunc', timeoutCustomerVisit);
});

onBeforeUnmount(() => {
  // 실행되던 부분을 없애는 곳
  store.commit('deleteTimeout', 'modal-top-board');
  store.commit('deleteTimeout', 'modal-top-customer-visit');
});

watchEffect(() => {
  // store에 저장되는 부분을 이곳에서 현제 페이지 변수로 넣기
  const result = store.state.modalTop.modalTopBoardCompare;
  const result2 = store.state.modalTop.modalTopCustomerVisitCompare;
  topBoardInit(result, result2);
});

async function topBoardInit(result, result2) {
  const oldTopBoardData = receptionDataCompare.value;
  if (!_.isEqual({ ...oldTopBoardData }, { ...result.receiption })) {
    store.commit(
      'boardReciptionMutation',
      result.receiption.length > 0 ? result.receiption[0].reception_id : ''
    );
    receptionData.value = result.receiption;
    receptionDataCompare.value = _.cloneDeep(result.receiption);
    receptionDataTable.value =
      receptionData.value.length > 0
        ? [
            {
              접수: receiptionFilter('접수'),
              상담: receiptionFilter('상담'),
              수납: receiptionFilter('수납'),
              세안: receiptionFilter('세안'),
              사진: receiptionFilter('사진'),
              동의서: receiptionFilter('동의서'),
              '디자인(진료)': receiptionFilter('진료'),
              환복: receiptionFilter('환복'),
              '수/시술': receiptionFilter('수/시술'),
              회복: receiptionFilter('회복'),
              귀가: receiptionFilter('귀가'),
            },
          ]
        : [];

    if (
      receptionDataTable.value.length > 0 &&
      Object.values(receptionDataTable.value[0]).some((a) => a)
    ) {
      receptionState.value = true;
    } else {
      receptionState.value = false;
    }
  }

  if (result2[0]) {
    record.value = result2[0];
    Object.keys(record.value).forEach(
      (a) => (record.value[a] = $wonCurrency(record.value[a]))
    );
  } else {
    record.value = []; // result2 가 없을 경우 처리
  }
}

function receiptionFilter(value) {
  const receptionFilter = receptionData.value.filter((a) => a.process === value);
  const result =
    receptionFilter.length === 0
      ? ''
      : receptionFilter.length > 0
      ? [
          {
            is: true,
            time: receptionFilter[0].start_time,
            name: receptionFilter[0].name,
            floor: receptionFilter[0].floor,
          },
          {
            is: false,
            time: receptionFilter[0].end_time,
            name: receptionFilter[0].name,
            floor: receptionFilter[0].floor,
          },
        ]
      : '';

  return result;
}
</script>

<script>
export default {
  name: 'ModalTopBoard',
};
</script>

<style></style>
