import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { errorHandling } from '@/util/errorHandler';

const editBomActions = {
  // 시/수술 선택 -> 의사 선택 펼치기 + 등록 데이터 넣어주는 함수
  surgeryToDoctorActive({ state, commit }) {
    if (state.editSurgeryToDoctorSelected) {
      commit('editSurgeryToDoctorSeenCommit', true);
      commit('editDoctorToBomSeenCommit', false);
      commit('editDoctorToBomSelectedCommit', '');
      commit('editBomRegistSelectedCommit', '');
      commit('isBomEditingCommit', false);

      const surgeryToDoctorSelectedInfo = state.menuCountData.filter(
        (data) => data.menu_id === state.editSurgeryToDoctorSelected
      );
      commit(
        'editSurgeryToDoctorUniqueDocIdCommit',
        _.sortedUniq(surgeryToDoctorSelectedInfo.map((row) => row.doctor_id))
      );
    } else if (!state.editSurgeryToDoctorSelected) {
      commit('editSurgeryToDoctorSeenCommit', false);
      commit('editDoctorToBomSeenCommit', false);
      commit('editBomRegistSelectedCommit', '');
    }
  },

  // 의사 선택 -> BOM 선택 펼치기
  doctorToBomActive({ state, commit, dispatch }) {
    if (state.editDoctorToBomSelected) {
      commit('editDoctorToBomSeenCommit', true);

      const surgeryToDoctorSelectedInfo = state.menuCountData.filter(
        (data) => data.menu_id === state.editSurgeryToDoctorSelected
      );

      const surgeryGroupByDocter = _.groupBy(surgeryToDoctorSelectedInfo, 'doctor_id');
      commit(
        'editRegistedBomByDoctorCommit',
        surgeryGroupByDocter[state.editDoctorToBomSelected.id]
      );

      if (state.editRegistedBomByDoctor) {
        // 기존과 새로 추가된 데이터 구분
        for (let i = 0; i < state.editRegistedBomByDoctor.length; i++) {
          state.editRegistedBomByDoctor[i].isNew = false;
        }
      }
    } else if (!state.editDoctorToBomSelected) {
      commit('editDoctorToBomSeenCommit', false);
      commit('editBomRegistSelectedCommit', '');
    }
    dispatch('editCalculateCost');
  },

  // 원가, 원가율 계산 기능
  async editCalculateCost({ state, commit }, payload) {
    if (state.editRegistedBomByDoctor && state.editSurgeryToDoctorSelected) {
      // 원가(VAT 포함)
      const selectedVatCost = state.surgeryData.filter(
        (data) => data.menu_id === state.editSurgeryToDoctorSelected
      )[0].price_include_vat;
      // 원가 계산
      var sumCost = 0;
      sumCost = [...state.editRegistedBomByDoctor].reduce((before, present) => {
        var input_cost = Number(present.cost_when_input);
        var modal_use_cnt;
        if (present.use_cnt) {
          modal_use_cnt = Number(present.use_cnt);
        } else {
          modal_use_cnt = 0;
        }

        if (payload) {
          const { event, productCode } = payload;
          if (present.product_code === productCode) {
            modal_use_cnt = Number(event.value);
          }
        }
        // 사용량 입력 시, 초기화 함수 중지
        commit('isBomEditingCommit', true);
        return before + input_cost * modal_use_cnt;
      }, 0);
      const costRate = Math.floor((sumCost / selectedVatCost) * 10000) / 100;
      commit('editRegistedBomCostCommit', [sumCost, costRate]);
    } else {
      commit('editRegistedBomCostCommit', [0, 0]);
    }
  },
  // BOM 선택하여 추가되는 함수
  async editBomRegistActive({ state, commit }) {
    if (
      state.editBomRegistSelected &&
      state.editSurgeryToDoctorSelected &&
      state.editDoctorToBomSelected
    ) {
      const registBomData = state.bomData.filter(
        (data) => data.product_code === state.editBomRegistSelected
      )[0];

      // 중복 상품일 경우 추가 X
      if (
        !registBomData ||
        (state.editRegistedBomByDoctor &&
          state.editRegistedBomByDoctor.filter(
            (data) => data.product_code === registBomData.product_code
          )[0])
      ) {
        Swal.fire({
          icon: 'error',
          title: '기존 상품이 존재합니다.',
          timer: 3000,
        });
      } else {
        const newProductRow = {
          bm_id: null,
          menu_id: state.editSurgeryToDoctorSelected,
          doctor_id: state.editDoctorToBomSelected.id,
          doc_nm: state.editDoctorToBomSelected.doc_nm,
          product_code: registBomData.product_code,
          product_name: registBomData.product_name,
          standard_name: registBomData.standard_name,
          cost_when_input: registBomData.cost_when_input,
          unit_when_input: registBomData.unit_when_input,
          use_cnt: undefined,
          isNew: true,
        };
        if (state.editRegistedBomByDoctor) {
          await state.editRegistedBomByDoctor.push(newProductRow);
        } else {
          commit('editRegistedBomByDoctorCommit', await new Array(newProductRow));
        }
      }
    }
  },

  // 상품 제거 기능 (새로운 상품, 기존 등록 상품인지 확인)
  editRemoveProductRow({ state, commit, dispatch }, product_code) {
    const filtered = state.editRegistedBomByDoctor.filter(
      (element) => element.product_code == product_code
    )[0];
    if (!filtered.isNew) {
      commit('bomDeleteStateCommit', true);
      commit('bomDeleteCategoryCommit', 'edit');
      commit('bomDeleteDataCommit', filtered);
    } else {
      commit(
        'editRegistedBomByDoctorCommit',
        state.editRegistedBomByDoctor.filter(
          (element) => element.product_code !== filtered.product_code
        )
      );
    }
    dispatch('editCalculateCost');
  },
  // 기존 등록 상품 제거
  async editRemoveOldProduct({ rootState, state, commit, dispatch }, filtered) {
    const URL = rootState.URL;
    const deleteId = state.editRegistedBomByDoctor.filter(
      (element) => element.bm_id == filtered.data.bm_id
    )[0].bm_id;
    if (deleteId) {
      try {
        await axios
          .delete(URL + `/bom-manage`, {
            data: [deleteId],
          })
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: 'success',
                title: '삭제 완료',
                text: '선택하신 상품이 삭제되었습니다.',
              });
              commit(
                'editRegistedBomByDoctorCommit',
                state.editRegistedBomByDoctor.filter(
                  (element) => element.bm_id !== deleteId
                )
              );
              commit('bomDeleteStateCommit', false);
              commit('bomDeleteCategoryCommit', '');
              dispatch('surgeryToDoctorActive');
            } else {
              Swal.fire({
                icon: 'error',
                title: '다시 시도해주세요.',
              });
            }
          });
      } catch (error) {
        errorHandling(null, error);
      }
    }
  },

  // 상품 등록 전, DB에 맞는 데이터 형식으로 변경하는 함수
  editConvertProduct({ state }) {
    if (state.editRegistedBomByDoctor) {
      const newInputData = [];

      state.editRegistedBomByDoctor.forEach((data) => {
        newInputData.push({
          menu_id: data.menu_id,
          doctor_id: data.doctor_id,
          product_id: data.product_code,
          use_cnt: data.use_cnt ? data.use_cnt : null,
        });
      });

      // const totalData = { old: oldInputData, new: newInputData };

      return newInputData;
    }
  },
  // 컨펌 modal 관련 함수
  editProductSaveConfirm({ state, commit }) {
    if (
      state.editRegistedBomByDoctor.find(
        (data) => data.use_cnt === null || data.use_cnt === undefined
      )
    ) {
      Swal.fire({
        icon: 'error',
        title: '상품 사용량을 입력해주세요.',
      });
    } else {
      commit('bomRegistCategoryCommit', 'edit');
      commit('bomSubmittedCommit', true);
    }
  },

  // 상품 등록 기능
  async editRegistProduct({ rootState, state, commit, dispatch }) {
    const URL = rootState.URL;
    if (state.bomSubmitted && state.editRegistedBomByDoctor) {
      try {
        const registData = await dispatch('editConvertProduct');
        await axios
          .post(URL + `/bom-manage`, {
            data: registData,
          })
          .then((res) => {
            if (res.data) {
              Swal.fire({
                icon: 'success',
                title: '등록 완료',
                text: '선택하신 상품이 등록되었습니다.',
              });
              commit('bomSubmittedCommit', false);
              dispatch('surgeryToDoctorActive');
            } else {
              Swal.fire({
                icon: 'error',
                title: '다시 시도해주세요.',
              });
            }
          });
      } catch (error) {
        errorHandling(null, error);
      }
    }
  },
};

export default editBomActions;
