<template>
  <Dialog
    :closeOnEscape="false"
    :closable="true"
    :modal="true"
    :maximizable="true"
    :style="{ width: '95vw', height: '70wh' }"
    @hide="closeModal"
    :auto-z-index="false"
  >
    <Toast />
    <template #header>
      <div>
        <span class="text-3xl font-medium text-cyan-800">
          {{
            store.state.board.boardModal.boardSendCutomerMarketingLabel
              ? `[${store.state.board.boardModal.boardSendCutomerMarketingLabel}]${store.state.board.boardModal.boardSendCutomerName} 고객님`
              : `${store.state.board.boardModal.boardSendCutomerName} 고객님`
          }}
        </span>
        <span class="text-3xl"> CRM 입력관리 </span>
      </div>
    </template>
    <div class="border-bottom-1 border-top-1 border-300 py-1">
      <ModalTopCustomer />
    </div>

    <div class="grid mt-1 border-bottom-1 border-300 pb-3">
      <div
        class="col-12 flex align-items-end"
        :class="{
          'justify-content-between': boardTitle().is,
          'justify-content-end': !boardTitle().is,
        }"
      >
        <p v-if="boardTitle().is" class="-mb-2 text-xl">{{ boardTitle().title }}</p>
        <ModalTopCalendar />
      </div>
      <ModalTopBoard />
    </div>

    <div class="grid mt-2">
      <div class="col-1" style="height: 59vh">
        <div
          class="border-1 border-300 border-round-md h-full w-full cursor-pointer text-center"
          style="display: table"
          id="inner-modal"
        >
          <div
            v-for="item in sideBar()"
            :key="item"
            class="tablerow"
            style="display: table-row"
          >
            <div
              @click="chooseMenu"
              class="tablecell border-round-md"
              style="display: table-cell; vertical-align:middle;}"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-11 relative">
        <div v-if="tee == '고객'">
          <customer_registration></customer_registration>
        </div>
        <div v-else-if="tee == '예약'">
          <reservation_registration></reservation_registration>
        </div>
        <div v-else-if="tee == '접수'">
          <reception_registration></reception_registration>
        </div>
        <div v-else-if="tee == '상담'">
          <counsel_registration></counsel_registration>
        </div>
        <div v-else-if="tee == '수납'">
          <Payment_modi />
        </div>
        <div v-else-if="tee == '세안'">
          <wash_face_registration></wash_face_registration>
        </div>
        <div v-else-if="tee == '사진'">
          <photo_registration></photo_registration>
        </div>
        <div v-else-if="tee == '동의서'">
          <agreement_registration></agreement_registration>
        </div>
        <div v-else-if="tee == '디자인(진료)'">
          <design_treatment_registration></design_treatment_registration>
        </div>
        <div v-else-if="tee == '환복'">
          <reunion_registration></reunion_registration>
        </div>
        <div v-else-if="tee == '수/시술'">
          <surgery_registration></surgery_registration>
        </div>
        <div v-else-if="tee == '회복'">
          <recovery_registration></recovery_registration>
        </div>
        <div v-else-if="tee == '귀가'">
          <homecoming_registration></homecoming_registration>
        </div>
        <div v-else>
          <div class="center">
            <p style="font-size: larger">좌측의 메뉴를 선택해주세요.</p>
          </div>
        </div>
      </div>
    </div>

    <ScrollTop
      target="parent"
      :threshold="200"
      id="custom-scrolltop"
      icon="pi pi-arrow-up"
      style="width: 30px; height: 30px; right: 0; bottom: 0"
    />
  </Dialog>
</template>

<script setup>
import customer_registration from './modal_components/customer_registration.vue';
import agreement_registration from './modal_components/agreement_registration.vue';
import counsel_registration from './modal_components/counsel_registration.vue';
import design_treatment_registration from './modal_components/design_treatment_registration.vue';
import homecoming_registration from './modal_components/homecoming_registration.vue';
import photo_registration from './modal_components/photo_registration.vue';
import reception_registration from './modal_components/reception_registration.vue';
import recovery_registration from './modal_components/recovery_registration.vue';
import reservation_registration from './modal_components/reservation_registration.vue';
import reunion_registration from './modal_components/reunion_registration.vue';
import surgery_registration from './modal_components/surgery_registration.vue';
import wash_face_registration from './modal_components/wash_face_registration.vue';
import Toast from 'primevue/toast';
import ModalTopCustomer from './moda_top/ModalTopCustomer.vue';
import ModalTopBoard from './moda_top/ModalTopBoard.vue';
import ModalTopCalendar from './moda_top/ModalTopCalendar.vue';
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import Payment_modi from './modal_components/payment_modi.vue';

// const app = getCurrentInstance();
// const $formatDate = app.appContext.config.globalProperties.$formatDate;
const store = useStore();

// 리팅 클리닉 수원
// const tee = ref(null);
const tee = ref('수/시술');
const sideBarList = ref([
  // '고객',
  // '예약',
  // '접수',
  // '상담',
  // '수납',
  // '세안',
  // '사진',
  // '동의서',
  // '디자인(진료)',
  // '환복',
  '수/시술',
  // '회복',
  // '귀가',
]);
const notToday = ref(['고객', '예약']);

const today = ref(
  new Date()
    .toLocaleString('en-US', {
      timeZone: 'Asia/Seoul',
      hour12: false,
    })
    .split(', ')[0]
    .split(/\//g)[2] +
    '-' +
    _.padStart(
      new Date()
        .toLocaleString('en-US', {
          timeZone: 'Asia/Seoul',
          hour12: false,
        })
        .split(', ')[0]
        .split(/\//g)[0],
      2,
      '0'
    ) +
    '-' +
    _.padStart(
      new Date()
        .toLocaleString('en-US', {
          timeZone: 'Asia/Seoul',
          hour12: false,
        })
        .split(', ')[0]
        .split(/\//g)[1],
      2,
      '0'
    )
);
// 리팅 클리닉 수원
// watchEffect(() => {
//   tee.value = store.state.board.sidebarValue;
// });

watchEffect(() => {
  if (new Date(store.state.board.boardModal.boardSendDate) > new Date(today.value)) {
    store.commit('boardSideBarValue', '');
  }
});

function sideBar() {
  if (
    new Date(store.state.board.boardModal.boardSendDate) <=
    new Date(today.value + ' 23:59:59')
  ) {
    return sideBarList.value;
  } else {
    return notToday.value;
  }
}

function chooseMenu(e) {
  const selectedValue = e.target.innerText;
  store.commit('boardSideBarValue', selectedValue);
  document
    .querySelectorAll('#inner-modal div')
    .forEach((a) => a.classList.remove('active'));
  e.target.classList.add('active');
}

function closeModal() {
  store.commit('boardSideBarValue', '');
  store.commit('boardSideBarValue', '');
  document
    .querySelectorAll('#inner-modal div')
    .forEach((a) => a.classList.remove('active'));

  store.commit('boardDateDataInput', { seletedDate: '' });
}
const boardTitle = () => {
  const titleDate = store.state.board.boardModal.boardSendDate;
  const titleReg = _.replace(
    titleDate,
    /^(\d{4})-(\d{2})-(\d{2})$/,
    '$1년 $2월 $3일 현황판'
  );
  const receptionIs = store.state.board.boardModal.boardReceptionCode;

  return { title: titleReg, is: receptionIs, a: titleDate };
};
</script>

<style scoped lang="scss">
.active {
  background-color: rgb(231, 231, 232);
}
.center {
  margin-top: 100px;
  text-align: center;
  color: blue;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  padding: 5px 5px;
}

::v-deep(.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td) {
  padding: 5px 5px;
}
</style>
