<template>
  <div>
    <Dialog
      v-model:visible="store.state.purchaseGetdata.productDialog"
      :style="{ width: '550px' }"
      header="상품 상세정보"
      :modal="true"
      class="p-fluid"
      @hide="closeSaveModal"
      :baseZIndex="1000"
      :autoZIndex="false"
    >
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> 상품코드 </span>
        <InputText
          v-model="selectedProduct.product_code"
          disabled
          @blur="changeCodeUpper($event)"
        />
      </div>
      <!-- <div class="flex">
        <div class="p-inputgroup mb-2 mr-2">
          <span class="p-inputgroup-addon w-8rem"> DB </span>
          <InputText v-model="selectedProduct.db_code" disabled />
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon w-8rem"> 구매팀 </span>
          <InputText
            v-model="v$.purchase_code.$model"
            :class="{
              'p-invalid': v$.purchase_code.$invalid && purchaseSubmmit,
            }"
          />
        </div>
      </div>
      <hr class="my-3 mb-5" /> -->
      <!-- <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> 구매처 </span>
        <Dropdown
          v-model="selectedProduct.purchase"
          :options="purchase"
          optionLabel="purchase"
          optionValue="purchase"
          :editable="true"
        />
      </div> -->
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> 병원명 </span>
        <InputText v-model="hospitalName" disabled />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> 상품명 </span>
        <InputText
          v-model="v$.product_name.$model"
          :class="{
            'p-invalid': v$.product_name.$invalid && purchaseSubmmit,
          }"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> 간호팀약어 </span>
        <InputText v-model="selectedProduct.nursing_abbreviation" />
      </div>
      <!-- <div class="flex">
        <div class="p-inputgroup mb-2 mr-2">
          <span class="p-inputgroup-addon w-8rem"> 규격 </span>
          <Dropdown
            v-model="selectedProduct.standard_name"
            :options="standard"
            optionLabel="standard_name"
            optionValue="standard_name"
            :editable="true"
          />
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon w-8rem"> 단위 </span>
          <InputText v-model="selectedProduct.unit_name" />
        </div>
      </div>
      <hr class="my-3 mb-5" /> -->
      <!-- <div class="flex">
        <div class="p-inputgroup mb-2 mr-2">
          <span class="p-inputgroup-addon w-8rem"> 최소구매단위 </span>
          <InputNumber v-model="selectedProduct.minimum" />
        </div>
        <div class="p-inputgroup mb-2">
          <span class="p-inputgroup-addon w-8rem"> 단가 </span>
          <InputNumber v-model="selectedProduct.cost" />
        </div>
      </div>
      <hr class="my-3 mb-5" /> -->
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> BOM 입력단위 </span>
        <Dropdown
          v-model="v$.unit_when_input.$model"
          :options="unitWhenInput"
          optionLabel="unit_when_input"
          optionValue="unit_when_input"
          :editable="true"
          :class="{
            'p-invalid': v$.unit_when_input.$invalid && purchaseSubmmit,
          }"
        />
      </div>
      <!-- <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> 나누는 </span>
        <InputNumber v-model="selectedProduct.divider" @blur="autoChange" />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-10rem"> BOM 원가 </span>
        <InputNumber v-model="selectedProduct.cost_when_input" @blur="autoChange" />
      </div>
      <hr class="my-3 mb-5" /> -->
      <!-- <div class="flex justify-content-center"> -->
      <!-- <div class="p-inputgroup mr-1">
          <span class="p-inputgroup-addon w-10rem"> BOM 조사품목 </span>
          <span class="p-inputgroup-addon w-8rem" style="background-color: white">
            <Checkbox v-model="selectedProduct.byactive" :binary="true" disabled />
          </span>
        </div> -->

      <div class="p-inputgroup">
        <span class="p-inputgroup-addon w-10rem"> 사용여부 </span>
        <span
          class="p-inputgroup-addon w-8rem"
          style="background-color: white; flex: 1 1 auto; width: 1%"
        >
          <Checkbox
            v-model="selectedProduct.active"
            :binary="true"
            :disabled="selectedProduct.newProduct"
          />
        </span>
      </div>
      <!-- </div> -->

      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeSaveModal"
        />
        <Button
          label="저장"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveConfirm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { useStore } from 'vuex';
import { ref, watchEffect, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const selectedProduct = ref({});
const purchase = ref();
const hospital = ref();
const standard = ref();
const unitWhenInput = ref();
const codes = ref();
const purchaseSubmmit = ref(false);
const hospitalName = ref('리팅성회외과 신사점');
const rules = {
  product_name: { required },
  unit_when_input: { required },
};

const v$ = useVuelidate(rules, selectedProduct);
const store = useStore();
const getters = computed(() => store.getters);

watchEffect(() => {
  selectedProduct.value = store.state.purchaseGetdata.productSelected;
  uniqExecute();
});

function uniqExecute() {
  const uniqValue = getters.value.productUniqGetters;
  purchase.value = uniqValue.purchase;
  hospital.value = uniqValue.hospital;
  standard.value = uniqValue.standard;
  unitWhenInput.value = uniqValue.unitwheninput;
  codes.value = uniqValue.codes;
}

const closeSaveModal = _.debounce(() => {
  store.commit('productDialogCommit', false);
  selectedProduct.value = '';
  purchaseSubmmit.value = false;
}, 100);

const changeCodeUpper = (e) => {
  v$.value.product_code.$model = _.toUpper(e.target.value);
};

const saveConfirm = _.debounce(() => {
  purchaseSubmmit.value = true;
  selectedProduct.value.hospital = hospitalName.value;
  if (selectedProduct.value.active) {
    selectedProduct.value.byactive = true;
  } else {
    selectedProduct.value.byactive = false;
  }

  const newProductIs = selectedProduct.value.newProduct;
  if (newProductIs) {
    if (!v$.value.$invalid) {
      store.commit('loadingChangeValue', true);
      store.dispatch('purchseProductSave', {
        value: selectedProduct.value,
        callFn: closeSaveModal,
      });
      store.commit('loadingChangeValue', false);
    }
  } else {
    if (!v$.value.product_name.$invalid) {
      store.commit('loadingChangeValue', true);
      store.dispatch('purchseProductSave', {
        value: selectedProduct.value,
        callFn: closeSaveModal,
      });
      store.commit('loadingChangeValue', false);
    }
  }
}, 500);

// const autoChange = _.debounce(() => {
//   if (
//     selectedProduct.value.divider &&
//     selectedProduct.value.cost_when_input &&
//     selectedProduct.value.unit_when_input
//   ) {
//     selectedProduct.value.byactive = true;
//   } else {
//     selectedProduct.value.byactive = false;
//   }
// }, 300);
</script>
<script>
export default {
  name: 'PurchaseDialog',
};
</script>

<style></style>
