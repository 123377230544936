import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import store from './store/index';
import globalMethods from './util/global';
import VueCookies from 'vue-cookies';
import axios from 'axios';
axios.defaults.withCredentials = true;

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import SplitButton from 'primevue/splitbutton';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import StyleClass from 'primevue/styleclass';
import Card from 'primevue/card';
import ScrollTop from 'primevue/scrolltop';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import InputNumber from 'primevue/inputnumber';
import ProgressBar from 'primevue/progressbar';
import Slider from 'primevue/slider';
import FileUpload from 'primevue/fileupload';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import ToggleButton from 'primevue/togglebutton';
import Editor from 'primevue/editor';
import OverlayPanel from 'primevue/overlaypanel';
import InputMask from 'primevue/inputmask';
import Listbox from 'primevue/listbox';
import Sidebar from 'primevue/sidebar';
import SelectButton from 'primevue/selectbutton';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Badge from 'primevue/badge';
import Divider from 'primevue/divider';
import Fieldset from 'primevue/fieldset';
import RadioButton from 'primevue/radiobutton';
import Tag from 'primevue/tag';
import Menubar from 'primevue/menubar';
import Toolbar from 'primevue/toolbar';

// BoardNav.vue
import TabMenu from 'primevue/tabmenu';
import MegaMenu from 'primevue/megamenu';

// BoardTable.vue
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup'; //optional for column grouping
import Row from 'primevue/row'; //optional for row

import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.css';

// BomManage.vue
import ConfirmationService from 'primevue/confirmationservice';
import BadgeDirective from 'primevue/badgedirective';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(globalMethods);
app.use(VueCookies);

// PrimeVUe
app.use(PrimeVue);
app.use(ToastService);

// BomManage.vue
app.use(ConfirmationService);

// StyleClass manages css classes declaratively to during enter/leave animations or just to toggle classes on an element.
app.directive('styleclass', StyleClass);
app.directive('badge', BadgeDirective);

// Tooltip
app.directive('tooltip', Tooltip);
app.component('InputText', InputText);
//eslint-disable-next-line
app.component('Button', Button);
//eslint-disable-next-line
app.component('Toast', Toast);
app.component('SplitButton', SplitButton);
//eslint-disable-next-line
app.component('Checkbox', Checkbox);
//eslint-disable-next-line
app.component('Dialog', Dialog);
//eslint-disable-next-line
app.component('Password', Password);
//eslint-disable-next-line
app.component('Dropdown', Dropdown);
//eslint-disable-next-line
app.component('Calendar', Calendar);
app.component('MultiSelect', MultiSelect);
app.component('TabMenu', TabMenu);
app.component('MegaMenu', MegaMenu);
app.component('DataTable', DataTable);
//eslint-disable-next-line
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
//eslint-disable-next-line
app.component('Row', Row);
app.component('FileUpload', FileUpload);
app.component('ProgressSpinner', ProgressSpinner);
//eslint-disable-next-line
app.component('Card', Card);
app.component('ScrollTop', ScrollTop);
//eslint-disable-next-line
app.component('Message', Message);
app.component('InputNumber', InputNumber);
app.component('ProgressBar', ProgressBar);
//eslint-disable-next-line
app.component('Slider', Slider);
//eslint-disable-next-line
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
//eslint-disable-next-line
app.component('Textarea', Textarea);
app.component('InputSwitch', InputSwitch);
app.component('ToggleButton', ToggleButton);
//eslint-disable-next-line
app.component('Editor', Editor);
app.component('OverlayPanel', OverlayPanel);
app.component('InputMask', InputMask);
//eslint-disable-next-line
app.component('Listbox', Listbox);
//eslint-disable-next-line
app.component('Sidebar', Sidebar);
app.component('SelectButton', SelectButton);
app.component('TriStateCheckbox', TriStateCheckbox);
//eslint-disable-next-line
app.component('Badge', Badge);
//eslint-disable-next-line
app.component('Divider', Divider);
//eslint-disable-next-line
app.component('Fieldset', Fieldset);
app.component('RadioButton', RadioButton);
//eslint-disable-next-line
app.component('Tag', Tag);
//eslint-disable-next-line
app.component('Menubar', Menubar);
//eslint-disable-next-line
app.component('Toolbar', Toolbar);

// mount
app.mount('#app');
