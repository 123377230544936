<template>
  <Dialog
    v-model:visible="store.state.login.generateModalIS"
    :header="cookieIs ? '계정생성' : '추가정보 입력'"
    position="center"
    :closeOnEscape="false"
    @hide="hideCreateId"
    :style="{ width: '500px' }"
    :modal="true"
    :baseZIndex="1000"
    :autoZIndex="false"
  >
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> e-mail </span>
        <InputText
          placeholder="e-mail"
          v-model="v$.email.$model"
          :class="{
            'p-invalid': v$.email.$invalid && (registerSubmitted || registerDuplicate),
          }"
          :disabled="duplicateButton"
          name="email"
        />
        <Button
          v-if="cookieIs"
          label="중복확인"
          class="p-button-outlined p-button-secondary w-6rem text-sm"
          id="id-duple"
          @click="duplicate"
        />
      </div>
      <div v-if="cookieIs" class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 비밀번호 </span>
        <Password
          v-model="v$.passValue1.$model"
          :class="{ 'p-invalid': v$.passValue1.$invalid && registerSubmitted }"
          name="password"
        />
      </div>
      <div v-if="cookieIs" class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 비밀번호확인 </span>
        <Password
          v-model="v$.passValue2.$model"
          :class="{ 'p-invalid': v$.passValue2.$invalid && registerSubmitted }"
          :feedback="false"
        />
      </div>
      <hr />
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 소속병원 </span>
        <Dropdown
          v-model="v$.selectedHospital.$model"
          :class="{
            'p-invalid': v$.selectedHospital.$invalid && registerSubmitted,
          }"
          :options="hospital ? hospital : []"
          optionLabel="name"
          optionValue="name"
          placeholder="소속선택"
          name="hospital"
          :baseZIndex="2102"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 소속부서 </span>
        <Dropdown
          v-model="v$.selectedDepartment.$model"
          :class="{
            'p-invalid': v$.selectedDepartment.$invalid && registerSubmitted,
          }"
          :options="department ? department : []"
          optionLabel="name"
          optionValue="name"
          placeholder="부서선택"
          name="department"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 이름 </span>
        <InputText
          placeholder="한글로만 기재해주세요."
          v-model="v$.username.$model"
          :class="{
            'p-invalid': v$.username.$invalid && registerSubmitted,
          }"
          name="name"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 생년월일 </span>
        <Calendar
          id="date"
          v-model="v$.birth.$model"
          :class="{
            'p-invalid': v$.birth.$invalid && registerSubmitted,
          }"
          :showIcon="true"
          dateFormat="yy-mm-dd"
          name="birth"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 핸드폰번호 </span>
        <InputText
          placeholder="핸드폰번호"
          v-model="v$.phone.$model"
          :class="{
            'p-invalid': v$.phone.$invalid && registerSubmitted,
          }"
          @input="changePhone"
          name="phone"
        />
      </div>
      <div class="p-inputgroup mb-2">
        <span class="p-inputgroup-addon w-7rem text-sm"> 요청권한 </span>
        <MultiSelect
          v-model="v$.selectdAuth.$model"
          :class="{
            'p-invalid': v$.selectdAuth.$invalid && registerSubmitted,
          }"
          :options="groupedCities ? groupedCities : []"
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          placeholder="권한요청"
        >
        </MultiSelect>
      </div>
      <div class="flex justify-content-end">
        <Button @click="createId" type="submit" label="계정생성" />
      </div>
    </form>
  </Dialog>
</template>

<script setup>
import { email, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import { ref, onBeforeMount, computed, onMounted, getCurrentInstance } from 'vue';
// import { useRouter } from 'vue-router';
import _ from 'lodash';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const $autoHypenPhone = app.appContext.config.globalProperties.$autoHypenPhone;

const store = useStore();
// const router = useRouter();
const nameReg = (value) => /^[가-힣]{2,6}$/.test(value);
const phoneReg = (value) => /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/.test(value);
const passSame = (value) => _.isEqual(state.value.passValue1, value);
const state = ref({
  email: '',
  passValue1: '',
  passValue2: '',
  selectedHospital: '',
  selectedDepartment: '',
  username: '',
  birth: '',
  phone: '',
  selectdAuth: '',
});
const rules = {
  email: {
    required,
    email,
  },
  passValue1: {
    required,
  },
  passValue2: {
    required,
    passSame,
  },
  selectedHospital: {
    required,
  },
  selectedDepartment: {
    required,
  },
  username: {
    required,
    nameReg,
  },
  birth: {
    required,
  },
  phone: {
    required,
    phoneReg,
  },
  selectdAuth: {
    required,
  },
};
const v$ = useVuelidate(rules, state);
const getters = computed(() => store.getters);

const cookieIs = ref(true);
const registerSubmitted = ref(false);
const duplicateButton = ref(false);
const registerDuplicate = ref(false);
const baseAuth = ref('');
const hospital = ref([
  { name: '리팅' },
  { name: '플란' },
  { name: '라미트' },
  { name: '다이트' },
  { name: '모모' },
]);
const department = ref('');
const groupedCities = ref('');

onBeforeMount(async () => {
  await store.dispatch('loginAuth');
  const loginAuthList = getters.value.loginAuthList;
  groupedCities.value = loginAuthList.authInfoUniq;
  baseAuth.value = loginAuthList.departmentObj;
  department.value = loginAuthList.department;
});

onMounted(async () => {
  await store.dispatch('loginGoogle');
  if (store.state.login.loginGoogleData) {
    cookieIs.value = false;
    duplicateButton.value = true;
    state.value = store.state.login.loginGoogleData;
    store.commit('generateModal', true);
  }
});

const createId = _.debounce(async () => {
  const customerData = {
    email: state.value.email,
    passValue1: state.value.passValue1,
    passValue2: state.value.passValue2,
    selectedHospital: state.value.selectedHospital,
    selectedDepartment: state.value.selectedDepartment,
    username: state.value.username,
    birth: state.value.birth ? $formatDate('date', state.value.birth) : '',
    phone: state.value.phone,
    selectdAuth: state.value.selectdAuth
      ? _.uniq(
          _.flattenDeep(
            state.value.selectdAuth.map((a) => {
              if (a.label === '기본 권한') {
                return [...baseAuth.value[state.value.selectedDepartment]];
              } else {
                return a.value;
              }
            })
          )
        ).join(',')
      : '',
  };

  const todayDayjs = dayjs(customerData.birth);

  if (v$.value.$invalid) {
    Swal.fire('항목 확인', '입력 항목을 확인하여 올바르게 입력 부탁드립니다.', 'warning');
  } else if (!duplicateButton.value) {
    Swal.fire('이메일 중복확인을 해주세요.', '', 'warning');
  } else if (v$.value.passValue2.$invalid) {
    Swal.fire('비밀번호가 맞지 않습니다.', '', 'warning');
  } else if (!todayDayjs.isBetween('1900-01-01', $formatDate('date'))) {
    Swal.fire('생년월일을 확인해주세요.', '', 'warning');
  } else {
    if (cookieIs.value) {
      store.commit('loadingChangeValue', true);
      try {
        await store.dispatch('loginRegist', customerData);
        store.commit('generateModal', false);
        Swal.fire(
          '회원가입이 완료되었습니다.',
          '기재한 이메일에서 가입인증해주세요.',
          'success'
        );
        store.commit('loadingChangeValue', false);
      } catch (error) {
        store.commit('loadingChangeValue', false);
        return;
      }
    } else {
      try {
        store.dispatch('googleRegist', customerData);
        store.commit('generateModal', false);
      } catch (error) {
        return;
      }
    }
  }
}, 250);

function handleSubmit(isFormValid) {
  registerSubmitted.value = true;

  if (!isFormValid) {
    return;
  }
}

function changePhone(e) {
  const inputValue = e.target.value;
  const inputReplace = inputValue.replace(/[^\d]/g, '');

  state.value.phone = $autoHypenPhone(inputReplace);
}

const duplicate = _.debounce(async () => {
  registerDuplicate.value = true;

  if (!v$.value.email.$model) {
    Swal.fire('이메일을 입력해주세요.', '', 'warning');
  } else if (!v$.value.email.$invalid) {
    const dupliData = await store.dispatch('loginEmailDuplication', state.value.email);

    if (dupliData.data) {
      Swal.fire('사용가능한 아이디 입니다.', '', 'success');
      duplicateButton.value = true;
    } else {
      Swal.fire('중복된 아이디가 있습니다.', '이메일을 확인해주세요.', 'info');
      duplicateButton.value = false;
    }
  }
}, 250);

async function hideCreateId() {
  Object.keys(state.value).forEach((a) => {
    state.value[a] = '';
  });
  await store.dispatch('loginLougOut');
  cookieIs.value = true;
  registerSubmitted.value = false;
  registerDuplicate.value = false;
  duplicateButton.value = false;
}
</script>

<script>
export default {
  name: 'GnerateId',
};
</script>

<style scoped lang="scss">
hr {
  border: solid 1px lightgray;
}
.swal2-container {
  z-index: 1500;
}

::v-deep(#id_duple .p-button-label) {
  font-size: 12px;
}

::v-deep(.p-datepicker) {
  z-index: 2102 !important;
}

::v-deep(.p-dropdown-panel) {
  z-index: 2102 !important;
}

::v-deep(.p-multiselect-panel) {
  z-index: 2102 !important;
}
</style>
