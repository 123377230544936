<script setup>
import { watchEffect, ref, getCurrentInstance, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import moduleSend from '../../../../util/van';
import _ from 'lodash';
import Swal from 'sweetalert2';
import WaitingDialog from './WaitingDialog.vue';

const app = getCurrentInstance();
// const $formatDate = app.appContext.config.globalProperties.$formatDate;
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;

const store = useStore();
const initDataCompare = ref();
const initData = ref();
const initHeader = ref();
const authList = ref();

watchEffect(() => {
  const oldValue = initDataCompare.value;
  const newValue = store.state.paymentStore.paymentInitWaitingData;

  if (!_.isEqual({ ...oldValue }, { ...newValue })) {
    initDataCompare.value = _.cloneDeep(store.state.paymentStore.paymentInitWaitingData);
    initData.value = _.cloneDeep(store.state.paymentStore.paymentInitWaitingData);
    initHeader.value = _.cloneDeep(store.state.paymentStore.paymentInitWaitingHeadData);
  }
});

onBeforeMount(() => {
  authList.value = store.state.userAuthData;
});

function soogi() {
  return (
    _.includes(authList.value, '000') ||
    _.includes(authList.value, '100') ||
    _.includes(authList.value, '110')
  );
}

function reTitle(is, value) {
  if (is) {
    return value.replace('결제', '환불');
  } else {
    return value;
  }
}

function checkIndex(a, i) {
  _.forEach(initHeader.value, (a) => {
    a.active = false;
  });
  if (a) {
    initHeader.value[i].active = true;
  }
}

const allDelete = _.debounce(() => {
  Swal.fire({
    title: '결제대기건을 모두 삭제하시겠습니까?',
    showCancelButton: true,
    confirmButtonText: '네',
    cancelButtonText: '아니오',
  }).then(async (res) => {
    if (res.isConfirmed) {
      const allPaymentWaitingId = _.map(initHeader.value, (a) => a.payment_waiting_id);
      await store.dispatch('paymentWaitingDelete', allPaymentWaitingId);
      Swal.fire('삭제가 완료되었습니다.', '', 'success');
    }
  });
}, 250);

const selectedDelete = _.debounce(() => {
  const selectedFilter = _.filter(initHeader.value, (a) => a.active === true);

  if (selectedFilter.length === 0) {
    Swal.fire('삭제할 결제대기건을 선택해주세요', '', 'warning');
  } else {
    Swal.fire({
      title: '선택하신 결제대기건을 삭제하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '네',
      cancelButtonText: '아니오',
    }).then(async (res) => {
      if (res.isConfirmed) {
        store.commit('paymentIndexActiveInit');
        const paymentWaitingId = [selectedFilter[0].payment_waiting_id];
        await store.dispatch('paymentWaitingDelete', paymentWaitingId);
        Swal.fire('삭제가 완료되었습니다.', '', 'success');
      }
      1;
    });
  }
});

const paymentWaitingExecution = _.debounce(async () => {
  const selectedFilter = _.filter(initHeader.value, (a) => a.active === true);

  if (selectedFilter.length === 0) {
    Swal.fire('결제할 결제대기건을 선택해주세요', '', 'warning');
    return;
  }

  const result = await store.dispatch('getCookieData')
  const isRefundCash = selectedFilter[0].payment_type === '현금결제' &&
                        selectedFilter[0].refund_active
  const REFUND_CASH_ABLE = ['경영지원', '병원운영']

  if (isRefundCash
      && !REFUND_CASH_ABLE.includes(result.userData[0].department)) {
    Swal.fire('현금 환불이 불가합니다\n 운영팀 문의 후 환불을 진행해주세요', '', 'warning');
  } else {
    const selectdSubData = initDataHeaderSub(selectedFilter[0].payment_group_id);
    store.commit('paymentDialogStateCommit', true);
    store.commit('selectdPaymentValueCommit', _.cloneDeep(selectedFilter)[0]);
    store.commit('selectdPaymentSubValueCommit', _.cloneDeep(selectdSubData));
    await connectionCheck();
  }
}, 250);

function initDataHeaderSub(value) {
  const filterValue = _.filter(
    initData.value,
    (a) => a.payment_group_id === value && a.payment_price
  );
  const filterMap = _.map(filterValue, (a) => ({
    menu_name: a.menu_name,
    payment_price: $wonCurrency(a.payment_price),
  }));
  return filterMap;
}

function initDataHeaderSubSum(value) {
  const filterValue = _.filter(initData.value, ['payment_group_id', value]);
  return $wonCurrency(_.sumBy(filterValue, 'payment_price'));
}

function topView() {
  return {
    paymentCount: _.filter(initHeader.value, (a) => {
      return !a.refund_active;
    }).length,
    paymentSum: $wonCurrency(
      _.sumBy(
        _.filter(initData.value, (a) => {
          return !a.refund_active;
        }),
        'payment_price'
      )
    ),
    refundCount: _.filter(initHeader.value, (a) => {
      return a.refund_active;
    }).length,
    refundSum: $wonCurrency(
      _.sumBy(
        _.filter(initData.value, (a) => {
          return a.refund_active;
        }),
        'payment_price'
      )
    ),
  };
}

async function connectionCheck() {
  const connectionCheckResponse = await moduleSend('***', '***', '***', null);

  if (connectionCheckResponse.recvBuf?.connection === '@@@') {
    store.commit('isConnectedToCatCommit', true);
  } else {
    store.commit('isConnectedToCatCommit', false);
  }
}
</script>

<template>
  <div class="border-bottom-1 border-400 relative pb-3">
    <h4 class="mb-2">대기</h4>
    <div class="absolute right-0" style="top: -8px">
      <Button
        label="전체삭제"
        class="p-button-outlined p-button-secondary p-button-sm mr-2"
        @click="allDelete"
      />
      <Button
        label="선택삭제"
        class="p-button-outlined p-button-danger p-button-sm mr-2"
        @click="selectedDelete"
      />
      <Button
        label="선택실행"
        class="p-button-outlined p-button-sm"
        @click="paymentWaitingExecution"
      />
    </div>
  </div>
  <div class="border-bottom-1 border-400 relative pb-3 mt-3 flex">
    <div class="col-6 align-itmes-center flex">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-chart-line"></i>
        </span>
        <div class="flex justify-content-center align-items-center input-group-div">
          <span v-if="topView().paymentCount" class="text-blue-600"
            >{{ topView().paymentCount }}건</span
          >
          <span v-if="topView().paymentCount && topView().refundCount"
            >&nbsp;/&nbsp;</span
          >
          <span v-if="topView().refundCount" class="text-red-600"
            >{{ topView().refundCount }}건</span
          >
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"> ₩ </span>
        <div
          class="flex justify-content-center align-items-center input-group-div flex-column"
        >
          <span v-if="topView().paymentSum" class="text-blue-600 mb-0">
            {{ topView().paymentSum }}
          </span>
          <span v-if="topView().refundSum" class="text-red-600 mb-0">
            {{ topView().refundSum }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <Accordion
      class="waiting-accordion relative"
      v-model:active-index="store.state.paymentStore.paymentWaitingActiveIndex"
    >
      <AccordionTab v-for="(a, i) in initHeader" :key="i">
        <template #header>
          <div class="flex justify-content-between align-items-center w-full">
            <div
              class="xl:text-base md:text-xs text-center w-3"
              :class="{
                'text-orange-500': /환불/.test(reTitle(a.refund_active, a.payment_type)),
              }"
            >
              {{
                a.payment_approval_date ? a.payment_approval_date : a.creation_timestamp
              }}
            </div>
            <div
              class="xl:text-base md:text-sm text-center w-3"
              :class="{
                'text-orange-500': /환불/.test(reTitle(a.refund_active, a.payment_type)),
              }"
            >
              {{ a.deposit_or_balance }}
            </div>
            <div
              class="xl:text-base md:text-sm text-center w-3"
              :class="{
                'text-orange-500': /환불/.test(reTitle(a.refund_active, a.payment_type)),
              }"
            >
              {{ `${a.soogi ? '수기' : ''}${reTitle(a.refund_active, a.payment_type)}` }}
            </div>
            <div
              class="xl:text-base md:text-sm text-center w-3"
              :class="{
                'text-orange-500': /환불/.test(reTitle(a.refund_active, a.payment_type)),
              }"
            >
              {{ initDataHeaderSubSum(a.payment_group_id) }}
            </div>
          </div>
          <div class="absolute" style="top: 6%; right: 1%">
            <Checkbox
              id="binary"
              :binary="true"
              v-model="a.active"
              @click="checkIndex(a.active, i)"
              :disabled="a.soogi && !soogi()"
            />
          </div>
        </template>

        <Fieldset
          v-for="(b, bi) in initDataHeaderSub(a.payment_group_id)"
          :key="bi"
          :class="{ 'mt-2': bi > 0 }"
        >
          {{ b.menu_name }}
          <template #legend>
            <div class="relative">
              {{ b.payment_price }}
              <div
                v-if="b.menu_id_before_change"
                class="absolute"
                style="top: -1.4em; right: -2.5em"
              >
                <Badge
                  :value="b.menu_id_before_change === b.menu_id ? '변경 전' : '변경 후'"
                  class="mr-2"
                  style="font-size: 13px"
                  :severity="b.menu_id_before_change === b.menu_id ? 'warning' : 'danger'"
                ></Badge>
              </div>
            </div>
          </template>
        </Fieldset>
      </AccordionTab>
    </Accordion>
  </div>

  <WaitingDialog />
</template>

<style scoped lang="scss">
.input-group-div {
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0;
  margin: 0;
  flex: 1 1 auto;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

::v-deep(.p-fieldset .p-fieldset-legend) {
  padding: 5px;
}

::v-deep(.p-fieldset .p-fieldset-content) {
  padding: 0;
}
</style>
