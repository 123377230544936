<template>
  <div class="surface-card shadow-2 p-5 border-round">
    <DataTable
      :value="customers"
      :loading="loading"
      :paginator="true"
      :rows="50"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 100]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      dataKey="reception_id"
      :rowHover="true"
      @row-dblclick="openModal($event)"
      v-model:filters="filters"
      filterDisplay="menu"
      removableSort
    >
      <ColumnGroup type="header">
        <Row>
          <Column
            header="이름"
            field="customer_name"
            sortable
            rowspan="2"
            headerStyle="width: 7%"
            :filterMatchModeOptions="store.state.matchModeOptions"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="이름 검색"
                @keyup.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            header="생년월일"
            field="birth"
            dataType="date"
            sortable
            rowspan="2"
            headerStyle="width: 8%"
            :filterMatchModeOptions="store.state.dateMatchModeOptions"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Calendar
                v-model="filterModel.value"
                dateFormat="yy-mm-dd"
                placeholder="yyyy-mm-dd"
                @keyup.enter="filterCallback()"
              />
            </template>
          </Column>
          <Column
            header="연락처"
            sortable
            field="phone"
            rowspan="2"
            headerStyle="width: 10%"
            :filterMatchModeOptions="store.state.matchModeOptions"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                locale="ko-KR"
                placeholder="연락처 검색"
                @keyup.enter="filterCallback()"
              >
              </InputText>
            </template>
          </Column>
          <!-- 리팅 클리닉 수원 -->
          <!-- <Column
            header="접수사유"
            field="reception_reason"
            rowspan="2"
            sortable
            headerStyle="width: 9%"
            :filterMatchModeOptions="store.state.matchModeOptions"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                locale="ko-KR"
                placeholder="접수사유 검색"
                @keyup.enter="filterCallback()"
              >
              </InputText>
            </template>
          </Column>
          <Column
            header="예상절차"
            field="after_process"
            rowspan="2"
            sortable
            headerStyle="width: 9%"
            :filterMatchModeOptions="store.state.matchModeOptions"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                v-model="filterModel.value"
                locale="ko-KR"
                placeholder="예상절차 검색"
                @keyup.enter="filterCallback()"
              >
              </InputText>
            </template>
          </Column>
          <Column header="접수" rowspan="2"></Column>
          <Column header="상담" rowspan="2"></Column>
          <Column header="수납" rowspan="2"></Column>
          <Column
            header="수술준비"
            colspan="3"
            headerStyle="justify-content: center;"
          ></Column>
          <Column header="디자인(진료)" rowspan="2"></Column>
          <Column header="수/시술" colspan="3"></Column>
          <Column header="귀가" rowspan="2"></Column> -->
        </Row>
        <!-- <Row>
          <Column header="세안"></Column>
          <Column header="사진"></Column>
          <Column header="동의서"></Column>
          <Column header="환복"></Column>
          <Column header="수/시술"></Column>
          <Column header="회복"></Column>
        </Row> -->
      </ColumnGroup>
      <template #loading> </template>
      <template #empty>
        <h1>Today No Reservation customers found.</h1>
      </template>

      <Column field="customer_name" class="font-semibold">
        <template #body="{ data }">
          <div
            class="flex justify-content-center align-items-center relative"
            :class="colorRow(data)"
            style="height: 60px; padding-right: 3rem"
          >
            <span>
              {{
                data.marketing_label
                  ? `[${data.marketing_label}]${data.customer_name}`
                  : data.customer_name
              }}
            </span>
            <Badge
              :value="data.restTime < 0 ? '오류' : data.restTime"
              class="absolute"
              style="top: 0; right: 0"
              v-if="
                !data.process?.귀가 &&
                data.restTime &&
                today === store.state.board.boardModal.boardSendDate
              "
              :severity="data.restTime < 0 ? 'warning' : ''"
            ></Badge>
          </div>
        </template>
      </Column>
      <Column field="birth" class="font-semibold">
        <template #body="{ data }">
          <div
            class="flex justify-content-center align-items-center"
            :class="colorRow(data)"
            style="height: 60px; padding-right: 2rem"
          >
            <span>
              {{ $formatDate('date', data.birth) }}
            </span>
          </div>
        </template>
      </Column>
      <Column field="phone" class="font-semibold">
        <template #body="{ data }">
          <div
            class="flex justify-content-center align-items-center"
            :class="colorRow(data)"
            style="height: 60px; padding-right: 2rem"
          >
            <span>
              {{ data.phone }}
            </span>
          </div>
        </template>
      </Column>
      <!-- 리팅 클리닉 수원 -->
      <!-- <Column field="reception_reason" class="font-semibold">
        <template #body="{ data }">
          <div
            v-if="/당일/.test(data.reception_reason)"
            class="flex justify-content-center align-items-center"
            :class="colorRow(data)"
            style="height: 60px"
          >
            <span>
              <div
                v-html="data.reception_reason.replace('당일수술', '당일수술<br>')"
                style="text-align: center"
              ></div>
            </span>
          </div>
          <div
            v-else-if="/퇴사경과/.test(data.reception_reason)"
            class="flex justify-content-center align-items-center"
            :class="colorRow(data)"
            style="height: 60px"
          >
            <span>
              <div
                v-html="data.reception_reason.replace('퇴사경과', '퇴사경과<br>(경과)')"
                style="text-align: center"
              ></div>
            </span>
          </div>
          <div
            v-else
            class="flex justify-content-center align-items-center"
            :class="colorRow(data)"
            style="height: 60px"
          >
            <span>
              {{ data.reception_reason }}
            </span>
          </div>
        </template>
      </Column>
      <Column field="after_process" class="font-semibold">
        <template #body="{ data }">
          <div
            class="flex justify-content-center align-items-center"
            :class="colorRow(data)"
            style="height: 60px"
          >
            <span>
              {{ data.after_process }}
            </span>
          </div>
        </template>
      </Column> -->
      <!-- <Column v-for="(a, i) in boardProcess" :key="i">
        <template #body="{ data }">
          <div
            class="flex flex-column justify-content-center align-items-center"
            :class="colorCell(data, a)"
            style="height: 60px"
          >
            <div v-if="a === '수납' && data.balance">
              {{ $wonCurrency(data.balance) }}
            </div>
            <div v-else-if="a === '수/시술' && data?.process['수/시술']?.floor">
              {{ data.process['수/시술'].floor }}
            </div>
            <div class="flex"> -->
      <!-- {{ boardColumnValue(a, data)?.time }} -->
      <!-- <div
                :class="{
                  'text-yellow-700 font-semibold':
                    timeCompare -
                      parseInt(boardColumnValue(a, data)?.startTime.replace(/:/g, '')) <
                      0 && today === store.state.board.boardModal.boardSendDate,
                }"
                v-if="boardColumnValue(a, data)?.startTime && a !== '상담'"
              >
                {{ boardColumnValue(a, data)?.startTime }}
              </div>
              <div
                :class="{
                  'text-yellow-700 font-semibold':
                    timeCompare -
                      parseInt(boardColumnValue(a, data)?.startTime.replace(/:/g, '')) <
                      0 && today === store.state.board.boardModal.boardSendDate,
                }"
                v-else-if="
                  a === '상담' && boardColumnValue(a, data)?.startTime !== '00:00'
                "
              >
                {{ boardColumnValue(a, data)?.startTime }}
              </div>
              <div v-if="boardColumnValue(a, data)?.endTime">&nbsp;&nbsp;</div>
              <div
                :class="{
                  'text-yellow-700 font-semibold':
                    timeCompare -
                      parseInt(boardColumnValue(a, data)?.endTime.replace(/:/g, '')) <
                      0 && today === store.state.board.boardModal.boardSendDate,
                }"
                v-if="boardColumnValue(a, data)?.endTime"
              >
                {{ boardColumnValue(a, data)?.endTime }}
              </div>
            </div>
            <div
              v-if="a === '상담' && boardColumnValue(a, data)?.startTime === '00:00'"
              class="text-blue-500 font-bold"
            >
              {{ boardColumnValue(a, data)?.writer + '(배정)' }}
            </div>
            <div v-else>
              {{ boardColumnValue(a, data)?.writer }}
            </div>
          </div>
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>

<script setup>
// 리팅 클리닉 수원
// import { boardProcess } from '../modal/modal_components/OmniList';
import _ from 'lodash';
import { ref, watchEffect, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { FilterOperator, FilterMatchMode } from 'primevue/api';
const app = getCurrentInstance();
// 리팅 클리닉 수원
// const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;
const $formatDate = app.appContext.config.globalProperties.$formatDate;

const store = useStore();
const compareData = ref({});

const customers = ref();
const loading = ref(true);
// 리팅 클리닉 수원
// const timeCompare = ref(
//   parseInt(
//     new Date()
//       .toLocaleString('en-US', {
//         timeZone: 'Asia/Seoul',
//         hour12: false,
//       })
//       .split(', ')[1]
//       .slice(0, 5)
//       .replace(/:/g, '')
//   )
// );
const today = ref(
  new Date()
    .toLocaleString('en-US', {
      timeZone: 'Asia/Seoul',
      hour12: false,
    })
    .split(', ')[0]
    .split(/\//g)[2] +
    '-' +
    _.padStart(
      new Date()
        .toLocaleString('en-US', {
          timeZone: 'Asia/Seoul',
          hour12: false,
        })
        .split(', ')[0]
        .split(/\//g)[0],
      2,
      '0'
    ) +
    '-' +
    _.padStart(
      new Date()
        .toLocaleString('en-US', {
          timeZone: 'Asia/Seoul',
          hour12: false,
        })
        .split(', ')[0]
        .split(/\//g)[1],
      2,
      '0'
    )
);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  customer_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  birth: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  phone: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  after_process: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  reception_reason: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
});

function colorRow(data) {
  if (today.value === store.state.board.boardModal.boardSendDate) {
    if (data.process?.귀가 || data.restTime === 0) {
      return 'surface-0';
    } else if (
      data.restTime >= 60 &&
      store.state.board.boardDataIs &&
      !(data.process?.귀가 || data.restTime === 0)
    ) {
      return 'bg-red-200';
    } else if (
      data.restTime >= 40 &&
      store.state.board.boardDataIs &&
      !(data.process?.귀가 || data.restTime === 0)
    ) {
      return 'bg-pink-200';
    } else if (
      data.restTime >= 20 &&
      store.state.board.boardDataIs &&
      !(data.process?.귀가 || data.restTime === 0)
    ) {
      return 'bg-orange-200';
    } else if (
      data.restTime >= 10 &&
      store.state.board.boardDataIs &&
      !(data.process?.귀가 || data.restTime === 0)
    ) {
      return 'bg-yellow-200';
    }
  }
}
// 리팅 클리닉 수원
// function colorCell(data, a) {
//   if (today.value === store.state.board.boardModal.boardSendDate) {
//     if (
//       a !== '접수' &&
//       a !== '수납' &&
//       a !== '귀가' &&
//       !data.process[a]?.end_time &&
//       data.process[a]?.start_time
//     ) {
//       return 'bg-blue-100';
//     } else if (
//       (a !== '접수' && a !== '수납' && a !== '귀가' && data.process[a]?.end_time) ||
//       ((a === '접수' || a === '수납' || a === '귀가') && data.process[a]?.start_time)
//     ) {
//       return 'bg-green-100';
//     } else if (a === '수납' && data.balance) {
//       return 'bg-yellow-100';
//     }
//   }
// }

watchEffect(() => {
  loading.value = store.state.board.boardLoading;
  const oldValue = compareData.value;
  const newValue = store.state.board.boardInitDataCompare;
  if (!_.isEqual({ ...oldValue }, { ...newValue })) {
    compareData.value = _.cloneDeep(newValue);
    if (store.state.board.boardInitDataCompare && store.state.board.sortState === null) {
      // 리팅 클리닉 수원
      customers.value = _.orderBy(
        store.state.board.boardInitDataCompare,
        (a) => a.customer_name,
        ['asc']
      );
    } else {
      if (store.state.board.boardInitDataCompare) {
        customers.value = _.orderBy(
          store.state.board.boardInitSort,
          (a) => a.customer_name,
          ['asc']
        );
      }
    }

    // 고객 생년월일 String -> Date 타입으로 변경
    if (customers.value) {
      customers.value.forEach((customer) => {
        customer.birth = new Date(customer.birth);
      });
    }
  }
});
// 리팅 클리닉 수원
// function boardColumnValue(field, data) {
//   if (data['process'][field]) {
//     return {
//       startTime: data['process'][field]['start_time']
//         ? data['process'][field]['start_time']
//         : '',
//       endTime:
//         data['process'][field]['end_time'] === null
//           ? ''
//           : data['process'][field]['end_time'],
//       time:
//         data['process'][field]['end_time'] === null
//           ? data['process'][field]['start_time']
//           : data['process'][field]['start_time'] +
//             ' ' +
//             data['process'][field]['end_time'],
//       writer: data['process'][field]['writer'],
//     };
//   } else {
//     return;
//   }
// }

const openModal = (e) => {
  store.commit('boardModalStateChange');
  store.commit('boardCustomerDataInput', {
    customer_id: e.data.customer_id,
    customer_name: e.data.customer_name,
    marketing_label: e.data.marketing_label,
  });
};
</script>
<script>
export default {
  name: 'BoardTableDataTable',
};
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  display: flex;
  justify-content: center;
}

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
  padding: 0;
}

::v-deep(.p-column-filter-menu) {
  margin-left: 0 !important;
}
</style>
