<template>
  <div>
    <Dialog
      v-model:visible="store.state.purchaseGetdata.purchaseDeleteState"
      :style="{ width: '450px' }"
      header="삭제확인"
      :modal="true"
      @hide="closeDeleteModal"
    >
      <div class="confirmation-content flex flex-wrap align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span class="vertical-align-text-bottom">
          <b>
            {{ deleteDataContents().zero }}
          </b>
          <b v-if="deleteDataContents().count"> 외 {{ deleteDataContents().count }}개 </b>
          를 삭제하시겠습니까?</span
        >
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDeleteModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const deleteDatas = ref();

watchEffect(() => {
  if (store.state.purchaseGetdata.purchaseDeleteState) {
    deleteDatas.value = store.state.purchaseGetdata.selectedProducts;
  }
});

function deleteDataContents() {
  return {
    count:
      _.reduce(
        deleteDatas.value,
        (a, b) => {
          return (a += 1);
        },
        0
      ) - 1,
    zero: deleteDatas.value[0].product_name,
  };
}

function closeDeleteModal() {
  store.commit('purchaseDeleteStateCommit', false);
}

function deleteExecution() {
  store.dispatch('purchaseDelete', deleteDatas.value);
}
</script>
<script>
export default {
  name: 'PurchaseDeleteDialog',
};
</script>

<style></style>
