<!-- 포토 -->
<template>
  <div
    class="absolute flex flex-column justify-content-center"
    style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
  >
    <div class="text-center">
      <p>버튼을 누르면 현재시간이 입력됩니다.</p>
      <p>시간을 입력하고 버튼을 누르면 버튼의 시간이 입력됩니다.</p>
    </div>
    <div class="grid">
      <div class="col-6">
        <div class="flex justify-content-center align-items-center">
          <StartButton class="mb-3" @click="clickButton('start')" />
        </div>
        <div class="flex justify-content-center align-items-center">
          <InputMask
            id="startButton"
            v-model="timeInput.start"
            mask="99:99"
            placeholder="24시:분"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="flex justify-content-center align-items-center">
          <EndButton class="mb-3" @click="clickButton('end')" />
        </div>
        <div class="flex justify-content-center align-items-center">
          <InputMask
            id="endButton"
            v-model="timeInput.end"
            mask="99:99"
            placeholder="24시:분"
          />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-3"></div>
      <div class="col-6">
        <DeleteButton class="mt-5" @click="deleteTime" />
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script setup>
import StartButton from './ModalSEButtons/StartButton.vue';
import EndButton from './ModalSEButtons/EndButton.vue';
import DeleteButton from './ModalSEButtons/DeleteButton.vue';
import { ref, onBeforeMount, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import _ from 'lodash';

const toast = useToast();
const app = getCurrentInstance();
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const store = useStore();
const sideBarValue = ref();
const receptionCode = ref();
const timeInput = ref({
  start: '',
  end: '',
});

onBeforeMount(() => {
  sideBarValue.value = store.state.board.sidebarValue;
  receptionCode.value = store.state.board.boardModal.boardReceptionCode;
});

const clickButton = _.debounce(async (id) => {
  const inputValue = timeInput.value[id];
  const nowTime = $formatDate('time', new Date());

  if (!receptionCode.value) {
    Swal.fire('접수 후 등록 부탁드립니다.', '', 'info');
  } else if (inputValue) {
    if (/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(inputValue)) {
      document.querySelector(`#${id}Button`).classList.remove('p-invalid');
      await registBoard(inputValue, id);
    } else {
      document.querySelector(`#${id}Button`).classList.add('p-invalid');
    }
  } else {
    await registBoard(nowTime, id);
  }
});

async function registBoard(time, id) {
  const sendData = {
    customerCode: store.state.board.boardModal.boardSendCutomerCode,
    receptionCode: store.state.board.boardModal.boardReceptionCode,
    date: store.state.board.boardModal.boardSendDate,
    time,
    sort: id,
    process: sideBarValue.value,
  };

  try {
    store.commit('loadingChangeValue', true);
    await axios.post(store.state.URL + '/registration-modal/photo', sendData);
    store.commit('loadingChangeValue', false);
  } catch (error) {
    $errorHandling(toast, error, clearFunc);
  }
}

async function deleteTime() {
  const sendData = {
    customerCode: store.state.board.boardModal.boardSendCutomerCode,
    receptionCode: store.state.board.boardModal.boardReceptionCode,
    date: store.state.board.boardModal.boardSendDate,
    process: sideBarValue.value,
  };

  try {
    store.commit('loadingChangeValue', true);
    await axios.delete(store.state.URL + '/registration-modal/photo', {
      data: sendData,
    });
    store.commit('loadingChangeValue', false);
  } catch (error) {
    $errorHandling(toast, error, clearFunc);
  }
}

function clearFunc() {}
</script>

<style scoped></style>
