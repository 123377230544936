// import _ from 'lodash';
import { formatDate } from '../../util/date';
import axios from 'axios';
import _ from 'lodash';
import { errorHandling } from '@/util/errorHandler';
import Swal from 'sweetalert2';

const myPageGetData = {
  state() {
    return {
      myAuthData: '',
      cookieData: '',
      myInfoData: '',
      isPassConfirm: false,
      modifySubmitted: false,
      cookieIs: false,
      onModify: false,
      isChangePass: false,
    };
  },
  mutations: {
    myAuthDataCommit(state, payload) {
      state.myAuthData = payload;
    },
    cookieDataCommit(state, payload) {
      state.cookieData = payload;
    },
    isPassConfirmCommit(state, payload) {
      state.isPassConfirm = payload;
    },
    modifySubmittedCommit(state, payload) {
      state.modifySubmitted = payload;
    },
    cookieIsCommit(state, payload) {
      state.cookieIs = payload;
    },
    onModifyCommit(state, payload) {
      state.onModify = payload;
    },
    isChangePassCommit(state, payload) {
      state.isChangePass = payload;
    },
    myInfoDataCommit(state, payload) {
      state.myInfoData = payload;
    },
    isAuthEditingCommit(state, payload) {
      state.isAuthEditing = payload;
    },
    myPageInit(state) {
      state.myAuthData = '';
      state.cookieData = '';
      state.myInfoData = '';
      state.isPassConfirm = false;
      state.modifySubmitted = false;
      state.cookieIs = false;
      state.onModify = false;
      state.isChangePass = false;
    },
  },
  actions: {
    async getCookieData({ rootState, commit }) {
      try {
        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.get(URL + '/social-verify');
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          commit('cookieDataCommit', result.data);
          return result.data;
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async getMyAuthData({ rootState, commit }) {
      try {
        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.get(URL + '/my-page/auth');
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          commit('myAuthDataCommit', result.data);
          return result.data;
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async confirmPassword({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        const query =
          (payload.userId ? `?userId=${payload.userId}` : '') +
          (payload.passValue ? `&passValue=${payload.passValue}` : '');
        commit('loadingChangeValue', true);
        const result = await axios.get(URL + `/my-page/certificate${query}`);
        commit('loadingChangeValue', false);

        if (result.status === 200 && result.data) {
          Swal.fire('비밀번호 확인이 완료되었습니다.', '', 'success').then(() => {
            commit('isPassConfirmCommit', true);
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    // 제출한 정보가 유효한지 확인하는 함수
    /** @param {boolean} isFormValid*/
    async handleSubmit({ state }, payload) {
      if (payload) {
        state.modifySubmitted = false;
        // 내정보 입력값에 따른 경고모달
        const customerData = {
          email: state.myInfoData.email.$model,
          oldPassValue: state.myInfoData.oldPassValue.$model,
          passValue1: state.myInfoData.passValue1.$model
            ? state.myInfoData.passValue1.$model
            : undefined,
          passValue2: state.myInfoData.passValue2.$model
            ? state.myInfoData.passValue2.$model
            : undefined,
          selectedHospital: state.myInfoData.selectedHospital.$model,
          selectedDepartment: state.myInfoData.selectedDepartment.$model,
          username: state.myInfoData.username.$model,
          birth: state.myInfoData.birth.$model
            ? formatDate('date', state.myInfoData.birth.$model)
            : undefined,
          phone: state.myInfoData.phone.$model,
        };
        const sendDataIs = _.values(customerData).every((a) => a);
        if (customerData.passValue1 && customerData.passValue2 && !sendDataIs) {
          Swal.fire({
            icon: 'warning',
            title: '내용을 모두 기입해주세요.',
            text: '내용을 모두 기입해야 수정이 가능합니다.',
          });
        } else if (customerData.passValue1 !== customerData.passValue2) {
          Swal.fire({
            icon: 'warning',
            title: '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
          });
        } else if (state.cookieData.userData[0].user_id !== customerData.email) {
          Swal.fire({
            icon: 'warning',
            title: '이메일 중복확인을 해주세요.',
          });
        } else if (!customerData.oldPassValue) {
          Swal.fire({
            icon: 'warning',
            title: '기존 비밀번호를 입력해주세요.',
          });
        } else if (!customerData.username || !customerData.birth || !customerData.phone) {
          Swal.fire({
            icon: 'warning',
            title: '정보를 입력해주세요.',
          });
        }
      } else {
        state.modifySubmitted = true;
      }
    },
    // 내정보 수정 초기화 버튼
    async resetForm({ state }, payload) {
      payload.email.$model = state.cookieData.userData[0].user_id;
      payload.selectedHospital.$model = state.cookieData.userData[0].hospital;
      payload.selectedDepartment.$model = state.cookieData.userData[0].department;
      payload.username.$model = state.cookieData.userData[0].name;
      payload.birth.$model = state.cookieData.userData[0].birth;
      payload.phone.$model = state.cookieData.userData[0].phone;
      payload.oldPassValue.$model = null;
      payload.passValue1.$model = null;
      payload.passValue2.$model = null;
      state.modifySubmitted = false;
      state.cookieIs = true;
      state.onModify = false;
      state.isChangePass = false;
    },
    // 내정보 수정버튼 클릭시 실제 작동 함수
    async modifyAccount({ rootState, state, dispatch, commit }, payload) {
      const URL = rootState.URL;
      const customerData = {
        email: payload.email.$model,
        oldPassValue: payload.oldPassValue.$model,
        passValue1: payload.passValue1.$model ? payload.passValue1.$model : undefined,
        passValue2: payload.passValue2.$model ? payload.passValue2.$model : undefined,
        selectedHospital: payload.selectedHospital.$model,
        selectedDepartment: payload.selectedDepartment.$model,
        username: payload.username.$model,
        birth: payload.birth.$model
          ? formatDate('date', payload.birth.$model)
          : undefined,
        phone: payload.phone.$model,
      };
      const sendDataIs = _.values(customerData).every((a) => a);
      // 내정보 입력값에 따른 경고모달
      if (customerData.passValue1 && customerData.passValue2 && !sendDataIs) {
        Swal.fire({
          icon: 'warning',
          title: '내용을 모두 기입, 선택해주세요.',
          text: '내용을 모두 기입해야 가입이 가능합니다.',
        });
      } else {
        if (state.cookieIs) {
          commit('loadingChangeValue', true);
          try {
            const result = await axios.put(URL + `/my-page/account`, {
              data: customerData,
            });
            commit('loadingChangeValue', false);
            if (result.data) {
              Swal.fire({
                icon: 'success',
                title: '회원수정이 완료되었습니다.',
              });
              await dispatch('getCookieData');
              dispatch('resetForm', state.myInfoData);
            } else {
              Swal.fire({
                icon: 'error',
                title: '다시 시도해주세요.',
              });
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: `오류 발생. 개발팀에 문의해주세요.`,
              text: `${error.response.data.message}`,
            }).then(() => {
              commit('loadingChangeValue', false);
            });
          }
        }
      }
    },
    async requestMyAuth({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.post(URL + '/my-page/auth', {
          data: payload,
        });
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          Swal.fire(
            '권한요청이 완료되었습니다.',
            '관리자가 권한을 승인할 때까지 기다려주세요.',
            'success'
          ).then(() => {
            commit('myAuthDataCommit', payload);
          });
          return result.data;
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async myPageAuthListGet({ rootState, commit }) {
      try {
        commit('loadingChangeValue', true);
        const result = await axios.get(rootState.URL + '/my-page/my-page-auth-list');
        commit('authListCommit', result.data);
        commit('loadingChangeValue', false);
      } catch (err) {
        commit('errorCommit', err);
      }
    },
  },
  getters: {
    // unregisteredMemberDataGetter(state) {
    //   const unregisteredMember = state.unregisteredMember;
    //   return unregisteredMember;
    // },
  },
};

export default myPageGetData;
