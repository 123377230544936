<template>
  <div>
    <TopNavigation class="mb-4"></TopNavigation>
    <div>
      <MyPageConfirmPassword />
      <div v-if="isPassConfirm" class="flex card">
        <TabView @tabClick="onTabClick($event)">
          <TabPanel header="내 정보 확인 / 변경">
            <div v-if="tabIndex === 0"><MyInfo></MyInfo></div>
          </TabPanel>
          <TabPanel header="권한 추가/변경">
            <div v-if="tabIndex === 1"><MyAuthority></MyAuthority></div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
  <MyInfoModifyDialog />
  <template>
    <div>
      <Toast />
    </div>
  </template>
</template>

<script setup>
import TopNavigation from '@/components/common/TopNavigation.vue';
import MyInfo from '@/components/myPage/MyInfo.vue';
import MyAuthority from '@/components/myPage/MyAuthority.vue';
import MyPageConfirmPassword from '@/components/myPage/MyPageConfirmPassword.vue';
import MyInfoModifyDialog from '@/components/myPage/MyInfoModifyDialog.vue';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { ref, watchEffect, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const tabIndex = ref(0);
const isPassConfirm = ref(false);

watchEffect(() => {
  isPassConfirm.value = store.state.myPageGetData.isPassConfirm;
});

// onBeforeMount(async () => {
//   const timeoutAuthData = {
//     name: 'mypage-getauthdata',
//     url: '/my-page/auth',
//     module: 'myPageGetData',
//     compareData: 'myPageGetAuthDataCompare',
//   };
//   store.dispatch('timeoutCommonFunc', timeoutAuthData);
// });

onUnmounted(() => {
  store.commit('myPageInit');
  // store.commit('deleteTimeout', 'mypage-getauthdata');
});

// tab이 바뀔 경우, 기존에 열려있던 탭을 unmount하도록 인식
function onTabClick(event) {
  tabIndex.value = parseInt(event.index);
}
</script>

<style lang="scss" scoped>
.tabview-custom {
  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 0.5rem;
  }
}

.p-tabview {
  line-height: 1.5;
  margin: 0;
  min-width: 35rem;
  max-width: 50rem;
}
</style>
