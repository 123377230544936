<template>
  <div class="surface-section mt-3">
    <DataTable
      :value="myAuthData"
      :paginator="true"
      :rows="20"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 30]"
      currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      removableSort
      dataKey="auth_number"
      v-model:filters="authFilters"
      filterDisplay="menu"
      :globalFilterFields="['auth_category', 'auth_content', 'is_request', 'is_valid']"
      :row-hover="true"
      style="width: 110%"
    >
      <template #header>
        <div class="flex justify-content-between align-items-end">
          <div>
            <h4>권한 신청/변경</h4>
          </div>
          <div>
            <Button label="권한 요청" class="p-button mr-2" @click="RequestAuth()" />
          </div>
        </div>
      </template>

      <Column
        field="auth_category"
        header="권한 분류"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        :sortable="true"
        style="min-width: 11rem"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.auth_category }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="분류명을 입력해주세요"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <Column
        field="auth_content"
        header="권한"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.matchModeOptions"
        :sortable="true"
        style="min-width: 8rem"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            {{ data.auth_content }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="권한명을 입력해주세요"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <!-- 권한갱신일자 -->
      <Column
        header="권한갱신일자"
        field="creation_timestamp"
        dataType="date"
        :showFilterOperator="false"
        :filterMatchModeOptions="$store.state.dateMatchModeOptions"
        sortable
        style="min-width: 12rem; text-align: center"
      >
        <template #body="{ data }">
          {{
            data.creation_timestamp
              ? $formatDate('date', data.creation_timestamp)
              : '없음'
          }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar
            v-model="filterModel.value"
            dateFormat="yy.mm.dd"
            placeholder="yyyy.mm.dd"
            @keyup.enter="filterCallback()"
          />
        </template>
      </Column>

      <Column
        field="is_request"
        header="권한요청"
        :sortable="true"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        style="min-width: 10rem"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <Checkbox
              v-model="data.is_request"
              :binary="true"
              @change="authCheckEvent(data)"
            />
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Checkbox
            v-model="filterModel.value"
            @change="filterCallback()"
            :binary="true"
          />
        </template>
      </Column>
      <Column
        field="is_valid"
        header="권한승인"
        :sortable="true"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        style="min-width: 10rem"
      >
        <template #body="{ data }">
          <div class="flex justify-content-center">
            <i
              class="pi"
              :class="{
                'true-icon pi-check-circle': data.is_valid,
                'false-icon pi-times-circle': !data.is_valid,
              }"
            ></i>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Checkbox
            v-model="filterModel.value"
            @change="filterCallback()"
            :binary="true"
          />
        </template>
      </Column>
    </DataTable>
  </div>
  <template>
    <div>
      <Toast />
      <ConfirmDialog></ConfirmDialog>
    </div>
  </template>
</template>

<script setup>
import { ref, onBeforeMount, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import ConfirmDialog from 'primevue/confirmdialog';
import Swal from 'sweetalert2';

const store = useStore();
const toast = useToast();
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $formatDate = app.appContext.config.globalProperties.$formatDate;
const myAuthData = ref([]);
const isAuthEditing = ref(false);

// data 입히기
onBeforeMount(async () => {
  myAuthData.value = await store.dispatch('getMyAuthData');
  await init();
});

const authFilters = ref({
  auth_category: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  auth_content: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  is_request: { value: null, matchMode: FilterMatchMode.EQUALS },
  is_valid: { value: null, matchMode: FilterMatchMode.EQUALS },
  creation_timestamp: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
});

// 개인 권한 데이터 초기 설정
async function init() {
  myAuthData.value.forEach((auth) => {
    if (!auth.creation_timestamp) {
      auth.creation_timestamp = null;
    } else {
      auth.creation_timestamp = new Date(auth.creation_timestamp);
    }
  });

  myAuthData.value.forEach((auth) => {
    auth.isModify = false;
  });
}

// 권한요청란 체크 시, 권한 요청 전 수정체크 데이터 수정하는 함수
async function authCheckEvent(data) {
  isAuthEditing.value = true;
  myAuthData.value.forEach((auth) => {
    if (auth.auth_number === data.auth_number) {
      auth.isModify = !auth.isModify;
    }
  });
}

// 권한 요청 함수
const RequestAuth = async () => {
  const data = myAuthData.value.filter((auth) => {
    return auth.isModify === true;
  });

  if (data && isAuthEditing.value) {
    try {
      const result = await store.dispatch('requestMyAuth', data);
      if (result) {
        isAuthEditing.value = false;
        myAuthData.value = await store.dispatch('getMyAuthData');
      }
    } catch (error) {
      $errorHandling(toast, error);
    }
  } else if (!isAuthEditing.value) {
    Swal.fire({
      icon: 'warning',
      title: `변경사항이 없습니다.`,
      text: `요청하고 싶은 권한을 체크하고, 권한 요청 버튼을 눌러주세요.`,
    });
  } else {
    Swal.fire({
      icon: 'error',
      title: `입력하신 값에 오류가 있습니다.`,
    });
  }
};
</script>

<style>
.p-datatable .p-datatable-tbody {
  justify-content: center;
}

.p-treeselect {
  width: 20rem;
}

@media screen and (max-width: 640px) {
  .p-treeselect {
    width: 100%;
  }
}
</style>
