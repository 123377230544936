const editBomState = {
  // BOM 입력수정
  editSurgeryToDoctor: '',
  editSurgeryToDoctorSeen: false,
  editDoctorToBomSeen: false,
  editSurgeryToDoctorSelected: '',
  editDoctorToBomSelected: '',
  editBomRegistSelected: '',
  editSurgeryToDoctorUniqueDocId: '',
  editRegistedBomByDoctor: '', // 등록된 상품 데이터
  editRegistedBomCost: '', // 등록된 상품들 전체 원가 및 원가율
};

export default editBomState;
