import Swal from 'sweetalert2';
import store from '@/store/index';

export const errorHandling = (toast, error, callback) => {
  const status = error.response?.status;
  store.commit('loadingChangeValue', false);
  if (status) {
    if (status == 404) {
      Swal.fire({
        title: '404 에러: 페이지를 찾을 수 없습니다.',
        text: `개발팀에 문의해주세요. ${error.message}`,
        icon: 'error',
        timer: 3000,
      });
    } else if (status == 400) {
      Swal.fire({
        title: '400: 페이지에 이상이 있습니다.',
        text: `개발팀에 문의해주세요. ${error.message}`,
        icon: 'error',
        timer: 3000,
      });
    } else if (status == 500) {
      Swal.fire({
        title: '서버 에러 발생했습니다.',
        text: `개발팀에 문의해주세요. ${error.message}`,
        icon: 'error',
        timer: 3000,
      });
    } else if (status == 401) {
      Swal.fire('로그인을 해주세요.', error.message, 'error').then(() =>
        window.location.replace('/')
      );
    }
    // 페이지의 권한이 없을 경우
    // 경고창을 표시하고 확인을 누르면 callback을 실행한다.
    else if (status == 406) {
      Swal.fire('권한이 없습니다.', '권한을 요청해주세요.', 'error').then(() => {
        callback && callback();
      });
    } else if (status == 403) {
      Swal.fire('에러가 발생했습니다.', `${error.response.data.message}`, 'error');
    } else if (status == 409) {
      Swal.fire('에러가 발생했습니다.', `${error.response.data.message}`, 'error');
    } else {
      Swal.fire({
        title: '원인 모를 에러가 발생했습니다.',
        text: `개발팀에 문의해주세요. ${error.message}`,
        icon: 'error',
        timer: 3000,
      });
    }
  }
};
