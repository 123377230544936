<template>
  <DataTable
    :value="staticValue"
    dataKey="main_code"
    class="p-datatable-lg"
    stripedRows
    paginator
    :rows="25"
    :rowsPerPageOptions="[25, 50, 75, 100]"
    sortMode="multiple"
    removableSort
    v-model:filters="filters"
    filterDisplay="menu"
    columnResizeMode="fit"
    :loading="loading"
    scrollable
    scrollHeight="70vh"
    editMode="cell"
    @cell-edit-complete="onCellEditComplete"
  >
    <template #loading>
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        animationDuration="2s"
      />
    </template>
    <template #header>
      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
        <div>
          <a
            href="https://docs.google.com/spreadsheets/d/1JusMWHvq6PUuLuHH6VW-g88G0Km-4xK9cEIl-pWa4f4/edit#gid=0"
            target="_blank"
            rel="noopener noreferrer"
            class="mr-5"
          >
            <span class="text-xl text-900 font-bold">구매팀 엑셀관리</span>
          </a>
          <strong
            class="text-lg font-italic text-red-900 underline"
            v-if="staticValue.filter((a) => a.product_name === '#N/A').length > 0"
            >#N/A로 표시된 상품이 있습니다. 스프레드시트에서 확인해주세요.</strong
          >
        </div>
        <div>
          <Button
            icon="pi pi-filter-slash"
            class="mr-2"
            label="필터삭제"
            severity="warning"
            outlined
            size="small"
            @click="initFilter()"
          />
          <Button label="DB전송" outlined size="small" @click="dbSend()" />
        </div>
      </div>
    </template>
    <Column field="main_code" header="메인코드" sortable>
      <template #body="{ data }">
        {{ data.main_code }}
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column field="db_code" header="DB코드" sortable>
      <template #body="{ data }">
        {{ data.db_code }}
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column field="purchase_code" header="구매팀코드" sortable>
      <template #body="{ data }">
        {{ data.purchase_code }}
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
      <template #editor="{ data, field }">
        <InputText v-model="data[field]" autofocus />
      </template>
    </Column>
    <Column field="product_name" header="상품명" sortable>
      <template #body="{ data }">
        {{ data.product_name }}
      </template>
      <template #filter="{ filterModel }">
        <InputText v-model="filterModel.value" type="text" class="p-column-filter" />
      </template>
    </Column>
    <Column field="unit" header="단위" sortable>
      <template #body="{ data }">
        {{ data.unit_name }}
      </template>
    </Column>
    <Column field="price" header="가격" sortable>
      <template #body="{ data }">
        {{
          (/0$/.test(data.cost) ? parseInt(data.cost) : data.cost)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }}
      </template>
    </Column>
  </DataTable>
</template>
<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, ref } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useStore } from 'vuex';
import _ from 'lodash';
import axios from 'axios';
import Swal from 'sweetalert2';

const store = useStore();
const URL = store.state.URL;
const eventSource = new EventSource(URL + '/purchase/excel-load', {
  withCredentials: true,
});

const staticValue = ref([]);
const staticValueCompare = ref([]);
const loading = ref(true);
const filters = ref();
function initFilter() {
  filters.value = {
    main_code: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    db_code: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    purchase_code: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    product_name: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
  };
}

async function onCellEditComplete(event) {
  const { newValue, field, index, value } = event;

  try {
    if (newValue !== value) {
      store.commit('loadingChangeValue', true);
      const result = await axios.post(URL + '/purchase/excel-update', {
        newValue,
        field,
        index,
      });
      store.commit('loadingChangeValue', false);

      if (result.status === 200) {
        Swal.fire({
          title: '업데이트 성공',
          text: '스프레드시트에 데이터가 업데이트 되었습니다.',
          icon: 'success',
          timer: 3000,
        });
      }
    }
  } catch (error) {
    Swal.fire({
      title: '업데이트 실패',
      text: error.response.data.message,
      icon: 'error',
      timer: 3000,
    });
  }
}

async function dbSend() {
  Swal.fire({
    icon: 'info',
    title: '스프레드시트의 데이터를 전송하시겠습니까?',
    showDenyButton: true,
    denyButtonText: '아니오',
    confirmButtonText: '네',
  }).then(async (req) => {
    if (req.isConfirmed) {
      if (staticValue.value.filter((a) => a.product_name === '#N/A').length > 0) {
        Swal.fire({
          title: '#N/A 오류',
          text: 'N/A 값이 있어 DB에 전송할 수 없습니다.',
          icon: 'error',
        });
      } else {
        try {
          store.commit('loadingChangeValue', true);
          const result = await axios.get(URL + '/purchase/excel-db');
          store.commit('loadingChangeValue', false);

          Swal.fire({
            icon: 'success',
            title: '전송을 완료하였습니다.',
            html: `맵핑${result.data.change}개<br>구매${result.data.purChange}개<br>변경되었습니다.`,
          });
        } catch (err) {
          Swal.fire({
            title: '전송 실패',
            text: err.response.data.message,
            icon: 'error',
            timer: 3000,
          }).then(() => {
            store.commit('loadingChangeValue', false);
          });
        }
      }
    }
  });
}

onBeforeMount(async () => {
  const result = await axios.get(URL + '/purchase/excel-init');
  staticValue.value = _.cloneDeep(result.data.transValues);
  staticValueCompare.value = _.cloneDeep(result.data.transValues);
  loading.value = false;
  initFilter();
});

onMounted(() => {
  eventSource.onmessage = function (event) {
    const eventData = JSON.parse(event.data);

    if (!_.isEqual(eventData, staticValueCompare.value)) {
      staticValue.value = _.cloneDeep(eventData);
      staticValueCompare.value = _.cloneDeep(eventData);
      loading.value = false;
    }
  };

  eventSource.onerror = function (event) {
    Swal.fire({
      icon: 'error',
      title: '에러가 발생하였습니다.',
      text: '스프레드시트를 확인하고 새로고침 해주세요.',
    }).then(() => {
      eventSource.close();
    });
  };
});

onBeforeUnmount(() => {
  eventSource.close();
});
</script>
<script>
export default {
  name: 'PurchaseExcelComponent',
};
</script>

<style></style>
