import _ from 'lodash';

const confirmBomGetters = {
  // doctorMenuConfirmData 입력
  getDoctorMenuConfirmData(state) {
    const result = _.uniqBy(state.menuCountData, function (elem) {
      return [elem.doctor_id, elem.menu_id, elem.confirm].join();
    });
    return result;
  },
  // 의사 클릭 시, 시/수술 Confirm 받았는지 확인 및 처리해주는 함수
  getConfirmBydoctor: (state) => (menuId) => {
    if (
      state.confirmDoctorToSurgerySelectedInfo &&
      state.confirmDoctorToSurgerySelected
    ) {
      const result = state.confirmDoctorToSurgerySelectedInfo.filter(
        (a) => a.menu_id === menuId
      );
      if (!result || result.length === 0) {
        return false;
      } else {
        return result[0].confirm;
      }
    } else {
      return false;
    }
  },
};
export default confirmBomGetters;
