import { errorHandling } from '@/util/errorHandler.js';
import axios from 'axios';
import _ from 'lodash';
import Swal from 'sweetalert2';
import global from '../../util/global.js';
import omniList from '../../components/modal/modal_components/OmniList';

const counselingAndTreatStore = {
  state() {
    return {
      counselModalRouter: 'counsel',
      paymentModalRouter: 'payments',
      treatModalRouter: 'treat',
      paymentsCheckingData: null,
      isDoctor: false,
      selectedDoctor: null,
      doctors: null,
      selectedSoogaList: [], // 선택된 수가표 => treat에서도 쓰므로 별도로 관리
      soogaData: [],
      isCounseling: true,
      isUpselling: false,
      originalSoogaData: null,
    };
  },
  mutations: {
    setIsUpselling(state, payload) {
      state.isUpselling = payload;
    },
    setOriginalSoogaData(state, payload) {
      state.originalSoogaData = payload;
    },
    paymentsCheckingCommit(state, payload) {
      state.paymentsCheckingData = payload;
    },
    setIsDoctor(state, payload) {
      state.isDoctor = payload;
    },
    setDoctors(state, payload) {
      state.doctors = payload;
    },
    setSelectedDoctor(state, payload) {
      state.selectedDoctor = payload;
    },
    pushSelectedSoogaData(state, soogaData) {
      state.selectedSoogaList = [...state.selectedSoogaList, soogaData];
    },
    changeSelectedSoogaData(state, payload) {
      state.selectedSoogaList = payload;
    },
    setSelectedSoogaDataDetail(state, payload) {
      const { key, menuId, value } = payload;
      state.selectedSoogaList.forEach((sooga) => {
        if (sooga.id === menuId && sooga[key] !== value) {
          sooga[key] = value;
        }
      });
    },
    // 일괄 할인 적용(현재 사용 X)
    // setDiscountFromBatch(state, payload) {
    //   const { sumOfSalesPrice, sumOfDiscountRate, discountNameFromBatch, toast } =
    //     payload;

    //   // 미리 계산하여 할인이 정상적으로 적용가능한지 확인
    //   state.selectedSoogaList.forEach((soogaData) => {
    //     soogaData.sales_price = Math.round(
    //       soogaData.price_include_vat *
    //         soogaData.menu_count *
    //         ((100 - sumOfDiscountRate) / 100)
    //     );
    //     soogaData.discount_rate = sumOfDiscountRate;
    //     soogaData.discount_name = discountNameFromBatch;
    //   });

    //   // 개별 할인 가격 합
    //   let sumOfSalesPriceTotal = 0;
    //   state.selectedSoogaList.forEach((soogaData) => {
    //     sumOfSalesPriceTotal += soogaData.sales_price;
    //   });
    //   sumOfSalesPriceTotal = _.round(sumOfSalesPriceTotal);

    //   // 할인율 적용 간의 가격차 = 일괄 할인 가격 - 개별 할인 가격 합
    //   const salesPriceGap = sumOfSalesPrice - sumOfSalesPriceTotal;

    //   if (salesPriceGap !== 0) {
    //     // 차이를 항목 개수 만큼 나눈 몫
    //     const salesPriceGapDist = parseInt(
    //       salesPriceGap / state.selectedSoogaList.length
    //     );
    //     // 차이를 나누고 남은 나머지
    //     let salesPriceGapRest = salesPriceGap % state.selectedSoogaList.length;

    //     // 모든 항목에 나눈 몫을 더하고 => 양수는 더해지고 음수는 뺀다.
    //     // 나머지를 위에서부터 1씩 더하거나 뺀다.
    //     state.selectedSoogaList.forEach((soogaData) => {
    //       // 일괄 가격 적용
    //       soogaData.sales_price += salesPriceGapDist;

    //       // 몫 처리 후, 남은 나머지 처리
    //       if (salesPriceGapRest > 0) {
    //         salesPriceGapRest -= 1;
    //         soogaData.sales_price += 1;
    //       } else if (salesPriceGapRest < 0) {
    //         salesPriceGapRest += 1;
    //         soogaData.sales_price -= 1;
    //       }
    //     });
    //   }

    //   toast.add({
    //     severity: 'success',
    //     summary: '일괄 할인 적용',
    //     detail: '각 수가표에 할인이 일괄 적용되었습니다.',
    //     life: 3000,
    //   });
    // },
    selectedSoogaListInit(state) {
      state.selectedSoogaList = [];
    },
    setSoogData(state, payload) {
      state.soogaData = payload;
    },
    setIsCounseling(state, payload) {
      state.isCounseling = payload;
    },
  },
  actions: {
    // 일괄 할인 계산 및 적용
    async calculateDicountBatch({ state, getters, dispatch }, payload) {
      const { sumOfSalesPrice, sumOfDiscountRate, discountNameFromBatch } = payload;

      // 선택된 수가표를 할인율 오름차순(할인불가 -> 최대할인 순) + 지불된 금액 많은 순으로 정렬
      const selectedSoogaList = await _.orderBy(
        _.cloneDeep(state.selectedSoogaList),
        ['discount_limit', 'paid_price'],
        ['asc', 'asc']
      );
      let possibleTotalDiscountRate = _.cloneDeep(sumOfDiscountRate);
      // 할인적용 시 총 금액 합계(차감하면서 할인율 계산(분자))
      let sumOfSalesPriceRest = _.cloneDeep(sumOfSalesPrice);
      // 기존 금액 합계(차감하면서 할인율 계산(분모))
      let sumOfPriceRest = _.cloneDeep(getters.getSumOfPriceOfSelectedSoogaList);
      selectedSoogaList.forEach(async (soogaData) => {
        if (soogaData.discount_limit === 0) {
          // 할인불가일 경우, 빼고 계산
          soogaData.discount_rate = 0;
        } else if (soogaData.discount_limit <= possibleTotalDiscountRate) {
          // 최대할인율 < 적용해야할 할인율일 경우, 최대할인율로 계산
          soogaData.discount_name = discountNameFromBatch;
          soogaData.discount_rate = soogaData.discount_limit;
        } else {
          // 최대할인율 > 적용해야할 할인율일 경우, 적용할인율로 계산
          soogaData.discount_name = discountNameFromBatch;
          soogaData.discount_rate = Math.round(possibleTotalDiscountRate * 100) / 100;
        }
        // 업셀링된 수가표의 경우, 기존에 수납된 금액보다 높은 금액을 책정해야 함
        const paidPriceRate =
          Math.round(
            (1 - soogaData.paid_price / soogaData.price_include_vat) * 100 * 100
          ) / 100;
        // 업셀링 + 기존 수납이 있을 경우 체크
        if (paidPriceRate < soogaData.discount_rate) {
          soogaData.sales_price = soogaData.paid_price;
          soogaData.discount_rate = paidPriceRate;
        } else {
          soogaData.sales_price = Math.ceil(
            soogaData.price_include_vat *
              soogaData.menu_count *
              (1 - soogaData.discount_rate / 100)
          );
        }

        // 할인인데 할인율 100%일 경우 변경
        if (soogaData.discount_rate === 100 && /할인/.test(soogaData.discount_name)) {
          soogaData.discount_name = await dispatch(
            'getDiscountServiceName',
            soogaData.discount_name
          );
        } else if (soogaData.sales_price < 0) {
          // 판매가가 음수일 경우 임시적으로 서비스 처리
          soogaData.discount_name = await dispatch(
            'getDiscountServiceName',
            soogaData.discount_name
          );
          soogaData.sales_price = 0;
          soogaData.discount_rate = 100;
          Swal.fire({
            title: '할인 일괄적용 오류',
            html: `할인 일괄적용 중에 오류가 발생하였습니다. <br>
                    개별로 할인 적용해주시고, 개발팀에 문의해주세요.`,
            icon: 'warning',
          });
        }

        sumOfPriceRest -= soogaData.price_include_vat * soogaData.menu_count;
        sumOfSalesPriceRest -= soogaData.sales_price;
        possibleTotalDiscountRate = (1 - sumOfSalesPriceRest / sumOfPriceRest) * 100;
      });

      // 할인율 적용 간의 가격차 = 일괄 할인 가격 - 개별 할인 가격 합
      const salesPriceGap = Math.ceil(sumOfSalesPriceRest);

      if (salesPriceGap !== 0) {
        // 가격차 처리 적용 가능한 개수
        let dicountPossibleLength = 0;
        // 할인율 적용 간의 가격차 처리 가능한 항목들 지정
        selectedSoogaList.forEach((soogaData) => {
          if (soogaData.discount_limit > soogaData.discount_rate) {
            if (soogaData.paid_price >= soogaData.sales_price && salesPriceGap < 0) {
              soogaData.isDiscountPossible = false;
            } else {
              soogaData.isDiscountPossible = true;
              dicountPossibleLength += 1;
            }
          } else {
            soogaData.isDiscountPossible = false;
          }
        });

        // 차이를 항목 개수 만큼 나눈 몫
        let salesPriceGapDist = 0;
        // 차이를 나누고 남은 나머지
        let salesPriceGapRest = 0;
        if (dicountPossibleLength !== 0) {
          salesPriceGapDist = parseInt(salesPriceGap / dicountPossibleLength);
          salesPriceGapRest = salesPriceGap % dicountPossibleLength;

          // 가격차 처리 가능한 항목에 나눈 몫을 더하고 => 양수는 더해지고 음수는 뺀다.
          // 나머지를 위에서부터 1씩 더하거나 뺀다.
          selectedSoogaList.forEach((soogaData) => {
            if (soogaData.isDiscountPossible) {
              // 일괄 가격 적용
              soogaData.sales_price += salesPriceGapDist;

              // 몫 처리 후, 남은 나머지 처리
              if (salesPriceGapRest !== 0) {
                soogaData.sales_price += salesPriceGapRest;
                salesPriceGapRest = 0;
              }
            }
          });
        } else {
          // 차이를 항목 개수 만큼 나눈 몫
          salesPriceGapDist = parseInt(salesPriceGap / selectedSoogaList.length);
          // 차이를 나누고 남은 나머지
          salesPriceGapRest = salesPriceGap % selectedSoogaList.length;

          selectedSoogaList.forEach((soogaData) => {
            // 일괄 가격 적용
            soogaData.sales_price += salesPriceGapDist;

            // 몫 처리 후, 남은 나머지 처리
            if (salesPriceGapRest !== 0) {
              soogaData.sales_price += salesPriceGapRest;
              salesPriceGapRest = 0;
            }
          });
        }
      }

      return selectedSoogaList;
    },
    // 할인 일괄 적용 가능 여부 계산
    async calculateSumOfDiscount({ state, commit, dispatch }, payload) {
      const { sumOfSalesPrice } = payload;
      const selectedSoogaList = _.cloneDeep(state.selectedSoogaList);
      let maxTotalDiscountPrice = 0;
      // 선택된 수가표 최대 할인 가격을 합해 해당 가격보다 낮을 경우 alert
      selectedSoogaList.forEach((soogaData) => {
        const maxDiscountPrice =
          soogaData.price_include_vat *
          soogaData.menu_count *
          ((100 - soogaData.discount_limit) / 100);

        if (soogaData.paid_price > maxDiscountPrice) {
          // 기존 수납이 진행된 경우
          maxTotalDiscountPrice += soogaData.paid_price;
        } else {
          // 최대 할인가격 구하기
          maxTotalDiscountPrice +=
            soogaData.price_include_vat *
            soogaData.menu_count *
            ((100 - soogaData.discount_limit) / 100);
        }
      });

      if (sumOfSalesPrice < maxTotalDiscountPrice) {
        Swal.fire({
          title: '할인불가',
          html: `최대할인금액보다 낮아 해당 금액만큼 할인할 수 없습니다. <br>
                  최대할인금액 : ${maxTotalDiscountPrice.toLocaleString('ko-KR')}`,
          icon: 'warning',
        });
      } else {
        const result = await dispatch('calculateDicountBatch', payload);
        selectedSoogaList.forEach((soogaData) => {
          const resultData = _.find(result, { id: soogaData.id });
          if (resultData) {
            soogaData.discount_rate = resultData.discount_rate;
            soogaData.discount_name = resultData.discount_name;
            soogaData.sales_price = resultData.sales_price;
          }
        });
        // 수가표 저장
        commit('changeSelectedSoogaData', selectedSoogaList);
      }
    },
    /**
     * 선택된 할인율 변경에 따른 수가표에서 판매가 계산하기
     * @param {object} soogaData
     */
    async calculateDiscountRate(_, soogaData) {
      // 기존 가격 가져오기(vat 포함가)
      const priceIncludeVat = soogaData.price_include_vat;

      const menuCount = soogaData.menu_count;

      const discountName = soogaData.discount_name;

      if (soogaData.sales_price >= priceIncludeVat * menuCount) {
        soogaData.discount_rate = 0;
      } else if (soogaData.sales_price <= 0) {
        if (/할인/.test(discountName) && soogaData.sales_price == 0) {
          Swal.fire({
            icon: 'warning',
            title: '할인 및 서비스 입력',
            text: '할인항목에서 판매가를 0원으로 조정할 수 없습니다. 서비스항목으로 변경해주세요.',
          });
          soogaData.sales_price = priceIncludeVat * menuCount;
          soogaData.discount_rate = 0;
        } else {
          soogaData.sales_price = 0;
          soogaData.discount_rate = 100;
        }
      } else {
        // 할인율 계산
        soogaData.discount_rate =
          100 - (soogaData.sales_price / (priceIncludeVat * menuCount)) * 100;
      }
      return soogaData;
    },

    /**
     * 선택된 할인율 변경에 따른 수가표에서 판매가 계산하기
     * @param {event} event
     * @param {number} menuId
     */
    async calculateSalesPrice({ dispatch }, soogaData) {
      // 기존 가격 가져오기(vat 포함가)
      const priceIncludeVat = soogaData.price_include_vat;
      const menuCount = soogaData.menu_count;
      const discountLimit = soogaData.discount_limit;
      const discountName = soogaData.discount_name;

      if (soogaData.discount_rate >= 100) {
        if (/할인/.test(discountName) && soogaData.discount_rate == 100) {
          soogaData.discount_name = await dispatch(
            'getDiscountServiceName',
            discountName
          );
          soogaData.sales_price = 0;
        } else {
          soogaData.sales_price = 0;
          soogaData.discount_rate = 100;
          soogaData.discount_name = await dispatch(
            'getDiscountServiceName',
            discountName
          );
        }
      } else if (soogaData.discount_rate <= 0) {
        soogaData.sales_price = priceIncludeVat * menuCount;
        soogaData.discount_rate = 0;
      } else {
        // 할인제한일 경우, 할인율 제한하기
        if (soogaData.discount_rate > discountLimit) {
          soogaData.discount_rate = discountLimit;
        }
        // 판매가 계산
        soogaData.sales_price =
          ((100 - soogaData.discount_rate) / 100) * (priceIncludeVat * menuCount);
      }
      return soogaData;
    },
    async getDiscountServiceName(_, name) {
      if (/할인/.test(name)) {
        // '할인'을 '서비스'로 변경
        name = name.replace('할인', '서비스');

        // discountNames 배열에서 name에 해당하는 객체 찾기
        const discountObj = omniList.discountNames.find((obj) => obj.name === name);

        // 객체를 찾으면 discountName를 반환하고, 찾지 못하면 실장서비스를 반환
        return discountObj ? discountObj.name : '실장서비스';
      } else {
        return '실장서비스';
      }
    },
    async getPaymentsChecking({ rootState, state, commit }) {
      // 22/11/03 이후로 사용하지 않음
      const URL = rootState.URL;
      const counselingId = rootState.counselingStore.counselingId;
      const counselModalRouter = state.counselModalRouter;
      const paymentModalRouter = state.paymentModalRouter;
      try {
        commit('loadingChangeValue', true);
        const res = await axios.get(
          URL +
            `/${counselModalRouter}/${paymentModalRouter}/check?counselingid=${counselingId}`
        );

        commit('paymentsCheckingCommit', res.data.data);
      } catch (error) {
        errorHandling(undefined, error);
      }
    },
    async getSoogaSurgeryPaymentsChecking({ rootState, state, commit }, payload) {
      const { counselingId, menuId, isCounseling } = payload;
      const URL = rootState.URL;
      const paymentModalRouter = state.paymentModalRouter;
      let modalRouter;
      if (isCounseling) {
        modalRouter = state.counselModalRouter;
      } else {
        modalRouter = state.treatModalRouter;
      }

      try {
        commit('loadingChangeValue', true);
        const res = await axios.get(
          URL +
            `/${modalRouter}/${paymentModalRouter}/check?counselingid=${counselingId}&menuid=${menuId}`
        );

        commit('paymentsCheckingCommit', res.data.data);
      } catch (error) {
        errorHandling(undefined, error);
      }
    },
    async upsertBoard({ getters, state, commit }, payload) {
      const { isStart, writer, process, date, time, counselerIsDoctor } = payload;
      const receptionId = getters.getBoardModalReceptionId;
      const customerId = getters.getBoardModalCustomerId;
      const formatDate = global.methods.formatDate('date', date);
      const URL = getters.getUrl;

      let modalRouter;
      if (state.isCounseling) {
        modalRouter = state.counselModalRouter;
      } else {
        modalRouter = state.treatModalRouter;
      }

      try {
        commit('loadingChangeValue', true);
        await axios.post(URL + `/${modalRouter}/board/upsert`, {
          data: {
            isStart: isStart,
            receptionId: receptionId,
            customerId: customerId,
            date: formatDate,
            time: time,
            writer: writer,
            process: process,
            counselerIsDoctor: counselerIsDoctor,
          },
        });
      } catch (error) {
        errorHandling(undefined, error);
      }
    },

    async upsertBoardOnlyWriter({ getters, state, commit }, payload) {
      const { isStart, writer, process, counselerIsDoctor } = payload;
      const receptionId = getters.getBoardModalReceptionId;
      const customerId = getters.getBoardModalCustomerId;
      const URL = getters.getUrl;

      let modalRouter;
      if (state.isCounseling) {
        modalRouter = state.counselModalRouter;
      } else {
        modalRouter = state.treatModalRouter;
      }

      try {
        commit('loadingChangeValue', true);
        await axios.post(URL + `/${modalRouter}/board/upsert-only-writer`, {
          data: {
            isStart: isStart,
            receptionId: receptionId,
            customerId: customerId,
            writer: writer,
            process: process,
            counselerIsDoctor: counselerIsDoctor,
          },
        });
      } catch (error) {
        errorHandling(undefined, error);
      }
    },
    async deleteBoard({ getters, state, commit }, payload) {
      const { process } = payload;
      const receptionId = getters.getBoardModalReceptionId;

      const URL = getters.getUrl;
      const counselModalRouter = state.counselModalRouter;
      try {
        commit('loadingChangeValue', true);
        const res = await axios.delete(
          URL + `/${counselModalRouter}/board/${receptionId}?process=${process}`
        );

        return res;
      } catch (error) {
        errorHandling(undefined, error);
      }
    },
    async getDoctors({ getters, commit, state }) {
      const counselModalRouter = state.counselModalRouter;
      const URL = getters.getUrl;

      try {
        commit('loadingChangeValue', true);
        const result = await axios.get(URL + `/${counselModalRouter}/doctors`);

        const doctorsData = result.data.doctorData;
        commit('setDoctors', doctorsData);
      } catch (error) {
        errorHandling(undefined, error);
      }
    },
    async soogaSurgeryDelete({ getters, state, commit }, payload) {
      // 진료에서는 수가표 마다 상담 아이디가 다르므로 payload 에서 받아옴
      const { counselingId, writer, counselerIsDoctor, menuId, counselingSurgeryId } =
        payload;
      const treatModalRouter = state.treatModalRouter;
      const counselModalRouter = state.counselModalRouter;
      const URL = getters.getUrl;

      if (state.isCounseling) {
        // 상담에서 삭제하는 거라면
        commit('loadingChangeValue', true);
        const res = await axios.delete(
          URL +
            `/${counselModalRouter}/counseling/${counselingId}?writer=${writer}&menuid=${menuId}&counselerIsDoctor=${counselerIsDoctor}&counselingSurgeryId=${counselingSurgeryId}`
        );

        return res;
      } else {
        commit('loadingChangeValue', true);
        const res = await axios.delete(
          URL +
            `/${treatModalRouter}/treatment?writer=${writer}&counselingSurgeryId=${counselingSurgeryId}&counselerIsDoctor=${counselerIsDoctor}`
        );

        return res;
      }
    },
    async getSoogaData({ getters, state, commit }) {
      const counselModalRouter = state.counselModalRouter;
      const URL = getters.getUrl;
      commit('loadingChangeValue', true);
      const res = await axios.get(URL + `/${counselModalRouter}/sooga-manage`);
      const soogaData = res.data;

      commit('setSoogData', soogaData);
    },
    async getMemo({ getters, state, commit }, classification) {
      const counselModalRouter = state.counselModalRouter;
      const URL = getters.getUrl;
      const customerId = getters.getBoardModalCustomerId;
      commit('loadingChangeValue', true);
      const res = await axios.get(
        URL +
          `/${counselModalRouter}/memo?customerid=${customerId}&classification=${classification}`
      );

      const memoLength = res.data.memo[0].length;

      const memo = memoLength > 0 ? res.data.memo[0][0].memo : '';
      commit('setCounselingMemo', memo);
    },
    async memoUpsert({ getters, commit, state }, payload) {
      const { memoValue, classification, commitName } = payload;
      const customerId = getters.getBoardModalCustomerId;
      const URL = getters.getUrl;

      let modalRouter;
      if (state.isCounseling) {
        modalRouter = state.counselModalRouter;
      } else {
        modalRouter = state.treatModalRouter;
      }

      const res = await axios.put(
        URL + `/${modalRouter}/registration-modal/memo-upsert`,
        {
          memoValue: memoValue,
          customerCode: customerId,
          classification: classification,
        }
      );

      commit(commitName, res.data);
    },
  },
  getters: {
    getSelectedDoctor(state) {
      return state.selectedDoctor;
    },
    getSelectedDoctorName(state) {
      const selectedDoctor = state.doctors.filter((doctor) => {
        return doctor.member_id === state.selectedDoctor;
      });

      return selectedDoctor?.[0]?.doc_nm;
    },
    getIsDoctor(state) {
      return state.isDoctor;
    },
    isWriterInDoctor: (state) => (writer) => {
      // writer 가 doctor인지 check 하는 getters
      if (state.doctors) {
        const idOfDoctors = state.doctors.map((doctor) => {
          return doctor.doc_id;
        });

        return idOfDoctors.includes(Number(writer));
      }
    },
    getSelectedSoogaList(state) {
      return state.selectedSoogaList;
    },
    getSelectedSoogaListDetailValue: (state) => (payload) => {
      // 선택된 수가표 리스트에서 해당되는 수가표의 object value 값
      const { key, menuId } = payload;
      let value;
      state.selectedSoogaList.forEach((sooga) => {
        if (sooga.id === menuId) {
          value = sooga[key];
        }
      });
      return value;
    },
    getSumOfPriceOfSelectedSoogaList(state) {
      // 선택된 수가표의 기본 가격의 합(vat 포함 가의 합)
      let sumOfPrice = 0;
      state.selectedSoogaList.forEach((soogaData) => {
        sumOfPrice += soogaData.price_include_vat * soogaData.menu_count;
      });

      return sumOfPrice;
    },
    getSumOfSalesPriceOfSelectedSoogaList(state) {
      // 선택된 수가표의 판매가의 합
      let sumOfSalesPrice = 0;
      state.selectedSoogaList.forEach((soogaData) => {
        sumOfSalesPrice += soogaData.sales_price;
      });

      return sumOfSalesPrice;
    },
    getSumOfDiscountRateValue(state, getters) {
      // 총 할인율 값 구하기
      return (
        getters.getSumOfSalesPriceOfSelectedSoogaList /
        getters.getSumOfPriceOfSelectedSoogaList
      );
    },
    getSumOfDiscountRateOfSelectedSoogaList(state, getters) {
      // 총 할인율을 구한다. 값이 없으면 0으로 default 처리
      return getters.getSumOfDiscountRateValue
        ? Math.round((100 - getters.getSumOfDiscountRateValue * 100) * 100) / 100
        : 0;
    },
    getSoogaData(state) {
      const selectedSoogaList = state.selectedSoogaList;
      const _data = state.soogaData;

      let selectedDataIds = [];
      let isinbomDataIds = [];
      if (selectedSoogaList) {
        selectedDataIds = selectedSoogaList.map((element) => {
          return element.id;
        });
        isinbomDataIds = selectedSoogaList.map((element) => {
          if (element.is_in_bom === false) {
            return element.id;
          }
        });
      }
      const menuCountsById = _.reduce(
        selectedSoogaList,
        (obj, value) => {
          // ojb 는 {}, param 은 [{...}] 에서 {...}
          if (!(value.id in obj)) {
            // counseling id 가 key 에 없을 경우
            obj[value.id] = { menuCount: 1 };
          }
          obj[value.id].menuCount = value.menu_count;

          return obj;
        },
        {}
      );

      _data.forEach((data) => {
        // 선택된 데이터 중 id 만 가져옴
        data.menu_count = 1;
        data.is_selected = false;
        data.is_selected_design = false;

        if (selectedDataIds.includes(data.id)) {
          // 선택된거에 있으면 is_selected = true
          data.is_selected = true;
          data.is_selected_design = true;

          // id 같은 데이터의 count 값을 대입
          data.menu_count = menuCountsById[data.id].menuCount;
        }
        //디자인(진료) is_in_bom이 false면 추가 선택 불가
        if (isinbomDataIds.includes(data.id)) {
          data.is_selected_design = true;
        }
      });

      return _data;
    },
    /**
     * 수가표에서 결제 여부 체크할 때 사용하는 getter
     * @param {object} state
     * @returns {array} objInArr  [{...}, {...}, ...]
     * @returns {object} objInObj  원본 데이터(response)
     * @returns {array} payment_id  [1,2,3,...]
     * @returns {boolean} paid  boolean
     */
    checkPayment(state) {
      const checkingData = state.paymentsCheckingData;

      const isPaid = _.reduce(
        // 수납 내역이 있을 경우 true
        checkingData,
        (isPaid, param) => {
          return isPaid || (param.active && !param.refund_active);
        },
        false
      );

      return {
        paid: isPaid, // boolean
      };
    },
    getSoogaInfo: (state) => (menuId) => {
      return state.soogaData.find((sooga) => {
        return sooga.id === menuId;
      });
    },
    getIsMenuIdChangedBefore: (state) => (searchMenuId) => {
      let isMenuIdChangedBefore = false;
      state.selectedSoogaList.forEach((sooga) => {
        if (sooga.menu_id_before_change === searchMenuId) {
          isMenuIdChangedBefore = true;
        }
      });
      return isMenuIdChangedBefore;
    },
  },
};

export default counselingAndTreatStore;
