const confirmBomMutations = {
  // 확인
  doctorMenuConfirmDataCommit(state, payload) {
    state.doctorMenuConfirmData = payload;
  },
  confirmDoctorToSurgerySeenCommit(state, payload) {
    state.confirmDoctorToSurgerySeen = payload;
  },
  confirmSurgeryToBomSeenCommit(state, payload) {
    state.confirmSurgeryToBomSeen = payload;
  },
  confirmSurgeryFilteredDataCommit(state, payload) {
    state.confirmSurgeryFilteredData = payload;
  },
  confirmDoctorToSurgerySelectedCommit(state, payload) {
    state.confirmDoctorToSurgerySelected = payload;
  },
  confirmSurgeryToBomSelectedCommit(state, payload) {
    state.confirmSurgeryToBomSelected = payload;
  },
  confirmBomConfirmSelectedCommit(state, payload) {
    state.confirmBomConfirmSelected = payload;
  },
  confirmDoctorToSurgeryUniqueMenuIdCommit(state, payload) {
    state.confirmDoctorToSurgeryUniqueMenuId = payload;
  },
  confirmDoctorToSurgeryCommit(state, payload) {
    state.confirmDoctorToSurgery = payload;
  },
  confirmSurgeryFilterSelectedCommit(state, payload) {
    state.confirmSurgeryFilterSelected = payload;
  },
  confirmSurgeryFilterOptionsCommit(state, payload) {
    state.confirmSurgeryFilterOptions = payload;
  },
  confirmRegistedBomCostCommit(state, payload) {
    state.confirmRegistedBomCost = payload;
  },
  confirmDoctorToSurgerySelectedInfoCommit(state, payload) {
    state.confirmDoctorToSurgerySelectedInfo = payload;
  },
  confirmRegistedBomBySurgeryCommit(state, payload) {
    state.confirmRegistedBomBySurgery = payload;
  },
  confirmRegistedBomBySurgeryCommitPush(state, payload) {
    state.confirmRegistedBomBySurgery.push(payload);
  },
};

export default confirmBomMutations;
