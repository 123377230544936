import _ from 'lodash';
import axios from 'axios';
import { errorHandling } from '@/util/errorHandler';
import Swal from 'sweetalert2';

const doctorGetdata = {
  state() {
    return {
      doctorGetdataCompare: '',
      doctorDialog: false,
      selectedDoctors: [],
      doctorDeleteState: false,
      updateDoctors: null,
      doctorUpdateState: false,
      unregisteredMember: '',
    };
  },
  mutations: {
    doctorDialogCommit(state, payload) {
      state.doctorDialog = payload;
    },
    selectedDoctorsCommit(state, payload) {
      state.selectedDoctors = payload;
    },
    doctorDeleteStateCommit(state, payload) {
      state.doctorDeleteState = payload;
    },
    updateDoctorsCommit(state, payload) {
      state.updateDoctors = payload;
    },
    doctorUpdateStateCommit(state, payload) {
      state.doctorUpdateState = payload;
    },
    unregisteredMemberCommit(state, payload) {
      state.unregisteredMember = payload;
    },
    doctorInit(state) {
      state.doctorGetdataCompare = '';
      state.doctorDialog = false;
      state.selectedDoctors = [];
      state.doctorDeleteState = false;
      state.updateDoctors = null;
      state.doctorUpdateState = false;
      state.unregisteredMember = '';
    },
  },
  actions: {
    async doctorRegistSave({ rootState, commit }, payload) {
      try {
        commit('loadingChangeValue', true);
        const URL = rootState.URL;
        const result = await axios.post(URL + '/doctor-manage', {
          data: payload.value,
        });
        commit('loadingChangeValue', false);

        if (result.status === 200) {
          Swal.fire('저장이 완료되었습니다.', '', 'success').then(() => {
            commit('loadingChangeValue', false);
            payload.callFn();
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async doctorDelete({ rootState, commit }, payload) {
      try {
        const URL = rootState.URL;
        const codeFilter = _.map(payload, (a) => {
          return a.doc_id;
        });
        commit('doctorDeleteStateCommit', false);

        commit('loadingChangeValue', true);
        const result = await axios.delete(URL + '/doctor-manage', {
          data: codeFilter,
        });
        commit('loadingChangeValue', false);
        if (result.status === 200) {
          Swal.fire('삭제가 완료되었습니다.', '', 'success').then(() => {
            commit('doctorDeleteStateCommit', false);
            commit('selectedDoctorsCommit', null);
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
    async formattingDate(_, payload) {
      if (payload) {
        const date = new Date(payload);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      } else {
        return null;
      }
    },
    async doctorUpdate({ rootState, commit, dispatch }, payload) {
      try {
        payload.value.hired_date = await dispatch(
          'formattingDate',
          payload.value.hired_date
        );
        payload.value.resigned_date = await dispatch(
          'formattingDate',
          payload.value.resigned_date
        );
        payload.value.birth = await dispatch('formattingDate', payload.value.birth);

        const URL = rootState.URL;
        commit('loadingChangeValue', true);
        const result = await axios.put(URL + '/doctor-manage', { data: payload.value });
        commit('loadingChangeValue', false);

        if (result.status === 200) {
          Swal.fire('수정이 완료되었습니다.', '', 'success').then(() => {
            commit('doctorUpdateStateCommit', false);
          });
        }
      } catch (error) {
        errorHandling(null, error);
      }
    },
  },
  getters: {
    unregisteredMemberDataGetter(state) {
      const unregisteredMember = state.unregisteredMember;
      return unregisteredMember;
    },
  },
};

export default doctorGetdata;
