<template>
  <div>
    <Dialog
      v-model:visible="store.state.doctorGetdata.doctorDeleteState"
      :style="{ width: '450px' }"
      header="삭제확인"
      :modal="true"
      @hide="closeDeleteModal"
      :baseZIndex="1000"
      :autoZIndex="false"
    >
      <div class="confirmation-content flex flex-wrap align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span class="vertical-align-text-bottom">
          <b> {{ deleteDataContents().zero }} 님 </b>
          <b v-if="deleteDataContents().count"> 외 {{ deleteDataContents().count }}개 </b>
          데이터를 삭제하시겠습니까?
        </span>
        <small class="text-red-600"
          ><b>(주의!! 해당 계정과 연결된 BOM 및 로그인 계정 모두 삭제됩니다!)</b></small
        >
      </div>
      <template #footer>
        <Button
          label="취소"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDeleteModal"
        />
        <Button
          label="확인"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteExecution"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import _ from 'lodash';
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const deleteDatas = ref();

watchEffect(() => {
  if (store.state.doctorGetdata.doctorDeleteState) {
    deleteDatas.value = store.state.doctorGetdata.selectedDoctors;
  }
});

// 삭제할 데이터 선택할 경우 개수 확인해주는 함수
function deleteDataContents() {
  return {
    count:
      _.reduce(
        deleteDatas.value,
        (a, b) => {
          return (a += 1);
        },
        0
      ) - 1,
    zero: deleteDatas.value[0].doc_nm,
  };
}

// 삭제확인 모달 닫을 시, 이벤트
function closeDeleteModal() {
  store.commit('doctorDeleteStateCommit', false);
}

// 삭제 진행 시, 이벤트
function deleteExecution() {
  store.dispatch('doctorDelete', deleteDatas.value);
}
</script>
<script>
export default {
  name: 'DoctorDeleteDialog',
};
</script>

<style></style>
