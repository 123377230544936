<template>
  <div
    class="col-6 sursery-card"
    v-for="(a, i) in initDataTitle"
    :data-id="a.id"
    :key="a.id"
  >
    <Card class="w-full relative">
      <template #title>
        <div class="gird flex justify-content-center align-items-center">
          <div @click="showBomInput($event, a)" class="col-7 cursor-pointer">
            {{ a.floor }}
          </div>
          <div class="col-5">
            <div class="p-inputgroup" style="height: 20px">
              <span class="p-inputgroup-addon" style="font-size: 12px">시작</span>
              <Calendar
                v-model="a.start_time"
                :timeOnly="true"
                hourFormat="24"
                placeholder="시작시간입력 후 BOM입력"
                @hide="inputBomTime(a, 'start_time')"
                :inputStyle="{ 'text-align': 'center', height: '100%' }"
                @input="correct($event, i, 'start_time')"
              />
            </div>
            <div class="p-inputgroup mt-2" style="height: 20px" v-if="a.end_time">
              <span class="p-inputgroup-addon" style="font-size: 12px">종료</span>
              <Calendar
                v-model="a.end_time"
                :timeOnly="true"
                hourFormat="24"
                placeholder="시작시간입력 후 BOM입력"
                @hide="inputBomTime(a, 'end_time')"
                :inputStyle="{ 'text-align': 'center', height: '100%' }"
                @input="correct($event, i, 'end_time')"
              />
            </div>
          </div>
        </div>
        <div class="absolute" style="top: 0; right: 1%">
          <i class="pi pi-times cursor-pointer" @click="deleteRoom(a.id)"></i>
        </div>
      </template>
      <template #content>
        <div
          click="showBomInput($event, a)"
          class="mb-1"
          v-for="b in sugeryInitDataBody(a.id)"
          :key="b"
        >
          {{ b }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  name: 'ExistsSurgery',
};
</script>

<script setup>
import { useStore } from 'vuex';
import {
  onBeforeMount,
  ref,
  watchEffect,
  onBeforeUnmount,
  computed,
  getCurrentInstance,
} from 'vue';
import _ from 'lodash';
import Swal from 'sweetalert2';

const initData = ref({});
const initDataCompare = ref({});
const initDataTitle = ref([]);
const initDataBody = ref({});
const storeComputed = computed(() => store.getters);
const initProcessingDataCompare = ref({});

const store = useStore();
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $formatDate = app.appContext.config.globalProperties.$formatDate;

function correct(e, i, indexValue) {
  const noNumberValue = e.target.value.replace(/[^\d]|\s/g, '');
  if (noNumberValue.length === 4) {
    initDataTitle.value[i][indexValue] = noNumberValue.replace(
      /\B(?=(\d{2})+(?!\d))/g,
      ':'
    );
  }
}

async function inputBomTime(a, sort) {
  const is = /^\d{2}:\d{2}$/.test(sort === 'start_time' ? a.start_time : a.end_time);

  const sendData = {
    time: is
      ? sort === 'start_time'
        ? a.start_time
        : a.end_time
      : $formatDate('time', sort === 'start_time' ? a.start_time : a.end_time),
    id: a.id,
    sort,
  };

  if (a.start_time) {
    await store.dispatch('surgeryTime', sendData);
  }
}

onBeforeMount(async () => {
  const timeoutData = {
    name: 'modal-surgery-room',
    url: '/registration-modal/today_surgery_info?code=$customer_id&date=$boardDate',
    module: 'surgeryStore',
    compareData: 'sugeryData',
  };

  store.dispatch('timeoutCommonFunc', timeoutData);
});

onBeforeUnmount(() => {
  store.commit('deleteTimeout', 'modal-surgery-room');
});

watchEffect(() => {
  surgeryInit();
  const result = store.state.surgeryStore.sugeryData;
  result && surgeryPrevious();
});

async function surgeryPrevious() {
  try {
    const oldData = initDataCompare.value;
    const newData = store.state.surgeryStore.sugeryData;
    if (!_.isEqual({ ...oldData }, { ...newData })) {
      initData.value = _.cloneDeep();
      initDataCompare.value = _.cloneDeep(store.state.surgeryStore.sugeryData);

      if (newData.result) {
        const specialDate = newData.result.map((a) => a.sugery_date);
        const specialUniq = _.uniq(specialDate);
        store.commit('boardSpecialDay1', specialUniq);
      }
    }
  } catch (error) {
    $errorHandling(null, error, clearFunc);
  }
}

function clearFunc() {
  store.commit('boardModalStateFalse');
}

function surgeryInit() {
  if (
    !_.isEqual(
      { ...initProcessingDataCompare.value },
      { ...storeComputed.value.sugeryInit }
    )
  ) {
    const initProcessingData = storeComputed.value.sugeryInit;
    initProcessingDataCompare.value = _.cloneDeep(storeComputed.value.sugeryInit);
    initDataTitle.value = initProcessingData.initDataTitle;
    initDataBody.value = initProcessingData.initDataBody;
  }
}

const sugeryInitDataBody = (key) => {
  return initDataBody.value[key];
};

function showBomInput(e, a) {
  if (a.start_time) {
    store.commit('surgeryBomId', e.target.closest('.sursery-card').dataset.id);
    store.commit('surgeryStateUpdate');
  } else {
    Swal.fire('시작시간을 먼저 입력해주세요.', '', 'warning');
  }
}

function deleteRoom(key) {
  store.dispatch('sugeryRoomDelete', key);
}
</script>

<style></style>
