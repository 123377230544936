<template>
  <Toast position="top-right" group="surgery-bom-modal" />

  <Sidebar
    v-model:visible="visibleLeft"
    :baseZIndex="10000"
    class="w-9"
    @hide="stateFalse"
  >
    <template #header>
      <div class="absolute top-0 left-0 ml-5">
        <h4>BOM 선택</h4>
      </div>
    </template>
    <div>
      <DataTable
        :value="listBom"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
        :row-class="ticketingRowClass"
        :rowHover="true"
        v-model:selection="selectedMenu"
        v-model:filters="filters1"
        @row-select="checkSelectedMenu($event)"
        @row-unselect="checkUnSelectedMenu($event)"
        @row-select-all="checkAllSelect($event)"
        @row-unselect-all="checkAllUnSelect($event)"
        removableSort
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 30]"
        currentPageReportTemplate="전체 {totalRecords}개 중 {first}-{last}"
      >
        <template #header>
          <div class="flex justify-content-between">
            <div>
              <!-- <Dropdown
                v-model="largeSelected"
                :options="large"
                optionLabel="large_category"
                optionValue="large_category"
                placeholder="공통 등록 BOM"
                @change="largeSelect($event)"
                class="mr-2"
              /> -->
              <InputText
                type="text"
                v-model="filters1['global'].value"
                placeholder="검색어 입력"
              />
            </div>
            <Button label="선택완료" class="p-button-sm" @click="selectedBom" />
          </div>
        </template>
        <Column selectionMode="multiple"></Column>
        <Column
          field="counseling_date"
          header="상담날짜"
          :style="{ minWidth: '8rem' }"
          :sortable="true"
          v-if="headerState('sort')"
        >
          <template #body="{ data }">
            {{ data.counseling_date }}
          </template>
        </Column>
        <Column field="menu_name" header="구분" :sortable="true">
          <template #body="{ data }">
            {{ data.menu_name }}
          </template>
        </Column>
        <Column field="abbreviation" header="약어" :sortable="true">
          <template #body="{ data }">
            {{ data.abbreviation }}
          </template>
        </Column>
        <Column field="medical_menu_content" header="내용">
          <template #body="{ data }">
            {{ data.medical_menu_content }}
          </template>
        </Column>
        <Column field="note" header="비고">
          <template #body="{ data }">
            {{ data.note }}
          </template>
        </Column>
        <Column header="수가가격" headerStyle="color:gray">
          <template #body="{ data }">
            {{ changeCurrency(data.price_include_vat) }}
          </template>
        </Column>
        <Column
          header="판매가"
          headerStyle="color:gray"
          :style="{ minWidth: '6rem' }"
          v-if="headerState('sales_price')"
        >
          <template #body="{ data }">
            <div class="flex justify-content-center align-items-center relative">
              {{ changeCurrency(data.sales_price) }}
              <Badge
                :value="data.is_paid"
                v-if="data.is_paid === '부분납부' || data.is_paid === '미납'"
                :severity="
                  data.is_paid === '부분납부'
                    ? 'warning'
                    : data.is_paid === '미납'
                    ? 'danger'
                    : ''
                "
                style="top: -110%"
                class="absolute"
              ></Badge>
            </div>
          </template>
        </Column>
        <Column
          header="할인율"
          headerStyle="color:gray"
          v-if="headerState('discount_rate')"
        >
          <template #body="{ data }">
            {{ data.discount_rate ? data.discount_rate + '%' : '' }}
          </template>
        </Column>
        <Column
          header="할인및서비스"
          headerStyle="color:gray"
          v-if="headerState('discount_name')"
        >
          <template #body="{ data }">
            {{ data.discount_name }}
          </template>
        </Column>
      </DataTable>
    </div>
  </Sidebar>
</template>

<script setup>
import { onBeforeMount, ref, watchEffect, getCurrentInstance, computed } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';

const toast = useToast();
const app = getCurrentInstance();
const $errorHandling = app.appContext.config.globalProperties.$errorHandling;
const $wonCurrency = app.appContext.config.globalProperties.$wonCurrency;

const store = useStore();
const getters = computed(() => store.getters);
const visibleLeft = ref(false);
// const large = ref([
//   {
//     large_category: '티케팅 내역',
//   },
//   {
//     large_category: '공통 등록 BOM',
//   },
//   {
//     large_category: `${store.state.surgeryStore.bomSelectedDocName}님 등록BOM`,
//   },
// ]);
// const largeSelected = ref();
const listBom = ref([]);
const selectedMenu = ref(null);
const filters1 = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
watchEffect(() => {
  visibleLeft.value = store.state.surgeryStore.bomMenuState;
  // if (!largeSelected.value || largeSelected.value === '티케팅 내역') {
  //   listBom.value = store.state.surgeryStore.docCustomerBomMenu
  //     ? store.state.surgeryStore.docCustomerBomMenu
  //     : [];
  // } else if (largeSelected.value === '공통 등록 BOM') {
  listBom.value = store.state.surgeryStore.commonBomMenu
    ? store.state.surgeryStore.commonBomMenu
    : [];
  if (listBom.value?.length > 0) {
    removeSelectedBom();
  }
  // } else {
  //   listBom.value = store.state.surgeryStore.docWholeBomMenu
  //     ? store.state.surgeryStore.docWholeBomMenu
  //     : [];
  // }
});

onBeforeMount(async () => {
  try {
    await store.dispatch('surgeryBomMenuLoad');
    // listBom.value = store.state.surgeryStore.docCustomerBomMenu
    //   ? store.state.surgeryStore.docCustomerBomMenu
    //   : [];
    listBom.value = store.state.surgeryStore.commonBomMenu
      ? store.state.surgeryStore.commonBomMenu
      : [];

    if (listBom.value?.length > 0) {
      removeSelectedBom();
    }
  } catch (error) {
    $errorHandling(toast, error, clearFunc);
  }
});

function clearFunc() {
  store.commit('boardModalStateFalse');
}

function stateFalse() {
  store.commit('surgeryBomMenuState');
  store.commit('surgeryBomDataReset');
  store.commit('surgerylargeSelectIndex', '');
}

function removeSelectedBom() {
  if (store.state.surgeryStore.surgeryFrontDataTable?.length > 0) {
    const filteredArray = store.state.surgeryStore.commonBomMenu.filter((a) => {
      const uniqueMenuIds = _.uniq(
        _.map(store.state.surgeryStore.surgeryFrontDataTable, 'menu_id')
      );
      return !uniqueMenuIds.includes(a.menu_id);
    });
    store.state.surgeryStore.commonBomMenu = _.cloneDeep(filteredArray);
  }
}

const headerState = (column) => {
  if (listBom.value[0]) {
    return listBom.value[0][column] === 0 ? '0' : listBom.value[0][column];
  } else {
    return false;
  }
};

const changeCurrency = (value) => {
  return $wonCurrency(value);
};

function ticketingRowClass(rowData) {
  if (rowData.counseling_date !== null) {
    return 'text-blue-500 font-semibold';
  }
  return '';
}

function selectedBom() {
  getters.value.surgerySelectedSubFilter;
  store.commit('selectedIndexReset');
}

function checkSelectedMenu(e) {
  const index = _.findIndex([...listBom.value], { ...e.data });
  store.commit('sugerySideSelected', index);
}

function checkUnSelectedMenu(e) {
  const index = _.findIndex([...listBom.value], { ...e.data });
  store.commit('sugerysideUnSelected', index);
}

function checkAllSelect(e) {
  store.commit('sugerySideSelected', _.range(0, e.data.length));
}

function checkAllUnSelect(e) {
  store.commit('sugerysideUnSelected', _.range(0, selectedMenu.value.length));
}
</script>
<script>
export default {
  name: 'BomMenuSideVar',
};
</script>

<style></style>
